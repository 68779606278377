import React from "react";

// Components
import { Stack, Button, Typography } from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { UpdateOrgDetailCardProps } from "./UpdateOrgModal";
import { useMutation } from "@tanstack/react-query";
import { adminUpdateOrgData } from "lib-frontend/modules/AxiosInstance";
import { isFQDN } from "validator";

export const UpdateGroupProvisioningForm = ({
  orgId,
  card,
  setRerenderKey,
}: UpdateOrgDetailCardProps): React.ReactElement => {
  const [domain, setDomain] = React.useState<string>("");

  const addDomainMutation = useMutation({
    mutationFn: async () =>
      adminUpdateOrgData(orgId, {
        auto_user_creation_allowed_domains: [...card.items[0].data, domain],
      }),
    onSuccess: () => {
      setDomain("");
      setRerenderKey((prev) => prev + 1);
    },
  });

  const deleteDomainMutation = useMutation({
    mutationFn: async (index) =>
      adminUpdateOrgData(orgId, {
        auto_user_creation_allowed_domains: card.items[0].data.filter((_, i) => i !== index),
      }),
    onSuccess: () => {
      setRerenderKey((prev) => prev + 1);
    },
  });

  // Domain is a valid format and not already in the list
  const isValidDomain = isFQDN(domain) && !card.items[0].data.includes(domain);

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
        <YoodliTextfield
          label="Domain"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
        />
        <Button disabled={!isValidDomain} onClick={() => addDomainMutation.mutate()}>
          Add
        </Button>
      </Stack>
      <Stack>
        {card.items[0].data.map((domain, index) => (
          <Stack
            key={index}
            direction="row"
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Typography>{domain}</Typography>
            <Button onClick={() => deleteDomainMutation.mutate(index)}>Delete</Button>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
