// Utils
import { getDynamicColor } from "../../utils/Colors";

export const DEFAULT_DATE_PICKER_WRAPPER_STYLES = {
  width: "100%",
  // select first child and set width to 100%

  "& .react-aria-DateRangePicker": {
    "& .control-button": {
      width: "100%",
      justifyContent: { xs: "space-between", sm: "center" },
      "&:focus-visible": {
        outline: "none !important",
      },
    },
    "& .react-aria-Group": {
      minWidth: 220,
    },

    "& [slot=start] + span": {
      padding: "0 4px",
    },

    "& [slot=end]": {
      marginRight: "2rem",
      flex: 1,
    },

    "& .react-aria-DateInput": {
      width: "unset",
      minWidth: "unset",
      padding: "unset",
      border: "unset",
      outline: "unset",
    },
  },
  "& .react-aria-Popover[data-trigger=DateRangePicker]": {
    maxWidth: "unset",
  },
};

export const DEFAULT_CALENDAR_STYLES = {
  fontFamily: "poppins",
  color: getDynamicColor("purple3"),
  "& .month-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    gap: "8px",
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "inherit",
    "& .month-button": {
      svg: {
        height: 20,
        width: 20,
      },
      height: 32,
      width: 32,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      "&:focus-visible": {
        outline: "none !important",
      },
      "&[data-disabled]": {
        cursor: "default",
      },
    },
  },
  "& .range-calendar": {
    width: "100%",
    td: {
      p: 0,
    },
    table: {
      borderSpacing: "0px !important",
    },
  },

  "& .react-aria-CalendarGridBody": {
    borderSpacing: "0px !important",
  },

  "& .react-aria-CalendarCell": {
    height: 32,
    width: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontFamily: "inherit",
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
    "&:focus-visible": {
      outline: "none !important",
    },
    "&[data-selected]": {
      backgroundColor: getDynamicColor("secondaryLight"),
      color: getDynamicColor("purple3"),
    },

    "&[data-disabled]": {
      opacity: 0.3,
      cursor: "default",
    },
    "&[data-selection-start]": {
      backgroundColor: getDynamicColor("primary"),
      color: `${getDynamicColor("light1")} !important`,
      border: `4px solid ${getDynamicColor("secondaryLight")}`,
      borderRadius: "100%",
      borderTopRightRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    "&[data-selection-end]": {
      backgroundColor: getDynamicColor("primary"),
      color: `${getDynamicColor("light1")} !important`,
      border: `4px solid ${getDynamicColor("secondaryLight")}`,
      borderRadius: "100%",
      borderTopLeftRadius: "0px !important",
      borderBottomLeftRadius: "0px !important",
    },

    "&:hover:not([data-disabled])": {
      backgroundColor: getDynamicColor("primary"),
      border: `4px solid ${getDynamicColor("secondaryLight")}`,
      color: "white !important",
      borderRadius: "100%",
    },
    "&[data-focused]": {
      backgroundColor: getDynamicColor("primary"),
      color: getDynamicColor("light1"),
    },
  },
  "& .react-aria-CalendarHeaderCell": {
    fontSize: "14px !important",
    color: getDynamicColor("dark5"),
    fontWeight: 500,
    fontFamily: "inherit",
  },
};
