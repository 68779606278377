import type { DateTime } from "luxon";
import React, { useRef } from "react";
import { useNavigate } from "react-router";

// Components
import { Close as CloseIcon } from "@mui/icons-material";
import { Warning as WarningIcon } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Fade,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import CreateOrgSectionSelect from "components/Admin/CreateOrgSectionSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";
import { YoodliSwitch } from "lib-frontend/components/YoodliSwitch";

// Utils
import { OrgTableRow } from "./AdminConfigOrgs";
import {
  useValidatePositiveInt,
  useValidateDate,
  useValidateHubSpotCompanyIdForNewOrgSync,
  useValidateHubSpotDealIdForNewOrgSync,
  useValidateUserEmailSync,
  useValidateNumberInRangeInclusive,
} from "auth/utils/validator";
import { useNavDrawerOffset } from "lib-frontend/hooks";
import {
  adminUpdateOrgData,
  createOrgV2,
  createOrgUserV2,
  adminCreateHubSpotYoodliInstance,
  adminGetHubSpotCompanyById,
  adminGetHubSpotDealById,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { toTitleCase } from "lib-frontend/utils/Utilities";
import {
  AdminCreateHubSpotYoodliInstanceRequest,
  AdminUpdateOrgRequest,
  AdminUpdateOrgSubscriptionRequestType,
} from "lib-fullstack/api/adminApiTypes";
import { CreateOrgV2Request } from "lib-fullstack/api/orgApiTypes";
import { WebServerInternalPath } from "utils/paths";
import { getEnumKey } from "utils/Utilities";
import {
  HUBSPOT_COMPANY_MODEL_ID,
  HUBSPOT_DEAL_MODEL_ID,
  HUBSPOT_NOOP_COMPANY_ID,
  HUBSPOT_NOOP_DEAL_ID,
} from "lib-fullstack/utils/constants";
import { getAppEnv, getHubSpotFrontendEnvConfig } from "lib-fullstack/client_env";
import {
  OrgRole,
  OrgCustomerType,
  OrgSubscriptionType,
  OrgCreationMethod,
} from "lib-fullstack/utils/enums";
import { OrgSubscriptionTypeLabel, OrgCustomerTypeLabel } from "utils/Enums";

const hubSpotEnv = getHubSpotFrontendEnvConfig(getAppEnv());

type UpdateModalProps = {
  orgList: OrgTableRow[];
  open: boolean;
  close: () => void;
};

export const CreateOrgModal = ({ close, open, orgList }: UpdateModalProps): React.ReactElement => {
  const navigate = useNavigate();
  const { modalStyles } = useNavDrawerOffset();
  const [orgName, setOrgName] = React.useState<string>("");
  const [closeOrgNames, setCloseOrgNames] = React.useState<string[]>([]);
  const [customerType, setCustomerType] = React.useState<OrgCustomerType>(undefined);
  const [userOwned, setUserOwned] = React.useState<boolean>(true);
  const [ownerEmail, setOwnerEmail] = React.useState<string>("");
  const [adminEmail, setAdminEmail] = React.useState<string>("");
  const [hubSpotDealId, setHubSpotDealId] = React.useState<string>("");
  const [hubSpotDealName, setHubSpotDealName] = React.useState<string>("");
  const [hubSpotCompanyId, setHubSpotCompanyId] = React.useState<string>("");
  const [hubSpotCompanyName, setHubSpotCompanyName] = React.useState<string>("");
  const [loadingText, setLoadingText] = React.useState<string>("");
  const [subscriptionType, setSubscriptionType] = React.useState<OrgSubscriptionType>(
    OrgSubscriptionType.PREPAID
  );
  const [licensedSeats, setLicensedSeats] = React.useState<string>("5");
  const [licenseEndDate, setLicenseEndDate] = React.useState<DateTime | null>(null);
  const [perSeatDollars, setPerSeatDollars] = React.useState<string>("15.00");

  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
  const [errorText, setErrorText] = React.useState<string>("");
  const [includeExampleGoals, setIncludeExampleGoals] = React.useState<boolean>(false);
  const ref = useRef(null);
  const timerRef = useRef(null);

  const handleOrgNameChange = (event) => {
    const name = event.target.value;
    const newCloseOrgNames = orgList.filter((org) =>
      org.name.toLowerCase().startsWith(name.toLowerCase())
    );
    setOrgName(name);
    if (name.length > 0) {
      setCloseOrgNames(newCloseOrgNames.map((org) => org.name).slice(0, 9));
    } else {
      setCloseOrgNames([]);
    }
    setAnchorEl(ref.current || event.currentTarget);
    // timer to close the popper
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setAnchorEl(null);
    }, 2000);
  };

  const handleDealIdChange = async (event) => {
    const dealId = event.target.value;
    setHubSpotDealId(dealId);
    const deal = await adminGetHubSpotDealById(dealId);
    console.log("deal", deal);
    if (deal?.dealName) {
      setHubSpotDealName(deal?.dealName);
    } else {
      setHubSpotDealName("");
    }
    if (deal?.companyId) {
      setHubSpotCompanyId(deal?.companyId);
      const company = await adminGetHubSpotCompanyById(deal?.companyId);
      if (company?.companyName) {
        setHubSpotCompanyName(company?.companyName);
      }
    }
  };

  const handleCompanyIdChange = async (event) => {
    const companyId = event.target.value;
    setHubSpotCompanyId(companyId);
    const company = await adminGetHubSpotCompanyById(companyId);
    console.log("company", company);
    if (company?.companyName) {
      setHubSpotCompanyName(company?.companyName);
    } else {
      setHubSpotCompanyName("");
    }
  };

  const errors = {
    ownerEmail: useValidateUserEmailSync(ownerEmail, !userOwned),
    adminEmail: useValidateUserEmailSync(adminEmail, false),
    licensedSeats: useValidatePositiveInt(licensedSeats),
    licenseEndDate: useValidateDate(licenseEndDate),
    orgName:
      orgName.trim().length === 0
        ? "Org name cannot be empty"
        : orgList.length > 0 &&
            orgList.some((org) => org.name.toLowerCase() === orgName.toLowerCase())
          ? "Org name already exists"
          : "",
    hubSpotDealId: useValidateHubSpotDealIdForNewOrgSync(hubSpotDealId, hubSpotCompanyId),
    hubSpotCompanyId: useValidateHubSpotCompanyIdForNewOrgSync(hubSpotCompanyId),
    // prettier-ignore
    perSeatDollars: useValidateNumberInRangeInclusive(perSeatDollars, 0.01, 20.00),
  };

  const isFormValid = () => {
    if (customerType === undefined) {
      return false;
    }

    if (
      subscriptionType === OrgSubscriptionType.PREPAID &&
      (parseInt(licensedSeats) <= 0 || licenseEndDate === null)
    ) {
      return false;
    }

    if (!userOwned && ownerEmail.length === 0) {
      return false;
    }

    if (!hubSpotDealId || hubSpotDealId.length === 0) {
      return false;
    }

    return ![...Object.values(errors)].some((error) =>
      typeof error === "string" ? !!error : !!error.error
    );
  };

  const handleToggleOwnerEmail = () => {
    setUserOwned((prev) => !prev);
  };

  const handleCreateOrg = async () => {
    setErrorText("");
    let newOrgId: string;
    try {
      try {
        setLoadingText("Creating organization...");
        const params: CreateOrgV2Request = {
          name: orgName,
          customer_type: customerType,
          subscription_type: subscriptionType,
          data_redaction_days: 0,
          owner_id: errors.ownerEmail.user?.id,
          org_creation_method: OrgCreationMethod.SALES,
          include_example_goals: includeExampleGoals,
        };
        const newOrg = await createOrgV2(params);
        newOrgId = newOrg.id;
      } catch (e) {
        console.log("createOrgV2 failure", e);
        setErrorText(`Error creating organization in Yoodli: ${e}`);
        return;
      }

      if ([OrgSubscriptionType.PREPAID, OrgSubscriptionType.FLEXIBLE].includes(subscriptionType)) {
        try {
          setLoadingText("Creating Yoodli instance in HubSpot...");
          const request: AdminCreateHubSpotYoodliInstanceRequest = {
            orgId: newOrgId,
            companyId: hubSpotCompanyId,
            dealId: hubSpotDealId,
          };

          await adminCreateHubSpotYoodliInstance(request);
        } catch (e) {
          console.log("adminCreateHubSpotYoodliInstance failure ", e);
          setErrorText(`Error creating Yoodli instance in HubSpot: ${e}`);
          return;
        }

        try {
          setLoadingText("Updating subscription info...");
          const request: AdminUpdateOrgRequest = {
            subscription: {
              requestType: AdminUpdateOrgSubscriptionRequestType.UPDATE_LICENSE,
              seatsLicensed: parseInt(licensedSeats),
              licenseEndDate: licenseEndDate.toISOString(),
              perSeatDollars: parseFloat(perSeatDollars),
            },
            features: {},
          };
          await adminUpdateOrgData(newOrgId, request);
        } catch (e) {
          console.log("adminUpdateOrgData failure ", e);
          setErrorText(`Error updating subscription info: ${e}`);
          return;
        }
      }

      if (errors.adminEmail.user) {
        try {
          setLoadingText("Adding admin...");
          await createOrgUserV2(newOrgId, {
            emails: [errors.adminEmail.user.email],
            org_role: OrgRole.ADMIN,
            hub_role: null,
            hub_ids: null,
            send_invite_email: true,
          });
        } catch (e) {
          console.log("createOrgUserV2 failure", e);
          setErrorText(`Error adding admin to organization: ${e}`);
          return;
        }
      }
    } finally {
      setLoadingText("");
    }
    navigate(`${WebServerInternalPath.ADMIN_CONFIG}/${newOrgId}`);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      PaperProps={{
        sx: {
          p: 3,
          borderRadius: "12px",
          width: { xs: "100%", sm: "min(100%, 500px)", lg: "min(100%, 600px)" },
          maxWidth: { xs: "calc(100% - 24px) !important", md: "calc(100% - 48px) !important" },
          maxHeight: { xs: "calc(100% - 24px) !important", md: "calc(100% - 48px) !important" },
          m: { xs: 1.5, md: 3 },
          ...modalStyles,
        },
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          zIndex: 2,
          top: 12,
          right: 12,
          p: 0.5,
        }}
        onClick={close}
      >
        <CloseIcon
          sx={{
            width: 18,
            height: 18,
          }}
        />
      </IconButton>
      <Stack
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          component="h2"
          sx={{
            fontFamily: "poppins",
            fontWeight: 600,
            fontSize: { xs: 16, md: 18 },
            width: "100%",
            textAlign: "center",
          }}
        >
          Create new organization
        </Typography>

        <Stack width="100%" gap={2} p={3}>
          {errorText && (
            <Typography p={1} color="error" fontWeight={600} fontSize="12px">
              {errorText}
            </Typography>
          )}
          <form
            style={{ width: "100%" }}
            onSubmit={async (e) => {
              e.preventDefault();
              await handleCreateOrg();
            }}
          >
            <Stack gap={3}>
              <Stack>
                <InputLabel
                  htmlFor="org-name-input"
                  sx={{ pb: 0.5, ml: 2, fontFamily: "poppins", fontSize: "16px", fontWeight: 500 }}
                >
                  Org Name
                </InputLabel>
                <YoodliTextfield
                  id="org-name-input"
                  type="text"
                  placeholder="Name of organization"
                  error={!!errors.orgName}
                  helperText={errors.orgName}
                  value={orgName}
                  onChange={(e) => handleOrgNameChange(e)}
                  maxChars={40}
                  InputProps={{
                    sx: {
                      fontSize: "16px",
                      fontWeight: 500,
                    },
                  }}
                />
              </Stack>
              {closeOrgNames.length > 0 && (
                <Popper open={!!anchorEl} anchorEl={anchorEl} style={{ zIndex: 3000 }} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={1000 / 4}>
                      <Box
                        sx={{
                          padding: "10px 20px 10px 20px",
                          borderRadius: "10px",
                          backgroundColor: getDynamicColor("dark1"),
                        }}
                      >
                        <Stack>
                          {closeOrgNames.map((orgName, i) => (
                            <Typography
                              key={i}
                              sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                fontFamily: "poppins",
                                color: getDynamicColor("redError"),
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                p: 1,
                              }}
                            >
                              <WarningIcon
                                sx={{
                                  color: getDynamicColor("redError"),
                                  height: 14,
                                  width: 14,
                                  mr: 1,
                                }}
                              />
                              {orgName}
                            </Typography>
                          ))}
                        </Stack>
                      </Box>
                    </Fade>
                  )}
                </Popper>
              )}
              <Stack>
                <InputLabel
                  htmlFor="customer-type-select"
                  sx={{ pb: 0.5, ml: 2, fontFamily: "poppins", fontSize: "16px", fontWeight: 500 }}
                >
                  Customer Type
                </InputLabel>
                <CreateOrgSectionSelect
                  value={customerType}
                  onChange={(e) => {
                    setCustomerType(e.target.value as OrgCustomerType);
                  }}
                  renderValue={(value) => {
                    if (!value) {
                      return <>Category</>;
                    }
                    const customerTypeKey = getEnumKey(OrgCustomerType, value);
                    return <>{toTitleCase(OrgCustomerTypeLabel[customerTypeKey])}</>;
                  }}
                >
                  <Typography
                    sx={{
                      px: 3,
                      pb: 2,
                      color: getDynamicColor("purple3"),
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Customer Type
                  </Typography>
                  {Object.entries(OrgCustomerTypeLabel).map(([key, type]) => {
                    return (
                      <MenuItem
                        key={key}
                        value={OrgCustomerType[key]}
                        sx={{
                          px: 3,
                          color: getDynamicColor("purple3"),
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        {type}
                      </MenuItem>
                    );
                  })}
                </CreateOrgSectionSelect>
              </Stack>
              <Stack display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <Typography
                  sx={{
                    fontSize: 16,
                    fontFamily: "poppins",
                    fontWeight: 500,
                    pr: 2,
                    width: "fit-content",
                  }}
                >
                  Set current user as Org Owner
                </Typography>
                <YoodliSwitch checked={userOwned} onClick={handleToggleOwnerEmail} />
              </Stack>
              {!userOwned && (
                <Stack>
                  <InputLabel
                    htmlFor="owner-email-input"
                    sx={{
                      pb: 0.5,
                      ml: 2,
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Owner Email
                  </InputLabel>
                  <YoodliTextfield
                    type="text"
                    placeholder="Owner Email"
                    id="owner-email-input"
                    value={ownerEmail}
                    error={!!errors.ownerEmail.error}
                    helperText={errors.ownerEmail.error}
                    onChange={(e) => setOwnerEmail(e.target.value)}
                    fullWidth
                    InputProps={{
                      sx: {
                        fontSize: "16px",
                        fontWeight: 500,
                      },
                    }}
                  />
                </Stack>
              )}
              <Stack>
                <InputLabel
                  htmlFor="admin-email-input"
                  sx={{
                    pb: 0.5,
                    ml: 2,
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Admin Email
                </InputLabel>
                <YoodliTextfield
                  type="text"
                  placeholder="(Optional) Add an admin email"
                  id="admin-email-input"
                  value={adminEmail}
                  onChange={(e) => setAdminEmail(e.target.value)}
                  error={!!errors.adminEmail.error}
                  helperText={errors.adminEmail.error}
                  fullWidth
                  InputProps={{
                    sx: {
                      fontSize: "16px",
                      fontWeight: 500,
                    },
                  }}
                />
              </Stack>
              <Stack>
                <Stack direction="row">
                  <InputLabel
                    htmlFor="hubspot-deal-id-input"
                    sx={{
                      pb: 0.5,
                      ml: 2,
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    <a
                      href={`https://app.hubspot.com/contacts/${hubSpotEnv.portalId}/objects/${HUBSPOT_DEAL_MODEL_ID}/views/all/list`}
                      target="_blank"
                    >
                      HubSpot Deal ID
                    </a>
                  </InputLabel>
                  <YoodliTooltip
                    title={
                      'HubSpot deal ID is the "Record ID" property on the ' +
                      "HubSpot deal and the number at the end of the deal URL. " +
                      "For development and testing purposes, you can use " +
                      `"${HUBSPOT_NOOP_DEAL_ID}" as the HubSpot deal ID.`
                    }
                  >
                    <InfoOutlinedIcon sx={{ width: "16x" }} />
                  </YoodliTooltip>
                </Stack>
                <YoodliTextfield
                  type="text"
                  placeholder="HubSpot deal ID"
                  id="hubspot-deal-id-input"
                  value={hubSpotDealId}
                  onChange={handleDealIdChange}
                  error={!!errors.hubSpotDealId}
                  helperText={errors.hubSpotDealId}
                  fullWidth
                  InputProps={{
                    sx: {
                      fontSize: "16px",
                      fontWeight: 500,
                    },
                  }}
                />
                <Typography
                  sx={{
                    color: getDynamicColor("purple3"),
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  {hubSpotDealName}
                </Typography>
              </Stack>
              <Stack>
                <Stack direction="row">
                  <InputLabel
                    htmlFor="hubspot-company-id-input"
                    sx={{
                      pb: 0.5,
                      ml: 2,
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    <a
                      href={`https://app.hubspot.com/contacts/${hubSpotEnv.portalId}/objects/${HUBSPOT_COMPANY_MODEL_ID}/views/all/list`}
                      target="_blank"
                    >
                      HubSpot Company ID
                    </a>
                  </InputLabel>
                  <YoodliTooltip
                    title={
                      'HubSpot company ID is the "Record ID" property on the ' +
                      "HubSpot company and the number at the end of the company URL." +
                      "For development and testing purposes, you can use " +
                      `"${HUBSPOT_NOOP_COMPANY_ID}" as the HubSpot deal ID.`
                    }
                  >
                    <InfoOutlinedIcon sx={{ width: "16x" }} />
                  </YoodliTooltip>
                </Stack>
                <YoodliTextfield
                  type="text"
                  placeholder="HubSpot company ID"
                  id="hubspot-company-id-input"
                  value={hubSpotCompanyId}
                  onChange={handleCompanyIdChange}
                  error={!!errors.hubSpotCompanyId}
                  helperText={errors.hubSpotCompanyId}
                  fullWidth
                  InputProps={{
                    sx: {
                      fontSize: "16px",
                      fontWeight: 500,
                    },
                  }}
                />
                <Typography
                  sx={{
                    color: getDynamicColor("purple3"),
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  {hubSpotCompanyName}
                </Typography>
              </Stack>
              <Stack>
                <InputLabel
                  htmlFor="org-subscription-type-select"
                  sx={{
                    pb: 0.5,
                    ml: 2,
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Subscription Type
                </InputLabel>
                <CreateOrgSectionSelect
                  value={subscriptionType}
                  onChange={(e) => {
                    setSubscriptionType(e.target.value as OrgSubscriptionType);
                  }}
                  renderValue={(value) => {
                    if (!value) {
                      return <>Category</>;
                    }
                    const subscriptionTypeKey = getEnumKey(OrgSubscriptionType, value);
                    return <>{toTitleCase(OrgSubscriptionTypeLabel[subscriptionTypeKey])}</>;
                  }}
                >
                  <Typography
                    sx={{
                      px: 3,
                      pb: 2,
                      color: getDynamicColor("purple3"),
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Stripe Subscription Type
                  </Typography>
                  {Object.entries(OrgSubscriptionTypeLabel).map(([key, type]) => {
                    return (
                      <MenuItem
                        key={key}
                        value={OrgSubscriptionType[key]}
                        sx={{
                          px: 3,
                          color: getDynamicColor("purple3"),
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        {type}
                      </MenuItem>
                    );
                  })}
                </CreateOrgSectionSelect>
              </Stack>
              {[OrgSubscriptionType.PREPAID, OrgSubscriptionType.FLEXIBLE].includes(
                subscriptionType
              ) && (
                <Stack gap={3}>
                  <Stack>
                    <InputLabel
                      htmlFor="seats-licensed-select"
                      sx={{
                        pb: 0.5,
                        ml: 2,
                        fontFamily: "poppins",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Number of seats to license (base for Flexible)
                    </InputLabel>
                    <YoodliTextfield
                      id="seats-licensed-select"
                      type="number"
                      placeholder="Number of seats to license"
                      value={licensedSeats}
                      required={true}
                      onChange={(e) => setLicensedSeats(e.target.value)}
                      error={!!errors.licensedSeats}
                      helperText={errors.licensedSeats}
                      fullWidth
                      InputProps={{
                        sx: {
                          fontSize: "16px",
                          fontWeight: 500,
                        },
                      }}
                    />
                  </Stack>
                  <Stack>
                    <InputLabel
                      htmlFor="license-end-date-select"
                      sx={{
                        pb: 0.5,
                        ml: 2,
                        fontFamily: "poppins",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Subscription License End Date
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us">
                      <DatePicker
                        disablePast
                        slotProps={{
                          textField: {
                            required: true,
                            helperText: errors.licenseEndDate,
                            error: !!errors.licenseEndDate,
                            InputProps: {
                              sx: {
                                color: getDynamicColor("purple3"),
                                fontFamily: "poppins",
                                fontSize: "16px",
                                fontWeight: 500,
                              },
                            },
                          },
                        }}
                        value={licenseEndDate}
                        onChange={setLicenseEndDate}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Stack>
              )}
              {subscriptionType === OrgSubscriptionType.FLEXIBLE && (
                <Stack gap={3}>
                  <Stack>
                    <InputLabel
                      htmlFor="per-seat-dollars-select"
                      sx={{
                        pb: 0.5,
                        ml: 2,
                        fontFamily: "poppins",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Price per an additional seat (USD)
                    </InputLabel>
                    <YoodliTextfield
                      id="per-seat-dollars-select"
                      type="number"
                      placeholder="Price for an additional seat"
                      value={perSeatDollars}
                      required={true}
                      onChange={(e) => setPerSeatDollars(e.target.value)}
                      error={!!errors.perSeatDollars}
                      helperText={errors.perSeatDollars}
                      fullWidth
                      InputProps={{
                        sx: {
                          fontSize: "16px",
                          fontWeight: 500,
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              )}
              <Stack>
                <Stack direction="row">
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontFamily: "poppins",
                      fontWeight: 500,
                      pr: 2,
                      width: "fit-content",
                    }}
                  >
                    Include example goals
                  </Typography>
                  <YoodliTooltip
                    title={
                      "Example goals are a set of pre-defined goals that are " +
                      "automatically created for the organization. These goals " +
                      'are "Understand the Cost of Inaction", "Understanding the ' +
                      'Current State", and "Setting the Agenda".'
                    }
                  >
                    <InfoOutlinedIcon sx={{ width: "16x" }} />
                  </YoodliTooltip>
                  <YoodliSwitch
                    checked={includeExampleGoals}
                    onClick={() => setIncludeExampleGoals(!includeExampleGoals)}
                  />
                </Stack>
              </Stack>
            </Stack>
            <input type="submit" style={{ display: "none" }} />
          </form>
        </Stack>
        <Button
          startIcon={
            loadingText.length > 0 ? (
              <CircularProgress
                size={20}
                sx={{
                  color: getDynamicColor("dark1"),
                }}
              />
            ) : (
              <></>
            )
          }
          onClick={handleCreateOrg}
          variant="gradient"
          disabled={!isFormValid() || !!loadingText}
          sx={{
            fontFamily: "poppins",
            fontSize: "16px",
            marginTop: 3,
            fontWeight: 700,
            "&:disabled": {
              color: getDynamicColor("light1"),
              background: getDynamicColor("dark4"),
            },
          }}
        >
          {loadingText.length == 0 ? "Create your organization" : loadingText}
        </Button>
      </Stack>
    </Dialog>
  );
};
