import parse from "html-react-parser";
import React from "react";
import { useNavigate, useLocation } from "react-router";

// Components
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { Stack, Button, Typography, Divider, Checkbox } from "@mui/material";
import { GoalLabels } from "lib-fullstack/utils/defaultGoals";
import { TemplateSubTypeChip } from "components/ConvoScenarios/TemplateSubTypeChip";
import { YoodliAutocomplete } from "lib-frontend/components/YoodliComponents/YoodliAutocomplete";
import { YoodliSelectOption } from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Assets
import { ReactComponent as RubricIcon } from "images/icons/icon-scale.svg";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgProgramsQueryParams } from "lib-fullstack/utils/queryParams";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { ProgramScenarioStep } from "lib-fullstack/api/programApiTypes";
import { CustomGoalItemWithUsage } from "lib-fullstack/api/scenarioApiTypes";
import { ScenarioTemplateSubType } from "lib-fullstack/db";
import { PlanStepType } from "lib-fullstack/utils/enums";
import { isEqualDistribution } from "lib-fullstack/utils/isEqualWeightDistribution";

type ScenarioCompletionItemProps = {
  label: string;
  sublabel?: string;
  inputEl?: JSX.Element;
  checked: boolean;
  onChecked: (checked: boolean) => void;
  disabled?: boolean;
  tooltip?: string;
};

const ScenarioCompletionItem = ({
  label,
  sublabel,
  inputEl,
  checked,
  onChecked,
  disabled,
  tooltip,
}: ScenarioCompletionItemProps): JSX.Element => {
  return (
    <Stack direction="row" gap={3} sx={{ alignItems: "center", fontFamily: "poppins" }}>
      <Checkbox
        checked={checked}
        onChange={(e) => onChecked(e.target.checked)}
        disabled={disabled}
        sx={{ width: 20, height: 20, borderColor: getDynamicColor("dark4") }}
      />
      <Stack direction="column" sx={{ height: "100%", justifyContent: "space-between" }}>
        <Stack direction="row" sx={{ gap: 0.5, alignItems: "center" }}>
          <Typography
            sx={{
              fontWeight: 700,
              color: checked ? getDynamicColor("purple3") : getDynamicColor("dark4"),
              fontSize: "14px",
            }}
          >
            {label}
          </Typography>
          {tooltip && (
            <YoodliTooltip title={tooltip}>
              <InfoIcon sx={{ width: "16px", height: "16px" }} />
            </YoodliTooltip>
          )}
        </Stack>
        <Typography
          sx={{
            maxWidth: "180px",
            fontWeight: 500,
            fontSize: "10px",
            color: checked ? getDynamicColor("purple3") : getDynamicColor("dark4"),
            lineHeight: 1.2,
          }}
        >
          {sublabel}
        </Typography>
      </Stack>
      {inputEl && inputEl}
    </Stack>
  );
};

type EditProgramStepProps = {
  scenarios: GetScenarioResponse[];
  customGoals: CustomGoalItemWithUsage[];
  handleRemoveScenario: () => void;
  handleSaveScenario: (newStep: ProgramScenarioStep) => void;
  stepToEdit?: ProgramScenarioStep;
};

const DEFAULT_NUM_ATTEMPTS = 1;
const DEFAULT_COMPLETION_SCORE = 50;

export const EditProgramStep = ({
  scenarios,
  customGoals,
  handleRemoveScenario,
  handleSaveScenario,
  stepToEdit,
}: EditProgramStepProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const location = useLocation();
  const qp = new URLSearchParams(location.search);

  const scenarioOptions: YoodliSelectOption[] = scenarios.map((scenario) => ({
    label: scenario.title,
    value: scenario.id,
    labelTag: (
      <TemplateSubTypeChip templateSubType={scenario.templateSubType as ScenarioTemplateSubType} />
    ),
  }));
  const [selectedScenarioOption, setSelectedScenarioOption] = React.useState<YoodliSelectOption>(
    scenarioOptions.find((option) => option.value === stepToEdit?.scenario_id) ?? null
  );
  const [scenariosInputValue, setScenariosInputValue] = React.useState<string>("");
  const [numAttempts, setNumAttempts] = React.useState<number>(
    stepToEdit?.completion_attempts > 0 ? stepToEdit?.completion_attempts : undefined
  );
  const [completionScore, setCompletionScore] = React.useState<number>(
    stepToEdit?.completion_score > 0 ? stepToEdit?.completion_score : undefined
  );
  const [shareByEmail, setShareByEmail] = React.useState<boolean>(
    stepToEdit?.completion_share_by_email ?? false
  );

  const [manualGrading, setManualGrading] = React.useState<boolean>(
    stepToEdit?.completion_human_evaluation ?? false
  );

  const selectedScenario = scenarios.find(
    (scenario) => scenario.id === selectedScenarioOption?.value
  );

  React.useEffect(() => {
    if (
      qp.has(OrgProgramsQueryParams.SCENARIO_ID) &&
      scenarios.some((s) => s.id === qp.get(OrgProgramsQueryParams.SCENARIO_ID))
    ) {
      setSelectedScenarioOption(
        scenarioOptions.find(
          (option) => option.value === qp.get(OrgProgramsQueryParams.SCENARIO_ID)
        )
      );
      qp.delete(OrgProgramsQueryParams.SCENARIO_ID);
      navigate({ search: qp.toString() }, { replace: true });
    }
  }, [scenarios]);

  const getScenarioGoals = () => {
    return selectedScenario.goalIds
      .map((goalId) => {
        if (Object.keys(GoalLabels).includes(goalId)) {
          return GoalLabels[goalId];
        } else {
          return customGoals.find((goal) => goal.id === goalId)?.name;
        }
      })
      .join(", ");
  };

  const renderScenarioInfo = () => {
    return (
      <Stack
        gap={1}
        sx={{ color: getDynamicColor("dark5"), fontSize: "14px", fontFamily: "poppins" }}
      >
        {selectedScenario?.persona && (
          <Typography>
            <strong style={{ color: getDynamicColor("purple3") }}>Default persona: </strong>
            {selectedScenario.persona.name}, {selectedScenario.persona.job_title} (
            {selectedScenario.persona.demeanor})
          </Typography>
        )}
        <Typography>
          <strong style={{ color: getDynamicColor("purple3") }}>Goals: </strong>
          {selectedScenario.goalIds.length > 0 ? getScenarioGoals() : "None"}
        </Typography>
        {!isEqualDistribution(selectedScenario?.goalWeights) && (
          <Stack
            direction="row"
            sx={{
              gap: 1,
              alignItems: "center",
              fontSize: "10px",
              fontWeight: 600,
              color: getDynamicColor("purple3"),
            }}
          >
            <RubricIcon />
            <Typography>Custom weighted rubric</Typography>
          </Stack>
        )}
        <Typography component="div">
          <strong style={{ color: getDynamicColor("purple3") }}>Description: </strong>
          {parse(selectedScenario.description)}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack
      gap={4}
      sx={{
        borderRadius: "8px",
        border: `1px solid ${getDynamicColor("dark3")}`,
        px: 2,
        pt: 3,
        pb: { xs: 4, md: 5 },
      }}
    >
      <Stack
        gap={1}
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <YoodliAutocomplete
          placeholder="Choose a scenario from the list"
          value={selectedScenarioOption}
          disablePortal={false}
          onChange={(_, newValue: YoodliSelectOption | null) => setSelectedScenarioOption(newValue)}
          inputValue={scenariosInputValue}
          onInputChange={(_, newInputValue) => {
            setScenariosInputValue(newInputValue);
          }}
          options={scenarioOptions}
          sx={{
            height: "50px",
          }}
        />
        {!isSmallScreen && (
          <Stack gap={1} direction="row" sx={{ alignItems: "center" }}>
            <Button
              onClick={handleRemoveScenario}
              sx={{
                fontSize: "14px",
                width: "85px",
                height: "32px",
              }}
            >
              Remove
            </Button>
            <Button
              onClick={() =>
                handleSaveScenario({
                  type: PlanStepType.Scenario,
                  scenario_id: selectedScenarioOption?.value,
                  completion_score: completionScore ?? 0,
                  completion_attempts: numAttempts ?? 0,
                  completion_share_by_email: shareByEmail,
                  completion_human_evaluation: manualGrading,
                })
              }
              disabled={!selectedScenarioOption || (!numAttempts && !completionScore)}
              variant="contained"
              sx={{ fontSize: "14px", width: "85px", height: "32px" }}
            >
              Save
            </Button>
          </Stack>
        )}
      </Stack>
      <Stack gap={3}>
        {selectedScenario && renderScenarioInfo()}
        <Divider sx={{ borderColor: getDynamicColor("dark4") }} />
        <Stack gap={1}>
          <Typography
            sx={{ color: getDynamicColor("purple3"), fontFamily: "poppins", fontSize: "14px" }}
          >
            <strong>Completion: </strong>
            Use conditions to define when a member has completed their training
          </Typography>
        </Stack>
        <Stack
          gap={{ xs: 3, md: 7 }}
          direction={{ xs: "column", md: "row" }}
          sx={{ alignItems: { xs: "flex-start", md: "center" }, flexWrap: "wrap" }}
        >
          <ScenarioCompletionItem
            label="Attempts"
            sublabel="Number of times members need to practice"
            inputEl={
              <YoodliTextfield
                value={numAttempts ?? ""}
                sx={{ width: "100px" }}
                onChange={(e) => setNumAttempts(parseInt(e.target.value))}
                placeholder="e.g. 3"
                type="number"
                inputProps={{
                  min: "1",
                  max: "10",
                }}
              />
            }
            checked={numAttempts !== undefined}
            onChecked={(checked) => {
              if (completionScore === undefined) {
                setShareByEmail(false);
              }
              setNumAttempts(checked ? DEFAULT_NUM_ATTEMPTS : undefined);
            }}
          />
          <Typography sx={{ color: getDynamicColor("purple3"), fontWeight: 700 }}>or</Typography>
          <ScenarioCompletionItem
            label="Score (%)"
            sublabel="Members need to achieve on at least one practice"
            inputEl={
              <YoodliTextfield
                value={completionScore ?? ""}
                sx={{ width: "100px" }}
                onChange={(e) => setCompletionScore(parseInt(e.target.value))}
                placeholder="e.g. 75"
                type="number"
                inputProps={{
                  min: "1",
                  max: "100",
                }}
              />
            }
            checked={completionScore !== undefined}
            onChecked={(checked) => {
              if (numAttempts === undefined) {
                setShareByEmail(false);
              }
              setCompletionScore(checked ? DEFAULT_COMPLETION_SCORE : undefined);
            }}
          />
        </Stack>
        <Typography sx={{ color: getDynamicColor("purple3"), fontWeight: 700 }}>and</Typography>
        <Stack
          gap={{ xs: 3, md: 7 }}
          direction={{ xs: "column", md: "row" }}
          sx={{ alignItems: { xs: "flex-start", md: "center" }, flexWrap: "wrap" }}
        >
          <ScenarioCompletionItem
            label="Sharing"
            sublabel="Member shares their attempt"
            checked={shareByEmail}
            onChecked={(checked) => {
              if (!checked) {
                setManualGrading(false);
              }
              setShareByEmail(checked);
            }}
            disabled={!numAttempts && !completionScore}
          />
          <ScenarioCompletionItem
            label="Grading"
            tooltip="Require admin grading for this scenario. Members must share their attempt in order to be graded."
            sublabel="Require grader approval"
            checked={manualGrading}
            onChecked={(checked) => {
              setManualGrading(checked);
              if (!shareByEmail) {
                setShareByEmail(true);
              }
            }}
            disabled={!numAttempts && !completionScore}
          />
        </Stack>
        {completionScore === undefined && !isEqualDistribution(selectedScenario?.goalWeights) && (
          <Stack
            direction="row"
            sx={{
              gap: 1,
              alignItems: "center",
              fontSize: "10px",
              fontWeight: 600,
              color: getDynamicColor("dark4"),
            }}
          >
            <RubricIcon />
            <Typography>Custom weighted rubric does not apply</Typography>
          </Stack>
        )}
      </Stack>
      {isSmallScreen && (
        <Stack gap={1} direction="row" sx={{ justifyContent: "flex-end", alignItems: "center" }}>
          <Button
            onClick={handleRemoveScenario}
            sx={{
              fontSize: "14px",
              color: getDynamicColor("dark4"),
              width: "85px",
              height: "32px",
            }}
          >
            Remove
          </Button>
          <Button
            onClick={() => {
              handleSaveScenario({
                type: PlanStepType.Scenario,
                scenario_id: selectedScenarioOption?.value,
                completion_score: completionScore ?? 0,
                completion_attempts: numAttempts ?? 0,
                completion_share_by_email: shareByEmail,
                completion_human_evaluation: manualGrading,
              });
            }}
            disabled={!selectedScenarioOption || (!numAttempts && !completionScore)}
            variant="contained"
            sx={{ fontSize: "14px", width: "85px", height: "32px" }}
          >
            Save
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
