// copying general flow of LiveSiteDocs, assuming there may be subdocuments in site soon
// don't need to include unsub functionality since you can't change sites in the same session

import { db } from "lib-fullstack";
import _ from "lodash";

import { initializeFirebase } from "lib-frontend/modules/FirebaseModule";
import { SiteConfig } from "lib-fullstack/workspaces/SiteConfig";
import { getSiteIdBySubdomain } from "lib-fullstack/workspaces";
import { getSubdomainFromHostname } from "lib-fullstack/workspaces/subdomain";

interface ISiteDocs {
  siteId: string;
  main: db.Site;
  featureDiffs: db.FeatureDiffs;
  imageDiffs: db.ImageDiffs;
}

let siteDocs: ISiteDocs;

type SiteDocsChangeListener = (siteDocs: ISiteDocs) => void;
const siteDocChangeListeners: SiteDocsChangeListener[] = [];

export function registerSiteDocsChangeListener(callback: SiteDocsChangeListener): () => void {
  siteDocChangeListeners.push(callback);
  return () => {
    _.remove(siteDocChangeListeners, (e) => e === callback);
  };
}

function callSiteDocsChangeListeners() {
  siteDocChangeListeners.forEach((callback) => callback(siteDocs));
}

export function setUpSiteDocsListeners(): void {
  const launchSiteDocsListeners = async () => {
    const { subdomain } = getSubdomainFromHostname();
    const siteId = await getSiteIdBySubdomain(subdomain);

    const [featureDiffs, imageDiffs, main] = await Promise.all([
      db.get<db.FeatureDiffs>(db.siteDocs(siteId), db.SiteDocTypes.FEATURE_DIFFS),
      db.get<db.ImageDiffs>(db.siteDocs(siteId), db.SiteDocTypes.IMAGE_DIFFS),
      db.get<db.Site>(db.sites, siteId),
    ]);
    siteDocs = {
      siteId,
      featureDiffs: featureDiffs?.data,
      imageDiffs: imageDiffs?.data,
      main: main.data,
    };
    callSiteDocsChangeListeners();
  };

  launchSiteDocsListeners().catch((err) => {
    // in unit tests, firebase can end up setup incorrectly
    // so silence related errors here
    // eslint-disable-next-line no-constant-binary-expression, valid-typeof
    if (typeof jest === undefined) {
      console.error("Error setting up site docs listeners", err);
    }
  });
}

export const liveSiteDocsAreResolved = (): boolean => {
  return !!siteDocs && !!siteDocs.siteId;
};

/**
 * Promise that resolves once user docs are resolved/ready. Once
 *   this resolves, liveSiteDocsAreResolved() should be true.
 */
export const pLiveSiteDocsReady = new Promise<void>((resolve) => {
  function cb() {
    if (_.includes(siteDocChangeListeners, cb)) {
      _.remove(siteDocChangeListeners, (e) => e === cb);
    }
    resolve();
  }
  registerSiteDocsChangeListener(cb);
});

const assertResolved = () => {
  if (!liveSiteDocsAreResolved()) {
    throw new Error("Site docs accessed but are not yet resolved.");
  }
};

initializeFirebase();
setUpSiteDocsListeners();

export function getStaticFullSiteConf(): SiteConfig {
  assertResolved();
  return {
    ...siteDocs.main,
    featureDiffs: siteDocs.featureDiffs,
    imageDiffs: siteDocs.imageDiffs,
  };
}

export function getSiteId(defaultSiteId?: string): string {
  if (defaultSiteId && !liveSiteDocsAreResolved()) {
    return defaultSiteId;
  }
  assertResolved();
  return siteDocs.siteId;
}
export const getDemoSpeechUser = (): string => {
  return getStaticFullSiteConf().demoSpeechUser ?? "demo_speech";
};
