import { useState } from "react";
import React from "react";

// Components
import { Close as CloseIcon } from "@mui/icons-material";
import { Dialog, Typography, Box, IconButton } from "@mui/material";
import SimpleVideoPlayer from "lib-frontend/components/VideoPlayer/SimpleVideoPlayer";

// Utils
import { CustomEventEnum, subscribe, unsubscribe } from "lib-frontend/events";
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";

/**
 * This function creates a video lightbox and a function to set the lightbox URL.
 *
 * @param videoURL The URL of the video to display in the lightbox.
 * @param videoHeader (optional) The header to display below the video in the lightbox.
 * @param videoDescription (optional) The description to display below the title in the lightbox.
 * @param onPlay (optional) A function to call when the video is played. Receives the current time in seconds.
 * @param onPause (optional) A function to call when the video is paused. Receives the current time in seconds.
 * @param onSeek (optional) A function to call when the video is seeked. Receives the current time in seconds.
 * @param onPlaybackSpeedChange (optional) A function to call when the video playback speed changes. Receives the new playback speed.
 * @param onClose (optional) A function to call when the lightbox is closed.
 * @param autoplay (optional) Whether to autoplay the video on open.
 * @returns An object containing the lightbox JSX element and a function to set the lightbox URL.
 *
 * @example
 * import videoLightbox from "/path/to/lightbox";
 * const { lightbox, setLightboxURL } = videoLightbox("");
 *
 * <Button onClick={() => setLightboxURL(url)}
 * {lightbox}
 */
export default function videoLightbox(
  videoURL: string,
  videoHeader?: string,
  videoDescription?: string,
  onPlay?: (seconds: number) => void,
  onPause?: (seconds: number) => void,
  onSeek?: (seconds: number) => void,
  onPlaybackSpeedChange?: (seconds: number) => void,
  onClose?: (timeToSeek?: number) => void,
  autoplay?: boolean,
  mirrorVideo?: boolean,
  currentTime?: number,
  playbackSpeed?: number,
  isMuted?: boolean
): {
  lightbox: JSX.Element;
  setLightboxURL: (url: string, title?: string, description?: string) => void;
  lightBoxUrl: string;
} {
  const [URL, setURL] = useState(videoURL);
  const [title, setTitle] = useState(videoHeader);
  const [description, setDescription] = useState(videoDescription);
  // stored in a ref to avoid re-rerendering, this is just used for tracking
  const parentVideoTime = React.useRef<number>(0);
  const open = !!URL;

  const { modalStyles } = useNavDrawerOffset();

  React.useEffect(() => {
    const closeLightbox = () => setURL("");
    subscribe(CustomEventEnum.PRICING_MODAL_OPEN, closeLightbox);

    return () => {
      unsubscribe(CustomEventEnum.PRICING_MODAL_OPEN, closeLightbox);
    };
  }, []);

  const setLightboxURL = (url: string, title?: string, description?: string) => {
    setURL(url);
    setTitle(title);
    setDescription(description);
  };

  const onDialogClose = () => {
    onClose?.(parentVideoTime.current);
    setLightboxURL("", "");
  };

  const isSpeechSummary = window.location.pathname.includes(WebServerExternalPath.SHARE);

  const lightbox = (
    <Dialog
      onClose={onDialogClose}
      PaperProps={{
        sx: {
          maxWidth: "none",
          borderRadius: "12px",
          width: {
            xs: "min(615px, 100%)",
            md: `min(${isSpeechSummary ? "1200px" : "960px"}, calc(100% - 120px))`,
          },
          margin: { xs: 1, md: 2 },
          position: "relative",
          ...modalStyles,
        },
      }}
      open={open}
      sx={{
        zIndex: 1901,

        ".video-player": {
          width: "100%",
          height: "auto",
          borderRadius: 0,
        },
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: getDynamicColor("dark4"),
          zIndex: 2,
          height: { xs: 30, md: 40 },
          width: { xs: 30, md: 40 },
        }}
        onClick={onDialogClose}
      >
        <CloseIcon />
      </IconButton>
      <Box width="100%" height="auto">
        <SimpleVideoPlayer
          skipToFrame={4.5}
          currentTime={currentTime}
          className="video-player"
          videoURL={URL}
          onPlay={onPlay}
          onPause={onPause}
          onSeek={onSeek}
          onPlaybackSpeedChange={onPlaybackSpeedChange}
          autoplay={autoplay}
          mirrored={mirrorVideo}
          setParentVideoTime={(time) => (parentVideoTime.current = time)}
          playbackSpeed={playbackSpeed}
          isMuted={isMuted}
        />
        {!!title && (
          <Typography
            sx={{
              p: { xs: 2, md: 3 },
              pb: { xs: description ? 0 : 2, md: description ? 0 : 3 },
              fontSize: { xs: 16, sm: 20, md: 26 },
              fontWeight: 700,
            }}
          >
            {title}
          </Typography>
        )}
        {!!description && (
          <Typography
            sx={{
              p: { xs: 2, md: 3 },
              pt: { xs: 0, md: 1 },
              fontSize: { xs: 14, sm: 16, md: 18 },
              fontWeight: 500,
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
    </Dialog>
  );

  return {
    setLightboxURL,
    lightbox,
    lightBoxUrl: URL,
  };
}
