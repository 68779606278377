import React from "react";

// Components
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Typography, Stack, CircularProgress, Box, TablePagination } from "@mui/material";
import { TableFilter } from "lib-frontend/components/YoodliComponents/TableComponents/TableFilter";
import { TableSortServer } from "lib-frontend/components/YoodliComponents/TableComponents/TableSortServer";
import { YoodliAvatar } from "lib-frontend/components/YoodliComponents/YoodliAvatar";
import {
  YoodliCtaModal,
  YoodliCtaModalTheme,
  CtaButtonHandlers,
  YoodliCtaModalState,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import {
  YoodliSelect,
  YoodliSelectVariant,
} from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { OrgMembersQueryKeys } from "../Members/OrgMembers";
import { OrgHubsQueryKeys } from "./OrgHubs";
import {
  useQuery as useApiQuery,
  keepPreviousData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { AxiosError } from "axios";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  deleteHubUserV2,
  listHubUsersV2,
  updateHubUserV2,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { getTimeDifference, parseHubRole } from "lib-frontend/utils/orgUtils";
import { ErrorResponse } from "lib-fullstack/api/apiTypes";
import {
  GetOrgMemberListSortOption,
  HubMemberResponse,
  OrgMemberResponse,
} from "lib-fullstack/api/orgApiTypes";
import {
  DEFAULT_ORG_MEMBER_FETCH_COUNT,
  ORG_MEMBER_FETCH_LIMIT,
} from "lib-fullstack/utils/constants";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { HubRole } from "lib-fullstack/utils/enums";
import { ApiErrorCode } from "lib-fullstack/utils/enums";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { EventSeat } from "@mui/icons-material";
import { MemberInfo } from "../Members/MembersTable";
import { CheckboxTableElement } from "lib-frontend/utils/sharedTableUtils";

const FILTER_NAMES = {
  role: [
    { label: "Group admin", value: "hub_admin" },
    { label: "Member", value: "hub_member" },
  ],
};

enum MenuAction {
  RemoveFromHub = "remove_from_hub",
}

type HubsMemberTableProps = {
  orgId: string;
  hubId: string;
  searchText: string;
  setSearchText: (searchText: string) => void;
  handleOpenDashboard: (user: OrgMemberResponse) => void;
  recalcTopContentHeight: () => void;
  bulkActionUserList: MemberInfo[];
  setBulkActionUserList: React.Dispatch<React.SetStateAction<MemberInfo[]>>;
};

type HubMembersTableRow = {
  id: string;
  name: string;
  email: string;
  num_started_speeches: number;
  date_last_activity: string;
  role: HubRole;
  action: null;
  seat_inactive: boolean;
  joined_hubs: HubMemberResponse[];
};

export const HubsMemberTable = ({
  orgId,
  hubId,
  searchText,
  setSearchText,
  handleOpenDashboard,
  recalcTopContentHeight,
  bulkActionUserList,
  setBulkActionUserList,
}: HubsMemberTableProps): JSX.Element => {
  const {
    isDefaultOrgOwnerAdmin,
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const queryClient = useQueryClient();
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_ORG_MEMBER_FETCH_COUNT,
  });

  const [sortFilter, setSortFilter] = React.useState<string>(
    GetOrgMemberListSortOption.EMAIL_ASC as string
  );
  const [roleFilters, setRoleFilters] = React.useState<string>(undefined);
  const [selectedUser, setSelectedUser] = React.useState<OrgMemberResponse>(null);
  const [openRemoveUserModal, setOpenRemoveUserModal] = React.useState<boolean>(false);
  const [removeErrorText, setRemoveErrorText] = React.useState<string>("");
  const [removeUserModalState, setOpenRemoveUserModalState] = React.useState<YoodliCtaModalState>(
    YoodliCtaModalState.Cta
  );
  const paginationStart = (pagination.pageIndex * pagination.pageSize).toString();
  const columnHelper = createColumnHelper<HubMembersTableRow>();

  const anyFilterEnabled = React.useMemo(() => {
    return roleFilters?.length > 0 || searchText?.length > 0;
  }, [roleFilters, searchText]);

  const hubUsersQuery = useApiQuery({
    queryKey: [
      OrgHubsQueryKeys.HUB_USERS,
      orgId,
      hubId,
      {
        start: paginationStart,
        limit: Math.min(pagination.pageSize, ORG_MEMBER_FETCH_LIMIT).toString(),
        sort: searchText
          ? GetOrgMemberListSortOption.EMAIL_ASC
          : (sortFilter ?? GetOrgMemberListSortOption.EMAIL_ASC),
        roles: roleFilters,
        prefix: searchText,
      },
    ],
    queryFn: async () =>
      listHubUsersV2(orgId, hubId, {
        start: paginationStart,
        limit: Math.min(pagination.pageSize, ORG_MEMBER_FETCH_LIMIT).toString(),
        sort: searchText
          ? GetOrgMemberListSortOption.EMAIL_ASC
          : ((sortFilter as GetOrgMemberListSortOption) ?? GetOrgMemberListSortOption.EMAIL_ASC),
        roles: roleFilters,
        prefix: searchText,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
  // redefine the member list whenever the data changes
  const membersList = React.useMemo(
    () => hubUsersQuery.data?.users ?? [],
    [hubUsersQuery.data?.users]
  );

  const bulkActionUserIdSet = React.useMemo(
    () => new Set(bulkActionUserList.map((user) => user.id)),
    [bulkActionUserList]
  );

  React.useEffect(() => {
    recalcTopContentHeight();
  }, [membersList]);

  const handlePageChange = (page: number) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: Math.max(page, 0) };
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: 0, pageSize: parseInt(event.target.value) };
    });
  };

  const handleMenuItemClick = async (row: Row<HubMembersTableRow>, action: MenuAction) => {
    const user = membersList.find((user) => user.email === row.original.email);
    setSelectedUser({ ...user });
    switch (action) {
      case MenuAction.RemoveFromHub:
        setOpenRemoveUserModal(true);
        break;
    }
  };

  const handleRemoveUserError = (e: AxiosError<ErrorResponse>) => {
    if (e.response?.data?.code === ApiErrorCode.IsLastHub) {
      setRemoveErrorText(
        "All members must be in at least one group. To remove this member from the organization, go to the Members tab."
      );
    }
    setOpenRemoveUserModalState(YoodliCtaModalState.Error);
  };

  const removeMemberMutation = useMutation({
    mutationFn: async () => {
      await deleteHubUserV2(orgId, hubId, selectedUser.user_id, false);
      setOpenRemoveUserModal(false);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [OrgHubsQueryKeys.HUB_USERS, orgId, hubId],
      });
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.HubUserCounts, orgId, hubId],
      });
    },
  });

  const {
    mutate: updateUserRole,
    isPending: updateUserRoleLoading,
    variables: updateUserRoleVars,
  } = useMutation({
    mutationFn: async ({ userId, role }: { userId: string; role: HubRole }) => {
      await updateHubUserV2(orgId, hubId, userId, role);
    },
    onSuccess: (_, variables) => {
      Instrumentation.logHubMemberStatusUpdated(variables.userId, variables.role, hubId, orgId);
      return queryClient.invalidateQueries({
        queryKey: [OrgHubsQueryKeys.HUB_USERS, orgId, hubId],
      });
    },
    onError: (e: Error) => {
      console.log("Error updating user's group role: ", e);
    },
  });

  const rowData = React.useMemo(
    () =>
      (membersList ?? []).map((user) => ({
        id: user.user_id,
        joined_hubs: user.hubs,
        name: user.name,
        email: user.email,
        num_started_speeches: user.num_started_speeches,
        date_last_activity: user.date_last_activity,
        role: user.hubs[0].role as HubRole,
        action: null,
        seat_inactive: user.seat_inactive,
      })),
    [membersList]
  );

  const allChecked = React.useMemo(() => {
    return membersList.every((member) => bulkActionUserIdSet.has(member.user_id));
  }, [rowData, bulkActionUserIdSet]);

  const someChecked = React.useMemo(() => {
    return membersList.some((member) => bulkActionUserIdSet.has(member.user_id));
  }, [rowData, bulkActionUserIdSet]);

  const columns = React.useMemo(
    () =>
      [
        columnHelper.accessor("name", {
          id: "name",
          header: () => <Typography sx={{ ml: { xs: 3, md: 5 } }}>Members</Typography>,
          cell: (info) => (
            <Stack
              direction="row"
              gap={2}
              sx={{ alignItems: "center", cursor: "pointer" }}
              onClick={() => {
                const user = membersList.find((user) => user.email === info.row.original.email);
                if (user) {
                  setSelectedUser(user);
                  handleOpenDashboard(user);
                }
              }}
            >
              <YoodliAvatar
                name={info.getValue()}
                sx={{
                  height: 35,
                  width: 35,
                  my: 2.5,
                }}
              />
              <Stack
                sx={{
                  maxWidth: { xs: "150px", md: "300px" },
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: getDynamicColor("purple3"),
                }}
              >
                <Typography
                  className="column-text"
                  sx={{
                    fontWeight: 700,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: getDynamicColor("primary"),
                  }}
                >
                  {info.getValue()}
                </Typography>
                <Typography
                  className="column-text"
                  sx={{
                    fontWeight: 400,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: 12,
                    color: getDynamicColor("dark5"),
                    lineHeight: 1.2,
                  }}
                >
                  {info.row.original.email}
                </Typography>
              </Stack>
            </Stack>
          ),
        }),
        columnHelper.accessor("num_started_speeches", {
          id: "num_started_speeches",
          header: () => (
            <Stack direction="row" gap={0.5} sx={{ alignItems: "center" }}>
              <Typography># of Yoodlis</Typography>
              <YoodliTooltip title="# of Yoodlis = number of uploads and recordings started">
                <InfoOutlinedIcon sx={{ width: 16, height: 16 }} />
              </YoodliTooltip>
            </Stack>
          ),
          cell: (info) => <Typography className="column-text">{info.getValue()}</Typography>,
        }),
        columnHelper.accessor("date_last_activity", {
          id: "date_last_activity",
          header: () => <Typography>Last Active</Typography>,
          cell: (info) => (
            <YoodliTooltip title={info.getValue() && getHumanReadableDate(info.getValue(), {})}>
              <Stack direction="column">
                <Typography
                  className="column-text"
                  sx={{
                    color: getDynamicColor("dark5"),
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {getTimeDifference(info.getValue())}
                </Typography>
                {info.row.original.seat_inactive && (
                  <Stack direction="row" sx={{ gap: 0.5 }}>
                    <EventSeat sx={{ width: 13, height: 13, color: getDynamicColor("dark4") }} />
                    <Typography
                      sx={{
                        color: getDynamicColor("dark4"),
                        fontFamily: "poppins",
                        fontSize: "10px",
                        fontWeight: 600,
                      }}
                    >
                      Inactive
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </YoodliTooltip>
          ),
        }),
        columnHelper.accessor("role", {
          id: "role",
          header: () => <Typography>Role</Typography>,
          cell: (info) => {
            if (isDefaultOrgOwnerAdmin) {
              const currRole = info.getValue();
              const user = membersList.find((user) => user.email === info.row.original.email);
              return (
                <YoodliSelect
                  yoodliVariant={YoodliSelectVariant.SECONDARY}
                  value={currRole}
                  loading={updateUserRoleLoading && updateUserRoleVars?.userId === user?.user_id}
                  sx={{ width: 150 }}
                  onChange={(e) =>
                    updateUserRole({
                      userId: user.user_id,
                      role: e.target.value as HubRole,
                    })
                  }
                  options={[
                    { label: "Group admin", value: HubRole.ADMIN },
                    { label: "Member", value: HubRole.MEMBER },
                  ]}
                />
              );
            }
            return <Typography className="column-text">{parseHubRole(info.getValue())}</Typography>;
          },
        }),
        columnHelper.accessor("action", {
          id: "action",
          header: () => <div style={{ width: "36px" }} />,
          cell: (info) => (
            <YoodliMenu
              type={YoodliMenuButtonType.Icon}
              menuItems={[
                {
                  title: "Remove from group",
                  onClick: () => handleMenuItemClick(info.row, MenuAction.RemoveFromHub),
                  type: YoodliMenuItemType.Warning,
                },
              ]}
            />
          ),
        }),
      ].filter(Boolean),
    [membersList, updateUserRoleLoading]
  );

  const table = useReactTable({
    data: rowData,
    columns: columns,
    state: {
      globalFilter: searchText,
      pagination,
      columnVisibility: {
        action: isDefaultOrgOwnerAdmin,
      },
    },
    rowCount: hubUsersQuery.data?.total,
    onGlobalFilterChange: setSearchText,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  const loading = [removeMemberMutation].some((mutation) => mutation.isPending);

  return (
    <Stack
      gap={1}
      sx={{
        height: "100%",
      }}
    >
      {hubUsersQuery.isPending ? (
        <CircularProgress sx={{ m: "auto" }} />
      ) : (
        <Box
          sx={{
            minHeight: membersList?.length > 4 ? 400 : "unset",
            overflowY: "auto",
            border: `1px solid ${getDynamicColor("dark2")}`,
            borderRadius: 2,
          }}
        >
          <table
            style={{
              width: "100%",
              boxShadow: Y_SHADOWS.dark_elevation,
              borderCollapse: "collapse",
              borderRadius: 2,
              borderSpacing: "0px",
            }}
          >
            <thead
              style={{
                backgroundColor: getDynamicColor("light1"),
                borderRadius: "8px",
                height: "35px",
              }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <Box
                  component="tr"
                  key={headerGroup.id}
                  sx={{
                    width: "100%",
                    borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                  }}
                >
                  {isDefaultOrgOwnerAdmin && (
                    <CheckboxTableElement
                      component="th"
                      checked={allChecked}
                      indeterminate={!allChecked && someChecked}
                      onChange={() => {
                        // If some or all are selected, deselect everything
                        if (someChecked) {
                          const newBulkActionUserList = bulkActionUserList.filter((userList) => {
                            return !rowData.map((row) => row.id).includes(userList.id);
                          });
                          setBulkActionUserList(newBulkActionUserList);
                        }
                        // If none are selected, select everything
                        else {
                          const newBulkActionUserList = [...bulkActionUserList];
                          for (const row of rowData) {
                            if (!bulkActionUserIdSet.has(row.id)) {
                              newBulkActionUserList.push({
                                id: row.id,
                                name: row.name,
                                email: row.email,
                                groupIds: row?.joined_hubs.map((hub) => hub.hub_id),
                              });
                            }
                          }
                          setBulkActionUserList(newBulkActionUserList);
                        }
                      }}
                    />
                  )}
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        borderBottom: "1px solid lightgrey",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: getDynamicColor("light1"),
                        borderRadius: 2,
                      }}
                    >
                      <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        sx={{
                          color: getDynamicColor("dark5"),
                          fontFamily: "poppins",
                          fontSize: "12px",
                          fontWeight: 600,
                          mr: 1,
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {[
                          "name",
                          "last_active",
                          "date_joined",
                          "date_last_activity",
                          "num_started_speeches",
                        ].includes(header.id) && (
                          <TableSortServer columnId={header.id} setSortFilter={setSortFilter} />
                        )}
                        {["role"].includes(header.id) && (
                          <TableFilter
                            setFilterState={setRoleFilters}
                            names={FILTER_NAMES[header.id]}
                            searchable={false}
                          />
                        )}
                      </Stack>
                      {/* Note: the reason the <TanbleFilters> are here instead of as a columnHelper is because when placed in a columnHelper,
                              it would re-render the menu opening every time the filtering changed, which is undesirable. No re-render problems here.
                          */}
                    </th>
                  ))}
                </Box>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Box
                    component="tr"
                    key={row.id}
                    sx={{
                      width: "max-content",
                      borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                      "&:last-of-type": {
                        borderBottomLeftRadius: 2,
                        borderBottomRightRadius: 2,
                        borderBottom: "unset",
                      },
                      "& .column-text": {
                        color: row.original.seat_inactive && getDynamicColor("dark4"),
                      },
                    }}
                  >
                    {isDefaultOrgOwnerAdmin && (
                      <CheckboxTableElement
                        component="td"
                        checked={bulkActionUserIdSet.has(row.original.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setBulkActionUserList([
                              ...bulkActionUserList,
                              {
                                id: row.original.id,
                                name: row.original.name,
                                email: row.original.email,
                                groupIds: (row.original.joined_hubs ?? []).map((hub) => hub.hub_id),
                              },
                            ]);
                          } else {
                            setBulkActionUserList(
                              bulkActionUserList.filter((user) => user.id !== row.original.id)
                            );
                          }
                        }}
                      />
                    )}
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{
                          color: getDynamicColor("purple3"),
                          fontFamily: "poppins",
                          fontSize: 12,
                          fontWeight: 600,
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </Box>
                );
              })}
              {table.getRowCount() === 0 && !anyFilterEnabled && (
                <tr>
                  <td colSpan={columns.length}>
                    <Stack
                      direction="column"
                      sx={{
                        mx: "auto",
                        width: "100%",
                        color: getDynamicColor("dark4"),
                        minHeight: "250px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>No members yet</Typography>
                      <Typography sx={{ textAlign: "center", whiteSpace: "wrap" }}>
                        Invite members to join this group using the buttons above
                      </Typography>
                    </Stack>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      )}
      <Stack alignItems="center">
        {table.getPageCount() > 1 && (
          <Stack alignItems="center">
            {table.getPageCount() > 1 && (
              <TablePagination
                component="div"
                sx={{ m: "auto", fontFamily: "poppins !important" }}
                count={table.getRowCount() ?? 0}
                rowsPerPageOptions={[5, 10, 20, 50]}
                page={pagination.pageIndex}
                onPageChange={(e, pageNum) => handlePageChange(pageNum)}
                rowsPerPage={pagination.pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Stack>
        )}
      </Stack>
      <YoodliCtaModal
        ctaBody={{
          title: (
            <>
              Are you sure you want to remove{" "}
              <strong style={{ color: getDynamicColor("primary") }}>{selectedUser?.name}</strong>{" "}
              from the{" "}
              <strong style={{ color: getDynamicColor("primary") }}>
                {defaultOrg.hubs.find((hub) => hubId === hub.id).name}
              </strong>{" "}
              group?
            </>
          ),
        }}
        errorBody={{
          title: (
            <>
              There was a problem removing{" "}
              <Box component="span" sx={{ color: getDynamicColor("redErrorDark") }}>
                {selectedUser?.name}
              </Box>{" "}
              from the group.
            </>
          ),
          subtitle: removeErrorText,
        }}
        state={removeUserModalState}
        setState={setOpenRemoveUserModalState}
        open={openRemoveUserModal}
        theme={YoodliCtaModalTheme.Danger}
        loading={loading}
        hideCloseButton={true}
        close={() => setOpenRemoveUserModal(false)}
        buttons={
          {
            primary: {
              text: "Remove from group",
              handler: () =>
                removeMemberMutation.mutateAsync().catch((e) => handleRemoveUserError(e)),
            },
            secondary: { text: "Cancel", handler: () => setOpenRemoveUserModal(false) },
          } as CtaButtonHandlers
        }
      />
    </Stack>
  );
};
