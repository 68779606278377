import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Components
import { Button, Stack, Typography } from "@mui/material";

// Utils
import EmptyCourseView from "./EmptyCourseView";
import CourseContent from "./CourseContentSection";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CourseResponse } from "lib-fullstack/api/hubApiTypes";
import { HubModalStatus } from "utils/Enums";
import { WebServerInternalPath } from "utils/paths";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { OrgSettingsTabs } from "lib-frontend/utils/orgUtils";
import { OrgContentQueryParams } from "lib-fullstack/utils/queryParams";

type CoursesSectionProps = {
  setModalStatus?: (modalStatus: HubModalStatus) => void;
  courses: CourseResponse[];
  setSelectedCourse?: (course: CourseResponse) => void;
  selectedOrgId: string;
  isHubAdmin?: boolean;
};

export const MANAGE_CONTENT_COURSES_SECTION_ID = "manage-content-courses-section";

export default function CoursesSection({
  setModalStatus,
  courses,
  setSelectedCourse,
  selectedOrgId,
  isHubAdmin,
}: CoursesSectionProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { isDefaultOrgOwnerAdmin } = React.useContext(UserOrgContext);
  const hasScrolled = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (!hasScrolled.current) {
      if (courses?.length && location.hash) {
        const el = document.getElementById(location.hash.substring(1)); // remove the #
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
          // clear the hash after scrolling so it doesn't keep scrolling when the courses change
          navigate(
            {
              pathname: location.pathname,
              search: location.search,
            },
            { replace: true }
          );
          hasScrolled.current = true;
        }
      } else if (!location.hash) {
        window.scrollTo({ top: 0 });
        hasScrolled.current = false;
      }
    }
  }, [courses, location]);

  const courseCount = `${courses?.length} course${courses?.length > 1 ? "s" : ""}`;

  const renderCta = () => {
    // If the user is an org owner/admin but is viewing the available content page
    if (isDefaultOrgOwnerAdmin && !isHubAdmin) {
      const qp = new URLSearchParams({
        [OrgContentQueryParams.TAB]: OrgSettingsTabs.LEARNING_MATERIALS,
      });
      return (
        <Button
          variant="text"
          onClick={() =>
            navigate({ pathname: WebServerInternalPath.ORG_CONTENT, search: qp.toString() })
          }
        >
          Manage
        </Button>
      );
    }
    if (isDefaultOrgOwnerAdmin) {
      return (
        <Button variant="outlined" onClick={() => setModalStatus(HubModalStatus.CREATE_COURSE)}>
          + Add a new course
        </Button>
      );
    }
    return null;
  };

  return (
    <Stack gap={3} id={MANAGE_CONTENT_COURSES_SECTION_ID}>
      <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "baseline" }}>
        <Stack
          direction="row"
          sx={{ flexWrap: "wrap", alignItems: "baseline", fontFamily: "poppins", fontWeight: 700 }}
        >
          <Typography
            sx={{
              fontSize: { xs: 14, md: 16 },
              mr: 2,
            }}
          >
            Courses
          </Typography>
          {courses?.length > 0 && (
            <Typography
              sx={{
                fontSize: { xs: 14, md: 16 },
                color: getDynamicColor("dark4"),
              }}
            >
              {courseCount}
            </Typography>
          )}
        </Stack>
        {renderCta()}
      </Stack>
      {courses?.length ? (
        <Stack gap={1}>
          {courses.map((course) => (
            <div
              id={`course-${course.id}`}
              key={course.id}
              style={{
                paddingTop: "16px",
              }}
            >
              <CourseContent
                course={course}
                setModalStatus={setModalStatus}
                setSelectedCourse={setSelectedCourse}
                selectedOrgId={selectedOrgId}
                isHubAdmin={isHubAdmin}
              />
            </div>
          ))}
        </Stack>
      ) : (
        <EmptyCourseView setModalStatus={setModalStatus} isHubAdmin={isHubAdmin} />
      )}
    </Stack>
  );
}
