import firebase from "firebase/app";
import React from "react";
import { Link } from "react-router-dom";

// Components
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Avatar, ListItem, Stack, Typography, Collapse } from "@mui/material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";
import { DefaultOrgSwitcher } from "lib-frontend/components/DefaultOrgSwitcher";

// Utils
import {
  currentUserEmail,
  currentUserFirstName,
  handleDoLogout,
} from "lib-frontend/utils/AccountUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { UITestId } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { WebServerInternalPath } from "utils/paths";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";

type AccountNavItemProps = {
  handleNavOpen: () => void;
  navOpen: boolean;
  expanded: boolean;
  handleToggleExpanded: () => void;
  showOrgSwitcher: boolean;
};

export const AccountNavItem = ({
  handleNavOpen,
  navOpen,
  expanded,
  handleToggleExpanded,
  showOrgSwitcher,
}: AccountNavItemProps): JSX.Element => {
  const { userInOrg, defaultOrgId, logOutEverywhereEnforced } = React.useContext(UserOrgContext);
  const { currentUser } = firebase.auth();
  const firstName = currentUserFirstName();
  const userEmail = currentUserEmail();
  const showAffiliateProgram = !isToastmasters() && !userInOrg;
  return (
    <>
      {showOrgSwitcher && (
        <Stack sx={{ margin: 1.5 }}>
          <DefaultOrgSwitcher expanded={navOpen} />
        </Stack>
      )}
      <ListItem
        sx={{
          mt: { xs: 1, md: 0 },
          whiteSpace: "normal",
          width: "100%",
          pr: 1.5,
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          transition: "all 0.2s ease-in-out",
          position: "relative",
          zIndex: 10001,
        }}
      >
        <YoodliTooltip
          title={
            <Stack>
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                {currentUser?.displayName}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                }}
              >
                {userEmail ?? "Email"}
              </Typography>
            </Stack>
          }
          enterDelay={500}
          placement="right"
          PopperProps={{
            sx: {
              zIndex: 10000,
              ml: navOpen ? 0 : "-140px !important",
            },
          }}
        >
          <Stack
            onClick={() => {
              if (!expanded && !navOpen) {
                handleNavOpen();
              }
              // minor delay to prevent weird CLS behavior
              setTimeout(
                () => {
                  handleToggleExpanded();
                },
                !navOpen ? 50 : 0
              );
            }}
            direction="row"
            sx={{
              justifyContent: "flex-start",
              cursor: "pointer",
              alignItems: "center",
              ml: "7px",
              maxWidth: 200,
              width: "100%",
            }}
          >
            <Stack direction="row" alignItems="center">
              <Avatar
                data-logrocket-hidden
                sx={{
                  height: "26px",
                  width: "26px",
                  mr: 1.5,
                }}
                alt={currentUser?.displayName ?? "User"}
                src={currentUser?.photoURL}
                data-testid={UITestId.AccountAvatar}
              />
              <Stack
                sx={{
                  opacity: navOpen ? 1 : 0,
                  transition: "opacity 0.2s ease-in-out",
                }}
              >
                <Typography
                  data-logrocket-hidden
                  sx={{
                    width: "100%",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    color: getDynamicColor("navTextColor"),
                    maxWidth: 112,
                    // set min width as well to prevent word wrap as the navbar width shrinks
                    minWidth: 112,
                    lineHeight: "18px",
                    maxHeight: 36,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {firstName}
                </Typography>
                <Typography
                  data-logrocket-hidden
                  sx={{
                    width: "100%",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                    color: getDynamicColor("navTextColor"),
                    maxWidth: 112,
                    // set min width as well to prevent word wrap as the navbar width shrinks
                    minWidth: 112,
                    lineHeight: "18px",
                    maxHeight: 36,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {userEmail}
                </Typography>
              </Stack>
            </Stack>

            <ExpandMoreIcon
              sx={{
                height: 30,
                width: 30,
                fill: getDynamicColor("primary"),
                ml: "auto",
                transform: !expanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.2s ease-in-out",
              }}
            />
          </Stack>
        </YoodliTooltip>
        <Collapse in={expanded} sx={{ width: "100%" }}>
          <Stack
            direction="column"
            gap={1}
            sx={{
              position: "relative",
              left: 7,
              pt: 1,
              overflow: "hidden",
            }}
          >
            <Link
              key="settings"
              to={WebServerExternalPath.ACCOUNT}
              style={{
                textDecoration: "none",
                color: getDynamicColor(isToastmasters() ? "light1" : "purple3"),
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  gap: 1,
                  p: 0.5,
                  fontWeight: 500,
                  width: "fit-content",
                }}
              >
                Settings
              </Typography>
            </Link>
            {showAffiliateProgram && (
              <Link
                key="join-affiliate-program"
                to={WebServerInternalPath.AFFILIATE_PROGRAM}
                style={{
                  textDecoration: "none",
                  color: getDynamicColor(isToastmasters() ? "light1" : "purple3"),
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    gap: 1,
                    p: 0.5,
                    fontWeight: 500,
                    width: "fit-content",
                  }}
                >
                  Join affiliate program
                </Typography>
              </Link>
            )}
            <Link
              key="help"
              to={WebServerExternalPath.SUPPORT}
              style={{
                textDecoration: "none",
                color: getDynamicColor(isToastmasters() ? "light1" : "purple3"),
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  gap: 1,
                  p: 0.5,
                  fontWeight: 500,
                  width: "fit-content",
                }}
              >
                Help
              </Typography>
            </Link>

            <Typography
              onClick={() => handleDoLogout(userInOrg, defaultOrgId, logOutEverywhereEnforced)}
              sx={{
                textDecoration: "none",
                color: getDynamicColor("primary"),
                fontSize: 14,
                gap: 1,
                p: 0.5,
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              data-testid={UITestId.SignOut}
            >
              Sign out
            </Typography>
          </Stack>
        </Collapse>
      </ListItem>
    </>
  );
};
