// Components
import { Divider, Stack } from "@mui/material";

// Utils
import CoursesSection from "./CoursesSection";
import DemoVideosSection from "./DemoVideosSection";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ContentsViewResponse, CourseResponse } from "lib-fullstack/api/hubApiTypes";
import { ContentVideoState } from "lib-fullstack/utils/enums";
import { HubModalStatus } from "utils/Enums";

type ManageContentTabProps = {
  setModalStatus?: (modalStatus: HubModalStatus) => void;
  contentView: ContentsViewResponse;
  selectedOrgId: string;
  setSelectedCourse?: (course: CourseResponse) => void;
  isHubAdmin: boolean;
};

export function LearningMaterialsTab({
  setModalStatus,
  contentView,
  selectedOrgId,
  setSelectedCourse,
  isHubAdmin,
}: ManageContentTabProps): JSX.Element {
  return (
    <Stack
      gap={4}
      sx={{
        p: { xs: 3, md: 4, lg: 6 },
      }}
    >
      <DemoVideosSection
        setModalStatus={setModalStatus}
        isHubAdmin={isHubAdmin}
        demoVideos={contentView?.demo_videos.filter((video) =>
          [
            ContentVideoState.READY,
            isHubAdmin && ContentVideoState.PROCESSING,
            isHubAdmin && ContentVideoState.ERROR,
          ].includes(video.state as ContentVideoState)
        )}
        selectedOrgId={selectedOrgId}
      />
      <Divider
        sx={{
          mx: { xs: -3, md: -4, lg: -6 },
          borderColor: getDynamicColor("dark3"),
        }}
      />
      <CoursesSection
        setModalStatus={setModalStatus}
        courses={contentView?.courses}
        setSelectedCourse={setSelectedCourse}
        selectedOrgId={selectedOrgId}
        isHubAdmin={isHubAdmin}
      />
    </Stack>
  );
}
