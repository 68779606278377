// Utils
import { getClientEnvConfig } from "lib-fullstack/client_env";

export const SHOULD_REFRESH_FIREBASE_ID_TOKEN = "shouldRefreshFirebaseIdToken";

export const CHROME_EXTENSION_LINK =
  "https://chrome.google.com/webstore/detail/speakeasy-ai-powered-spee/gokfedolfbdipnljhkobbkhmifhlbhek";

export const COOKIE_YES_COOKIE_BANNER_CLASS_NAME = "cky-banner-bottom";

export const DRAWER_MENU_WIDTH = {
  OPEN: "215px",
  CLOSED: "75px",
  OPEN_MOBILE: "265px",
};

export const SS_BANNER_HEIGHT = {
  LARGE: "64px",
  SMALL: "119px",
};

export const SS_BANNER_SMALL_THRESHOLD = 1280;

export const GOOD_EYE_CONTACT_THRESH = 0.85;

export const LOGIN_SESSION_ACTIVE = "login-session-active";

export const TRY_FPR_BANNER_DISMISSED = "try-fpr-banner-dismissed";

export const CONNECT_CALENDAR_TITLE = "Connect your calendar";

export const VIDEO_WIDTH = 300;
export const VIDEO_RATIO = 16 / 9;

export const WIZARD_HEADER_HEIGHT = "85px";
export const WIZARD_FOOTER_HEIGHT = "85px";

export const MEMBER_BUILDER_ENABLED = "member_builder_enabled";

const envConfig = getClientEnvConfig();
console.debug(`Environment: ${envConfig.envName}`);

export { envConfig };
