/**
 * Get the accepted file types that can be uploaded to an org
 * @returns An array of objects specifying the accepted file types
 */
export const getAcceptedFileTypes = (): {
  // TODO @danc: Ensure .wav + .mp3 are allowed
  mime: string;
  regexp: RegExp | null;
  maxSize: number;
}[] => {
  const fileTypes = [
    { mime: "application/pdf", regexp: null, maxSize: 50 * 1024 * 1024 /* 50MB */ },
    { mime: "video/*", regexp: new RegExp("^video/.*"), maxSize: 5 * 1024 * 1024 * 1024 /* 5GB */ },
    { mime: "audio/*", regexp: new RegExp("^audio/.*"), maxSize: 5 * 1024 * 1024 * 1024 /* 5GB */ },
  ];

  return fileTypes;
};

/**
 * Parse the file type to a human-readable format
 * @param fileType The file mime type
 * @returns The human-readable file type
 */
export const parseFileType = (fileType: string): string => {
  if (fileType.includes("pdf")) {
    return "PDF";
  } else if (
    fileType.includes("msword") ||
    fileType.includes("vnd.openxmlformats-officedocument.wordprocessingml.document")
  ) {
    return "Document";
  } else if (fileType.includes("video")) {
    return "Video";
  } else if (fileType.includes("audio")) {
    return "Audio";
  } else if (
    fileType.includes("spreadsheet") ||
    fileType.includes("vnd.openxmlformats-officedocument.spreadsheetml.sheet")
  ) {
    return "Spreadsheet";
  } else if (
    fileType.includes("presentation") ||
    fileType.includes("vnd.openxmlformats-officedocument.presentationml.presentation")
  ) {
    return "Presentation";
  } else {
    return "Other";
  }
};
