// Utils
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

/**
 * Enum indicating the category of a billable action.
 */
export enum BillableActionCategoryEnum {
  /**
   * A user created or modified a comment.
   */
  CommentLeft = "comment-left",
  /**
   * A user uploaded a file.
   */
  FileUploaded = "file-uploaded",
  /**
   * An admin user created or modified a coachbot.
   */
  ModifyAdminContentCoachbot = "modify-admin-content-coachbot",
  /**
   * An admin user created or modified a course.
   */
  ModifyAdminContentCourse = "modify-admin-content-course",
  /**
   * An admin user created or modified a custom goal.
   */
  ModifyAdminContentCustomGoal = "modify-admin-content-custom-goal",
  /**
   * An admin user created or modified a file.
   */
  ModifyAdminContentFile = "modify-admin-content-file",
  /**
   * An admin user created or modified a group.
   */
  ModifyAdminContentGroup = "modify-admin-content-group",
  /**
   * An admin user created or modified hub membership.
   */
  ModifyAdminContentHubMembership = "modify-admin-content-hub-membership",
  /**
   * An admin user created or modified an interview bank.
   */
  ModifyAdminContentInterviewBank = "modify-admin-content-interview-bank",
  /**
   * An admin user created or modified org membership.
   */
  ModifyAdminContentOrgMembership = "modify-admin-content-org-membership",
  /**
   * An admin user created or modified a persona.
   */
  ModifyAdminContentPersona = "modify-admin-content-persona",
  /**
   * An admin user created or modified a program.
   */
  ModifyAdminContentProgram = "modify-admin-content-program",
  /**
   * An admin user created or modified a scenario.
   */
  ModifyAdminContentScenario = "modify-admin-content-scenario",
  /**
   * An admin user created or modified a welcome video.
   */
  ModifyAdminContentWelcomeVideo = "modify-admin-content-welcome-video",
  /**
   * A user played a game.
   */
  PlayedGame = "played-game",
  /**
   * A user viewed a Yoodli recording.
   */
  ViewedYoodliRecording = "viewed-yoodli-recording",
  /**
   * A user uploaded a Zoodli.
   */
  ZoodliUploaded = "zoodli-uploaded",
  /**
   * A user recorded a Yoodli.
   */
  YoodliRecorded = "yoodli-recorded",
}

/**
 * Enum indicating the category of a billable action.
 */
export const BillableActionCategory = RTStringEnum(BillableActionCategoryEnum);
