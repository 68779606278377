import React from "react";
import { useLocation, useNavigate } from "react-router";

// Components
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Stack, Button } from "@mui/material";
import { InvitedEmailResponse, MAX_EMAIL_INVITE_COUNT } from "components/Orgs/InviteMembers";
import { Wizard } from "components/Wizard/Wizard";
import { WizardStep } from "components/Wizard/WizardTypes";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";
import { defaultHeaderSx } from "lib-frontend/ui/Theme";

// Utils
import { OrgOverviewQueryKeys } from "../Overview/OrgOverview";
import { CreateHub } from "./CreateHub";
import { Done } from "./Done";
import { InviteMembers } from "./InviteMembers";
import { useMutation, useQuery as useApiQuery, useQueryClient } from "@tanstack/react-query";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  createHubV2,
  createOrgUserV2,
  getOrgEmailBranding,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { toTitleCase } from "lib-frontend/utils/Utilities";
import { AddOrgUserResult, HubV2Response } from "lib-fullstack/api/orgApiTypes";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";
import { HubRole } from "lib-fullstack/utils/enums";
import { WIZARD_FOOTER_HEIGHT } from "utils/Constants";
import { WebServerInternalPath } from "utils/paths";

const ORG_EMAIL_BRANDING_QUERY_KEY = "orgEmailBranding";

export const CreateHubWizard = (): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { invalidateDefaultOrgQuery, defaultOrgId, isDefaultOrgOwnerAdmin } =
    React.useContext(UserOrgContext);

  const [currStepIndex, setCurrStepIndex] = React.useState<number>(0);
  const [hubName, setHubName] = React.useState<string>("");
  const [newHub, setNewHub] = React.useState<HubV2Response>(null);
  const [emailsToInvite, setEmailsToInvite] = React.useState<string[]>([]);
  const [role, setRole] = React.useState<HubRole>(HubRole.MEMBER);
  const [welcomeMessage, setWelcomeMessage] = React.useState<string>("");
  const [emailResponses, setEmailResponses] = React.useState<InvitedEmailResponse>({
    addedEmails: [],
    invitedEmails: [],
    failedEmails: [],
    noLicenseEmails: [],
    emailSentEmails: [],
  });

  const { showNotificationBanner, notifAnchorRef } = useNotification();

  const orgEmailBrandingQuery = useApiQuery({
    queryKey: [ORG_EMAIL_BRANDING_QUERY_KEY, defaultOrgId],
    queryFn: () => getOrgEmailBranding(defaultOrgId),
    enabled: !!defaultOrgId,
    refetchOnWindowFocus: false,
  });

  const createHubMutation = useMutation({
    mutationFn: async () => await createHubV2(defaultOrgId, hubName),
    onSuccess: (data) => {
      setNewHub(data);
      Instrumentation.logOrgGroupCreated(defaultOrgId);
      return queryClient.invalidateQueries({
        queryKey: [OrgOverviewQueryKeys.HubData, defaultOrgId],
      });
    },
  });

  const inviteMembersMutation = useMutation({
    mutationFn: async () => {
      return await createOrgUserV2(defaultOrgId, {
        emails: emailsToInvite,
        org_role: null,
        hub_role: role,
        hub_ids: [newHub.id],
        send_invite_email: true,
        welcome_message: welcomeMessage,
      });
    },
    onSuccess: (response) => {
      if (response.results.length > 0) {
        const newEmailResponses = {
          addedEmails: [],
          invitedEmails: [],
          failedEmails: [],
          noLicenseEmails: [],
          emailSentEmails: [],
        };
        response.results.forEach((resp) => {
          switch (resp.result) {
            case AddOrgUserResult.ADDED:
              newEmailResponses.addedEmails.push(resp.email);
              break;
            case AddOrgUserResult.INVITE_WITHOUT_EMAIL:
              newEmailResponses.invitedEmails.push(resp.email);
              break;
            case AddOrgUserResult.INTERNAL_ERROR:
              newEmailResponses.failedEmails.push(resp.email);
              break;
            case AddOrgUserResult.NO_MORE_LICENSE:
              newEmailResponses.noLicenseEmails.push(resp.email);
              break;
            case AddOrgUserResult.INVITE_WITH_EMAIL:
              newEmailResponses.emailSentEmails.push(resp.email);
          }
        });
        setEmailResponses(newEmailResponses);
        if (
          newEmailResponses.failedEmails.length > 0 ||
          newEmailResponses.noLicenseEmails.length > 0
        ) {
          showNotificationBanner(
            `Some invites failed to send. Please try again or contact support.`,
            {
              variant: YoodliNotificationBannerVariant.Danger,
            }
          );
        }
      }
    },
  });

  React.useEffect(() => {
    if (orgEmailBrandingQuery.isSuccess) {
      const orgEmailBranding = orgEmailBrandingQuery.data;
      if (orgEmailBranding?.welcome_message) {
        setWelcomeMessage(orgEmailBranding?.welcome_message);
      }
    }
  }, [orgEmailBrandingQuery.isPending]);

  const backLabel = React.useMemo(() => {
    return location.state?.from
      ? location.state?.from
      : isDefaultOrgOwnerAdmin
        ? "overview"
        : "hubs";
  }, [isDefaultOrgOwnerAdmin, location.state?.from]);

  const createHubStep = React.useMemo(() => {
    return {
      label: "Name",
      stepIdx: 0,
      id: "start",
      component: <CreateHub hubName={hubName} setHubName={setHubName} />,
      validate: () => hubName.length > 0 && !createHubMutation.isPending,
      showProgressTracker: true,
      nextCopy: createHubMutation.isPending ? "Creating group..." : "Create",
      next: async () => {
        await createHubMutation.mutateAsync();
      },
      hidePrev: true,
    };
  }, [hubName, createHubMutation.isPending]);

  const inviteMembersStep = React.useMemo(() => {
    return {
      label: "Invite",
      stepIdx: 1,
      id: "invite",
      component: (
        <InviteMembers
          hub={newHub}
          emailsToInvite={emailsToInvite}
          setEmailsToInvite={setEmailsToInvite}
          role={role}
          setRole={setRole}
          welcomeMessage={welcomeMessage}
          setWelcomeMessage={setWelcomeMessage}
        />
      ),
      validate: () => emailsToInvite.length <= MAX_EMAIL_INVITE_COUNT,
      showProgressTracker: true,
      next: async () => {
        if (emailsToInvite.length > 0) {
          await inviteMembersMutation.mutateAsync();
        }
      },
      nextCopy: emailsToInvite.length > 0 ? "Send invites" : "Skip invites for now",
      hidePrev: true,
    };
  }, [newHub, emailsToInvite, role, welcomeMessage]);

  const doneStep = React.useMemo(() => {
    return {
      label: "Done",
      stepIdx: 2,
      id: "done",
      component: <Done emailResponses={emailResponses} hub={newHub} role={role} />,
      validate: () => true,
      showProgressTracker: true,
      next: async () => {
        await invalidateDefaultOrgQuery();
        const hubParam = new URLSearchParams({ hubId: newHub.id });
        navigate(
          {
            pathname: WebServerInternalPath.ORG_GROUPS,
            search: hubParam.toString(),
          },
          { replace: true }
        );
      },
      nextCopy: "Done",
      prevCopy: "Invite more members",
    };
  }, [
    emailResponses.emailSentEmails,
    emailResponses.invitedEmails,
    emailResponses.failedEmails,
    emailResponses.noLicenseEmails,
    emailResponses.addedEmails,
    newHub,
    role,
  ]);

  const CREATE_HUB_STEPS: WizardStep[] = [createHubStep, inviteMembersStep, doneStep];

  return (
    <Stack
      sx={{
        background: getDynamicColor("light1"),
        height: {
          xs: `calc(100vh - ${WEBCLIENT_TOP_NAVBAR_HEIGHT})`,
          md: `calc(100vh - ${WIZARD_FOOTER_HEIGHT})`,
        },
        position: "relative",
      }}
    >
      <Box
        sx={{
          ...defaultHeaderSx,
          background: getDynamicColor("purple3"),
          display: "flex",
          alignItems: "center",
          pl: { xs: 0, md: 4 },
          position: "sticky",
          top: 0,
          left: 0,
          right: 0,
        }}
        ref={notifAnchorRef}
      >
        <Button
          variant="text"
          sx={{
            color: getDynamicColor("light1"),
          }}
          startIcon={<ChevronLeftIcon />}
          onClick={() => navigate(`/org/${backLabel}`)}
        >
          {toTitleCase(backLabel)}
        </Button>
      </Box>
      <Wizard
        steps={CREATE_HUB_STEPS}
        routeLeavingGuardUIProps={{
          title: "Are you sure you want to leave this page?",
          body: "Your changes will not be saved",
          when: currStepIndex < 2,
        }}
        currStepIndex={currStepIndex}
        setCurrStepIndex={setCurrStepIndex}
        shouldUseNavDrawerOffset
        containerSx={{
          height: `calc(100% - ${WIZARD_FOOTER_HEIGHT} - ${
            isSmallScreen ? WEBCLIENT_TOP_NAVBAR_HEIGHT : "0px"
          } )`,
          pt: 6,
          overflow: "auto",
        }}
      />
    </Stack>
  );
};
