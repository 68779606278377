import React from "react";
import { useDropzone } from "react-dropzone";

// Components
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";

// Utils
import { FileTable } from "./FileTable";
import { useMutation, useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { createOrgFile, listOrgFiles } from "lib-frontend/modules/axiosOrgFiles";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { uploadBlobToResumableUploadUrl } from "lib-frontend/utils/resumableUpload";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { asyncMap } from "lib-fullstack/utils/asyncMap";
import { OrgFileSourceType } from "lib-fullstack/utils/enums";
import { getAcceptedFileTypes } from "utils/orgContentUtils";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";

export const ORG_FILES_QUERY_KEY = "orgFiles";

export const OrgFileLibrary = (): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);

  const fileInputRef = React.useRef(null);

  const onDropCallback = React.useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      createOrgFileMutation.mutate(acceptedFiles);
    }
  }, []);

  const fileTypes = getAcceptedFileTypes();
  const acceptString = fileTypes.map((fileType) => fileType.mime).join(",");

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropCallback,
    accept: acceptString,
  });

  const orgFilesQuery = useApiQuery({
    queryKey: [ORG_FILES_QUERY_KEY, defaultOrgId],
    queryFn: () =>
      listOrgFiles(defaultOrgId, {
        space_id: curSpaceId,
      }),
    enabled: !!defaultOrgId,
    refetchOnWindowFocus: false,
  });

  const createOrgFileMutation = useMutation({
    mutationFn: (files: File[]) =>
      createOrgFile(defaultOrgId, {
        space_id: curSpaceId,
        files: files.map((file) => ({
          source_type: OrgFileSourceType.Upload,
          name: file.name,
          mime_type: file.type,
        })),
      }),
    onSuccess: async (res, files) => {
      await orgFilesQuery.refetch();
      return asyncMap(
        res.files,
        async (fileResponse, i) => {
          await uploadBlobToResumableUploadUrl(
            files[i],
            fileResponse.upload_url,
            null,
            `upload (${files[i].name})`
          );
        },
        5,
        1
      );
    },
  });

  const handleUploadFileClick = () => {
    // Trigger the hidden file input element when the button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const renderContent = () => {
    if (orgFilesQuery.isLoading) {
      return <CircularProgress sx={{ mx: "auto" }} />;
    } else if (orgFilesQuery.data?.files?.length) {
      return <FileTable files={orgFilesQuery.data?.files} />;
    } else {
      return (
        <Stack
          {...getRootProps()}
          gap="5px"
          sx={{
            alignItems: "center",
            justifyContent: "center",
            height: "220px",
            border: `1px dashed ${getDynamicColor("dark3")}`,
            borderRadius: "8px",
          }}
        >
          <CloudUploadOutlinedIcon
            sx={{ width: 24, height: 24, color: getDynamicColor("dark4") }}
          />
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: 500,
              color: getDynamicColor("dark4"),
              fontSize: "12px",
            }}
          >
            {isSmallScreen ? "Click" : "Drag your files here or click"} "Add from device" to browse
          </Typography>
        </Stack>
      );
    }
  };

  return (
    <Stack
      gap={{ xs: "50px", md: 5 }}
      sx={{ pt: { xs: 5, md: "50px" }, pl: { xs: 1, md: "60px" }, pr: { xs: 1, md: "50px" } }}
    >
      <Stack
        gap="20px"
        direction={{ xs: "column", md: "row" }}
        sx={{
          alignItems: { xs: "flex-start", md: "center" },
          justifyContent: "space-between",
          px: { xs: "20px", md: 0 },
        }}
      >
        <Stack gap={1}>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: 700,
              color: getDynamicColor("purple3"),
              fontSize: { xs: 14, md: 16 },
            }}
          >
            Your files
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: 600,
              color: getDynamicColor("dark5"),
              fontSize: "12px",
              height: { xs: "auto", md: "36px" },
            }}
          >
            Drag and drop or upload files to use in scenarios, coaching guides, and custom goal
            creation.
          </Typography>
        </Stack>
        <Button
          onClick={handleUploadFileClick}
          variant="contained"
          size="xlarge"
          sx={{ whiteSpace: "nowrap" }}
        >
          Add from device
        </Button>
      </Stack>
      {renderContent()}
      <input {...getInputProps()} type="file" accept={acceptString} ref={fileInputRef} />
    </Stack>
  );
};
