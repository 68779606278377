import React from "react";
import { useNavigate, useLocation } from "react-router";

// Components
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { ReactComponent as ContentIcon } from "images/icons/icon-content.svg";
import { Box, Button, Stack, Typography } from "@mui/material";
import { CustomizePracticeQueryKey } from "components/ConvoScenarios/convoScenarioUtils";
import { CustomizePracticeTab } from "components/Orgs/ManageContent/CustomizePractice/CustomizePracticeTab";
import { CoachBotLibrary } from "components/Orgs/ManageContent/ManageCoachBotTab/CoachBotLibrary";
import { LearningMaterialsTab } from "components/Orgs/ManageContent/VideosAndCourses/LearningMaterialsTab";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import YoodliHeaderTabs from "lib-frontend/components/YoodliComponents/YoodliHeaderTabs";
import { defaultHeaderSx } from "lib-frontend/ui/Theme";
import ManageContentSpacesDrawer from "./ManageContentSpacesDrawer";
import { OrgCreateSpace } from "./OrgCreateSpace";

// Utils
import { OrgLoading } from "../OrgLoading";
import { TrialEnded } from "../TrialEnded";
import { TrialEndedBanner } from "../TrialEndedBanner";
import { OrgFileLibrary } from "./OrgFileLibrary/OrgFileLibrary";
import { OrgManageContentModal } from "./OrgManageContentModal";
import { useQuery as useApiQuery, useQueryClient } from "@tanstack/react-query";
import useCourseContentAndDemoVideosV2 from "hooks/useCourseContentAndDemoVideosV2";
import { useQueryParamState } from "hooks/useQueryParamState";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";
import { getOrgContentView, listOrgInterviewBanks } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgSettingsTabLabel, OrgSettingsTabs, isOrgTrialEnded } from "lib-frontend/utils/orgUtils";
import { getEnabledFlag } from "lib-frontend/utils/unleash";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { ContentVideoState } from "lib-fullstack/utils/enums";
import { OrgContentQueryParams, OrgSettingsQueryParams } from "lib-fullstack/utils/queryParams";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";
import { HubModalStatus } from "utils/Enums";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";

export const VIDEOS_AND_COURSES_QUERY_KEY = "videosAndCourses";
export const INTERVIEW_BANKS_QUERY_KEY = "interviewBanks";

export default function OrgManageContent(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const {
    defaultOrgId,
    loading: contextLoading,
    adminInfo: { defaultOrg, defaultOrgLoading },
  } = React.useContext(UserOrgContext);
  const { notifAnchorRef } = useNotification();
  const qp = new URLSearchParams(location.search);

  const [selectedTab, setSelectedTab] = React.useState<OrgSettingsTabs>(
    OrgSettingsTabs.CUSTOMIZE_PRACTICE
  );
  const [modalStatus, setModalStatus] = React.useState<HubModalStatus>(HubModalStatus.CLOSED);
  const [navGuardModalOpen, setNavGuardModalOpen] = React.useState<boolean>(false);

  const [shouldBlockNav, setShouldBlockNav] = React.useState<boolean>(false);

  const [showScenarioTemplates, setShowScenarioTemplates] = useQueryParamState(
    OrgContentQueryParams.SHOW_SCENARIO_TEMPLATES,
    undefined
  );
  const [templateSelected, setTemplateSelected] = React.useState<GetScenarioResponse>(null);

  const [showBackHeader, setShowBackHeader] = React.useState<boolean>(!!showScenarioTemplates);

  const [contentSpacesDrawerOpen, setContentSpacesDrawerOpen] = React.useState<boolean>(false);

  const { spaces, curSpaceId } = React.useContext(ContentSpacesContext);

  const isSmallScreen = useIsSmallScreen();

  React.useEffect(() => {
    // if there is a query param of a tab, set the tab
    const qp = new URLSearchParams(window.location.search);
    const tab = qp.get(OrgContentQueryParams.TAB);

    if (tab && defaultOrg) {
      if (tab === OrgSettingsTabs.COACHBOT && !defaultOrg?.coach_bot_enabled) {
        qp.set(OrgContentQueryParams.TAB, OrgSettingsTabs.CUSTOMIZE_PRACTICE);
        navigate(`${location.pathname}?${qp.toString()}`, { replace: true });
      } else {
        setSelectedTab(tab as OrgSettingsTabs);
      }
    }
  }, [defaultOrg]);

  const handleBack = (fromNavGuardModal?: boolean) => {
    if (shouldBlockNav && !fromNavGuardModal) {
      setNavGuardModalOpen(true);
    } else if (templateSelected) {
      setTemplateSelected(null);
    } else {
      setShowBackHeader(false);
      setShowScenarioTemplates(undefined);
      setShowSpaceCreate(false);
    }
  };

  const videosAndCoursesQueryResult = useApiQuery({
    queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, defaultOrgId],
    queryFn: async () => getOrgContentView(defaultOrgId),
    enabled: !!defaultOrg,
  });

  const videosAndCourses = videosAndCoursesQueryResult.data;

  const interviewBanksQueryResult = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.InterviewBanks, defaultOrg?.id],
    queryFn: async () => listOrgInterviewBanks(defaultOrg?.id),
    enabled: !!defaultOrg,
  });

  const interviewBanks = interviewBanksQueryResult.data;

  const {
    handleUpdateCourseVideos,
    handleCreateCourseVideos,
    handleCreateCourse,
    handleUpdateCourseVideo,
    handleDeleteCourseVideo,
    clearUploadingVideos,
    handleCreateDemoVideos,
    handleUpdateDemoVideo,
    handleDeleteDemoVideo,
    uploading,
    uploadingVideos,
    setSelectedCourse,
    selectedCourse,
    handleUpdateCourse,
    handleDeleteCourse,
  } = useCourseContentAndDemoVideosV2(
    defaultOrgId,
    defaultOrg?.hubs.find((hub) => hub.org_default)?.id
  );

  // If there is a processing video, poll for updates
  React.useEffect(() => {
    if (
      !videosAndCourses ||
      (!videosAndCourses.demo_videos.some(
        (video) => video.state === ContentVideoState.PROCESSING
      ) &&
        !videosAndCourses.courses.some((course) =>
          course.videos.some((video) => video.state === ContentVideoState.PROCESSING)
        ))
    ) {
      return;
    }
    const intervalId = setInterval(() => {
      void queryClient.invalidateQueries({
        queryKey: [VIDEOS_AND_COURSES_QUERY_KEY, defaultOrgId],
      });
    }, 5000);
    return () => clearInterval(intervalId);
  }, [videosAndCourses]);

  const handleTabChange = (tab: OrgSettingsTabs) => {
    if (
      !shouldBlockNav ||
      (shouldBlockNav &&
        window.confirm("Are you sure you want to change tabs? your changes might not be saved!"))
    ) {
      setSelectedTab(tab);
      setShouldBlockNav(false);
      qp.set(OrgSettingsQueryParams.TAB, tab);
      navigate({ search: qp.toString() });
    }
  };

  const getEnabledTabs = React.useCallback(() => {
    let tabs = Object.values(OrgSettingsTabs);
    if (!defaultOrg?.coach_bot_enabled) {
      tabs = tabs.filter((tab) => tab !== OrgSettingsTabs.COACHBOT);
    }
    if (!getEnabledFlag("file-library-ux")) {
      tabs = tabs.filter((tab) => tab !== OrgSettingsTabs.FILES);
    }
    return tabs;
  }, [defaultOrg]);

  const [showSpaceCreate, setShowSpaceCreate] = React.useState<boolean>(false);

  const renderTab = () => {
    if (isOrgTrialEnded(defaultOrg)) {
      return (
        <Box sx={{ pt: 4 }}>
          <TrialEnded org={defaultOrg} />
        </Box>
      );
    }
    switch (selectedTab) {
      case OrgSettingsTabs.CUSTOMIZE_PRACTICE:
      default:
        return (
          <CustomizePracticeTab
            selectedOrg={defaultOrg}
            selectedOrgInterviewBanks={interviewBanks}
            setShouldBlockNav={setShouldBlockNav}
            setShowBackHeader={setShowBackHeader}
            showBackHeader={showBackHeader}
            showScenarioTemplates={showScenarioTemplates}
            setShowScenarioTemplates={setShowScenarioTemplates}
            templateSelected={templateSelected}
            setTemplateSelected={setTemplateSelected}
          />
        );
      case OrgSettingsTabs.LEARNING_MATERIALS:
        return (
          <LearningMaterialsTab
            setModalStatus={setModalStatus}
            contentView={videosAndCourses}
            selectedOrgId={defaultOrgId}
            setSelectedCourse={setSelectedCourse}
            // This variable seems to have been just mysteriously not set but...compiled somehow??
            isHubAdmin={true}
          />
        );
      case OrgSettingsTabs.COACHBOT:
        if (defaultOrg?.coach_bot_enabled) {
          return <CoachBotLibrary setShouldBlockNav={setShouldBlockNav} />;
        }
        break;
      case OrgSettingsTabs.FILES:
        return <OrgFileLibrary />;
    }
  };

  const loading =
    contextLoading ||
    defaultOrgLoading ||
    videosAndCoursesQueryResult.isLoading ||
    interviewBanksQueryResult.isLoading;
  if (loading) {
    return <OrgLoading prompts={["Loading organization content..."]} />;
  }

  const handleCreateContentSpace = () => {
    setContentSpacesDrawerOpen(false);
    setShowBackHeader(true);
    setShowSpaceCreate(true);
  };

  const handleContentSpaceClose = () => {
    setContentSpacesDrawerOpen(false);
  };

  const renderBackHeader = () => {
    const labelKey = Object.values(OrgSettingsTabs).find((value) => value === selectedTab);
    const label = showSpaceCreate
      ? spaces.find((space) => space.id === curSpaceId)?.name
      : `Back to ${OrgSettingsTabLabel[labelKey].toLowerCase()}`;
    return (
      <Button
        variant="text"
        sx={{
          color: getDynamicColor("light1"),
          fontSize: 14,
        }}
        startIcon={<ChevronLeftIcon />}
        onClick={() => {
          handleBack();
        }}
      >
        {label}
      </Button>
    );
  };

  return (
    <>
      <Stack
        direction="column"
        sx={{
          background: getDynamicColor("light1"),
          minHeight: "100dvh",
          position: "relative",
        }}
      >
        {getEnabledFlag("enable-org-content-spaces") && (
          <ManageContentSpacesDrawer
            open={contentSpacesDrawerOpen}
            setOpen={setContentSpacesDrawerOpen}
            handleClose={handleContentSpaceClose}
            handleCreate={handleCreateContentSpace}
          />
        )}
        {showBackHeader ? (
          <Box
            sx={{
              ...defaultHeaderSx,
              px: { xs: 2, md: 3, lg: 4, xl: 5 },
              flexWrap: "wrap",
              alignItems: "center",
              display: "flex",
              position: "sticky",
              top: {
                xs: WEBCLIENT_TOP_NAVBAR_HEIGHT,
                md: 0,
              },
              left: 0,
              right: 0,
            }}
          >
            {renderBackHeader()}
          </Box>
        ) : (
          <Stack
            sx={{
              position: "sticky",
              top: {
                xs: WEBCLIENT_TOP_NAVBAR_HEIGHT,
                md: 0,
              },
              left: 0,
              right: 0,
              zIndex: 51,
            }}
          >
            <Stack
              direction="row"
              gap={{ xs: 0, md: 1 }}
              sx={{
                ...defaultHeaderSx,
                alignItems: "center",
                justifyContent: "space-between",
                px: { xs: 2, md: 3, lg: 4, xl: 5 },
                flexWrap: "wrap",
              }}
              ref={notifAnchorRef}
            >
              <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
                <Typography
                  color={getDynamicColor("light1")}
                  sx={{ fontFamily: "Poppins", fontSize: 16, fontWeight: 600 }}
                >
                  Customize
                </Typography>
                {isOrgTrialEnded(defaultOrg) && <TrialEndedBanner />}
              </Stack>
              {getEnabledFlag("enable-org-content-spaces") && (
                <Stack>
                  <Button
                    onClick={() => setContentSpacesDrawerOpen(true)}
                    startIcon={
                      <Box
                        sx={{
                          svg: {
                            display: "block",
                            path: {
                              fill: "currentColor",
                            },
                          },
                        }}
                      >
                        <ContentIcon />
                      </Box>
                    }
                    sx={{
                      color: getDynamicColor("light1"),
                      fill: getDynamicColor("light1"),
                      borderRadius: "50px",
                      border: "2px solid",
                      fontSize: "14px",
                      lineHeight: "24px",
                    }}
                  >
                    {isSmallScreen ? "Manage" : "Manage Content Spaces"}
                  </Button>
                </Stack>
              )}
            </Stack>
            {!isOrgTrialEnded(defaultOrg) && (
              <YoodliHeaderTabs
                selectedTab={selectedTab}
                handleTabChange={handleTabChange}
                allTabs={getEnabledTabs()}
                tabLabels={OrgSettingsTabLabel}
              />
            )}
          </Stack>
        )}
        <Stack
          sx={{
            maxWidth: "xxl",
            width: "100%",
            mx: "auto",
          }}
        >
          {showSpaceCreate ? (
            <OrgCreateSpace onComplete={() => setShowSpaceCreate(false)} />
          ) : (
            renderTab()
          )}
        </Stack>
      </Stack>
      <OrgManageContentModal
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        allHubs={defaultOrg?.hubs ?? []}
        contentView={videosAndCourses}
        handleDeleteDemoVideo={handleDeleteDemoVideo}
        handleUpdateDemoVideo={handleUpdateDemoVideo}
        handleCreateDemoVideos={handleCreateDemoVideos}
        uploadingVideos={uploadingVideos}
        uploading={uploading}
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
        handleCreateCourseVideos={handleCreateCourseVideos}
        handleDeleteCourseVideo={handleDeleteCourseVideo}
        handleUpdateCourseVideo={handleUpdateCourseVideo}
        handleUpdateCourseVideos={handleUpdateCourseVideos}
        handleCreateCourse={handleCreateCourse}
        handleUpdateCourse={handleUpdateCourse}
        handleDeleteCourse={handleDeleteCourse}
        clearUploadingVideos={clearUploadingVideos}
      />
      <YoodliCtaModal
        ctaBody={{
          title: "Are you sure you want to leave this page?",
          subtitle: "Your changes might not have been saved!",
        }}
        open={navGuardModalOpen}
        theme={YoodliCtaModalTheme.Danger}
        hideCloseButton={true}
        close={() => setNavGuardModalOpen(false)}
        buttons={
          {
            primary: {
              text: "Discard changes",
              handler: () => {
                handleBack(true);
              },
            },
            secondary: { text: "Keep editing", handler: () => setNavGuardModalOpen(false) },
          } as CtaButtonHandlers
        }
      />
    </>
  );
}
