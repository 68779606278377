import {
  RTOrgFileSourceType,
  RTOrgFileContentType,
  RTOrgFileState,
} from "lib-fullstack/utils/enums";
import { NullableOptional } from "lib-fullstack/utils/runtypesHelpers";
import { Record, Static, String, Optional, Union, Null, Array as RTArray } from "runtypes";

export const CreateOrgFileRequestItem = Record({
  source_type: RTOrgFileSourceType,
  name: String,
  /** This is needed for OrgFileSourceType.Upload */
  mime_type: Optional(String),
});
export type CreateOrgFileRequestItem = Static<typeof CreateOrgFileRequestItem>;

export const CreateOrgFileRequest = Record({
  space_id: String,
  files: RTArray(CreateOrgFileRequestItem),
});
export type CreateOrgFileRequest = Static<typeof CreateOrgFileRequest>;

export const CreateOrgFileResponseItem = Record({
  id: String,
  /** This is provided for upload type */
  upload_url: Optional(String),
});
export type CreateOrgFileResponseItem = Static<typeof CreateOrgFileResponseItem>;

export const CreateOrgFileResponse = Record({
  files: RTArray(CreateOrgFileResponseItem),
});
export type CreateOrgFileResponse = Static<typeof CreateOrgFileResponse>;

export const GetOrgFileQueryParams = Record({
  space_id: String,
});
export type GetOrgFileQueryParams = Static<typeof GetOrgFileQueryParams>;

export const GetOrgFilesQueryParams = Record({
  space_id: String,
});
export type GetOrgFilesQueryParams = Static<typeof GetOrgFilesQueryParams>;

export const OrgFileItem = Record({
  id: String,
  space_id: String,
  source_type: RTOrgFileSourceType,
  name: String,
  updated_date: String,
  mime_type: String,
  state: RTOrgFileState,
  error_reason: NullableOptional(String),
  content_type: Union(RTOrgFileContentType, Null),
  coach_bot_content_ids: RTArray(String),
  ai_preread_scenario_ids: RTArray(String),
  custom_goal_ids: RTArray(String),
  signed_url: String,
});
export type OrgFileItem = Static<typeof OrgFileItem>;

export const GetOrgFilesResponse = Record({
  files: RTArray(OrgFileItem),
});
export type GetOrgFilesResponse = Static<typeof GetOrgFilesResponse>;

export const UpdateOrgFileRequest = Record({
  /** This needs to match the target space ID, not for updating the space. */
  space_id: String,
  name: Optional(String),
  /**
   * This param cannot be passed together with other properties (only name at this time)
   * "pending" is accepted when the current state is "uploading"
   */
  state: Optional(RTOrgFileState),
});
export type UpdateOrgFileRequest = Static<typeof UpdateOrgFileRequest>;

export const DeleteOrgFilesQueryParams = Record({
  space_id: String,
});
export type DeleteOrgFilesQueryParams = Static<typeof DeleteOrgFilesQueryParams>;
