import { BillableActionCategoryEnum } from "lib-fullstack/utils/enums/billableActionCategory";
import {
  CommentAnalyticsEvents,
  HubAnalyticEvents,
  KeyActionAnalyticsEvents,
  OrgAnalyticsEvents,
  OrgProgramsAnalyticsEvents,
  ProductAnalyticEvents,
  SpeechSummaryAnalyticsEvents,
} from "./productAnalyticEvents";

/**
 * Collection of all categories of billable actions.
 */
export const BILLABLE_ACTION_CATEGORIES: Record<
  BillableActionCategoryEnum,
  ProductAnalyticEvents[]
> = {
  [BillableActionCategoryEnum.CommentLeft]: [
    CommentAnalyticsEvents.DELETED,
    CommentAnalyticsEvents.REPLY_CREATED,
    CommentAnalyticsEvents.UPDATED,
    KeyActionAnalyticsEvents.COMMENT,
  ],
  // Covered by lib-backend/speech/speechHandler.ts:processAfterSpeechAnalysis().
  [BillableActionCategoryEnum.FileUploaded]: [],
  [BillableActionCategoryEnum.ModifyAdminContentCoachbot]: [
    OrgAnalyticsEvents.ORG_COACHBOT_CREATED,
    OrgAnalyticsEvents.ORG_COACHBOT_DELETED,
    OrgAnalyticsEvents.ORG_COACHBOT_UPDATED,
  ],
  [BillableActionCategoryEnum.ModifyAdminContentCourse]: [
    OrgAnalyticsEvents.ORG_COURSE_CREATED,
    OrgAnalyticsEvents.ORG_COURSE_DELETED,
    OrgAnalyticsEvents.ORG_COURSE_UPDATED,
  ],
  [BillableActionCategoryEnum.ModifyAdminContentCustomGoal]: [
    OrgAnalyticsEvents.ORG_CUSTOM_GOAL_CREATED,
    OrgAnalyticsEvents.ORG_CUSTOM_GOAL_DELETED,
    OrgAnalyticsEvents.ORG_CUSTOM_GOAL_UPDATED,
  ],
  // Feature not yet implemented.
  [BillableActionCategoryEnum.ModifyAdminContentFile]: [],
  [BillableActionCategoryEnum.ModifyAdminContentGroup]: [
    OrgAnalyticsEvents.ORG_GROUP_CREATED,
    OrgAnalyticsEvents.ORG_GROUP_DELETED,
  ],
  [BillableActionCategoryEnum.ModifyAdminContentHubMembership]: [
    HubAnalyticEvents.HUB_MEMBER_STATUS_UPDATED,
  ],
  [BillableActionCategoryEnum.ModifyAdminContentInterviewBank]: [
    OrgAnalyticsEvents.ORG_INTERVIEW_BANK_CREATED,
    OrgAnalyticsEvents.ORG_INTERVIEW_BANK_DELETED,
    OrgAnalyticsEvents.ORG_INTERVIEW_BANK_UPDATED,
  ],
  [BillableActionCategoryEnum.ModifyAdminContentOrgMembership]: [
    OrgAnalyticsEvents.ORG_INVITATION_SENT,
  ],
  [BillableActionCategoryEnum.ModifyAdminContentPersona]: [
    OrgAnalyticsEvents.ORG_PERSONA_CREATED,
    OrgAnalyticsEvents.ORG_PERSONA_DELETED,
    OrgAnalyticsEvents.ORG_PERSONA_UPDATED,
  ],
  [BillableActionCategoryEnum.ModifyAdminContentProgram]: [
    OrgProgramsAnalyticsEvents.PROGRAM_ARCHIVED,
    OrgProgramsAnalyticsEvents.PROGRAM_CREATED,
    OrgProgramsAnalyticsEvents.PROGRAM_DELETED,
    OrgProgramsAnalyticsEvents.PROGRAM_GROUP_ADDED,
    OrgProgramsAnalyticsEvents.PROGRAM_PUBLISHED,
    OrgProgramsAnalyticsEvents.PROGRAM_SCENARIO_ADDED,
  ],
  [BillableActionCategoryEnum.ModifyAdminContentScenario]: [
    OrgAnalyticsEvents.ORG_SCENARIO_CREATED,
    OrgAnalyticsEvents.ORG_SCENARIO_DELETED,
    OrgAnalyticsEvents.ORG_SCENARIO_UPDATED,
  ],
  [BillableActionCategoryEnum.ModifyAdminContentWelcomeVideo]: [
    OrgAnalyticsEvents.ORG_WELCOME_VIDEO_CREATED,
    OrgAnalyticsEvents.ORG_WELCOME_VIDEO_DELETED,
    OrgAnalyticsEvents.ORG_WELCOME_VIDEO_UPDATED,
  ],
  [BillableActionCategoryEnum.PlayedGame]: [KeyActionAnalyticsEvents.GAME],
  [BillableActionCategoryEnum.ViewedYoodliRecording]: [
    SpeechSummaryAnalyticsEvents.SPEECH_SUMMARY_RECORDING_DOWNLOADED,
    SpeechSummaryAnalyticsEvents.SPEECH_SUMMARY_RECORDING_PLAYED,
    SpeechSummaryAnalyticsEvents.SPEECH_SUMMARY_VIEWED,
  ],
  // Covered by lib-backend/speech/speechHandler.ts:processAfterSpeechAnalysis().
  [BillableActionCategoryEnum.YoodliRecorded]: [],
  // Covered by lib-backend/speech/speechHandler.ts:processAfterSpeechAnalysis().
  [BillableActionCategoryEnum.ZoodliUploaded]: [],
};

/**
 * Set of all unique actions across all categories.
 */
export const ALL_BILLABLE_ACTIONS: Set<string> = new Set(
  Object.values(BILLABLE_ACTION_CATEGORIES).flat()
);
