import React from "react";

// Components
import { Button, CircularProgress, Link, Stack, Typography } from "@mui/material";
import { YoodliSelectOption } from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import OrgSectionWrapper from "../OrgSectionWrapper";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { createOrgUserV2, deleteHubUserV2 } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { MemberInfo } from "./MembersTable";
import { OrgMembersQueryKeys, OrgMembersSectionStatus } from "./OrgMembers";
import { YoodliMultiSelect } from "lib-frontend/components/YoodliComponents/YoodliMultiSelect";
import { asyncMap } from "lib-fullstack/utils/asyncMap";
import { HubRole } from "lib-fullstack/utils/enums";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";
import { YoodliNotificationOptions } from "lib-frontend/contexts/NotificationContext";
import { OrgHubsQueryKeys } from "../Hubs/OrgHubs";

type ReassignGroupsWizardProps = {
  origin: "org" | "group";
  backCopy: string;
  bulkActionUserList: MemberInfo[];
  setBulkActionUserList: (members: MemberInfo[]) => void;
  setSectionStatus: (status: string) => void;
  handleShowNotificationBanner: (notificationData: {
    message: string | JSX.Element;
    options: YoodliNotificationOptions;
  }) => void;
};

export default function ReassignGroupsWizard({
  origin,
  backCopy,
  setSectionStatus,
  bulkActionUserList,
  setBulkActionUserList,
  handleShowNotificationBanner,
}: ReassignGroupsWizardProps): JSX.Element {
  const queryClient = useQueryClient();
  const {
    defaultOrgId,
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const [selectedGroupIds, setSelectedGroupIds] = React.useState<string[]>([]);

  const groupsToAssign = React.useMemo(
    () =>
      selectedGroupIds.map((id) => ({
        label: defaultOrg?.hubs.find((h) => h.id === id)?.name ?? "Group",
        value: id,
      })),
    [selectedGroupIds, defaultOrg]
  );

  const groupOptions = React.useMemo(
    () => defaultOrg?.hubs.map((h) => ({ label: h.name, value: h.id })),
    [defaultOrg?.hubs]
  );

  const { mutate: handleReassignGroups, isPending: loading } = useMutation({
    mutationFn: async () => {
      await asyncMap(
        bulkActionUserList,
        async (member) => {
          // saving deletions for end
          const groupIdsToAdd = selectedGroupIds.filter((id) => !member.groupIds.includes(id));
          const groupIdsToRemove = member.groupIds.filter((id) => !selectedGroupIds.includes(id));

          await createOrgUserV2(defaultOrgId, {
            emails: [member.email],
            org_role: null,
            hub_role: HubRole.MEMBER,
            hub_ids: groupIdsToAdd,
            send_invite_email: true,
          });
          // save deletions for end to avoid situation there is a problem removing the user's last hub
          await Promise.all(
            groupIdsToRemove.map((groupId) => {
              deleteHubUserV2(defaultOrgId, groupId, member.id, false).catch((err) => {
                console.error("Error deleting user from group", err);
              });
            })
          );
        },
        5,
        1
      );
    },
    onSuccess: async () => {
      if (origin === "group") {
        void queryClient.invalidateQueries({
          queryKey: [OrgMembersQueryKeys.HubUserCounts, defaultOrgId],
        });
        await queryClient.invalidateQueries({
          queryKey: [OrgHubsQueryKeys.HUB_USERS, defaultOrgId],
        });
      } else {
        void queryClient.invalidateQueries({
          queryKey: [OrgMembersQueryKeys.OrgMembers, defaultOrgId],
        });
        void queryClient.invalidateQueries({
          queryKey: [OrgMembersQueryKeys.OrgUserCounts, defaultOrgId],
        });
      }
      handleBack();
      setBulkActionUserList([]);
      handleShowNotificationBanner({
        message: `${bulkActionUserList.length} members reassigned successfully`,
        options: { variant: YoodliNotificationBannerVariant.Success },
      });
    },
    onError: () => {
      handleBack();
      handleShowNotificationBanner({
        message: `Error updating group assignments for members`,
        options: { variant: YoodliNotificationBannerVariant.Danger },
      });
    },
  });

  const renderCtas = () => {
    return (
      <Stack
        sx={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          pl: { xs: 2, md: 8 },
          pr: { xs: 2, md: 6 },
          gap: 5,
          flexWrap: "wrap",
          rowGap: 0,
        }}
      >
        <Button onClick={handleBack}>Cancel</Button>
        <Button
          startIcon={
            loading && (
              <CircularProgress
                size={20}
                sx={{
                  color: getDynamicColor("dark1"),
                }}
              />
            )
          }
          sx={{
            fontSize: "16px",
            whiteSpace: "nowrap",
            fontFamily: "poppins",
            fontWeight: 700,
            px: 3,
            py: 2,
          }}
          variant="contained"
          onClick={() => handleReassignGroups()}
          disabled={selectedGroupIds.length === 0 || loading}
        >
          Reassign {bulkActionUserList.length} members
        </Button>
      </Stack>
    );
  };

  const handleBack = () => {
    setSectionStatus(OrgMembersSectionStatus.Default);
  };

  const renderContent = () => {
    return (
      <Stack
        sx={{
          px: { xs: 4, lg: 8 },
          width: { sm: "100%", lg: "50%" },
          maxWidth: "lg",
          minWidth: "fit-content",
          fontFamily: "poppins",
          gap: { xs: 2, md: 4, xl: 6 },
        }}
      >
        <Stack sx={{ gap: 2 }}>
          <Typography component="h2" sx={{ fontWeight: 700, fontFamily: "Poppins" }}>
            Reassign {bulkActionUserList.length} members
          </Typography>
          <Typography component="div">
            Choose all groups these members should be included in. They will only see content
            assigned to the groups they’re part of.{" "}
            <Link
              href="https://support.yoodli.ai/en/articles/9613597-learning-content"
              target="_blank"
            >
              Learn more about groups
            </Link>
          </Typography>
        </Stack>
        <YoodliMultiSelect
          isCheckboxSelect
          value={groupsToAssign}
          onChange={(e, vals: YoodliSelectOption[], reason, detail) => {
            if (selectedGroupIds.includes(detail?.option?.value)) {
              setSelectedGroupIds(selectedGroupIds.filter((id) => id !== detail?.option?.value));
            } else {
              // if selecting a new hub but you havent made any edits yet, also include the default hub
              setSelectedGroupIds([...selectedGroupIds, detail?.option?.value].filter(Boolean));
            }
          }}
          renderTags={() => (
            <Typography sx={{ color: getDynamicColor("dark4"), ml: 2 }}>
              {selectedGroupIds.length} groups selected
            </Typography>
          )}
          placeholder="Search or select group(s)"
          options={groupOptions}
        />
        {selectedGroupIds.length > 0 && (
          <Stack sx={{ gap: { xs: 2, md: 4, xl: 6 } }}>
            <Stack sx={{ gap: 1 }}>
              <Typography>Selected members will be assigned to the following groups:</Typography>
              <Stack sx={{ overflow: "auto", maxHeight: 200 }}>
                {selectedGroupIds.map((id) => (
                  <Typography key={id} sx={{ fontWeight: 700 }}>
                    {defaultOrg?.hubs.find((h) => h.id === id)?.name}
                  </Typography>
                ))}
              </Stack>
            </Stack>
            <Stack sx={{ gap: 1 }}>
              <Typography>
                Click "Reassign" to apply this immediate change to the following members:
              </Typography>
              <Stack sx={{ overflow: "auto", maxHeight: 200 }}>
                {bulkActionUserList.map((member) => (
                  <Typography key={member.email}>{member.name}</Typography>
                ))}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  };

  return (
    <OrgSectionWrapper
      backCopy={backCopy}
      loading={loading}
      ctas={renderCtas()}
      handleBack={handleBack}
      showFooter
    >
      {renderContent()}
    </OrgSectionWrapper>
  );
}
