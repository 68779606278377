import React from "react";

// Components
import { Circle as CircleIcon } from "@mui/icons-material";
import { Button, Stack, SxProps, Box } from "@mui/material";
import PricingModal from "components/Pricing/PricingModal";
import UsageItem from "lib-frontend/components/UsageItem";

// Assets
import { ReactComponent as UpgradeIcon } from "images/icons/icon-upgrade.svg";

// Utils
import ThresholdReachedModal from "./ThresholdReachedModal";
import { usePricingUsage } from "hooks";
import { usePrevious } from "lib-frontend/hooks";
import { PricingUsage } from "lib-frontend/hooks/usePricingUsage";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getLiveUserDocReadonly } from "lib-frontend/utils/LiveUserDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { PricingModalCtaLocations } from "lib-fullstack/utils/productAnalyticEvents";
import { UsagePlanType } from "lib-fullstack/utils/pricingTypes";

const PricingUsageBar = ({
  location,
  pricingUsage,
  isWithinModal,
  onModalClose,
  isRecordingComplete,
  showPricingUsageGateModal,
  thresholdSx = {},
}: {
  location: PricingModalCtaLocations;
  pricingUsage?: PricingUsage;
  isWithinModal?: boolean;
  onModalClose?: () => void;
  isRecordingComplete?: boolean;
  showPricingUsageGateModal?: boolean;
  thresholdSx?: SxProps;
}): JSX.Element => {
  // Constants
  const userDocReadonly = getLiveUserDocReadonly();
  // allow pricingUsage to be passed from parent, or allow this component to fetch it itself
  const { thresholdHit, thresholdAlmostHit } = pricingUsage ?? usePricingUsage();
  const [thresholdJustHit, setThresholdJustHit] = React.useState(false);

  // State
  const [showPricingModal, setShowPricingModal] = React.useState(false);
  const [showThresholdHitModal, setShowThresholdHitModal] =
    React.useState(showPricingUsageGateModal);

  // Effects
  React.useLayoutEffect(() => {
    setShowThresholdHitModal(showPricingUsageGateModal);
  }, [showPricingUsageGateModal]);

  const prevThresholdHit = usePrevious(thresholdHit);
  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!prevThresholdHit && thresholdHit) {
      setThresholdJustHit(true);
    } else if (isRecordingComplete) {
      setThresholdJustHit(false);
    }
  }, [thresholdHit, isRecordingComplete]);

  // Handlers
  const handleUpgradeClick = (_location = location) => {
    setShowPricingModal(true);
    Instrumentation.logPricingModalViewed(_location, "UPGRADE", window.location.href);
    // wait until the open animation finishes before removing the threshold modal to avoid pop out
    setTimeout(() => {
      if (isWithinModal) {
        setShowThresholdHitModal(false);
      }
    }, 500);
  };
  const handlePricingModalClose = () => {
    if (!isWithinModal) {
      setShowPricingModal(false);
    } else {
      onModalClose?.();
    }
  };

  return (
    <>
      {showThresholdHitModal ? (
        <ThresholdReachedModal
          open={thresholdHit}
          location={location}
          isWithinModal={isWithinModal}
          onUpgradeClick={handleUpgradeClick}
          handleClose={
            isWithinModal
              ? () => {
                  onModalClose?.();
                }
              : null
          }
          overrideSx={{
            ...thresholdSx,
          }}
        />
      ) : (
        <Stack
          direction={{ xs: "row", sm: "row" }}
          alignItems="center"
          justifyContent="center"
          sx={{
            flexWrap: "wrap",
            columnGap: { xs: 1.5, sm: 3 },
            rowGap: 1.5,
            width: "100%",
            height: { xs: "fit-content", md: 70 },
            backgroundColor: getDynamicColor(thresholdAlmostHit ? "light2" : "light1"),
            p: 2,
            py: 1.5,
            borderRadius: isWithinModal ? 0 : "8px",
            border: `1px solid ${getDynamicColor("dark2")}`,
            maxWidth: "xxl",
          }}
        >
          <UsageItem
            open
            numUsed={userDocReadonly.usedSpeeches}
            quota={userDocReadonly.usageQuota}
            usagePlanType={userDocReadonly.usagePlanType as UsagePlanType}
            thresholdJustHit={thresholdJustHit}
            progressSize={22}
            icon={
              <CircleIcon
                sx={{
                  width: "12px",
                  height: "12px",
                  color: getDynamicColor("purple3"),
                }}
              />
            }
            progressColor={getDynamicColor("purple3")}
            textSx={{
              fontSize: { xs: 12, md: 14 },
              color: getDynamicColor("purple3"),
            }}
            wrapperSx={{
              gap: 1.5,
            }}
          />
          <Button
            variant="gradient"
            onClick={() => handleUpgradeClick()}
            startIcon={
              <Box sx={{ width: "19px", height: "16px" }}>
                <UpgradeIcon />
              </Box>
            }
            sx={{
              fontSize: 14,
              fontWeight: 700,
            }}
          >
            UPGRADE
          </Button>
        </Stack>
      )}

      <PricingModal
        open={showPricingModal}
        hideBackdrop={showThresholdHitModal || isWithinModal}
        onClose={handlePricingModalClose}
      />
    </>
  );
};

export default PricingUsageBar;
