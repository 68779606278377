// Utils
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

/**
 * Enum indicating desired SSO pop-up or redirect behavior during sign-in.
 */
export enum SsoPopupRedirectOption {
  /**
   * Use a pop-up window during SSO sign-in.
   */
  USE_POPUP = "use_popup",
  /**
   * Use a redirect during SSO sign-in, unless the site is embedded.
   * If the site is embedded, use a pop-up window.
   * This should generally be the choice for most SSO providers.
   */
  USE_REDIRECT = "use_redirect",
  /**
   * Use a redirect during SSO sign-in, even when the site is embedded.
   * This should only be used for SSO providers who are known to allow
   * redirects from embedded sites, and the customer acknowledges
   * that this is against the security best practices.
   */
  USE_EMBEDDED_REDIRECT = "use_embedded_redirect",
}

/**
 * Enum indicating desired SSO pop-up or redirect behavior during sign-in.
 */
export const SsoPopupRedirectOptionType = RTStringEnum(SsoPopupRedirectOption);
