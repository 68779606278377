type FilterIconProps = {
  stroke?: string;
};

export default function FilterIcon({ stroke }: FilterIconProps): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path d="M2 5H18" stroke="inherit" strokeWidth="2" strokeLinecap="round" />
      <path d="M4 10.5H16" stroke="inherit" strokeWidth="2" strokeLinecap="round" />
      <path d="M6 15.5H14" stroke="inherit" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
