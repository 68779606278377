import parse from "html-react-parser";
import React from "react";
import { useNavigate } from "react-router";

// Components
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Stack,
  Box,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  Collapse,
  Divider,
  Link,
} from "@mui/material";
import { TEMPLATE_SUB_TYPE_DATA } from "components/ConvoScenarios/convoScenarioUtils";
import { defaultHeaderSx } from "lib-frontend/ui/Theme";

// Assets
import { ReactComponent as CalendarIcon } from "images/icons/icon-calendar.svg";
import { ReactComponent as ScenarioIcon } from "images/icons/icon-scenario.svg";
import { ReactComponent as TrophyIcon } from "images/icons/icon-star-trophy.svg";

// Utils
import { OrgProgramsQueryKeys, ScenarioSubQueryKeys } from "./OrgPrograms";
import { ProgramDetails } from "./ProgramDetails";
import { ProgramStateIndicator } from "./ProgramStateIndicator";
import { StepProgressTracker } from "./StepProgressTracker";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  listCustomGoalsMemberView,
  listScenariosMemberView,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getScenarioPracticePath, parseProgramProgressDate } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { getSpeechSummaryPath } from "lib-frontend/utils/SpeechHandlers";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { GetScenarioLimitedResponse } from "lib-fullstack/api/scenarioApiTypes";
import { PlanStepResult, ProgramMemberViewItem } from "lib-fullstack/api/programApiTypes";
import { CustomGoalItem } from "lib-fullstack/api/scenarioApiTypes";
import { getDisplayableTime } from "lib-fullstack/utils/dateUtils";
import { GoalLabels } from "lib-fullstack/utils/defaultGoals";
import { PlanStepState, ProgramRecordState, UITestId } from "lib-fullstack/utils/enums";
import { scenarioTypeMap } from "lib-fullstack/utils/orgUtils";
import { ProgramMemberNextStepCtaWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";

type ProgramStepProps = {
  step: PlanStepResult;
  scenario: GetScenarioLimitedResponse;
  customGoals: CustomGoalItem[];
  programId: string;
};

const ProgramStep = ({ step, scenario, customGoals, programId }: ProgramStepProps) => {
  const navigate = useNavigate();
  const isSmallScreen = useIsSmallScreen();

  const { defaultOrgId } = React.useContext(UserOrgContext);

  const [expanded, setExpanded] = React.useState<boolean>(false);

  const stepDetails = [
    {
      icon: <ScenarioIcon fill={getDynamicColor("purple3")} />,
      text: TEMPLATE_SUB_TYPE_DATA[scenario.templateSubType].label,
    },
    {
      icon: (
        <Box
          sx={{
            svg: {
              "path, rect": {
                stroke: getDynamicColor("purple3"),
              },
              width: 20,
              height: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <TrophyIcon />
        </Box>
      ),
      text: `${step.completion_score ? `${step.completion_score}%` : ""}${
        step.completion_score && step.completion_attempts ? " or " : ""
      }${
        step.completion_attempts
          ? `${step.completion_attempts} attempt${step.completion_attempts !== 1 ? "s" : ""}`
          : ""
      }${step.completion_human_evaluation ? ` and grading` : ""}`.trim(),
    },
  ];

  const getScenarioGoals = () => {
    return scenario.goalIds
      .map((goalId) => {
        if (Object.keys(GoalLabels).includes(goalId)) {
          return GoalLabels[goalId];
        } else {
          return customGoals.find((goal) => goal.id === goalId)?.name;
        }
      })
      .join(", ");
  };

  return (
    <Stack
      gap={1}
      sx={{
        borderRadius: "8px",
        border: `1px solid ${getDynamicColor("dark3")}`,
        p: 3,
      }}
    >
      <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Stack gap={2} direction="row" sx={{ alignItems: "center" }}>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "18px",
              fontWeight: 700,
              color: getDynamicColor("purple3"),
            }}
          >
            {scenario.title}
          </Typography>
          {!isSmallScreen && <ProgramStateIndicator state={step.state} />}
        </Stack>
        <IconButton
          onClick={() => setExpanded((expanded) => !expanded)}
          sx={{ color: getDynamicColor("primary") }}
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Stack>
      <ProgramDetails programDetails={stepDetails} />
      {isSmallScreen && <ProgramStateIndicator state={step.state} />}
      <Collapse in={expanded}>
        <Stack gap={6} sx={{ pt: 3, pb: 4 }}>
          <Stack
            gap={1}
            sx={{ color: getDynamicColor("purple3"), fontFamily: "poppins", fontSize: "14px" }}
          >
            {scenario.persona && (
              <Typography>
                <strong>Default persona: </strong>
                {isSmallScreen && <br />}
                {scenario.persona.name}, {scenario.persona.job_title} ({scenario.persona.demeanor})
              </Typography>
            )}
            <Typography>
              <strong>Goals: </strong> {isSmallScreen && <br />}
              {scenario.goalIds.length > 0 ? getScenarioGoals() : "None"}
            </Typography>
            <Typography component="div">
              <strong>Description: </strong>
              {isSmallScreen && <br />}
              {parse(scenario.description)}
            </Typography>
          </Stack>
          {step.attempts.length > 0 && (
            <Stack gap={{ xs: 2, md: 3 }} direction="column-reverse">
              {step.attempts.map((attempt, index) => (
                <Stack gap={{ xs: 2, md: 1 }} key={attempt.speech_id}>
                  <Divider sx={{ borderColor: getDynamicColor("dark2") }} />
                  <Stack
                    gap={1}
                    direction={{ xs: "column", md: "row" }}
                    sx={{
                      alignItems: { xs: "flex-start", md: "center" },
                      justifyContent: "space-between",
                      px: { xs: 0, md: 3 },
                    }}
                  >
                    <Stack gap={0.5}>
                      <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
                        <Typography
                          sx={{
                            color: getDynamicColor("dark4"),
                            fontFamily: "poppins",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Attempt {index + 1}
                        </Typography>
                        <Typography
                          sx={{
                            color: getDynamicColor("purple3"),
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          {getDisplayableTime(attempt.total_time_sec)}
                        </Typography>
                      </Stack>
                      <Link
                        href={getSpeechSummaryPath(attempt.speech_slug, false)}
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          textDecorationLine: "underline",
                          "&:hover": {
                            textDecorationLine: "underline",
                          },
                        }}
                      >
                        {attempt.speech_name}
                      </Link>
                    </Stack>
                    <Stack gap={0.5}>
                      <Typography
                        sx={{
                          color: getDynamicColor("dark4"),
                          fontFamily: "poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Score achieved
                      </Typography>
                      <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
                        <Typography
                          sx={{
                            color: getDynamicColor("purple3"),
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {attempt.score ? `${attempt.score}%` : "N/A"}
                        </Typography>
                        {attempt.completed_by_this_speech && (
                          <ProgramStateIndicator state={PlanStepState.Completed} />
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          )}
        </Stack>
      </Collapse>
      <Button
        onClick={() => {
          Instrumentation.logProgramMemberNextStepCtaClicked(
            defaultOrgId,
            programId,
            ProgramMemberNextStepCtaWheres.LEARNING
          );
          navigate(
            getScenarioPracticePath(
              scenario.id,
              defaultOrgId,
              false,
              scenarioTypeMap[scenario.scenarioTypeId]
            )
          );
        }}
        variant={step.state === PlanStepState.Completed ? "text" : "outlined"}
        sx={{
          px: step.state === PlanStepState.Completed ? 0 : "auto",
          fontSize: step.state === PlanStepState.Completed ? "14px" : "12px",
          fontWeight: step.state === PlanStepState.Completed ? 700 : 600,
          textDecorationLine: step.state === PlanStepState.Completed ? "underline" : "none",
          "&:hover": {
            textDecorationLine: step.state === PlanStepState.Completed ? "underline" : "none",
          },
        }}
      >
        Practice {step.state === PlanStepState.Completed && "Again"}
      </Button>
    </Stack>
  );
};

type MemberProgramInfoProps = {
  program: ProgramMemberViewItem;
  handleBack: () => void;
};

export const MemberProgramInfo = ({ program, handleBack }: MemberProgramInfoProps): JSX.Element => {
  const navigate = useNavigate();

  const { defaultOrgId } = React.useContext(UserOrgContext);

  React.useEffect(() => {
    if (defaultOrgId) {
      Instrumentation.logProgramLearningPageViewed(defaultOrgId, program.id);
    }
  }, [defaultOrgId]);

  const scenariosQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.ScenarioList, defaultOrgId, ScenarioSubQueryKeys.Member],
    queryFn: () => listScenariosMemberView(defaultOrgId),
    enabled: !!defaultOrgId,
    refetchOnWindowFocus: false,
  });

  const customGoalsQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.GoalList, defaultOrgId],
    queryFn: () => listCustomGoalsMemberView(defaultOrgId),
    enabled: !!defaultOrgId,
  });

  const loading = scenariosQuery.isLoading || customGoalsQuery.isLoading || !defaultOrgId;

  const practiceNextScenario = () => {
    Instrumentation.logProgramMemberNextStepCtaClicked(
      defaultOrgId,
      program.id,
      ProgramMemberNextStepCtaWheres.LEARNING
    );
    const nextStep = program.plan_step_results.find(
      (step) => step.state !== PlanStepState.Completed
    );
    const nextScenario = scenariosQuery.data.contentArray.find(
      (scenario) => scenario.id === nextStep.scenario_id
    );
    navigate(
      getScenarioPracticePath(
        nextScenario.id,
        defaultOrgId,
        false,
        scenarioTypeMap[nextScenario.scenarioTypeId]
      )
    );
  };

  if (!program) {
    return;
  }

  return (
    <Stack
      gap={{ xs: 3, md: 6 }}
      sx={{
        minHeight: { xs: `calc(100vh - ${WEBCLIENT_TOP_NAVBAR_HEIGHT})`, md: "100dvh" },
        background: getDynamicColor("light1"),
        pb: { xs: 2, md: 14 },
      }}
    >
      <Box
        sx={{
          ...defaultHeaderSx,
          display: "flex",
          alignItems: "center",
          pl: 4,
        }}
      >
        <Button
          data-testid={UITestId.MyLearningProgramInfoBackButton}
          onClick={handleBack}
          startIcon={<ArrowBackIosIcon />}
          sx={{
            color: getDynamicColor("light1"),
          }}
        >
          Programs
        </Button>
      </Box>
      {loading ? (
        <CircularProgress sx={{ m: "auto" }} />
      ) : (
        <Stack gap={3} sx={{ px: { xs: 2, md: 7 } }}>
          <Stack
            gap={2}
            direction={{ xs: "column", md: "row" }}
            sx={{ justifyContent: "space-between" }}
          >
            <Stack gap={2}>
              <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
                <Typography
                  data-testid={`${UITestId.MyLearningProgramInfoTitle}-${program.id}`}
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "18px",
                    fontWeight: 700,
                    color: getDynamicColor("purple3"),
                  }}
                >
                  {program.name}
                </Typography>
                <ProgramStateIndicator state={program.state} />
              </Stack>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  color: getDynamicColor("purple3"),
                }}
              >
                {program.description}
              </Typography>
              {program.state !== ProgramRecordState.Completed && (
                <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
                  <CalendarIcon
                    style={{
                      color: getDynamicColor("purple3"),
                    }}
                  />
                  <Typography
                    sx={{
                      color: getDynamicColor("purple3"),
                      fontFamily: "poppins",
                      fontSize: 10,
                      fontWeight: 400,
                    }}
                  >
                    {parseProgramProgressDate(program)}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Stack gap={{ xs: 3, md: 4 }} direction={{ xs: "column-reverse", md: "column" }}>
              {program.state !== ProgramRecordState.Completed && (
                <Button
                  data-testid={`${UITestId.MyLearningProgramInfoPracticeButton}-${program.id}`}
                  onClick={practiceNextScenario}
                  variant="contained"
                  sx={{ fontSize: "12px" }}
                >
                  Practice {program.plan_step_results?.length > 1 ? "next " : ""}scenario
                </Button>
              )}
              <Box sx={{ maxWidth: { xs: "175px", md: "100%" } }}>
                <StepProgressTracker planStepResults={program.plan_step_results} />
              </Box>
            </Stack>
          </Stack>
          <Stack gap={5}>
            {program.plan_step_results.map((step, index) => (
              <ProgramStep
                key={`${step.scenario_id}-${index}`}
                step={step}
                scenario={scenariosQuery.data.contentArray.find(
                  (scenario) => scenario.id === step.scenario_id
                )}
                customGoals={customGoalsQuery.data.customGoals}
                programId={program.id}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
