import firebase from "firebase/app";
import React from "react";
import { useNavigate, useLocation } from "react-router";

// Components
import { Button, Stack, Typography, Box } from "@mui/material";
import TermsAndConditions from "components/Onboarding/TermsAndConditions";
import { OrgLoading } from "components/Orgs/OrgLoading";

// Assets
import { ReactComponent as PoweredByYoodliGraphic } from "images/graphics/graphic-powered-by-yoodli.svg";

// Utils
import { useQuery as useApiQuery, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { UserOrgContext, UserOrgProfile } from "lib-frontend/contexts/UserOrgContext";
import {
  actOrgInviteV2,
  getUser,
  getOrgAccessInfo,
  acceptInviteToHub,
} from "lib-frontend/modules/AxiosInstance";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { ErrorResponse, GetUserFieldType } from "lib-fullstack/api/apiTypes";
import { OrgInviteAction } from "lib-fullstack/api/orgApiTypes";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";
import { ApiErrorCode } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { OrgInviteQueryParams } from "lib-fullstack/utils/queryParams";

const ACCEPT_INVITE_QUERY_KEY = "acceptInvite";

export default function AcceptInvite(): JSX.Element {
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const location = useLocation();
  const qp = new URLSearchParams(location.search);
  const orgId = qp.get(OrgInviteQueryParams.ORG_ID);
  const hubId = qp.get(OrgInviteQueryParams.HUB_ID);

  const {
    setUserOrgProfile,
    invalidateDefaultOrgQuery,
    invalidateOrgListQuery,
    invalidateUserOrgQuery,
  } = React.useContext(UserOrgContext);

  const [error, setError] = React.useState<string>(undefined);
  const [signUpNotice, setSignUpNotice] = React.useState<string>(undefined);
  const [goToHome, setGoToHome] = React.useState<boolean>(false);

  const accessInfoQuery = useApiQuery({
    queryKey: [ACCEPT_INVITE_QUERY_KEY, orgId, hubId],
    queryFn: () => getOrgAccessInfo({ orgId: orgId, hubId: hubId }),
    enabled: !!orgId || !!hubId,
    refetchOnWindowFocus: false,
  });

  const routeHome = () => {
    setUserOrgProfile(UserOrgProfile.PERSONAL);
    navigate(WebServerExternalPath.HOME_LOGGED_IN);
  };

  const acceptInviteMutation = useMutation({
    mutationFn: () =>
      qp.has(OrgInviteQueryParams.HUB_ID) && qp.has(OrgInviteQueryParams.INVITE_ID)
        ? acceptInviteToHub(
            qp.get(OrgInviteQueryParams.HUB_ID),
            qp.get(OrgInviteQueryParams.INVITE_ID)
          )
        : actOrgInviteV2(orgId, firebase.auth().currentUser.email, OrgInviteAction.ACCEPT),
    onMutate: () => setError(undefined),
    onError: (e: AxiosError<ErrorResponse>) => {
      let errorText = "Error accepting invite. Please try again.";
      if (e.response?.data?.code === ApiErrorCode.InviteNotFound) {
        errorText = `Invitation to ${
          firebase.auth().currentUser.email
        } was not found. Please contact your organization admin if you believe this is an error.`;
        setGoToHome(true);
      } else if (e.response?.data?.code === ApiErrorCode.NoMoreLicense) {
        errorText =
          "This organization has reached the maximum number of members allowed. Please contact your organization admin to increase the number of licenses and then try again.";
      }
      setError(errorText);
    },
    onSuccess: async () => {
      const user = await getUser([GetUserFieldType.SIGN_UP_NOTICE]);
      await invalidateUserOrgQuery();
      await invalidateDefaultOrgQuery();
      await invalidateOrgListQuery();
      if (user?.sign_up_notice) {
        setSignUpNotice(user.sign_up_notice);
      } else {
        routeHome();
      }
    },
  });

  const loading = accessInfoQuery.isPending || acceptInviteMutation.isPending;

  if (loading) {
    return <OrgLoading />;
  }

  return signUpNotice ? (
    <TermsAndConditions signUpNotice={signUpNotice} onSuccess={routeHome} />
  ) : (
    <Box
      sx={{
        height: `calc(100vh - ${isSmallScreen ? WEBCLIENT_TOP_NAVBAR_HEIGHT : "0px"})`,
        backgroundColor: getDynamicColor("light1"),
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: 2,
      }}
    >
      <Stack
        gap={2}
        sx={{
          pt: 7,
          pb: 5,
          px: 2,
          alignItems: "center",
          borderRadius: "16px",
          border: !isSmallScreen && `2px solid ${getDynamicColor("dark2")}`,
          boxShadow: !isSmallScreen && Y_SHADOWS.box_shadow_1,
          width: "min(600px, 100%)",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          Hi {firebase.auth().currentUser.displayName}! Accept your invitation to
        </Typography>
        <Typography
          sx={{
            fontFamily: "poppins",
            fontSize: "28px",
            fontWeight: 800,
            background: getDynamicColor("gradient.default"),
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {accessInfoQuery.data.org_name}
        </Typography>
        {accessInfoQuery.data.data_redaction_days > 0 && (
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "12px",
              color: getDynamicColor("dark5"),
              maxWidth: "370px",
            }}
          >
            For security purposes, your admin has set up data retention. Upon joining{" "}
            {accessInfoQuery.data.org_name}, all existing recordings will be stored for{" "}
            <strong>{accessInfoQuery.data.data_redaction_days}</strong> day
            {accessInfoQuery.data.data_redaction_days === 1 ? "" : "s"}. New recordings and
            transcripts will be kept for <strong>{accessInfoQuery.data.data_redaction_days}</strong>{" "}
            day
            {accessInfoQuery.data.data_redaction_days === 1 ? "" : "s"}.
          </Typography>
        )}
        <Button
          onClick={() => {
            if (goToHome) {
              routeHome();
            } else {
              acceptInviteMutation.mutate();
            }
          }}
          variant="gradient"
          sx={{ fontSize: goToHome ? "12px" : "16px", px: "28px", py: 2, fontWeight: 600, mt: 2 }}
        >
          {goToHome ? "Go to Home" : "Join the organization"}
        </Button>
        {error && (
          <Typography
            sx={{
              color: getDynamicColor("redError"),
              fontFamily: "poppins",
              fontSize: "12px",
              fontWeight: 500,
            }}
          >
            {error}
          </Typography>
        )}
      </Stack>
      <Box sx={{ position: "absolute", bottom: 40 }}>
        <PoweredByYoodliGraphic />
      </Box>
    </Box>
  );
}
