import React from "react";

// Components
import { Stack } from "@mui/material";
import {
  CUSTOM_GOAL_NAME_MAX_CHARS,
  CUSTOM_GOAL_USER_DESC_MAX_CHARS,
} from "components/ConvoScenarios/convoScenarioUtils";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import { WYSIWYGEditor } from "components/WYSIWYGEditor";

// Utils
import { useWYSIWYG } from "hooks/useWYSIWYG";
import { ConvoScenarioStepWrapper } from "./ConvoScenarioStepWrapper";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CreateScoreGoalRequest } from "lib-fullstack/api/scenarioApiTypes";
import { ScoreGoal } from "lib-fullstack/db";

export type RatedGoalDescriptionStepProps = {
  ratedGoal: CreateScoreGoalRequest;
  setRatedGoal: React.Dispatch<React.SetStateAction<CreateScoreGoalRequest>>;
  goalEditId: string;
};

export const RatedGoalDescriptionStep = ({
  ratedGoal,
  goalEditId,
  setRatedGoal,
}: RatedGoalDescriptionStepProps): JSX.Element => {
  const handleUpdateRatedGoal = (key: keyof ScoreGoal, val: string | number) => {
    setRatedGoal((prev) => ({ ...prev, [key]: val }));
  };

  const { editor } = useWYSIWYG({
    content: ratedGoal.userDescription ?? "",
    placeholder: "e.g. Ask for constructive feedback during your conversation.",
    characterLimit: CUSTOM_GOAL_USER_DESC_MAX_CHARS,
    onUpdate: (update: string) => {
      handleUpdateRatedGoal("userDescription", update);
    },
  });

  React.useEffect(() => {
    if (ratedGoal?.userDescription !== editor?.getHTML()) {
      editor?.commands.setContent(ratedGoal?.userDescription);
    }
  }, [ratedGoal?.userDescription]);

  return (
    <ConvoScenarioStepWrapper
      title={goalEditId ? "Update Rated Goal" : "Create a Rated Goal"}
      subTitle="Name this goal and add a short description"
    >
      <Stack
        gap={6}
        sx={{
          position: "relative",
        }}
      >
        <YoodliLabeledInput
          label="Name"
          labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
          inputEl={
            <YoodliTextfield
              autoFocus
              maxChars={CUSTOM_GOAL_NAME_MAX_CHARS}
              inputProps={{
                className: "blockEnterToNavigate",
              }}
              InputLabelProps={{ shrink: true }}
              placeholder="e.g. Seek feedback"
              value={ratedGoal.name}
              onChange={(e) => handleUpdateRatedGoal("name", e.target.value)}
            />
          }
        />
        <YoodliLabeledInput
          label="Add a member-facing description (optional)"
          labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
          inputEl={
            <WYSIWYGEditor
              editor={editor}
              excludeHeaderStyles
              excludeNumberedList
              excludeBulletedList
              characterLimit={CUSTOM_GOAL_USER_DESC_MAX_CHARS}
            />
          }
        />
      </Stack>
    </ConvoScenarioStepWrapper>
  );
};
