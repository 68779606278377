import React from "react";

// Components
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { IconButton, Stack, Typography, Button } from "@mui/material";
import {
  FileLibraryFilterOption,
  SortFilterMenu,
  SortFilterType,
  SortOption,
} from "components/Builder/SortFilterMenu";
import PdfExtractor from "components/PdfExtractor";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";
import { YoodliTable } from "lib-frontend/components/YoodliComponents/YoodliTable";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import videoLightbox from "ui/VideoLightbox";

// Utils
import { ORG_FILES_QUERY_KEY } from "./OrgFileLibrary";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  PaginationState,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { deleteOrgFile, patchOrgFile } from "lib-frontend/modules/axiosOrgFiles";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getTimeDifference } from "lib-frontend/utils/orgUtils";
import { FileLibraryTableRow, TableRow } from "lib-frontend/utils/sharedTableUtils";
import { useIsMediumScreen } from "lib-frontend/utils/themeUtils";
import { OrgFileItem } from "lib-fullstack/api/orgFileApiTypes";
import { parseFileType } from "utils/orgContentUtils";

const FILE_LIBRARY_PAGE_SIZE = 10;

enum FileTableSortOptions {
  NameAsc = "name",
  NameDesc = "-name",
  TypeAsc = "type",
  TypeDesc = "-type",
  LastUpdatedAsc = "updated_date",
  LastUpdatedDesc = "-updated_date",
}

type FileTableProps = {
  files: OrgFileItem[];
};

export const FileTable = ({ files }: FileTableProps): JSX.Element => {
  const isMediumScreen = useIsMediumScreen();
  const columnHelper = createColumnHelper<FileLibraryTableRow>();
  const queryClient = useQueryClient();

  const { defaultOrgId } = React.useContext(UserOrgContext);

  const [searchText, setSearchText] = React.useState<string>("");
  const [filterBy, setFilterBy] = React.useState<SortOption>(undefined);
  const [sortBy, setSortBy] = React.useState<string>(FileTableSortOptions.LastUpdatedDesc);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: FILE_LIBRARY_PAGE_SIZE,
  });
  const [bulkActionFiles, setBulkActionFiles] = React.useState<string[]>([]);
  const [fileToDelete, setFileToDelete] = React.useState<FileLibraryTableRow>(null);
  const [fileToRename, setFileToRename] = React.useState<FileLibraryTableRow>(null);
  const [updatedFileName, setUpdatedFileName] = React.useState<string>("");
  const [pdfUrl, setPdfUrl] = React.useState<string>("");
  const { lightbox, setLightboxURL } = videoLightbox("");

  const filterOptions = React.useMemo(() => {
    return [
      { value: FileLibraryFilterOption.All, label: "All" },
      { value: FileLibraryFilterOption.Pdf, label: "PDF" },
      { value: FileLibraryFilterOption.Document, label: "Document" },
      { value: FileLibraryFilterOption.Video, label: "Video" },
      { value: FileLibraryFilterOption.Audio, label: "Audio" },
      { value: FileLibraryFilterOption.Spreadsheet, label: "Spreadsheet" },
      { value: FileLibraryFilterOption.Presentation, label: "Presentation" },
    ];
  }, []);

  // Need to memoize this state update to prevent rerendering the table and unfocusing the textfield
  const handleTextfieldChange = React.useCallback((e) => {
    setUpdatedFileName(e.target.value);
  }, []);

  const columns = React.useMemo(() => {
    return [
      columnHelper.accessor("name", {
        id: "name",
        header: () => "File name",
        cell: (info) => {
          const fileName = renameFileMutation.isPending ? updatedFileName : info.getValue();
          return (
            <Stack gap="6px">
              {fileToRename?.id === info.row.original.id ? (
                <Stack gap={1} direction="row" sx={{ alignItems: "center" }}>
                  <YoodliTextfield
                    value={updatedFileName}
                    onChange={handleTextfieldChange}
                    autoFocus
                    InputProps={{
                      sx: {
                        height: 36,
                        width: { xs: 175, lg: 250 },
                      },
                    }}
                    sx={{
                      width: { xs: 175, lg: 250 },
                    }}
                  />
                  <IconButton
                    onClick={() => renameFileMutation.mutate()}
                    sx={{ color: getDynamicColor("primary") }}
                  >
                    <CheckIcon />
                  </IconButton>
                </Stack>
              ) : (
                <Stack gap={1} direction="row" sx={{ alignItems: "center" }}>
                  <UploadFileIcon sx={{ width: 20, height: 20 }} />
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                      maxWidth: { xs: 70, sm: 175, lg: 250 },
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {fileName}
                  </Typography>
                </Stack>
              )}
              {isMediumScreen && (
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "10px",
                    fontWeight: 400,
                    color: getDynamicColor("dark5"),
                  }}
                >
                  Updated: {getTimeDifference(info.row.original.updated_date)}
                </Typography>
              )}
            </Stack>
          );
        },
      }),
      isMediumScreen
        ? null
        : columnHelper.accessor("updated_date", {
            id: "updated_date",
            header: () => "Last updated",
            cell: (info) => (
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 400,
                  color: getDynamicColor("dark5"),
                }}
              >
                {getTimeDifference(info.getValue())}
              </Typography>
            ),
          }), // TODO @danc: Implement upload progress bar for this column
      columnHelper.accessor("type", {
        id: "type",
        header: () => (isMediumScreen ? "Type" : "Content type"),
        cell: (info) => (
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "12px",
              fontWeight: 400,
              color: getDynamicColor("dark5"),
            }}
          >
            {info.getValue()}
          </Typography>
        ),
      }),
      columnHelper.accessor("id", {
        id: "actions",
        header: () => <></>,
        cell: (info) => (
          <YoodliMenu
            type={YoodliMenuButtonType.Icon}
            icon={<MoreVertIcon />}
            buttonSx={{ border: "none", color: getDynamicColor("dark4") }}
            menuDisabled={!!fileToRename}
            menuItems={[
              {
                title: (
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Delete
                  </Typography>
                ),
                type: YoodliMenuItemType.Primary,
                onClick: () => {
                  setFileToDelete(info.row.original);
                },
              },
              {
                title: (
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Preview
                  </Typography>
                ),
                type: YoodliMenuItemType.Primary,
                onClick: () => handleViewFile(info.getValue()),
              },
              {
                title: (
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Rename
                  </Typography>
                ),
                type: YoodliMenuItemType.Primary,
                onClick: () => {
                  setFileToRename(info.row.original);
                  setUpdatedFileName(info.row.original.name);
                },
              },
              {
                title: (
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Download
                  </Typography>
                ),
                type: YoodliMenuItemType.Primary,
                onClick: () => handleDownloadFile(info.row.original.signed_url),
              },
            ]}
          />
        ),
      }),
    ].filter(Boolean);
  }, [
    files,
    isMediumScreen,
    fileToRename,
    updatedFileName,
    setFileToRename,
    handleTextfieldChange,
  ]);

  const rows: FileLibraryTableRow[] = React.useMemo(() => {
    // Filter files based on search and menu filter
    const filteredFiles = files.filter((file) => {
      return (
        (!searchText || file.name.toLowerCase().includes(searchText.toLowerCase())) &&
        (!filterBy || parseFileType(file.mime_type).includes(filterBy.label))
      );
    });

    // Sort files based on filter
    filteredFiles.sort((a, b) => {
      if (sortBy === FileTableSortOptions.NameAsc) {
        return a.name.localeCompare(b.name);
      } else if (sortBy === FileTableSortOptions.NameDesc) {
        return b.name.localeCompare(a.name);
      } else if (sortBy === FileTableSortOptions.TypeAsc) {
        return a.mime_type.localeCompare(b.mime_type);
      } else if (sortBy === FileTableSortOptions.TypeDesc) {
        return b.mime_type.localeCompare(a.mime_type);
      } else if (sortBy === FileTableSortOptions.LastUpdatedAsc) {
        return a.updated_date.localeCompare(b.updated_date);
      } else if (sortBy === FileTableSortOptions.LastUpdatedDesc) {
        return b.updated_date.localeCompare(a.updated_date);
      }
      return 0;
    });

    // Paginate and map files
    const startIndex = pagination.pageIndex * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    return filteredFiles.slice(startIndex, endIndex).map((file) => ({
      id: file.id,
      name: file.name,
      updated_date: file.updated_date,
      type: parseFileType(file.mime_type),
      signed_url: file.signed_url,
    }));
  }, [files, searchText, sortBy, filterBy, pagination]);

  const deleteFileMutation = useMutation({
    mutationFn: () => deleteOrgFile(defaultOrgId, fileToDelete.id, { space_id: "default" }),
    onSuccess: () => {
      setBulkActionFiles(bulkActionFiles.filter((fileId) => fileId !== fileToDelete.id));
      return queryClient.invalidateQueries({ queryKey: [ORG_FILES_QUERY_KEY, defaultOrgId] });
    },
  });

  const bulkDeleteFileMutation = useMutation({
    mutationFn: () =>
      Promise.all(
        bulkActionFiles.map((fileId) =>
          deleteOrgFile(defaultOrgId, fileId, { space_id: "default" })
        )
      ),
    onSuccess: () => {
      setBulkActionFiles([]);
      return queryClient.invalidateQueries({ queryKey: [ORG_FILES_QUERY_KEY, defaultOrgId] });
    },
  });

  const renameFileMutation = useMutation({
    mutationFn: () =>
      patchOrgFile(defaultOrgId, fileToRename.id, { space_id: "default", name: updatedFileName }),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [ORG_FILES_QUERY_KEY, defaultOrgId] });
      setFileToRename(null);
    },
  });

  const handleSelectAllTableItems = () => {
    if (bulkActionFiles.length > 0) {
      setBulkActionFiles([]);
    } else {
      setBulkActionFiles(rows.map((row) => row.id));
    }
  };

  const handleSelectTableItem = (rowId: string) => {
    setBulkActionFiles((oldBulkActionFiles) => {
      if (oldBulkActionFiles.includes(rowId)) {
        return oldBulkActionFiles.filter((fileId) => fileId !== rowId);
      } else {
        return [...oldBulkActionFiles, rowId];
      }
    });
  };

  const handleDownloadFile = async (signedUrl: string) => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.cssText = "display: block";
    a.href = signedUrl;
    a.click();
    document.body.removeChild(a);
  };

  const handleViewFile = (fileId: string) => {
    const file = files.find((x) => x.id === fileId);
    if (file) {
      if (file.mime_type?.includes("video") || file.mime_type?.includes("audio")) {
        setLightboxURL(file.signed_url);
      } else if (file.mime_type.includes("pdf")) {
        setPdfUrl(file.signed_url);
      } else {
        console.error(`Unsupported file type for viewing: ${file.mime_type}`);
        return;
      }
    } else {
      console.error(`Could not find file with id: ${fileId}`);
      return;
    }
  };

  const table = useReactTable({
    data: rows,
    columns: columns,
    state: {
      pagination,
    },
    rowCount: files.length,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  return (
    <>
      <Stack gap={2}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={1}
          justifyContent="space-between"
          sx={{ width: "100%", alignItems: { xs: "flex-end", md: "center" }, px: { xs: 1, md: 0 } }}
        >
          {bulkActionFiles.length > 0 && (
            <Stack
              direction="row"
              gap={1}
              sx={{
                alignItems: "center",
                backgroundColor: getDynamicColor("programChart.blue"),
                borderRadius: "50px",
                height: 35,
              }}
            >
              <IconButton onClick={() => setBulkActionFiles([])}>
                <CloseIcon sx={{ width: 16, height: 16, color: getDynamicColor("purple3") }} />
              </IconButton>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontWeight: 500,
                  fontSize: "12px",
                  color: getDynamicColor("purple3"),
                  whiteSpace: "nowrap",
                }}
              >
                {bulkActionFiles.length} selected
              </Typography>
              <Button
                onClick={() => bulkDeleteFileMutation.mutate()} // TODO @danc: add confirmation modal (check with design)
                disabled={!!fileToRename}
                color="error"
                endIcon={<DeleteOutlineIcon sx={{ width: 16, height: 16 }} />}
                sx={{ fontWeight: 500, fontSize: "12px" }}
              >
                Delete
              </Button>
            </Stack>
          )}
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              gap: "20px",
              flexWrap: "nowrap",
              ml: "auto",
            }}
          >
            <SortFilterMenu
              sortFilterType={SortFilterType.Filter}
              sortOptions={filterOptions}
              sortBy={filterBy}
              setSortBy={setFilterBy}
            />
            <YoodliSearchBar
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              clearSearch={() => setSearchText("")}
              placeholder="Search Files"
              InputProps={{
                sx: {
                  height: "40px",
                },
              }}
              sx={{
                minWidth: { xs: "125px", md: "150px" },
                maxWidth: { xs: "unset", sm: "210px" },
                height: "40px",
              }}
            />
          </Stack>
        </Stack>
        <YoodliTable
          table={table as Table<TableRow>} // TODO @danc: How to fix this type issue?
          emptyState={<div>TODO</div>} // TODO @danc: Implement empty state
          pagination={pagination}
          setPagination={setPagination}
          sortProps={{
            sortAvailableColumns: ["name", "updated_date", "type"],
            setSortFilter: setSortBy,
          }}
          bulkActionProps={{
            showBulkAction: true,
            allChecked: bulkActionFiles.length === rows.length,
            someChecked: bulkActionFiles.length > 0,
            onHeaderChecked: handleSelectAllTableItems,
            handleRowItemChecked: handleSelectTableItem,
            isRowItemChecked: (row) => bulkActionFiles.includes(row),
          }}
          dataRowSxOverride={{
            height: { xs: 75, lg: 55 },
          }}
        />
      </Stack>
      <YoodliCtaModal
        open={!!fileToDelete}
        ctaBody={{
          title: `Are you sure you want to delete ${fileToDelete?.name}?`,
          subtitle: "This will permanently remove it from your library and cannot be undone.",
        }}
        theme={YoodliCtaModalTheme.Danger}
        close={() => setFileToDelete(null)}
        buttons={
          {
            primary: { text: "Delete", handler: deleteFileMutation.mutate },
            secondary: { text: "Cancel", handler: () => setFileToDelete(null) },
          } as CtaButtonHandlers
        }
      />
      {lightbox}
      <PdfExtractor pdfUrl={pdfUrl} useDialog onDialogClose={() => setPdfUrl("")} />
    </>
  );
};
