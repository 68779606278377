import React from "react";

interface UIStateContextType {
  isStandardAppBarVisible: boolean;
  setIsStandardAppBarVisible: (visible: boolean) => void;
}

const UIStateContext = React.createContext<UIStateContextType | undefined>(undefined);

export function UIStateProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [isStandardAppBarVisible, setIsStandardAppBarVisible] = React.useState(true);

  return (
    <UIStateContext.Provider value={{ isStandardAppBarVisible, setIsStandardAppBarVisible }}>
      {children}
    </UIStateContext.Provider>
  );
}

export function useUIState(): UIStateContextType {
  const context = React.useContext(UIStateContext);
  if (context === undefined) {
    throw new Error("useUIState must be used within a UIStateProvider");
  }
  return context;
}
