// Components
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

// todo styling - https://github.com/Yoodli/yoodli/issues/14014

type ContentSpaceCardProps = {
  name: string;
  adminCount: number;
  selected: boolean;
  isDefault: boolean;
  additionalMenu?: JSX.Element;
};

const ContentSpaceCard = ({
  name,
  adminCount,
  selected,
  isDefault,
  additionalMenu,
}: ContentSpaceCardProps): JSX.Element => {
  return (
    <Stack
      sx={{
        border: `1px solid ${getDynamicColor("dark3")}`,
        borderRadius: "4px",
        width: "424px",
        height: "256px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
        }}
      >
        {name}
      </Typography>
      <Typography>{`admin Count: ${adminCount}`}</Typography>
      <Typography>{`${selected ? "selected" : "not selected"}`}</Typography>
      <Typography>{`${isDefault ? "default space" : "not default space"}`}</Typography>
      {additionalMenu}
    </Stack>
  );
};

export default ContentSpaceCard;
