// Components
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Stack, Box, Button, CircularProgress, SxProps } from "@mui/material";
import { defaultHeaderSx, ADMIN_HEADER_HEIGHT } from "lib-frontend/ui/Theme";

// Utils
import { useNotification } from "lib-frontend/contexts/useNotification";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";

type OrgSectionWrapperProps = {
  backCopy: string;
  handleBack: () => void;
  loading: boolean;
  ctas?: JSX.Element;
  footerHeight?: string;
  showFooter?: boolean;
  backCtaSx?: SxProps;
  contentWrapperSx?: SxProps;
};

const FOOTER_HEIGHT = "85px";

export default function OrgSectionWrapper({
  children,
  backCopy,
  handleBack,
  loading,
  ctas,
  showFooter = true,
  footerHeight = FOOTER_HEIGHT,
  backCtaSx,
  contentWrapperSx,
}: React.PropsWithChildren<OrgSectionWrapperProps>): JSX.Element {
  const isSmallScreen = useIsSmallScreen();
  const { notifAnchorRef } = useNotification();
  return (
    <Stack
      sx={{
        height: `calc(100vh - ${isSmallScreen ? WEBCLIENT_TOP_NAVBAR_HEIGHT : "0px"})`,
        background: getDynamicColor("light1"),
      }}
    >
      <Box
        sx={{
          ...defaultHeaderSx,
          display: "flex",
          alignItems: "center",
        }}
        ref={notifAnchorRef}
      >
        <Button
          onClick={handleBack}
          startIcon={<ArrowBackIosIcon />}
          sx={{
            ml: { xs: 1, md: 4 },
            fontWeight: 700,
            letterSpacing: "0.17px",
            color: getDynamicColor("light1"),
            textAlign: "left",
            fontSize: { xs: 14, md: 16 },
            ...backCtaSx,
          }}
        >
          {backCopy}
        </Button>
      </Box>

      <Box
        sx={{
          py: { xs: 4, md: 8 },
          height: `calc(100% - ${ADMIN_HEADER_HEIGHT}px - ${showFooter ? footerHeight : "0px"})`,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          maxWidth: "xxl",
          ...contentWrapperSx,
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          children
        )}
      </Box>
      {showFooter && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            borderTop: `0.5px solid ${getDynamicColor("dark4")}`,
            height: footerHeight,
            alignItems: "center",
          }}
        >
          {ctas}
        </Box>
      )}
    </Stack>
  );
}
