import React from "react";

// Components
import { Divider, Stack } from "@mui/material";
import CoursesSection from "components/Orgs/ManageContent/VideosAndCourses/CoursesSection";
import DemoVideosSection from "components/Orgs/ManageContent/VideosAndCourses/DemoVideosSection";

// Utils
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { ContentVideoState } from "lib-fullstack/utils/enums";
import { ContentsViewResponse } from "lib-fullstack/api/hubApiTypes";

export const VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY = "videosAndCourses";

export const LearningMaterialsTab = ({
  videosAndCourses,
}: {
  videosAndCourses: ContentsViewResponse;
}): JSX.Element => {
  const { defaultOrgId, isDefaultOrgOwnerAdmin } = React.useContext(UserOrgContext);
  const hubDemoVideos = videosAndCourses?.demo_videos.filter((video) =>
    [ContentVideoState.READY].includes(video.state)
  );

  const hubCourses = videosAndCourses?.courses;

  return (
    <Stack gap={4}>
      <DemoVideosSection
        isHubAdmin={false}
        showManageContentButton={isDefaultOrgOwnerAdmin}
        demoVideos={hubDemoVideos}
        selectedOrgId={defaultOrgId}
      />
      <Divider
        sx={{
          mx: { xs: -3, md: -4, lg: -6 },
          borderColor: getDynamicColor("dark3"),
        }}
      />
      <CoursesSection courses={hubCourses} isHubAdmin={false} selectedOrgId={defaultOrgId} />
      <Divider
        sx={{
          mx: { xs: -3, md: -4, lg: -6 },
          borderColor: getDynamicColor("dark3"),
        }}
      />
    </Stack>
  );
};
