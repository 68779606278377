import React from "react";

// Components
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import { Box, CircularProgress, Stack } from "@mui/material";
import ActionCard from "lib-frontend/components/YoodliComponents/ActionCard";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";

// Assets
import { ReactComponent as CsvIcon } from "../../../images/icons/pdf-icon.svg";

// Utils
import { MEMBER_COUNT_HEIGHT, OrgMembersQueryKeys } from "../Members/OrgMembers";
import { TableActionBar, TableTab } from "../TableActionBar";
import { ORG_HUBS_HEADER_HEIGHT } from "./HubsHeader";
import { HubsInviteTable } from "./HubsInviteTable";
import { HubsMemberTable } from "./HubsMemberTable";
import { OrgHubsSectionStatus } from "./OrgHubs";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { useQueryParamState } from "hooks/useQueryParamState";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { inviteUserToHub } from "lib-frontend/modules/AxiosInstance";
import { TableTabLabel, getHubInviteCount, getHubUserCount } from "lib-frontend/utils/orgUtils";
import { OrgHubsQueryParams } from "lib-fullstack/utils/queryParams";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";
import { HubRole } from "lib-fullstack/utils/enums";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OrgInviteTypes } from "lib-fullstack/utils/productAnalyticEvents";
import { MemberInfo } from "../Members/MembersTable";
import { OrgMemberResponse } from "lib-fullstack/api/orgApiTypes";

type ActivityProps = {
  orgId: string;
  hubId: string;
  setSectionStatus: (status: OrgHubsSectionStatus) => void;
  handleOpenDashboard: (user: OrgMemberResponse) => void;
  bulkActionUserList: MemberInfo[];
  setBulkActionUserList: React.Dispatch<React.SetStateAction<MemberInfo[]>>;
};

export const tableTabDefaults: TableTab[] = [
  {
    label: TableTabLabel.Members,
    count: 0,
  },
  {
    label: TableTabLabel.Invites,
    count: 0,
  },
];

export const Activity = ({
  orgId,
  hubId,
  setSectionStatus,
  handleOpenDashboard,
  bulkActionUserList,
  setBulkActionUserList,
}: ActivityProps): JSX.Element => {
  const topContentRef = React.useRef<HTMLDivElement>(null);
  const [topContentHeight, setTopContentHeight] = React.useState<number>(
    topContentRef?.current?.clientHeight ?? 0
  );
  const { showNotificationBanner } = useNotification();

  const [selectedTabLabel, setSelectedTabLabel] = useQueryParamState(
    OrgHubsQueryParams.TABLE,
    TableTabLabel.Members
  );
  const [searchText, setSearchText] = React.useState<string>("");
  const [inviteLinkCopied, setInviteLinkCopied] = React.useState<boolean>(false);

  const { data: tableTabs, isPending: userCountLoading } = useApiQuery({
    queryKey: [OrgMembersQueryKeys.HubUserCounts, orgId, hubId],
    queryFn: async () => {
      const invitesCount = await getHubInviteCount(orgId, hubId);
      const membersCount = await getHubUserCount(orgId, hubId);
      return [
        {
          label: TableTabLabel.Members,
          count: membersCount,
        },
        {
          label: TableTabLabel.Invites,
          count: invitesCount,
        },
      ] as TableTab[];
    },
    enabled: !!orgId && !!hubId,
  });

  const handleCopyInviteLink = () => {
    if (!inviteLinkCopied) {
      inviteUserToHub(hubId, null, HubRole.MEMBER)
        .then((invite) => {
          showNotificationBanner("Invite link copied to clipboard", {
            variant: YoodliNotificationBannerVariant.Success,
          });
          setInviteLinkCopied(true);
          copyToMyClipboard(`${window.location.origin}/${invite.invite_path}`);
          Instrumentation.logOrgInvitationSent(orgId, OrgInviteTypes.LINK);
        })
        .catch((err) => {
          console.error("Error getting invite url", err);
          showNotificationBanner("There was a problem getting your invite URL.", {
            variant: YoodliNotificationBannerVariant.Danger,
          });
        });
      setTimeout(() => {
        setInviteLinkCopied(false);
      }, 2000);
    }
  };

  const actionCards = [
    {
      icon: <MailOutlineRoundedIcon sx={{ width: 24, height: 24 }} />,
      title: "Invite members",
      subtitle: `Send invitations via email`,
      handler: () => {
        setSectionStatus(OrgHubsSectionStatus.InviteMembers);
      },
    },
    {
      icon: <InsertLinkRoundedIcon sx={{ width: 24, height: 24 }} />,
      title: "Copy invite link",
      subtitle: "Copy a link to the current group",
      handler: handleCopyInviteLink,
    },
    {
      icon: <CsvIcon style={{ width: 24, height: 24 }} />,
      title: "Bulk invite by CSV",
      subtitle: "Upload a CSV to bulk invite members",
      handler: () => setSectionStatus(OrgHubsSectionStatus.BulkInviteByCsv),
    },
  ];

  const recalcTopContentHeight = () => {
    setTopContentHeight(topContentRef?.current?.clientHeight);
  };

  const renderTable = () => {
    let content = (
      <HubsMemberTable
        orgId={orgId}
        hubId={hubId}
        searchText={searchText}
        setSearchText={setSearchText}
        handleOpenDashboard={handleOpenDashboard}
        recalcTopContentHeight={recalcTopContentHeight}
        bulkActionUserList={bulkActionUserList}
        setBulkActionUserList={setBulkActionUserList}
      />
    );
    if (selectedTabLabel === TableTabLabel.Invites) {
      content = (
        <HubsInviteTable
          orgId={orgId}
          hubId={hubId}
          searchText={searchText}
          setSearchText={setSearchText}
          recalcTopContentHeight={recalcTopContentHeight}
          bulkActionUserList={bulkActionUserList}
          setBulkActionUserList={setBulkActionUserList}
        />
      );
    }
    return (
      <Box
        sx={{
          position: "relative",
          height: `calc(100% - ${topContentHeight + MEMBER_COUNT_HEIGHT}px)`,
        }}
      >
        {content}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        p: 4,
        height: `calc(100dvh - ${ORG_HUBS_HEADER_HEIGHT}px)`,
      }}
    >
      <Stack gap={4} sx={{ position: "relative", height: "100%" }}>
        <Stack gap={4} ref={topContentRef} onLoad={recalcTopContentHeight}>
          <Stack
            direction="row"
            gap={{ xs: 2, lg: 4 }}
            flexWrap={{ xs: "nowrap", sm: "wrap" }}
            justifyContent={{ xs: "unset", sm: "center" }}
            overflow={{ xs: "auto", sm: "unset" }}
          >
            {actionCards.map((card) => (
              <ActionCard
                key={card.title}
                icon={card.icon}
                title={card.title}
                subtitle={card.subtitle}
                handler={card.handler}
              />
            ))}
          </Stack>
          {!userCountLoading && (
            <TableActionBar
              origin="group"
              tableTabs={tableTabs}
              selectedTabLabel={selectedTabLabel as TableTabLabel}
              setSelectedTabLabel={setSelectedTabLabel}
              searchText={searchText}
              setSearchText={setSearchText}
              bulkActionUserList={bulkActionUserList}
              setBulkActionUserList={setBulkActionUserList}
              setSectionStatus={setSectionStatus}
            />
          )}
        </Stack>
        {!userCountLoading && renderTable()}
        {userCountLoading && (
          <Stack
            sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
          >
            <CircularProgress />
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
