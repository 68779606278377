import React from "react";

// Utils
// Utilities/Functions
import { SortStatus, TableSortBase } from "./TableSortBase";
import { Table } from "@tanstack/react-table";
import { TableRow } from "lib-frontend/utils/sharedTableUtils";

export type TableSortClientProps = {
  column: string;
  table: Table<TableRow>;
};

export const TableSortClient = (props: { column: string; table: Table<TableRow> }): JSX.Element => {
  const [sortCreated, setSortCreated] = React.useState<SortStatus>(SortStatus.NONE);
  const { column, table } = props;
  const handler = table.getColumn(column).getToggleSortingHandler();
  const handleSortCreated = (e) => {
    handler(e);
    if (sortCreated === SortStatus.NONE) {
      setSortCreated(SortStatus.ASC);
    } else if (sortCreated === SortStatus.ASC) {
      setSortCreated(SortStatus.DESC);
    } else {
      setSortCreated(SortStatus.NONE);
    }
  };

  return (
    <TableSortBase
      sortCreated={sortCreated}
      setSortCreated={setSortCreated}
      handleSortCreated={handleSortCreated}
    />
  );
};
