import React from "react";

// Components
import {
  ExpandCircleDownRounded as ExpandCircleDownRoundedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
  CloseRounded as CloseRoundedIcon,
} from "@mui/icons-material";
import {
  Box,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
  SvgIconTypeMap,
  TooltipProps,
  Stack,
  Divider,
  Drawer,
  DrawerProps,
  IconButton,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

// Utils
import YoodliTooltip from "./YoodliTooltip";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";

export type YoodliSelectOption = {
  // the main option label
  label: string;
  // the subLabel underneath the main label
  subLabel?: string;
  // tag for the option in the top right
  labelTag?: string | JSX.Element;
  // actual behind the scenes unique value (uid, key, etc.)
  value: string;
  // styles for the entire MenuItem element
  optionSx?: SxProps;
  // styles for the main label (this affects the menu options as well as the selected option)
  labelSx?: SxProps;
  // override styles that only affect the selected label (outside the menu dropdown, in the select input box)
  selectedLabelSx?: SxProps;
  // override styles that only affect the selected option in the menu
  selectedOptionSx?: SxProps;
  // styles for the subLabel, if it exists
  subLabelSx?: SxProps;
  // styles for the labelTag, if it exists
  labelTagSx?: SxProps;
  // show a divider between this and the previous element
  showDivider?: boolean;
  // display this JSX element instead of the default option (used in MultiSelect)
  JSXOverride?: JSX.Element;
  disabled?: boolean;
};
export interface YoodliSelectProps {
  options?: YoodliSelectOption[];
  loading?: boolean;
  hideLoader?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  yoodliVariant?: YoodliSelectVariant;
  customTheme?: YoodliSelectVariantProps;
  tooltip?: Omit<TooltipProps, "children"> & { key?: string };
  hideTooltipWhenOpen?: boolean;
  useDrawer?: boolean;
  drawerProps?: DrawerProps;
  drawerTopOffset?: number;
  // allow global override on the styles of the selected label/option, otherwise use the theme's styles + defaults
  selectedLabelSx?: SxProps;
  selectedOptionSx?: SxProps;
}

export enum YoodliSelectVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  LIGHT = "light",
  UNDERLINE = "underline",
}

type IconType = OverridableComponent<SvgIconTypeMap<object, "svg">> & { muiName: string };
type YoodliSelectVariantProps = {
  selectSx?: SxProps;
  inputSx?: SxProps;
  menuSx?: SxProps;
  menuListSx?: SxProps;
  optionSx?: SxProps;
  // styles for the selected label i.e. showing in the select input box
  selectedLabelSx?: SxProps;
  // styles for the selected option in the menu
  selectedOptionSx?: SxProps;
  optionLabelSx?: SxProps;
  subLabelSx?: SxProps;
  iconSx?: SxProps;
  icon?: IconType;
};

export const SELECT_VALUE_STYLES: SxProps = {
  fontWeight: 700,
  fontSize: { xs: 14, md: 16 },
  width: "100%",
};

const primaryTheme: YoodliSelectVariantProps = {
  selectSx: {
    minWidth: { xs: "100%", md: 250 },
    backgroundColor: getDynamicColor("light1"),
  },
  inputSx: {
    fontSize: 16,
    fontWeight: 500,
  },
  selectedLabelSx: {
    color: getDynamicColor("purple3"),
  },
  selectedOptionSx: {
    color: getDynamicColor("primary"),
    backgroundColor: getDynamicColor("light2"),
    "svg *": {
      fill: getDynamicColor("primary"),
    },
    "&:hover": {
      color: getDynamicColor("primary"),
      backgroundColor: getDynamicColor("light2"),
    },
  },
  menuSx: {
    mt: 1,
  },
  menuListSx: {
    p: 1.5,
    // need to override default MUI padding on list with !important
    pr: "12px !important",
  },
  optionSx: {
    p: 1.5,
    fontSize: 16,
    fontWeight: 500,
  },
  icon: ExpandCircleDownRoundedIcon,
};

const secondaryTheme: YoodliSelectVariantProps = {
  inputSx: {
    fontSize: 14,
    fontWeight: 400,
  },
  menuSx: {
    mt: 0.5,
  },
  menuListSx: {
    p: 1,
    // need to override default MUI padding on list with !important
    pr: "8px !important",
  },
  optionSx: {
    p: 1,
    fontSize: 12,
    fontWeight: 400,
  },
  icon: ExpandMoreRoundedIcon,
};

export const setBorderColor = (isDisabled: boolean, isOpen: boolean): string => {
  if (isDisabled) {
    return `1px solid ${getDynamicColor("dark4")}`;
  }
  if (isOpen) {
    return `1px solid ${getDynamicColor("primary")}`;
  }
  return `1px solid ${getDynamicColor("dark4")}`;
};

export const YoodliSelect = (props: SelectProps & YoodliSelectProps): JSX.Element => {
  const YoodliSelectThemes: Record<YoodliSelectVariant, YoodliSelectVariantProps> =
    React.useMemo(() => {
      return {
        [YoodliSelectVariant.PRIMARY]: primaryTheme,
        [YoodliSelectVariant.LIGHT]: {
          ...primaryTheme,
          selectSx: {
            ...primaryTheme.selectSx,
            color: getDynamicColor("light1"),
            backgroundColor: "transparent",
            border: "none",
            minWidth: "auto",
          },
          selectedLabelSx: {
            color: getDynamicColor("light1"),
          },
          iconSx: {
            color: getDynamicColor("light1"),
            svg: {
              color: getDynamicColor("light1"),
              fill: getDynamicColor("light1"),
            },
          },
        },
        [YoodliSelectVariant.SECONDARY]: {
          ...secondaryTheme,
          selectSx: {
            minWidth: { xs: "100%", md: 150 },
          },
        },
        [YoodliSelectVariant.UNDERLINE]: {
          ...secondaryTheme,
          selectSx: {
            color: getDynamicColor("light1"),
            backgroundColor: "transparent",
            border: "none",
            minWidth: "auto",
          },
          optionLabelSx: {
            mb: 0,
            fontSize: 12,
            fontWeight: 400,
          },
          labelSx: {
            mb: 0,
          },
        },
      };
    }, []);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);

  const tooltipShowingTimeout = React.useRef<NodeJS.Timeout | null>(null);

  // if the value changes and the drawer is open, close it
  React.useEffect(() => {
    if (drawerOpen && props.value !== "placeholder") {
      setDrawerOpen(false);
    }
  }, [props.value]);

  // TODO: this only works for drawers at the moment. need to apply to menus as well
  React.useEffect(() => {
    if (drawerOpen) {
      setTimeout(() => {
        const selectedElement = document.querySelector(".selected");
        const scrollContainer = document.querySelector(".yoodli-select-drawer-container");

        if (selectedElement && scrollContainer) {
          const containerRect = scrollContainer.getBoundingClientRect();
          const elementRect = selectedElement.getBoundingClientRect();

          // Check if element is outside the visible area of the container
          const isAbove = elementRect.top < containerRect.top;
          const isBelow = elementRect.bottom > containerRect.bottom;

          // Only scroll if the element is not fully visible
          if (isAbove || isBelow) {
            selectedElement.scrollIntoView({ block: "start" });
          }
        }
      }, 20);
    }
  }, [drawerOpen]);

  // custom props are not supported on MuiSelect, so copy props and remove them before spreading
  const selectProps = { ...props };
  delete selectProps.loading;
  delete selectProps.yoodliVariant;
  delete selectProps.customTheme;
  delete selectProps.hideLoader;
  delete selectProps.hideTooltipWhenOpen;
  delete selectProps.tooltip;
  delete selectProps.drawerProps;
  delete selectProps.useDrawer;
  const variant = props.yoodliVariant ?? YoodliSelectVariant.PRIMARY;
  const theme: YoodliSelectVariantProps = props.customTheme ?? YoodliSelectThemes[variant];
  const mergedSelectSx: SxProps = { ...theme?.selectSx, ...props?.sx };
  const mergedMenuSx: SxProps = { ...theme?.menuSx, ...props?.MenuProps?.sx };
  const mergedMenuListSx: SxProps = {
    ...theme?.menuListSx,
    ...props?.MenuProps?.MenuListProps?.sx,
  };

  const tooltipMouseEnterLeaveProps = {
    onMouseEnter: () => {
      if (tooltipShowingTimeout.current) {
        clearTimeout(tooltipShowingTimeout.current);
        tooltipShowingTimeout.current = null;
      }
      tooltipShowingTimeout.current = setTimeout(
        () => setTooltipOpen(true),
        props.tooltip?.enterDelay ?? 0
      );
    },
    onMouseLeave: () => {
      if (tooltipShowingTimeout.current) {
        clearTimeout(tooltipShowingTimeout.current);
        tooltipShowingTimeout.current = null;
      }
      setTooltipOpen(false);
    },
  };

  const IconComponent = (props: {
    expanded: boolean;
    disabled: boolean;
    yoodliVariant?: YoodliSelectVariant;
    customIcon?: IconType;
    iconSx?: SxProps;
  }): JSX.Element => {
    const renderIcon = () => {
      const Element =
        props.customIcon ??
        YoodliSelectThemes[props.yoodliVariant]?.icon ??
        ExpandCircleDownRoundedIcon;
      return (
        <Element
          sx={{
            color: `${props.disabled ? getDynamicColor("dark4") : getDynamicColor("primary")}`,
            transform: `${props.expanded ? "rotate(180deg)" : "rotate(0)"}`,
            ...props.iconSx,
          }}
        />
      );
    };
    return (
      <Box
        className="yoodliSelectIcon"
        sx={{
          transition: "transform 0.5s ease",
          height: 24,
          width: 24,
          position: "absolute",
          right: 12,
          pointerEvents: "none",
        }}
      >
        {renderIcon()}
      </Box>
    );
  };

  const selectedStyles = {
    // target .selected within MenuItem/Input, and .selected ON the MenuItem/Input itself
    ".MuiInputBase-input .selected, .MuiInputBase-input.selected": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      ".label": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      ...theme?.selectedLabelSx,
      ...props?.selectedLabelSx,
    },
    ".MuiMenuItem-root .selected, .MuiMenuItem-root.selected": {
      ...theme?.selectedOptionSx,
      ...props?.selectedOptionSx,
    },
  };

  const mappedOptions = React.useMemo(() => {
    return (
      props.options?.map((option) => {
        const mergedOptionLabelSx: SxProps = {
          ...theme?.optionLabelSx,
          ...option.labelSx,
        };
        return (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            sx={{
              all: "initial",
              width: "100%",
              "&.Mui-disabled": {
                opacity: 1,
              },
            }}
            disableRipple
            disableTouchRipple
          >
            {option.showDivider && (
              <Divider
                sx={{
                  my: 1,
                  position: "relative",
                  zIndex: 2,
                }}
              />
            )}
            <Box
              // this is the menu item that has the hover effects and the click listener
              className={option.value === props.value ? "selected" : ""}
              onMouseEnter={() => setTooltipOpen(false)}
              onMouseLeave={() => setTooltipOpen(false)}
              onClick={() => setTooltipOpen(false)}
              sx={{
                cursor: "pointer",
                fontFamily: "poppins",
                color: `${
                  props.value === option.value
                    ? getDynamicColor("primary")
                    : getDynamicColor("purple3")
                }`,
                "&:hover": {
                  backgroundColor: getDynamicColor("dark1"),
                },
                ...SELECT_VALUE_STYLES,
                width: "fill-available",
                ...({ ...theme?.optionSx, ...option.optionSx } as SxProps),
                fontSize: "inherit",
              }}
            >
              {option.JSXOverride ? (
                option.JSXOverride
              ) : (
                <Box sx={{ width: "100%" }}>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    gap={1}
                    sx={{
                      flexGrow: 1,
                      width: "100%",
                      ...mergedOptionLabelSx,
                    }}
                  >
                    <Typography
                      className="label"
                      sx={{
                        flexGrow: 1,
                        ...mergedOptionLabelSx,
                      }}
                    >
                      {option.label}
                    </Typography>
                    {option.labelTag && (
                      <Typography
                        component="div"
                        sx={{
                          fontSize: 12,
                          color: getDynamicColor("dark4"),
                          textAlign: "right",
                          ...option.labelTagSx,
                        }}
                      >
                        {option.labelTag}
                      </Typography>
                    )}
                  </Stack>
                  {option.subLabel && (
                    <Typography
                      className="subLabel"
                      sx={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: getDynamicColor("dark4"),
                        textTransform: "uppercase",
                        letterSpacing: "1px",
                        ...option.subLabelSx,
                      }}
                    >
                      {option.subLabel}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </MenuItem>
        );
      }) ?? []
    );
  }, [props.options]);

  return (
    <Box>
      <YoodliTooltip
        sx={{
          width: props.fullWidth ? "100%" : "auto",
        }}
        {...props.tooltip}
        {...tooltipMouseEnterLeaveProps}
        open={tooltipOpen && (props.hideTooltipWhenOpen ? !isOpen : true)}
        PopperProps={{
          ...props.tooltip?.PopperProps,
          sx: {
            ...props.tooltip?.sx,
            zIndex: 10000,
          },
        }}
      >
        <Select
          {...selectProps}
          open={isOpen}
          disabled={props.disabled || props.loading}
          onOpen={(e) => {
            selectProps?.onOpen?.(e);
            if (props.useDrawer) {
              setDrawerOpen(true);
            } else {
              setIsOpen(true);
            }
          }}
          onClose={(e) => {
            selectProps?.onClose?.(e);
            if (props.useDrawer) {
              setDrawerOpen(false);
            } else {
              setIsOpen(false);
            }
          }}
          sx={{
            fontSize: 16,
            color:
              props.value === "placeholder" ? getDynamicColor("dark4") : getDynamicColor("purple3"),
            fontWeight: 500,
            fontFamily: "poppins",
            borderRadius: "4px",
            border: setBorderColor(props.disabled, isOpen),
            fieldset: {
              border: "none",
            },
            width: "100%",
            flexGrow: 1,
            ".MuiPaper-root": {
              borderRadius: "8px",
              boxShadow: Y_SHADOWS.box_shadow_1,
            },
            pl: "0px !important",
            "&.Mui-disabled": { color: `${getDynamicColor("dark4")} !important` },
            ...mergedSelectSx,
            ...selectedStyles,
          }}
          inputProps={{
            ...selectProps.inputProps,
            sx: {
              display: "flex",
              alignItems: "center",
              border: "none",
              fontFamily: "poppins",
              py: 0,
              pl: 1.5,
              // this is the padding on the right side of the input box, to allow room for icon. Can be overridden by the inputSx prop
              pr: "44px !important",
              height: "100% !important",
              // don't display the potential divider on the selected item
              ".MuiDivider-root": {
                display: "none",
              },
              "&.Mui-disabled": {
                color: `${getDynamicColor("dark4")} !important`,
                WebkitTextFillColor: `${getDynamicColor("dark4")} !important`,
              },
              ...theme?.inputSx,
              ...props?.inputProps?.sx,
            },
          }}
          IconComponent={
            props.IconComponent ??
            (() => (
              <IconComponent
                expanded={isOpen}
                yoodliVariant={props.yoodliVariant}
                customIcon={theme.icon}
                disabled={selectProps.disabled}
                iconSx={theme?.iconSx}
              />
            ))
          }
          MenuProps={{
            disablePortal: true,
            elevation: 0,
            sx: {
              cursor: "default !important",
              position: "fixed",
              // this is the default, and is potentially changed by theme, or can be overwritten by the MenuProps.sx prop as well
              pr: "12px !important",
              ...mergedMenuSx,
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
              ...props?.MenuProps?.anchorOrigin,
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
              ...props?.MenuProps?.transformOrigin,
            },
            MenuListProps: {
              className: "yoodli-select-menu-container",
              sx: {
                borderRadius: "8px",
                ...mergedMenuListSx,
              },
            },
            ...props?.MenuProps,
          }}
          startAdornment={
            <>
              {!props?.hideLoader && props?.loading && (
                <Box sx={{ pl: 2, m: "auto" }}>
                  <CircularProgress
                    size={14}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: getDynamicColor("dark4"),
                    }}
                  />
                </Box>
              )}
            </>
          }
        >
          {props.children ?? mappedOptions ?? []}
        </Select>
      </YoodliTooltip>
      {props.useDrawer && (
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          className="yoodli-select-drawer-container"
          sx={{
            "& .MuiDrawer-paper": {
              overflow: "visible !important",
              width: "100%",
              maxWidth: `min(500px, calc(100vw - 32px))`,
              mt: `${props.drawerTopOffset ?? parseInt(WEBCLIENT_TOP_NAVBAR_HEIGHT)}px`,
              pl: 1,
              ...selectedStyles,
            },
          }}
          {...props.drawerProps}
        >
          <IconButton
            onClick={(e) => {
              setDrawerOpen(false);
              setIsOpen(false);
              selectProps?.onClose?.(e);
            }}
            sx={{
              position: "absolute",
              top: 24,
              left: -18,
              zIndex: 10000,
              backgroundColor: getDynamicColor("primary"),
              color: getDynamicColor("light1"),
              borderRadius: "50%",
              width: 36,
              height: 36,
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: getDynamicColor("primaryHover"),
                color: getDynamicColor("light1"),
                transform: "scale(1.05)",
              },
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
          <Box
            sx={{
              py: 2,
              pr: 1,
              overflowY: "auto",
              height: `calc(100vh - ${
                props.drawerTopOffset ?? parseInt(WEBCLIENT_TOP_NAVBAR_HEIGHT)
              }px)`,
            }}
          >
            {props.children ?? mappedOptions ?? []}
          </Box>
        </Drawer>
      )}
    </Box>
  );
};
