export interface IImageResizer {
  resizeFile(file: File): Promise<File>;
}

export default class ImageResizer implements IImageResizer {
  private MIN_IMAGE_SIZE = 64;
  private MAX_IMAGE_SIZE = 512;
  private MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  private errorCallback: (error: string) => void;

  constructor(
    errorCallback: (error: string) => void,
    size?: {
      minDim?: number;
      maxDim?: number;
      maxFileSize?: number;
    }
  ) {
    if (size?.minDim) {
      this.MIN_IMAGE_SIZE = size.minDim;
    }
    if (size?.maxDim) {
      this.MAX_IMAGE_SIZE = size.maxDim;
    }
    if (size?.maxFileSize) {
      this.MAX_FILE_SIZE = size.maxFileSize;
    }
    this.errorCallback = errorCallback;
  }

  private bytesToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    if (bytes === 0) {
      return "0 Byte";
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
  }

  private getResizedDimensions(
    width: number,
    height: number
  ): { newWidth: number; newHeight: number } {
    let newWidth;
    let newHeight;
    // make sure new widths are within bounds
    if (width < this.MIN_IMAGE_SIZE || height < this.MIN_IMAGE_SIZE) {
      if (width > height) {
        newWidth = this.MIN_IMAGE_SIZE;
        newHeight = (height / width) * newWidth;
      } else {
        newHeight = this.MIN_IMAGE_SIZE;
        newWidth = (width / height) * newHeight;
      }
    } else if (width > this.MAX_IMAGE_SIZE || height > this.MAX_IMAGE_SIZE) {
      if (width < height) {
        newHeight = this.MAX_IMAGE_SIZE;
        newWidth = (width / height) * newHeight;
      } else {
        newWidth = this.MAX_IMAGE_SIZE;
        newHeight = (height / width) * newWidth;
      }
    }

    newWidth = Math.round(newWidth);
    newHeight = Math.round(newHeight);

    return { newWidth, newHeight };
  }

  private async getResizedImageFile(image: HTMLImageElement, file: File): Promise<File> {
    let newFile: File;
    try {
      await new Promise<void>((resolve) => {
        const { newWidth, newHeight } = this.getResizedDimensions(image.width, image.height);
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, newWidth, newHeight);
        canvas.toBlob(async (blob) => {
          if (blob) {
            const extIdx = file.name.lastIndexOf(".");
            const ext = file.name.substring(extIdx);
            file = new File(
              [blob],
              `${file.name.substring(0, extIdx)}_${newWidth}x${newHeight}${ext}`,
              {
                type: file.type,
                lastModified: Date.now(),
              }
            );

            resolve();
          }
        });
      });
    } catch (er) {
      console.log("Error resizing image", er);
      this.errorCallback("Error loading the image");
      return;
    }
    return newFile ?? file;
  }

  public async resizeFile(file: File): Promise<File> {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    let width = 0;
    let height = 0;
    try {
      await new Promise<void>((resolve, reject) => {
        image.onload = () => {
          width = image.width;
          height = image.height;
          resolve();
        };
        image.onerror = () => {
          reject();
        };
      });
    } catch (_er) {
      this.errorCallback("Error loading the image");
      return;
    }

    if (
      width < this.MIN_IMAGE_SIZE ||
      width > this.MAX_IMAGE_SIZE ||
      height < this.MIN_IMAGE_SIZE ||
      height > this.MAX_IMAGE_SIZE
    ) {
      file = await this.getResizedImageFile(image, file);
    }

    // after resizing (if needed), double check file size
    // ideally this should never happen since the dimensions are so small, but theoretically could
    if (file.size > this.MAX_FILE_SIZE) {
      console.log(`Image file size is too large: ${this.bytesToSize(file.size)}`);
      this.errorCallback("File is too large. Please upload a smaller image.");
      return;
    }
    return file;
  }
}
