import React from "react";

interface IUploadedRecordingIdsContext {
  uploadedRecordingIds: string[];
  setUploadedRecordingIds: (s: string[]) => void;
}

export const UploadedRecordingIdsContext = React.createContext<IUploadedRecordingIdsContext>({
  uploadedRecordingIds: [],
  setUploadedRecordingIds: () => {
    return;
  },
});
