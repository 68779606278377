// Components
import { Box, Button, Stack, Typography } from "@mui/material";
import { analyticExplainerVideos } from "components/Dashboard/DashboardTypes";
import { AnalyticToCardText } from "components/Home/ReportCard/ReportCardCopyLogic";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { AggregateAnalyticEnum } from "lib-fullstack/db";

type ReportCardItemProps = {
  icon: JSX.Element;
  analytic: AggregateAnalyticEnum;
  description: string;
  learningResourceButtonCopy?: string;
  setLightboxURL?: (url: string, title: string) => void;
  isFtux?: boolean;
};

export const ReportCardItem = ({
  icon,
  analytic,
  description,
  learningResourceButtonCopy,
  setLightboxURL,
  isFtux,
}: ReportCardItemProps): JSX.Element => {
  const analyticString = isFtux ? analytic : AnalyticToCardText[analytic].label;

  const onLearningResourceButtonClick = () => {
    setLightboxURL?.(analyticExplainerVideos[analytic], learningResourceButtonCopy);
    Instrumentation.logReportCardVideoButtonClicked(learningResourceButtonCopy);
  };

  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: getDynamicColor("light1"),
        border: `1px solid ${getDynamicColor("dark2")}`,
        borderRadius: "4px",
        py: 2,
        width: "100%",
        height: "100%",
      }}
    >
      <Stack sx={{ width: "100px", alignItems: "center" }}>
        <Box sx={{ display: "inline-block", height: "30px", width: "30px" }}>{icon}</Box>
      </Stack>
      <Stack gap={1} width="100%" pr={1.5}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontSize: "10px",
            fontWeight: 700,
            letterSpacing: "0.8px",
            fontFamily: "poppins",
            color: getDynamicColor("purple3"),
          }}
        >
          {analyticString}
        </Typography>
        <Typography sx={{ fontSize: 12, fontFamily: "poppins", color: getDynamicColor("purple3") }}>
          {description}
        </Typography>
        {learningResourceButtonCopy && (
          <Button
            variant="outlined"
            sx={{ fontSize: 11, mt: 0.5 }}
            onClick={onLearningResourceButtonClick}
          >
            {learningResourceButtonCopy}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
