import _ from "lodash";

// Components
import { Container, Typography, LinearProgress } from "@mui/material";
import { OnboardingChecklistContainer } from "components/Home/OnboardingChecklist/OnboardingChecklistContainer";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OnboardingChecklistDoc } from "lib-frontend/utils/onboardingUtils";
import { OnboardingChecklistTask } from "lib-fullstack/db";
import { onboardingChecklists } from "lib-fullstack/utils/onboardingChecklistUtils";

type ChecklistProps = {
  displayName: string;
  onboardingChecklistDoc: OnboardingChecklistDoc;
};

const Checklist = ({ onboardingChecklistDoc }: ChecklistProps): JSX.Element => {
  let allCompleted = false;
  const allTasks: OnboardingChecklistTask[] =
    onboardingChecklists[onboardingChecklistDoc.checklistId];
  const completedTaskIds = onboardingChecklistDoc.completedTasks?.map(
    (task) => task.task as OnboardingChecklistTask
  );
  const dismissedTaskIds = onboardingChecklistDoc.dismissedTasks?.map(
    (task) => task.task as OnboardingChecklistTask
  );
  const numCompletedTasks = completedTaskIds?.filter((taskId) => allTasks.includes(taskId)).length;
  const numDismissedTasks = dismissedTaskIds?.filter((taskId) => allTasks.includes(taskId)).length;
  if (numCompletedTasks === allTasks.length) {
    allCompleted = true;
  }

  // Render

  const determineChecklistProgressValue = () => {
    // Add +1 so that the progress bar starts a little completed
    return ((numCompletedTasks + 1) / (allTasks.length + 1)) * 100;
  };

  const pageContentTitle = () => {
    if (allCompleted) {
      return "Nice work, you’re all set up!";
    } else {
      return "A couple to-dos to get you started";
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        pt: { xs: 4, md: 6 },
        pb: { xs: 4, md: 6 },
        display: "flex",
        flexDirection: "column",
        gap: 3.5,
      }}
    >
      <LinearProgress
        variant="determinate"
        value={determineChecklistProgressValue()}
        sx={{
          borderRadius: "20px",
          background: getDynamicColor("light1"),
          height: "8px",
          ".MuiLinearProgress-bar": {
            background: getDynamicColor("gradient.default"),
            borderRadius: "20px",
          },
        }}
      />
      <Typography
        data-logrocket-hidden
        sx={{
          color: getDynamicColor("purple3"),
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 700,
        }}
      >
        {pageContentTitle()}
      </Typography>
      <OnboardingChecklistContainer
        onboardingChecklistDoc={onboardingChecklistDoc}
        allCompleted={allCompleted}
        allTasks={allTasks}
        completedTaskIds={completedTaskIds}
        dismissedTaskIds={dismissedTaskIds}
        numCompletedTasks={numCompletedTasks}
        numDismissedTasks={numDismissedTasks}
      />
    </Container>
  );
};

export default Checklist;
