import firebase from "firebase/app";
import { db } from "lib-fullstack";
import { isMacOs, isWindows } from "react-device-detect";

// Utils
import { BillableAction } from "./billableAction";
import { BQStreamingLogger } from "./BQStreaming";
import { PracticeTypeId } from "./practiceRecorderUtils";
import { PlanChangeType, SettingsTab } from "./PricingData";
import { logRemarkRead, logRemark } from "./Remarks";
import * as amplitude from "@amplitude/analytics-browser";
import * as Sentry from "@sentry/react";
import {
  currentUserEmail,
  currentUserName,
  getScopedUserId,
} from "lib-frontend/utils/AccountUtils";
import { getSiteId, pLiveSiteDocsReady } from "lib-frontend/utils/LiveSiteDocs";
import {
  getLiveUserDocMain,
  userDocsService,
  updateThisUserDocMain,
} from "lib-frontend/utils/LiveUserDocs";
import { isEqual } from "lib-frontend/utils/Utilities";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import {
  ContextTypeValues,
  OnboardingChecklistTask,
  ProductTip,
  SelfConfidenceEval,
} from "lib-fullstack/db";
import { CalendarAction } from "lib-fullstack/utils/calendar";
import { OBQ1Option, RecordingSupportedLanguage } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { UsagePlanType } from "lib-fullstack/utils/pricingTypes";
import {
  AnalyticsAnalyticEvents,
  AuthAnalyticsEvents,
  CalendarAnalyticsEvents,
  CommentAnalyticsEvents,
  DashboardAnalyticsEvents,
  DataRedactionEvents,
  EndUserScenarioAnalyticsEvents,
  EndUserScenarioCreationWheres,
  EventHubsWheres,
  EventsCalHows,
  EventsCalWheres,
  EventsJobDescriptionUploadHows,
  EventsPoodliWheres,
  EventsRecordWheres,
  HomePageChecklistEvents,
  HomePageProductTipEvents,
  HomePageReportCardEvents,
  HubAnalyticEvents,
  InterviewAnalyticsEvents,
  KeyActionAnalyticsEvents,
  LibrarySnackbarAnalyticsEvents,
  NavigationAnalyticsEvents,
  OnboardingAnalyticsEvents,
  OrgAnalyticsEvents,
  OrgCoachbotType,
  OrgInviteTypes,
  OrgProgramsAnalyticsEvents,
  OrgReportWheres,
  PoodliAnalyticsEvents,
  PoodliDownloadedWheres,
  PoodliOnboardingEvents,
  PoodliUpgradeCtaLocations,
  PracticeRecorderAnalyticsEvents,
  PracticeRecorderWhere,
  PricingAnalyticEvents,
  PricingModalCtaLocations,
  PricingReimbursementLetterLinkLocation,
  ProductAnalyticEvents,
  ProductTipEventWheres,
  ProgramMemberNextStepCtaWheres,
  RecordingAnalyticsEvents,
  RecurringEventSettings,
  SelfConfidenceEvalAnalyticsEvents,
  SettingsPageAnalyticsEvents,
  SpeechSummaryAnalyticsEvents,
  TalkingPointsInRecordingState,
} from "lib-fullstack/utils/productAnalyticEvents";
import { getSubdomainFromHostname } from "lib-fullstack/workspaces/subdomain";
import * as LogRocket from "logrocket";
import { FirebaseAnalyticsLogger } from "lib-frontend/utils/firebaseAnalyticsLogger";

export function getPoodliDownloadUrl(where: PoodliDownloadedWheres): string {
  return `${WebServerExternalPath.DOWNLOAD_POODLI_DIRECT}?where=${where}`;
}

export enum SelfConfidenceEvalWhere {
  CHECKLIST = "CHECKLIST",
  SPEECH_SUMMARY = "SPEECH_SUMMARY",
}

export type SNACKBAR_VERSION_WHAT = ["A.0", "B.0"][number]; // Documented here: https://docs.google.com/spreadsheets/d/1QnAG5vtUjIpZMD2x9kkMWYRSTcrV6spn1Oi4vL4Njx8/edit#gid=850691259
export type SNACKBAR_LOCATION_WHERE = ["video_library"][number];
export type RECORDING_TYPE_WHAT = [
  "speech",
  "interview",
  "conversation",
  "interview_default",
  "interview_ai",
][number];
export type INTERVIEW_TOPIC_WHAT = [db.InterviewTopic][number];
export type INTERVIEW_STYLE_WHAT = [db.AIPersona][number];

const sendEventToGTM = (eventName: string, parameters?: { [p: string]: unknown }): void => {
  // @ts-ignore
  if (typeof window !== "undefined" && window.dataLayer) {
    // @ts-ignore
    window.dataLayer.push({ event: eventName, ...parameters });
  }
};

// --- AMPLITUDE START ---

// INSTRUMENTATION events used for product analytics and marketing.
export const Instrumentation = {
  // Log a key action taken by the user, and how they did it.
  logKeyAction: (
    eventName: KeyActionAnalyticsEvents,
    parameters?: { [p: string]: unknown }
  ): void => {
    const countEvent = "n" + eventName;
    const liveUserDocMain = getLiveUserDocMain(); // do before increment to avoid any race
    // Bump counters
    updateThisUserDocMain({
      [countEvent]: db.value("increment", 1),
    }).catch(console.error);

    const amplitudeEvent = new amplitude.Identify();
    amplitudeEvent.set(countEvent, (liveUserDocMain[countEvent] ?? 0) + 1);
    amplitude.identify(amplitudeEvent);

    Instrumentation.logAmplitudeEvent(eventName, parameters);
  },

  logAmplitudeEvent: (
    eventName: ProductAnalyticEvents,
    parameters?: { [p: string]: unknown }
  ): void => {
    FirebaseAnalyticsLogger.log(eventName);
    BQStreamingLogger.log("GA_EVENT", { eventName });
    Instrumentation.internalLogAmplitudeEvent(eventName, parameters);
  },

  // Log events to Amplitude.
  // Add numDaysSinceSignup to each event so we can graph FLX engagement (e.g. first 7, 14, 28 days since signup)
  internalLogAmplitudeEvent: (
    eventName: ProductAnalyticEvents,
    parameters?: { [p: string]: unknown }
  ): void => {
    const envName = getClientEnvConfig().envName;
    if (["local", "development", "staging"].includes(envName))
      console.log(
        `[amplitude] ${eventName}: ${
          JSON.stringify(parameters) ?? "{}"
        } at ${new Date().toISOString()}`
      );

    // Time diff between today and UTC date
    const timeDiff =
      (Date.parse(firebase.auth().currentUser?.metadata.creationTime) - new Date().getTime()) * -1;
    const numDaysSinceSignup = Math.ceil(timeDiff / (1000 * 3600 * 24));

    const concatParameters = {
      ...parameters,
      days_since_signup: numDaysSinceSignup,
    };

    // capture the logrocket session url if available
    // frustratingly, logrocket can return a string message instead of a session url, so detect that and ignore it
    const logRocketSession = LogRocket?.sessionURL;
    if (logRocketSession && logRocketSession.indexOf("LogRocket.init") === -1) {
      concatParameters["log_rocket_url"] = logRocketSession;
    }

    amplitude.track(eventName, concatParameters);

    // Update the user's last activity if this is a billable action.
    // Explicitly do not wait for this promise to resolve or reject.
    void BillableAction.updateUserLastActivity(Date.now(), firebase.auth().currentUser, eventName);
  },

  logUserSignUpCheckpoint: (): void => {
    Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGN_UP, {
      email: currentUserEmail(),
      name: currentUserName(),
      userId: getScopedUserId(),
    });
    setNewAmplitudeUserIdAndProperties().catch((err: Error) => {
      console.error(err);
      Sentry.captureException(err);
    });
  },

  logSignupPageLoaded: (
    orgId: string | undefined,
    hubId: string | undefined,
    isFocusedActivity: boolean
  ): void => {
    Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGNUP_PAGE_LOADED, {
      org_id: orgId ?? null,
      hub_id: hubId ?? null,
      is_focused_activity: isFocusedActivity,
    });
  },

  logUserSignInCheckpoint: (): void => {
    Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGN_IN, {
      email: currentUserEmail(),
      name: currentUserName(),
      userId: getScopedUserId(),
    });
    // in case the user logged in prior to the Amplitude integration
    setNewAmplitudeUserIdAndProperties().catch((err: Error) => {
      console.error(err);
      Sentry.captureException(err);
    });
  },

  logOnboardingCompleteCheckpoint: (
    eventsFocus: OBQ1Option,
    calendarAction: CalendarAction
  ): void => {
    Instrumentation.logAmplitudeEvent(OnboardingAnalyticsEvents.COMPLETE, {
      onboarding_events_focus: eventsFocus,
      onboarding_calendar_action: calendarAction,
    });
  },

  logCalConnectedCheckpoint: (calendar: EventsCalHows, whereProperty: EventsCalWheres): void => {
    Instrumentation.logAmplitudeEvent(CalendarAnalyticsEvents.CONNECTED, {
      where: whereProperty,
      calendar,
      user_id: getScopedUserId(),
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setExistingAmplitudeUserProperties({ has_cal: true });
  },
  logCalSkippedCheckpoint: (whereProperty: EventsCalWheres): void => {
    Instrumentation.logAmplitudeEvent(CalendarAnalyticsEvents.SKIPPED, {
      where: whereProperty,
    });
  },
  logCalDisconnectedCheckpoint: (whereProperty: EventsCalWheres): void => {
    Instrumentation.logAmplitudeEvent(CalendarAnalyticsEvents.DISCONNECTED, {
      where: whereProperty,
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setExistingAmplitudeUserProperties({ has_cal: false });
  },
  logCalSwitchCheckpoint: (newMode: db.CalendarMode, whereProperty: EventsCalWheres): void => {
    let modeName: string;
    if (newMode === db.CalendarMode.ZOODLI) {
      modeName = "zoodli";
    } else {
      modeName = "poodli";
    }
    Instrumentation.logAmplitudeEvent(CalendarAnalyticsEvents.CAL_SWITCH, {
      calendar_mode: modeName,
      where: whereProperty,
    });
  },
  logCalAutostartToggledCheckpoint: (
    value: boolean,
    recurring_event_setting: RecurringEventSettings,
    whereProperty: EventsCalWheres
  ): void => {
    Instrumentation.logAmplitudeEvent(CalendarAnalyticsEvents.AUTOSTART_TOGGLED, {
      value: value,
      recurring_event_setting: recurring_event_setting,
      where: whereProperty,
    });
  },
  logRecordSpeechCheckpoint: (whereProperty: EventsRecordWheres): void => {
    Instrumentation.logAmplitudeEvent(NavigationAnalyticsEvents.SPEECH, {
      where: whereProperty,
    });
  },
  logRecordConversationCheckpoint: (whereProperty: EventsRecordWheres): void => {
    Instrumentation.logAmplitudeEvent(NavigationAnalyticsEvents.CONVERSATION, {
      where: whereProperty,
    });
  },
  logRecordInterviewCheckpoint: (whereProperty: EventsRecordWheres): void => {
    Instrumentation.logAmplitudeEvent(NavigationAnalyticsEvents.INTERVIEW, {
      where: whereProperty,
    });
  },
  logRecordZoodliCheckpoint: (whereProperty: EventsRecordWheres): void => {
    Instrumentation.logAmplitudeEvent(NavigationAnalyticsEvents.ZOODLI, {
      where: whereProperty,
    });
  },
  logDashboardCheckpoint: (
    url: string,
    hoistedAnalytics: string[],
    dashboardRecordingFilter: string
  ): void => {
    const { subdomain } = getSubdomainFromHostname();

    Instrumentation.logAmplitudeEvent(DashboardAnalyticsEvents.VIEWED, {
      url,
      subdomain,
      hoisted_analytics_array: hoistedAnalytics,
      dashboard_recording_filter: dashboardRecordingFilter,
    });
  },
  logCTASnackbarViewed: (version: SNACKBAR_VERSION_WHAT, where: SNACKBAR_LOCATION_WHERE): void => {
    Instrumentation.logAmplitudeEvent(LibrarySnackbarAnalyticsEvents.VIEWED, {
      snackbar_version: version,
      snackbar_location: where,
    });
  },
  logCTASnackbarClicked: (version: SNACKBAR_VERSION_WHAT, where: SNACKBAR_LOCATION_WHERE): void => {
    Instrumentation.logAmplitudeEvent(LibrarySnackbarAnalyticsEvents.CLICKED, {
      snackbar_version: version,
      snackbar_location: where,
    });
  },
  logRecordingStarted: (
    currentQuestionPosition: number,
    numTotalQuestions: number,
    type: RECORDING_TYPE_WHAT
  ): void => {
    Instrumentation.logAmplitudeEvent(RecordingAnalyticsEvents.SPEECHREC_START, {
      question_count_current: currentQuestionPosition,
      question_count_total: numTotalQuestions,
      recording_type: type,
    });
  },
  logRecordingStopped: (
    currentQuestionPosition: number,
    numTotalQuestions: number,
    type: RECORDING_TYPE_WHAT
  ): void => {
    Instrumentation.logAmplitudeEvent(RecordingAnalyticsEvents.SPEECHREC_STOP, {
      question_count_current: currentQuestionPosition,
      question_count_total: numTotalQuestions,
      recording_type: type,
    });
  },
  logInterviewTopicSelected: (topic: INTERVIEW_TOPIC_WHAT, type: RECORDING_TYPE_WHAT): void => {
    Instrumentation.logAmplitudeEvent(InterviewAnalyticsEvents.TOPIC_SELECTED, {
      inteview_topic: topic,
      recording_type: type,
    });
  },
  logInterviewStyleSelected: (style: INTERVIEW_STYLE_WHAT, type: RECORDING_TYPE_WHAT): void => {
    Instrumentation.logAmplitudeEvent(InterviewAnalyticsEvents.STYLE_SELECTED, {
      interview_style: style,
      recording_type: type,
    });
  },
  logInterviewQuestionStarted: (
    currentQuestionPosition: number,
    numTotalQuestions: number,
    type: RECORDING_TYPE_WHAT
  ): void => {
    Instrumentation.logAmplitudeEvent(InterviewAnalyticsEvents.QUESTION_STARTED, {
      question_count_current: currentQuestionPosition,
      question_count_total: numTotalQuestions,
      recording_type: type,
    });
  },
  logPoodliDownload: (where?: string): void => {
    let platform = "unknown";
    if (isMacOs) {
      platform = "mac";
    } else if (isWindows) {
      platform = "windows";
    }

    Instrumentation.logAmplitudeEvent(PoodliAnalyticsEvents.DOWNLOADED, {
      platform,
      where,
    });
  },

  // Remarks
  logRemarkRead, // remark read is slightly different and not a key action - mainly for david to know which types of remarks get read more often
  logRemark,

  // Pushes outstanding events
  flush: (): Promise<void> => {
    return amplitude.flush().promise;
  },

  logContextTagUpdated: (
    where: db.PossibleUserChangeLocations,
    previous_value: ContextTypeValues | "na",
    current_value: ContextTypeValues
  ): void => {
    Instrumentation.logAmplitudeEvent(KeyActionAnalyticsEvents.CONTEXT_TAG_UPDATED, {
      context_tag_location: where,
      context_tag_previous_value: previous_value,
      context_tag_current_value: current_value,
    });
  },
  logPoodliLatestYoodliInsightCardClicked: (
    insightCardCategory: string,
    insightCardText?: string,
    insightCardUrl?: string
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PoodliAnalyticsEvents.POODLI_LATEST_YOODLI_INSIGHT_CARD_CLICKED,
      {
        insight_card_category: insightCardCategory,
        insight_card_text: insightCardText,
        insight_card_url: insightCardUrl,
      }
    );
  },
  logPoodliLatestYoodliInsightCardChanged: (
    insightCardIndex: number,
    insightCardTotal: number
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PoodliAnalyticsEvents.POODLI_LATEST_YOODLI_INSIGHT_CARD_CHANGED,
      {
        insight_card_index: insightCardIndex,
        insight_card_total: insightCardTotal,
      }
    );
  },
  logPoodliAggregateInsightsCardChanged: (
    insightCardIndex: number,
    insightCardTotal: number
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PoodliAnalyticsEvents.POODLI_AGGREGATE_INSIGHTS_CARD_CHANGED,
      {
        insight_card_index: insightCardIndex,
        insight_card_total: insightCardTotal,
      }
    );
  },
  logPoodliOpenItemsCtaClicked: (ctaText: string): void => {
    Instrumentation.logAmplitudeEvent(PoodliAnalyticsEvents.POODLI_OPEN_ITEMS_CTA_CLICKED, {
      cta_text: ctaText,
    });
  },
  logPoodliLatestSeeAllInsights: (): void => {
    Instrumentation.logAmplitudeEvent(PoodliAnalyticsEvents.POODLI_LATEST_SEE_ALL_INSIGHTS);
  },
  logPoodliFocusAreaLearnMoreClicked: (focusArea: string): void => {
    Instrumentation.logAmplitudeEvent(PoodliAnalyticsEvents.POODLI_FOCUS_AREA_LEARN_MORE, {
      focus_area: focusArea,
    });
  },
  logPoodliFocusAreaTourpointDismissed: (focusArea: string): void => {
    Instrumentation.logAmplitudeEvent(PoodliAnalyticsEvents.POODLI_FOCUS_AREA_TOURPOINT_DISMISSED, {
      focus_area: focusArea,
    });
  },
  logPoodliFocusAreaQualitativeProgressCtaClicked: (
    focusArea: string,
    focus_area_day_index: number
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PoodliAnalyticsEvents.POODLI_FOCUS_AREA_QUALITATIVE_PROGRESS_CTA_CLICKED,
      {
        focus_area: focusArea,
        focus_area_day_index,
      }
    );
  },
  logPoodliFocusAreaCallProgressHovered: (
    focusArea: string,
    callProgressIconIndex: number
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PoodliAnalyticsEvents.POODLI_FOCUS_AREA_CALL_PROGRESS_HOVERED,
      {
        focus_area: focusArea,
        call_progress_icon_index: callProgressIconIndex,
      }
    );
  },
  logPoodliLiveAnalyticsUpdated: (oldAnalytics: string[], newAnalytics: string[]): void => {
    Instrumentation.logAmplitudeEvent(PoodliAnalyticsEvents.LIVE_ANALYTICS_UPDATED, {
      poodli_live_analytics_previous_value: oldAnalytics,
      poodli_live_analytics_current_value: newAnalytics,
    });
  },
  logHubMemberStatusUpdated: (
    newHubMemberStatus: string,
    userId: string,
    hubId: string,
    orgId: string
  ): void => {
    Instrumentation.logAmplitudeEvent(HubAnalyticEvents.HUB_MEMBER_STATUS_UPDATED, {
      new_hub_member_status: newHubMemberStatus,
      hub_member_id: userId,
      hub_id: hubId,
      org_id: orgId,
    });
  },
  logDefaultOrgChanged: (oldOrgId: string, newOrgId: string): void => {
    Instrumentation.logAmplitudeEvent(HubAnalyticEvents.DEFAULT_ORG_CHANGED, {
      old_org_id: oldOrgId,
      new_org_id: newOrgId,
    });
  },
  logTalkingPointsTemplateStarted: (where: EventsPoodliWheres): void => {
    Instrumentation.logAmplitudeEvent(PoodliAnalyticsEvents.TALKING_POINTS_TEMPLATE_STARTED, {
      template_location: where,
    });
  },
  logTalkingPointsTemplateSaved: (numTalkingPoints: number): void => {
    Instrumentation.logAmplitudeEvent(PoodliAnalyticsEvents.TALKING_POINTS_TEMPLATE_SAVED, {
      number_of_talking_points: numTalkingPoints,
    });
  },
  logTalkingPointsEntryAdded: (adhoc: boolean, calEventId?: string): void => {
    Instrumentation.logAmplitudeEvent(PoodliAnalyticsEvents.TALKING_POINTS_ENTRY_ADDED, {
      talking_point_type: adhoc
        ? TalkingPointsInRecordingState.AD_HOC
        : TalkingPointsInRecordingState.TEMPLATE,
      cal_event_id: calEventId,
    });
  },
  logTalkingPointsInRecordingStateChanged: (
    recordingState: TalkingPointsInRecordingState
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PoodliAnalyticsEvents.TALKING_POINTS_IN_RECORDING_STATE_CHANGED,
      {
        recording_talking_point_state: recordingState,
      }
    );
  },
  logTalkingPointsEntryChecked: (adhoc: boolean, calEventId?: string): void => {
    Instrumentation.logAmplitudeEvent(PoodliAnalyticsEvents.TALKING_POINTS_ENTRY_CHECKED_OFF, {
      talking_point_type: adhoc
        ? TalkingPointsInRecordingState.AD_HOC
        : TalkingPointsInRecordingState.TEMPLATE,
      cal_event_id: calEventId,
    });
  },
  logPricingCardClicked: (usagePlanType: UsagePlanType, isAnnual: boolean): void => {
    Instrumentation.logAmplitudeEvent(PricingAnalyticEvents.PRICING_LP_CTA_CLICKED, {
      pricing_plan: usagePlanType,
      is_annual_plan: isAnnual,
    });
  },
  logPricingModalUpgradeClicked: (
    usagePlanType: UsagePlanType,
    usagePlanPrice: number,
    isAnnual: boolean
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PricingAnalyticEvents.PRICING_PLAN_MODAL_UPGRADE_BUTTON_CLICKED,
      {
        pricing_plan: usagePlanType,
        pricing_plan_price: usagePlanPrice,
        is_annual_plan: isAnnual,
      }
    );
  },
  logPricingModalDowngradeClicked: (
    usagePlanType: UsagePlanType,
    usagePlanPrice: number,
    isAnnual: boolean
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PricingAnalyticEvents.PRICING_PLAN_MODAL_DOWNGRADE_BUTTON_CLICKED,
      {
        pricing_plan: usagePlanType,
        pricing_plan_price: usagePlanPrice,
        is_annual_plan: isAnnual,
      }
    );
  },
  logPlanChangeCompleted: (
    planChangeType: PlanChangeType,
    usagePlanType: UsagePlanType,
    usagePlanPrice: number,
    isAnnual: boolean
  ): void => {
    if (planChangeType === PlanChangeType.UPGRADE) {
      sendEventToGTM(PricingAnalyticEvents.PRICING_PLAN_UPGRADE_COMPLETE, {
        pricing_plan: usagePlanType,
        pricing_plan_price: usagePlanPrice,
        is_annual_plan: isAnnual,
      });
    }
    Instrumentation.logAmplitudeEvent(
      planChangeType === PlanChangeType.UPGRADE
        ? PricingAnalyticEvents.PRICING_PLAN_UPGRADE_COMPLETE
        : PricingAnalyticEvents.PRICING_PLAN_DOWNGRADE_COMPLETE,
      {
        pricing_plan: usagePlanType,
        pricing_plan_price: usagePlanPrice,
        is_annual_plan: isAnnual,
      }
    );
  },
  logPricingModalViewed: (
    ctaLocation: PricingModalCtaLocations,
    ctaText: string,
    ctaDisplayUrl: string
  ): void => {
    Instrumentation.logAmplitudeEvent(PricingAnalyticEvents.PRICING_PLAN_MODAL_VIEWED, {
      cta_location: ctaLocation,
      cta_text: ctaText,
      cta_display_url: ctaDisplayUrl,
    });
  },
  logPoodliUpgradeButtonClicked: (where: PoodliUpgradeCtaLocations): void => {
    Instrumentation.logAmplitudeEvent(PricingAnalyticEvents.PRICING_POODLI_UPGRADE_BUTTON_CLICKED, {
      where,
    });
  },
  logSettingsPageViewed: (tab: SettingsTab): void => {
    Instrumentation.logAmplitudeEvent(SettingsPageAnalyticsEvents.VIEWED, {
      tab,
    });
  },
  logSettingsPricingCancelScheduledChangeClicked: (
    usagePlanType: UsagePlanType,
    price: number,
    isAnnual: boolean
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PricingAnalyticEvents.SETTINGS_PRICING_PLAN_CANCEL_SCHEDULED_CHANGE_CLICKED,
      {
        scheduled_pricing_plan_name: usagePlanType,
        scheduled_pricing_plan_price: price,
        is_annual_plan: isAnnual,
      }
    );
  },
  logPricingCancelScheduledChangeComplete: (
    usagePlanType: UsagePlanType,
    price: number,
    isAnnual: boolean
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PricingAnalyticEvents.PRICING_PLAN_CANCEL_SCHEDULED_CHANGE_COMPLETE,
      {
        scheduled_pricing_plan_name: usagePlanType,
        scheduled_pricing_plan_price: price,
        is_annual_plan: isAnnual,
      }
    );
  },
  logSettingsPricingUpgradeButtonClicked: (
    usagePlanType: UsagePlanType,
    price: number,
    isAnnual: boolean
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PricingAnalyticEvents.SETTINGS_PRICING_PLAN_UPGRADE_BUTTON_CLICKED,
      {
        pricing_plan_name: usagePlanType,
        pricing_plan_price: price,
        is_annual_plan: isAnnual,
      }
    );
  },
  logPricingReimbursementLetterClicked: (where: PricingReimbursementLetterLinkLocation): void => {
    Instrumentation.logAmplitudeEvent(PricingAnalyticEvents.PRICING_REIMBURSEMENT_LETTER_CLICKED, {
      where,
    });
  },
  logPoodliTrayIconClicked: (whileRecording: string): void => {
    Instrumentation.logAmplitudeEvent(PoodliAnalyticsEvents.POODLI_TRAY_ICON_CLICKED, {
      while_recording: whileRecording,
    });
  },
  logPoodliDemoVideoPlayed: (secondsIntoVideo: number): void => {
    Instrumentation.logAmplitudeEvent(PoodliOnboardingEvents.POODLI_DEMO_VIDEO_PLAYED, {
      seconds_into_video: secondsIntoVideo,
    });
  },
  logPoodliDemoVideoPaused: (secondsIntoVideo: number): void => {
    Instrumentation.logAmplitudeEvent(PoodliOnboardingEvents.POODLI_DEMO_VIDEO_PAUSED, {
      seconds_into_video: secondsIntoVideo,
    });
  },
  logPoodliDemoVideoSeeked: (secondsIntoVideo: number): void => {
    Instrumentation.logAmplitudeEvent(PoodliOnboardingEvents.POODLI_DEMO_VIDEO_SEEKED, {
      seconds_into_video: secondsIntoVideo,
    });
  },
  logPoodliDemoVideoPlaybackSpeedChanged: (playbackSpeed: number): void => {
    Instrumentation.logAmplitudeEvent(
      PoodliOnboardingEvents.POODLI_DEMO_VIDEO_PLAYBACK_SPEED_CHANGED,
      {
        playback_speed: playbackSpeed + "x",
      }
    );
  },
  // Practice Recorder
  logPracticeRecorderPromptGenerated: (practiceType: string, prompt: string): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_PROMPT_GENERATED,
      {
        practice_type: practiceType,
        prompt,
      }
    );
  },
  logPracticeRecorderHelpfulHintDismissed: (practiveType: string): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_HELPFUL_HINT_DISMISSED,
      {
        practice_type: practiveType,
      }
    );
  },
  logPracticeRecorderRecordingStarted: (
    practiceType: string,
    topic: string,
    persona: string,
    videoMirrored: boolean,
    videoEnabled: boolean,
    countdownEnabled: boolean,
    prompt: string,
    totalQuestionCount: number
  ): void => {
    // remove undefined values from payload
    const payload = Object.fromEntries(
      Object.entries({
        practice_type: practiceType,
        topic: practiceType !== PracticeTypeId.SPEECH ? topic : undefined,
        persona,
        video_mirrored: videoMirrored,
        video_enabled: videoEnabled,
        countdown_enabled: countdownEnabled,
        prompt: practiceType === PracticeTypeId.SPEECH ? prompt : undefined,
        total_question_count:
          practiceType === PracticeTypeId.INTERVIEW ? totalQuestionCount : undefined,
      }).filter(([_, val]) => val !== undefined)
    );
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_RECORDING_STARTED,
      payload
    );
  },
  logPracticeRecorderRecordingPaused: (practiceType: string, recordingDuration: number): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_RECORDING_PAUSED,
      {
        practice_type: practiceType,
        recording_duration: recordingDuration,
      }
    );
  },
  logPracticeRecorderRecordingPreviewed: (
    practiceType: string,
    recordingDuration: number
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_RECORDING_PREVIEWED,
      {
        practice_type: practiceType,
        recording_duration: recordingDuration,
      }
    );
  },
  logPracticeRecorderRecordingResumed: (
    practiceType: string,
    recordingDuration: number,
    timePaused: number
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_RECORDING_RESUMED,
      {
        practice_type: practiceType,
        recording_duration: recordingDuration,
        time_paused: timePaused,
      }
    );
  },
  logPracticeRecorderRecordingEnded: (
    recordingDuration: number,
    practiceType: string,
    topic: string,
    persona: string,
    videoMirrored: boolean,
    countdownEnabled: boolean,
    prompt: string,
    totalQuestionCount: number
  ): void => {
    // remove undefined values from payload
    const payload = Object.fromEntries(
      Object.entries({
        recording_duration: recordingDuration,
        practice_type: practiceType,
        topic: practiceType !== PracticeTypeId.SPEECH ? topic : undefined,
        persona,
        video_mirrored: videoMirrored,
        countdown_enabled: countdownEnabled,
        prompt: practiceType === PracticeTypeId.SPEECH ? prompt : undefined,
        total_question_count:
          practiceType === PracticeTypeId.INTERVIEW ? totalQuestionCount : undefined,
      }).filter(([_, val]) => val !== undefined)
    );
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_RECORDING_ENDED,
      payload
    );
  },
  logPracticeRecorderRecordingSaved: (practiceType: string, recordingDuration: number): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_RECORDING_SAVED,
      {
        practice_type: practiceType,
        recording_duration: recordingDuration,
      }
    );
  },
  logPracticeRecorderRecordingRestart: (practiceType: string, recordingDuration: number): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_RECORDING_RESTART,
      {
        practice_type: practiceType,
        recording_duration: recordingDuration,
      }
    );
  },
  logPracticeRecorderFollowupQuestionRequested: (
    practiceType: string,
    recordingDuration: number,
    followupQuestionCount: number,
    isAi: boolean
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_FOLLOWUP_QUESTION_REQUESTED,
      {
        practice_type: practiceType,
        recording_duration: recordingDuration,
        followup_question_count: followupQuestionCount,
        is_ai: isAi,
      }
    );
  },
  logPracticeRecorderCustomizationDrawerOpened: (practiceType: string): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_CUSTOMIZATION_DRAWER_OPENED,
      {
        practice_type: practiceType,
      }
    );
  },
  logPracticeRecorderPersonaSelected: (practiceType: string, persona: string): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_PERSONA_SELECTED,
      {
        practice_type: practiceType,
        persona,
      }
    );
  },
  logPracticeRecorderTopicSelected: (practiceType: string, topic: string): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_TOPIC_SELECTED,
      {
        practice_type: practiceType,
        topic,
      }
    );
  },
  logPracticeRecorderCompanySelected: (practiceType: string, company: string): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_COMPANY_SELECTED,
      {
        practice_type: practiceType,
        company,
      }
    );
  },
  logPracticeRecorderRoleSelected: (practiceType: string, role: string): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_ROLE_SELECTED,
      {
        practice_type: practiceType,
        role,
      }
    );
  },
  logPracticeRecorderAddQuestionsButtonClicked: (
    practiceType: string,
    where: PracticeRecorderWhere
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_ADD_QUESTIONS_BUTTON_CLICKED,
      {
        practice_type: practiceType,
        where,
      }
    );
  },
  logPracticeRecorderCountdownEnabledChanged: (
    practiceType: string,
    countdownEnabled: boolean
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_COUNTDOWN_ENABLED_CHANGED,
      {
        practice_type: practiceType,
        countdown_enabled: countdownEnabled,
      }
    );
  },
  logPracticeRecorderTtsEnabledChanged: (practiceType: string, ttsEnabled: boolean): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_TTS_ENABLED_CHANGED,
      {
        practice_type: practiceType,
        tts_enabled: ttsEnabled,
      }
    );
  },
  logPracticeRecorderAutoRespondModeEnabledChanged: (
    practiceType: string,
    autoRespondModeEnabled: boolean
  ): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_AUTO_RESPOND_MODE_ENABLED_CHANGED,
      {
        practice_type: practiceType,
        auto_respond_mode_enabled: autoRespondModeEnabled,
      }
    );
  },
  logPracticeRecorderVideoMirroredChanged: (practiceType: string, videoMirrored: boolean): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_VIDEO_MIRRORED_CHANGED,
      {
        practice_type: practiceType,
        video_mirrored: videoMirrored,
      }
    );
  },
  logPracticeRecorderLoaded: (practiceType: string, isFocusedActivity: boolean): void => {
    Instrumentation.logAmplitudeEvent(PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_LOADED, {
      practice_type: practiceType,
      is_focused_activity: isFocusedActivity,
    });
  },
  logPracticeRecorderScreenShared: (practiceType: string, whileRecording: boolean): void => {
    Instrumentation.logAmplitudeEvent(PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_LOADED, {
      practice_type: practiceType,
      while_recording: whileRecording,
    });
  },
  logSpeechSummaryBannerCtaClicked: (ctaText: string): void => {
    Instrumentation.logAmplitudeEvent(
      SpeechSummaryAnalyticsEvents.SPEECH_SUMMARY_BANNER_CTA_CLICKED,
      {
        cta_text: ctaText,
      }
    );
  },
  logRemarkPracticeFUQCtaClicked: (question: string): void => {
    Instrumentation.logAmplitudeEvent(CommentAnalyticsEvents.REMARK_PRACTICE_FUQ_CTA_CLICKED, {
      question,
    });
  },
  logOrgInterviewBankCreated: (orgId: string, where: EventHubsWheres): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_INTERVIEW_BANK_CREATED, {
      org_id: orgId,
      where,
    });
  },
  logOrgInterviewBankUpdated: (orgId: string, where: EventHubsWheres): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_INTERVIEW_BANK_UPDATED, {
      org_id: orgId,
      where,
    });
  },
  logOrgInterviewBankDeleted: (orgId: string, bankId: string, where: EventHubsWheres): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_INTERVIEW_BANK_DELETED, {
      org_id: orgId,
      bank_id: bankId,
      where,
    });
  },
  logYoodliOnboardingChecklistItemSelected: (
    checklist_item_text: string,
    checklist_item_id: OnboardingChecklistTask,
    checklist_item_index: number,
    checklist_item_is_completed: boolean
  ): void => {
    Instrumentation.logAmplitudeEvent(
      HomePageChecklistEvents.YOODLI_ONBOARDING_CHECKLIST_ITEM_SELECTED,
      {
        checklist_item_text,
        checklist_item_id,
        checklist_item_index,
        checklist_item_is_completed,
      }
    );
  },
  logYoodliOnboardingChecklistCTAClicked: (
    checklist_item_text: string,
    checklist_item_id: OnboardingChecklistTask,
    checklist_item_index: number,
    checklist_item_is_completed: boolean,
    cta_text: string,
    cta_url?: string
  ): void => {
    Instrumentation.logAmplitudeEvent(
      HomePageChecklistEvents.YOODLI_ONBOARDING_CHECKLIST_CTA_CLICKED,
      {
        checklist_item_text,
        checklist_item_id,
        checklist_item_index,
        checklist_item_is_completed,
        cta_text,
        cta_url,
      }
    );
  },
  logYoodliOnboardingChecklistItemCompleted: (
    checklist_item_id: OnboardingChecklistTask,
    checklist_item_index: number
  ): void => {
    Instrumentation.logAmplitudeEvent(
      HomePageChecklistEvents.YOODLI_ONBOARDING_CHECKLIST_ITEM_COMPLETED,
      {
        checklist_item_id,
        checklist_item_index,
      }
    );
  },
  logYoodliOnboardingChecklistItemDismissed: (
    checklist_item_id: OnboardingChecklistTask,
    checklist_item_index: number
  ): void => {
    Instrumentation.logAmplitudeEvent(
      HomePageChecklistEvents.YOODLI_ONBOARDING_CHECKLIST_ITEM_DISMISSED,
      {
        checklist_item_id,
        checklist_item_index,
      }
    );
  },
  logYoodliChecklistDemoVideoPlayed: (seconds_into_video: number, demo_video_url: string): void => {
    Instrumentation.logAmplitudeEvent(HomePageChecklistEvents.YOODLI_CHECKLIST_DEMO_VIDEO_PLAYED, {
      seconds_into_video,
      demo_video_url,
    });
  },
  logYoodliChecklistDemoVideoPaused: (seconds_into_video: number, demo_video_url: string): void => {
    Instrumentation.logAmplitudeEvent(HomePageChecklistEvents.YOODLI_CHECKLIST_DEMO_VIDEO_PAUSED, {
      seconds_into_video,
      demo_video_url,
    });
  },
  logYoodliChecklistDemoVideoSeeked: (seconds_into_video: number, demo_video_url: string): void => {
    Instrumentation.logAmplitudeEvent(HomePageChecklistEvents.YOODLI_CHECKLIST_DEMO_VIDEO_SEEKED, {
      seconds_into_video,
      demo_video_url,
    });
  },
  logYoodliChecklistDemoVideoPlaybackSpeedChanged: (
    playback_speed: string,
    demo_video_url: string
  ): void => {
    Instrumentation.logAmplitudeEvent(
      HomePageChecklistEvents.YOODLI_CHECKLIST_DEMO_VIDEO_PLAYBACK_SPEED_CHANGED,
      {
        playback_speed,
        demo_video_url,
      }
    );
  },
  logUserDataRedactionDaysUpdated: (days: number): void => {
    Instrumentation.logAmplitudeEvent(DataRedactionEvents.USER_DATA_REDACTION_DAYS_UPDATED, {
      days: days,
    });
  },
  logOrgDataRedactionDaysUpdated: (days: number, orgId: string): void => {
    Instrumentation.logAmplitudeEvent(DataRedactionEvents.ORG_DATA_REDACTION_DAYS_UPDATED, {
      days: days,
      org_id: orgId,
    });
  },
  logUsageTipGifPlayed: (
    usageTipTitle: string,
    usageTipIndex: number,
    usageTipId: ProductTip,
    where: ProductTipEventWheres
  ): void => {
    Instrumentation.logAmplitudeEvent(HomePageProductTipEvents.USAGE_TIP_GIF_PLAYED, {
      usage_tip_title: usageTipTitle,
      usage_tip_index: usageTipIndex,
      usage_tip_id: usageTipId,
      where: where,
    });
  },
  logUsageTipCtaClicked: (
    usageTipTitle: string,
    usageTipIndex: number,
    ctaText: string,
    usageTipId: ProductTip,
    where: ProductTipEventWheres
  ): void => {
    Instrumentation.logAmplitudeEvent(HomePageProductTipEvents.USAGE_TIP_CTA_CLICKED, {
      usage_tip_title: usageTipTitle,
      usage_tip_index: usageTipIndex,
      cta_text: ctaText,
      usage_tip_id: usageTipId,
      where: where,
    });
  },
  logUsageTipCarouselSlideManuallyChanged: (usageTipIndex: number): void => {
    Instrumentation.logAmplitudeEvent(
      HomePageProductTipEvents.USAGE_TIP_CAROUSEL_SLIDE_MANUALLY_CHANGED,
      {
        usage_tip_index: usageTipIndex,
      }
    );
  },
  logAnalyticCardExpanded: (analytic: string): void => {
    Instrumentation.logAmplitudeEvent(AnalyticsAnalyticEvents.ANALYTIC_CARD_EXPANDED, {
      analytic,
    });
  },
  logReportCardDateRangeChanged: (dateRange: string): void => {
    Instrumentation.logAmplitudeEvent(HomePageReportCardEvents.REPORT_CARD_DATE_RANGE_CHANGED, {
      date_range: dateRange,
    });
  },
  logReportCardVideoButtonClicked: (buttonText: string): void => {
    Instrumentation.logAmplitudeEvent(HomePageReportCardEvents.REPORT_CARD_VIDEO_BUTTON_CLICKED, {
      button_text: buttonText,
    });
  },
  logSpeechSummaryRecordingSeeked: (speechId: string, secondsIntoVideo: number): void => {
    Instrumentation.logAmplitudeEvent(
      SpeechSummaryAnalyticsEvents.SPEECH_SUMMARY_RECORDING_SEEKED,
      {
        speech_id: speechId,
        seconds_into_video: secondsIntoVideo,
      }
    );
  },
  logSpeechSummaryRecordingPlayed: (speechId: string, secondsIntoVideo: number): void => {
    Instrumentation.logAmplitudeEvent(
      SpeechSummaryAnalyticsEvents.SPEECH_SUMMARY_RECORDING_PLAYED,
      {
        speech_id: speechId,
        seconds_into_video: secondsIntoVideo,
      }
    );
  },
  logSpeechSummaryRecordingPaused: (speechId: string, secondsIntoVideo: number): void => {
    Instrumentation.logAmplitudeEvent(
      SpeechSummaryAnalyticsEvents.SPEECH_SUMMARY_RECORDING_PAUSED,
      {
        speech_id: speechId,
        seconds_into_video: secondsIntoVideo,
      }
    );
  },
  logSpeechSummaryRecordingPlaybackSpeedChanged: (
    speechId: string,
    playbackSpeed: number
  ): void => {
    Instrumentation.logAmplitudeEvent(
      SpeechSummaryAnalyticsEvents.SPEECH_SUMMARY_RECORDING_PLAYBACK_SPEED_CHANGED,
      {
        speech_id: speechId,
        playback_speed: playbackSpeed,
      }
    );
  },

  // Self Confidence Eval
  logSelfConfidenceEvalConfidenceLevelQuestionViewed: (
    where: SelfConfidenceEvalWhere | string,
    nCountableRecordingsCompleted: number
  ): void => {
    Instrumentation.logAmplitudeEvent(
      SelfConfidenceEvalAnalyticsEvents.CONFIDENCE_LEVEL_QUESTION_VIEWED,
      {
        where: where,
        num_countable_recordings_completed: nCountableRecordingsCompleted,
      }
    );
  },
  logSelfConfidenceEvalConfidenceLevelCtaClicked: (
    where: SelfConfidenceEvalWhere | string,
    nCountableRecordingsCompleted: number,
    itemText: string,
    itemIndex: number
  ): void => {
    Instrumentation.logAmplitudeEvent(
      SelfConfidenceEvalAnalyticsEvents.CONFIDENCE_LEVEL_CTA_CLICKED,
      {
        where: where,
        num_countable_recordings_completed: nCountableRecordingsCompleted,
        self_confidence_eval_confidence_level_item_text: itemText,
        self_confidence_eval_confidence_level_item_index: itemIndex,
      }
    );
  },
  logSelfConfidenceEvalReasonQuestionViewed: (
    where: SelfConfidenceEvalWhere | string,
    nCountableRecordingsCompleted: number
  ): void => {
    Instrumentation.logAmplitudeEvent(SelfConfidenceEvalAnalyticsEvents.REASON_QUESTION_VIEWED, {
      where: where,
      num_countable_recordings_completed: nCountableRecordingsCompleted,
    });
  },
  logSelfConfidenceEvalReasonOptionsSaved: (
    where: SelfConfidenceEvalWhere | string,
    nCountableRecordingsCompleted: number,
    reasons: string[]
  ): void => {
    Instrumentation.logAmplitudeEvent(SelfConfidenceEvalAnalyticsEvents.REASON_OPTIONS_SAVED, {
      where: where,
      num_countable_recordings_completed: nCountableRecordingsCompleted,
      self_confidence_eval_reasons: reasons,
    });
  },
  logSelfConfidenceEvalConfirmationViewed: (
    where: SelfConfidenceEvalWhere | string,
    nCountableRecordingsCompleted: number
  ): void => {
    Instrumentation.logAmplitudeEvent(SelfConfidenceEvalAnalyticsEvents.CONFIRMATION_VIEWED, {
      where: where,
      num_countable_recordings_completed: nCountableRecordingsCompleted,
    });
  },
  logSelfConfidenceEvalCloseButtonClicked: (
    where: SelfConfidenceEvalWhere | string,
    nCountableRecordingsCompleted: number
  ): void => {
    Instrumentation.logAmplitudeEvent(
      SelfConfidenceEvalAnalyticsEvents.CONFIRMATION_CLOSE_BUTTON_CLICKED,
      {
        where: where,
        num_countable_recordings_completed: nCountableRecordingsCompleted,
      }
    );
  },
  logSelfConfidenceEvalCompleted: (evalResults: SelfConfidenceEval): void => {
    Instrumentation.logAmplitudeEvent(SelfConfidenceEvalAnalyticsEvents.SUBMITTED, {
      eval: evalResults,
    });
  },
  logProgramCreated: (orgId: string, programId: string, duplicate: boolean): void => {
    Instrumentation.logAmplitudeEvent(OrgProgramsAnalyticsEvents.PROGRAM_CREATED, {
      org_id: orgId,
      program_id: programId,
      duplicate: duplicate,
    });
  },
  logProgramPublished: (orgId: string, programId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgProgramsAnalyticsEvents.PROGRAM_PUBLISHED, {
      org_id: orgId,
      program_id: programId,
    });
  },
  logProgramArchived: (orgId: string, programId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgProgramsAnalyticsEvents.PROGRAM_ARCHIVED, {
      org_id: orgId,
      program_id: programId,
    });
  },
  logProgramDeleted: (orgId: string, programId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgProgramsAnalyticsEvents.PROGRAM_DELETED, {
      org_id: orgId,
      program_id: programId,
    });
  },
  logProgramScenarioAdded: (orgId: string, programId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgProgramsAnalyticsEvents.PROGRAM_SCENARIO_ADDED, {
      org_id: orgId,
      program_id: programId,
    });
  },
  logProgramGroupAdded: (orgId: string, programId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgProgramsAnalyticsEvents.PROGRAM_GROUP_ADDED, {
      org_id: orgId,
      program_id: programId,
    });
  },
  logProgramAdminOverviewViewed: (orgId: string, programId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgProgramsAnalyticsEvents.PROGRAM_ADMIN_OVERVIEW_VIEWED, {
      org_id: orgId,
      program_id: programId,
    });
  },
  logLearningPageViewed: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgProgramsAnalyticsEvents.LEARNING_PAGE_VIEWED, {
      org_id: orgId,
    });
  },
  logProgramLearningPageViewed: (orgId: string, programId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgProgramsAnalyticsEvents.PROGRAM_LEARNING_PAGE_VIEWED, {
      org_id: orgId,
      program_id: programId,
    });
  },
  logProgramMemberNextStepCtaClicked: (
    orgId: string,
    programId: string,
    where: ProgramMemberNextStepCtaWheres
  ): void => {
    Instrumentation.logAmplitudeEvent(
      OrgProgramsAnalyticsEvents.PROGRAM_MEMBER_NEXT_STEP_CTA_CLICKED,
      {
        org_id: orgId,
        program_id: programId,
        where,
      }
    );
  },
  logEndUserScenarioCreated: (where: EndUserScenarioCreationWheres): void => {
    Instrumentation.logAmplitudeEvent(EndUserScenarioAnalyticsEvents.END_USER_SCENARIO_CREATED, {
      where,
    });
  },
  logEndUserScenarioUpdated: (): void => {
    Instrumentation.logAmplitudeEvent(EndUserScenarioAnalyticsEvents.END_USER_SCENARIO_UPDATED, {});
  },
  logEndUserScenarioDeleted: (): void => {
    Instrumentation.logAmplitudeEvent(EndUserScenarioAnalyticsEvents.END_USER_SCENARIO_DELETED, {});
  },
  logEndUserPersonaCreated: (where: EndUserScenarioCreationWheres): void => {
    Instrumentation.logAmplitudeEvent(EndUserScenarioAnalyticsEvents.END_USER_PERSONA_CREATED, {
      where,
    });
  },
  logEndUserPersonaUpdated: (): void => {
    Instrumentation.logAmplitudeEvent(EndUserScenarioAnalyticsEvents.END_USER_PERSONA_UPDATED, {});
  },
  logEndUserPersonaDeleted: (): void => {
    Instrumentation.logAmplitudeEvent(EndUserScenarioAnalyticsEvents.END_USER_PERSONA_DELETED, {});
  },
  logOrgGroupCreated: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_GROUP_CREATED, {
      org_id: orgId,
    });
  },
  logOrgGroupDeleted: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_GROUP_DELETED, {
      org_id: orgId,
    });
  },
  logOrgInvitationSent: (orgId: string, type: OrgInviteTypes): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_INVITATION_SENT, {
      org_id: orgId,
      type,
    });
  },
  logOrgMemberRemoved: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_MEMBER_REMOVED, {
      org_id: orgId,
    });
  },
  logOrgScenarioCreated: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_SCENARIO_CREATED, {
      org_id: orgId,
    });
  },
  logOrgScenarioUpdated: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_SCENARIO_UPDATED, {
      org_id: orgId,
    });
  },
  logOrgScenarioDeleted: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_SCENARIO_DELETED, {
      org_id: orgId,
    });
  },
  logOrgPersonaCreated: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_PERSONA_CREATED, {
      org_id: orgId,
    });
  },
  logOrgPersonaUpdated: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_PERSONA_UPDATED, {
      org_id: orgId,
    });
  },
  logOrgPersonaDeleted: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_PERSONA_DELETED, {
      org_id: orgId,
    });
  },
  logOrgCustomGoalCreated: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_CUSTOM_GOAL_CREATED, {
      org_id: orgId,
    });
  },
  logOrgCustomGoalUpdated: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_CUSTOM_GOAL_UPDATED, {
      org_id: orgId,
    });
  },
  logOrgCustomGoalDeleted: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_CUSTOM_GOAL_DELETED, {
      org_id: orgId,
    });
  },
  logOrgCoachbotCreated: (orgId: string, type: OrgCoachbotType): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_COACHBOT_CREATED, {
      org_id: orgId,
      type,
    });
  },
  logOrgCoachbotUpdated: (orgId: string, type: OrgCoachbotType): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_COACHBOT_UPDATED, {
      org_id: orgId,
      type,
    });
  },
  logOrgCoachbotDeleted: (orgId: string, type: OrgCoachbotType): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_COACHBOT_DELETED, {
      org_id: orgId,
      type,
    });
  },
  logOrgWelcomeVideoCreated: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_WELCOME_VIDEO_CREATED, {
      org_id: orgId,
    });
  },
  logOrgWelcomeVideoUpdated: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_WELCOME_VIDEO_UPDATED, {
      org_id: orgId,
    });
  },
  logOrgWelcomeVideoDeleted: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_WELCOME_VIDEO_DELETED, {
      org_id: orgId,
    });
  },
  logOrgCourseCreated: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_COURSE_CREATED, {
      org_id: orgId,
    });
  },
  logOrgCourseUpdated: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_COURSE_UPDATED, {
      org_id: orgId,
    });
  },
  logOrgCourseDeleted: (orgId: string): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_COURSE_DELETED, {
      org_id: orgId,
    });
  },
  logOrgReportGenerated: (orgId: string, type: OrgReportWheres): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.ORG_REPORT_GENERATED, {
      org_id: orgId,
      type,
    });
  },
  logScenarioJobDescriptionAdded: (how: EventsJobDescriptionUploadHows): void => {
    Instrumentation.logAmplitudeEvent(OrgAnalyticsEvents.SCENARIO_JOB_DESCRIPTION_ADDED, {
      how,
    });
  },
  logPracticeRecorderLanguageChanged: (language: RecordingSupportedLanguage): void => {
    Instrumentation.logAmplitudeEvent(
      PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_LANGUAGE_CHANGED,
      {
        language,
      }
    );
  },
};

/**
 * Make sure that Amplitude has correct user ID and properties (once set, these properties cannot be updated!)
 * https://amplitude.github.io/Amplitude-JavaScript/Identify/
 * */
export async function setNewAmplitudeUserIdAndProperties(): Promise<void> {
  const uid = firebase.auth().currentUser?.uid;

  if (!uid) {
    return;
  }

  const siteId = getSiteId();
  const userId = `${siteId}/${uid}`;
  amplitude.setUserId(userId);

  // Amplitude needs ISO date string
  const signup_date_utc = firebase.auth().currentUser?.metadata.creationTime;
  const signup_date_iso = new Date(signup_date_utc).toISOString();

  const nextUserProperties = {
    provider_id: firebase.auth().currentUser?.providerId,
    email: currentUserEmail(),
    email_verified: firebase.auth().currentUser?.emailVerified,
    display_name: currentUserName(),
    site_id: siteId,
    signup_date: signup_date_iso,
  };
  const amplitudeEvent = new amplitude.Identify();
  for (const [key, value] of Object.entries(nextUserProperties)) {
    amplitudeEvent.setOnce(key, value); // Set once, then never update
    amplitude.identify(amplitudeEvent);
  }
}

export async function setExistingAmplitudeUserProperties(properties: {
  [k: string]: string | boolean;
}): Promise<void> {
  const amplitudeEvent = new amplitude.Identify();
  for (const [key, value] of Object.entries(properties)) {
    amplitudeEvent.set(key, value);
    amplitude.identify(amplitudeEvent);
  }
}

// Anonymizes users on Amplitude and emits sign out events
export async function resetAmplitudeUser(): Promise<void> {
  Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.SIGN_OUT, {
    email: currentUserEmail(),
    name: currentUserName(),
    userId: getScopedUserId(),
  });

  await amplitude.flush();

  amplitude.reset();
}

// --- AMPLITUDE END / GOOGLE/FIREBASE ANALYTICS START ---

export function getNumberKeyActions(userDocMain?: db.UserDocMain): number {
  userDocMain = userDocMain ?? getLiveUserDocMain();

  const n = Object.values(KeyActionAnalyticsEvents).reduce(
    (acc, k) => acc + (userDocMain["n" + k] ?? 0),
    0
  );
  return n ?? 0;
}

/**
 * Ensure that GoogleAnalytics has correct user ID and properties.
 */
let lastGASetUserId;
let lastGASetUserProperties;

export async function setGAUserIdAndProperties(): Promise<void> {
  const uid = firebase.auth().currentUser?.uid;
  if (uid !== lastGASetUserId) {
    FirebaseAnalyticsLogger.setUserId(getScopedUserId());
    lastGASetUserId = uid;
  }
  await pLiveSiteDocsReady;
  await userDocsService.awaitUserDocsResolved();
  const userDocMain = getLiveUserDocMain();
  const nextUserProperties = {
    onboard_q_events_focus: userDocMain?.onboardingAnswers?.eventsFocus,
    provider_id: firebase.auth().currentUser?.providerId,
    email: currentUserEmail(),
    email_verified: firebase.auth().currentUser?.emailVerified,
    display_name: currentUserName(),
    nka: getNumberKeyActions().toString(),
    site_id: getSiteId(),
  };
  if (!isEqual(nextUserProperties, lastGASetUserProperties)) {
    FirebaseAnalyticsLogger.setUserProperties(nextUserProperties);
    // Absolutely needed to send Firebase/Google analytics properties to BQ for querying purposes
    BQStreamingLogger.log("USER_PROPERTIES", nextUserProperties);

    lastGASetUserProperties = nextUserProperties;
  }
}

// userDocsService will always exist, except for tests
userDocsService?.registerUserDocsChangeListener(setGAUserIdAndProperties);

// --- GOOGLE/FIREBASE ANALYTICS END ---
