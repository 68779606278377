import React from "react";

// Components
import { Stack, Typography } from "@mui/material";
import {
  CUSTOM_GOAL_MAX_SCORE,
  CUSTOM_GOAL_MIN_SCORE,
  CUSTOM_GOAL_NAME_MAX_CHARS,
  CUSTOM_GOAL_USER_DESC_MAX_CHARS,
} from "components/ConvoScenarios/convoScenarioUtils";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import { WYSIWYGEditor } from "components/WYSIWYGEditor";

// Utils
import { useWYSIWYG } from "hooks/useWYSIWYG";
import { ConvoScenarioStepWrapper } from "./ConvoScenarioStepWrapper";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { MinMaxScoreSelector } from "./MinMaxScoreSelector";
import { CreateCompoundGoalRequest } from "lib-fullstack/api/scenarioApiTypes";
import { CompoundGoal } from "lib-fullstack/db";

export type CompoundGoalDescriptionStepProps = {
  compoundGoal: CreateCompoundGoalRequest;
  setCompoundGoal: React.Dispatch<React.SetStateAction<CreateCompoundGoalRequest>>;
  goalEditId: string;
};

export const CompoundGoalDescriptionStep = ({
  compoundGoal,
  setCompoundGoal,
  goalEditId,
}: CompoundGoalDescriptionStepProps): JSX.Element => {
  const handleUpdateCompoundGoal = (key: keyof CompoundGoal, val: string | number) => {
    setCompoundGoal((prev) => ({ ...prev, [key]: val }));
  };

  const { editor } = useWYSIWYG({
    content: compoundGoal.userDescription,
    placeholder: "e.g. Use the STARRY approach to navigate a difficult conversation.",
    characterLimit: CUSTOM_GOAL_USER_DESC_MAX_CHARS,
    onUpdate: (update: string) => {
      handleUpdateCompoundGoal("userDescription", update);
    },
  });

  React.useEffect(() => {
    if (compoundGoal?.userDescription !== editor?.getHTML()) {
      editor?.commands.setContent(compoundGoal?.userDescription);
    }
  }, [compoundGoal?.userDescription]);

  return (
    <ConvoScenarioStepWrapper
      title={goalEditId ? "Update Compound Goal" : "Create a Compound Goal"}
      subTitle="Name this compound goal, set up the scoring scale, and add a short description"
    >
      <Stack
        gap={3}
        sx={{
          position: "relative",
        }}
      >
        <YoodliLabeledInput
          label="Name"
          labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
          inputEl={
            <YoodliTextfield
              autoFocus
              inputProps={{
                className: "blockEnterToNavigate",
              }}
              maxChars={CUSTOM_GOAL_NAME_MAX_CHARS}
              InputLabelProps={{ shrink: true }}
              placeholder="e.g. STARRY approach"
              value={compoundGoal.name}
              onChange={(e) => handleUpdateCompoundGoal("name", e.target.value)}
            />
          }
        />
        <Stack>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: 14,
              fontWeight: 600,
              color: getDynamicColor("purple3"),
            }}
          >
            Score
          </Typography>
          <Stack direction="row" gap={3} sx={{ justifyContent: "space-between", flexWrap: "wrap" }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: 400,
                color: getDynamicColor("dark5"),
                maxWidth: { sm: "100%", md: "400px" },
              }}
            >
              Set the max score members can achieve for each subgoal (max 10). An aggregate score
              will be calculated based on the average of all subgoal scores.
            </Typography>
            <MinMaxScoreSelector
              min={{ value: CUSTOM_GOAL_MIN_SCORE, disabled: true }}
              max={{
                value: compoundGoal.maxScore,
                minValue: CUSTOM_GOAL_MIN_SCORE,
                maxValue: CUSTOM_GOAL_MAX_SCORE,
                onChange: (value: string) => {
                  handleUpdateCompoundGoal("maxScore", value ? Number(value) : undefined);
                },
              }}
            />
          </Stack>
        </Stack>
        <YoodliLabeledInput
          label="Add a member-facing description (optional)"
          labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
          inputEl={
            <WYSIWYGEditor
              editor={editor}
              excludeHeaderStyles
              excludeNumberedList
              excludeBulletedList
              characterLimit={CUSTOM_GOAL_USER_DESC_MAX_CHARS}
            />
          }
        />
      </Stack>
    </ConvoScenarioStepWrapper>
  );
};
