import { getAxiosInstance, AuthorizationAPIHeaders } from "./AxiosInstance";
import * as api from "lib-fullstack/api/orgSpaceApiTypes";

/**
 * Create a new space for an organization.
 * @param orgId The ID of the organization.
 * @param body The request body.
 * @returns The response containing the ID of the created space.
 * @throws Error if the request fails.
 */
export async function createOrgSpace(
  orgId: string,
  body: api.CreateOrgSpaceRequest
): Promise<api.CreateOrgSpaceResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await getAxiosInstance().post(`/v2/orgs/${orgId}/spaces`, body, {
      headers: headers,
    });
    return api.CreateOrgSpaceResponse.check(ret.data);
  } catch (err) {
    throw Error("Bad createOrgSpaces request, status " + err.response?.status);
  }
}

/**
 * Get spaces for an organization available for the user.
 * @param orgId The ID of the organization.
 * @returns The spaces available to the user.
 * @throws Error if the request fails.
 */
export async function listOrgSpaces(orgId: string): Promise<api.GetOrgSpacesResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await getAxiosInstance().get(`/v2/orgs/${orgId}/spaces`, {
      headers: headers,
    });
    return api.GetOrgSpacesResponse.check(ret.data);
  } catch (err) {
    throw Error("Bad listOrgSpaces request, status " + err.response?.status);
  }
}

/**
 * Update an organization space.
 * @param orgId The ID of the organization.
 * @param spaceId The ID of the space to update.
 * @param body The request body.
 * @throws Error if the request fails.
 */
export async function updateOrgSpace(
  orgId: string,
  spaceId: string,
  body: api.UpdateOrgSpaceRequest
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await getAxiosInstance().patch(`/v2/orgs/${orgId}/spaces/${spaceId}`, body, {
      headers: headers,
    });
  } catch (err) {
    throw Error("Bad updateOrgSpace request, status " + err.response?.status);
  }
}

/**
 * Delete an organization space.
 * @param orgId The ID of the organization.
 * @param spaceId The ID of the space to delete.
 * @throws Error if the request fails.
 */
export async function deleteOrgSpace(orgId: string, spaceId: string): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    return await getAxiosInstance().delete(`/v2/orgs/${orgId}/spaces/${spaceId}`, {
      headers: headers,
    });
  } catch (err) {
    throw Error("Bad deleteOrgSpace request, status " + err.response?.status);
  }
}
