import firebase from "firebase/app";
import {
  AuthAnalyticsEvents,
  ProductAnalyticEvents,
} from "lib-fullstack/utils/productAnalyticEvents";
import { initializeFirebase } from "lib-frontend/modules/FirebaseModule";

/**
 * All events that are logged to Firebase Analytics.
 */
export const ALL_FIREBASE_LOGGED_EVENTS = new Set<string>([
  AuthAnalyticsEvents.SIGN_IN,
  AuthAnalyticsEvents.SIGN_OUT,
  AuthAnalyticsEvents.SIGN_UP,
]);

/**
 * Utility class for logging events to Firebase Analytics.
 */
export class FirebaseAnalyticsLogger {
  /**
   * !! Important !! For calling Firebase/Google (it's the same thing) Analytics for users to pull information like
   * utm, ga, traffic_source, etc
   * @param callback
   */
  static ifFirebaseAnalytics(callback: (fbAnalytics: firebase.analytics.Analytics) => void): void {
    void firebase.analytics.isSupported().then((isSupported) => {
      if (isSupported) callback(firebase.analytics());
    });
  }

  /**
   * Set current user ID for Firebase Analytics.
   */
  static setUserId(userId: string): void {
    FirebaseAnalyticsLogger.ifFirebaseAnalytics((fb) => fb.setUserId(userId));
  }

  /**
   * Set current user properties for Firebase Analytics.
   */
  static setUserProperties(properties: firebase.analytics.CustomParams): void {
    FirebaseAnalyticsLogger.ifFirebaseAnalytics((fb) => fb.setUserProperties(properties));
  }

  /**
   * Logs Google Analytics events to Firebase. Important for data like traffic_source_XX, etc.
   */
  static log(eventName: ProductAnalyticEvents, parameters?: { [p: string]: unknown }): void {
    if (!ALL_FIREBASE_LOGGED_EVENTS.has(eventName)) {
      return;
    }

    const logEvent = () => {
      if (parameters !== undefined) {
        FirebaseAnalyticsLogger.ifFirebaseAnalytics((fb) =>
          fb.logEvent(eventName, { ...parameters })
        );
      } else {
        FirebaseAnalyticsLogger.ifFirebaseAnalytics((fb) => fb.logEvent(eventName));
      }
    };
    if (!firebase.apps.length) {
      initializeFirebase();
      logEvent();
    } else {
      logEvent();
    }
  }
}
