import React from "react";

// Components
import { Button, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import CreateContentSpaceCard from "./CreateContentSpaceCard";
import ContentSpaceCard from "./ContentSpaceCard";

// Utils
import { useLocation } from "react-router";
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";

import { getDynamicColor } from "lib-frontend/utils/Colors";

// todo styling - https://github.com/Yoodli/yoodli/issues/14014
const PADDING_TOP = "18px";

type ManageContentSpacesDrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleClose: () => void;
  handleCreate: () => void;
};

const ManageContentSpacesDrawer = ({
  open,
  handleClose,
  handleCreate,
}: ManageContentSpacesDrawerProps): JSX.Element => {
  const location = useLocation();

  React.useEffect(() => {
    handleClose();
  }, [location.pathname]);

  const { navDrawerOffset } = useNavDrawerOffset();

  const { spaces, isWorking, curSpaceId, selectSpace } = React.useContext(ContentSpacesContext);

  const onDeleteClick = (_spaceId: string) => {
    console.log("Delete Org Content Space!"); // todo https://github.com/Yoodli/yoodli/issues/13843
  };

  const onSelectClick = (spaceId: string) => {
    selectSpace(spaceId)
      .then(() => {
        handleClose();
      })
      .catch((e) => {
        console.error(e.toString());
      });
  };

  const renderContentSpaceOptions = (spaceId: string, selected: boolean): JSX.Element => {
    return (
      <Stack>
        <Button disabled={selected} onClick={() => onSelectClick(spaceId)}>
          Select
        </Button>
        <Button onClick={() => onDeleteClick(spaceId)}>Delete</Button>
      </Stack>
    );
  };

  return (
    <Drawer
      open={open}
      anchor="bottom"
      onClose={() => handleClose()}
      hideBackdrop
      sx={{
        left: navDrawerOffset,
        zIndex: 1099,
      }}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          position: "relative",
          top: PADDING_TOP,
          height: `calc(100% - ${PADDING_TOP})`,
          background: getDynamicColor("light1"),
          alignItems: "left",
          px: 8,
          py: 7,
        },
      }}
    >
      <IconButton
        onClick={() => handleClose()}
        sx={{
          position: "absolute",
          right: 35,
          top: 45,
          alignSelf: "top",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        sx={{
          textAlign: "left",
          fontFamily: "poppins",
          fontWeight: 700,
          fontSize: 24,
          color: getDynamicColor("purple3"),
          mt: 3,
          ml: 3,
        }}
      >
        Your Content Spaces
      </Typography>
      <Stack
        sx={{
          my: 4,
          mx: 3,
        }}
      >
        <CreateContentSpaceCard onClick={handleCreate} />
        {isWorking && <div>Loading...</div>}
        {spaces.map((space, key) => {
          const selected = curSpaceId ? space.id === curSpaceId : space.is_default;

          return (
            <ContentSpaceCard
              key={key}
              name={space.name}
              adminCount={space.admins.length}
              selected={selected}
              isDefault={space.is_default}
              additionalMenu={renderContentSpaceOptions(space.id, selected)}
            />
          );
        })}
      </Stack>
    </Drawer>
  );
};

export default ManageContentSpacesDrawer;
