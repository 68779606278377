// Components
import { Skeleton, Stack } from "@mui/material";

export function VideoListSkeleton({ numRows = 10 }: { numRows?: number }): JSX.Element {
  return (
    <Stack gap={{ xs: 1.5, lg: 1 }}>
      {Array.from({ length: numRows }).map((v, i) => (
        <Skeleton
          variant="rectangular"
          key={`skeleton-${i}`}
          sx={{
            height: { xs: 74, lg: 67 },
          }}
        ></Skeleton>
      ))}
    </Stack>
  );
}
