import { db } from "lib-fullstack";
import React from "react";

// Components
import { Stack } from "@mui/material";
import VideoListing, { JournalView } from "components/VideoJournal/VideoListing";

// Utils
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { PaginationState } from "@tanstack/react-table";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getSpeechesSharedWithOrg } from "lib-frontend/modules/AxiosInstance";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import {
  GetSharedWithOrgSortOption,
  OrgMemberResponse,
  SharedWithOrgSpeech,
} from "lib-fullstack/api/orgApiTypes";
import { DEFAULT_SHARED_WITH_ORG_FETCH_COUNT } from "lib-fullstack/utils/constants";
import { queryThumbnails, getThumbnail } from "utils/thumbnailHandler";

type SharedWithOrgProps = {
  user: OrgMemberResponse;
};

const SHARED_WITH_ORG_SPEECHES_QUERY_KEY = "sharedWithOrgSpeeches";

const mapSpeechesToDoc = (speeches: SharedWithOrgSpeech[]): db.Doc<db.Speech>[] => {
  return speeches.map(
    (speech) =>
      ({
        ref: {
          id: speech.id,
          collection: { path: `sites/yoodli/users/${speech.recordedBy}/speeches` },
        },
        data: speech as db.Speech,
      }) as db.Doc<db.Speech>
  );
};

export const SharedWithOrg = ({ user }: SharedWithOrgProps): JSX.Element => {
  const { defaultOrgId } = React.useContext(UserOrgContext);

  const [loading, setLoading] = React.useState(true);
  const [forceUpdateVal, forceUpdate] = React.useState(0);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_SHARED_WITH_ORG_FETCH_COUNT,
  });
  const [pagesRequested, setPagesRequested] = React.useState([]);
  const paginationStartInd = (pagination.pageIndex * pagination.pageSize).toString();

  const [sortFilter, setSortFilter] = React.useState<GetSharedWithOrgSortOption>(
    GetSharedWithOrgSortOption.RECORDED_DATE_DESC
  );
  const speechesQuery = useApiQuery({
    queryKey: [
      SHARED_WITH_ORG_SPEECHES_QUERY_KEY,
      getSiteId(),
      user?.user_id,
      {
        start: paginationStartInd,
        limit: Math.min(pagination.pageSize).toString(),
        sort: sortFilter ?? GetSharedWithOrgSortOption.RECORDED_DATE_DESC,
      },
    ],
    queryFn: async () => {
      try {
        setLoading(true);
        const params = {
          sort: sortFilter ?? GetSharedWithOrgSortOption.RECORDED_DATE_DESC,
          start: paginationStartInd,
          limit: Math.min(pagination.pageSize).toString(),
        };
        const response = await getSpeechesSharedWithOrg(defaultOrgId, user?.user_id, params);

        // easily keep track of which pages have been requested, so i know whether to show the loader or not
        // if the page has already been requested, it will load from cache, so we dont want to to pop in and out the loader
        if (
          !pagesRequested.includes(JSON.stringify({ page: pagination.pageIndex, sort: sortFilter }))
        ) {
          setPagesRequested((prev) => [
            ...prev,
            JSON.stringify({ page: pagination.pageIndex, sort: sortFilter }),
          ]);
        }

        return response;
      } catch (error) {
        console.error("Error fetching speeches shared with org", error);
        return { speeches: [], total: 0 };
      }
    },
    enabled: !!user?.user_id && !!defaultOrgId,
    placeholderData: { speeches: [], total: 0 },
  });

  const speechDocs = React.useMemo(() => {
    const speechDocuments = mapSpeechesToDoc(speechesQuery.data?.speeches ?? []);
    queryThumbnails(speechDocuments)
      .then(() => {
        forceUpdate((prev) => prev + 1);
      })
      .catch((error) => {
        console.error(`Failed to fetch thumbnails: ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
    return speechDocuments;
  }, [speechesQuery.data?.speeches]);

  const sharedWithOrgItems = React.useMemo(() => {
    return speechDocs.map((speech) => ({
      dbSpeech: speech,
      thumbnailUrl: getThumbnail(speech.ref.id),
    }));
    // must include the forceUpdateVal here so when thumbnails are ready, they re applied to the speeches
  }, [speechDocs, forceUpdateVal]);

  const handlePageChange = (page: number) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: Math.max(page, 0) };
    });
  };

  // must convert VideoListing/Speech sort options to GetSharedWithOrgSortOption
  const handleSetSortFilter = (newSortFilter: string) => {
    if (newSortFilter === "title") {
      setSortFilter(GetSharedWithOrgSortOption.NAME_ASC);
    } else if (newSortFilter === "-title") {
      setSortFilter(GetSharedWithOrgSortOption.NAME_DESC);
    } else if (newSortFilter === "created") {
      setSortFilter(GetSharedWithOrgSortOption.RECORDED_DATE_ASC);
    } else if (newSortFilter === "-created") {
      setSortFilter(GetSharedWithOrgSortOption.RECORDED_DATE_DESC);
    } else if (newSortFilter === "totalTime") {
      setSortFilter(GetSharedWithOrgSortOption.TOTAL_TIME_S_ASC);
    } else if (newSortFilter === "-totalTime") {
      setSortFilter(GetSharedWithOrgSortOption.TOTAL_TIME_S_DESC);
    }
  };

  return (
    <Stack direction="column" alignItems="stretch">
      <VideoListing
        simpleTable
        hideTableFilter
        maxSpeechesPerPage={5}
        loadingFromProps={
          loading ||
          !pagesRequested.includes(JSON.stringify({ page: pagination.pageIndex, sort: sortFilter }))
        }
        paginationProps={{
          count: speechesQuery.data?.total,
          ...pagination,
        }}
        journalView={JournalView.SharedWithOrg}
        sharedWithOrgItems={sharedWithOrgItems}
        handlePageChangeProp={handlePageChange}
        serverSortHeaders={["title", "created", "totalTime"]}
        setSortFilter={handleSetSortFilter}
      />
    </Stack>
  );
};
