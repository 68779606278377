import React from "react";

// Components
import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  MoreHorizRounded as MoreHorizRoundedIcon,
  WarningAmberRounded as WarningAmberRoundedIcon,
} from "@mui/icons-material";
import { Box, Button, Fade, Stack, Typography } from "@mui/material";
import PdfExtractor from "components/PdfExtractor";
import AnimatedEllipsis from "lib-frontend/components/AnimatedEllipsis";
import IconMenu from "lib-frontend/components/IconMenu";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import videoLightbox from "ui/VideoLightbox";

// Utils
import { CoachBotUploadedFile } from "./CoachBotTypes";
import { FileUploadProgress } from "./SelfServeCoachBotWizard/CoachBotUploadContent/FileUploadProgress";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import {
  GetAllCoachBotContentResponse,
  GetCoachBotContentResponse,
} from "lib-fullstack/api/hubApiTypes";
import {
  AnalyticProcessingState,
  AutoGenerationState,
  OrgFileContentType,
} from "lib-fullstack/utils/enums";
import { getEnabledFlag } from "lib-frontend/utils/unleash";

type CoachBotContentProps = {
  coachBotContent: GetAllCoachBotContentResponse;
  newlyUploadedCoachBotContent: CoachBotUploadedFile[];
  handleSetCoachBotContentIsActive: (contentId: string, isActive: boolean) => Promise<void>;
  handleDeleteCoachBotContent: (contentId: string) => Promise<void>;
  handleUpdateCoachBotContentFilename: (contentId: string, newFileName: string) => Promise<void>;
  handleGenerateScenariosFromCoachBotContent: (contentId: string) => Promise<void>;
  handleGenerateGoalsFromCoachBotContent: (contentId: string) => Promise<void>;
  showManageContent: boolean;
  setShowManageContent: (show: boolean) => void;
};
export const CoachBotContent = ({
  coachBotContent,
  newlyUploadedCoachBotContent,
  handleSetCoachBotContentIsActive,
  handleDeleteCoachBotContent,
  handleUpdateCoachBotContentFilename,
  handleGenerateScenariosFromCoachBotContent,
  handleGenerateGoalsFromCoachBotContent,
  showManageContent,
  setShowManageContent,
}: CoachBotContentProps): JSX.Element => {
  const [editingFileId, setEditingFileId] = React.useState<string | null>(null);
  const [loadingFileId, setLoadingFileId] = React.useState<string | null>(null);
  const [pdfUrl, setPdfUrl] = React.useState<string>("");
  const [newFileName, setNewFileName] = React.useState<string>("");
  const columnHelper = createColumnHelper<GetCoachBotContentResponse | CoachBotUploadedFile>();

  const tableData = React.useMemo(() => {
    // filter any fields form the newly uploaded list that already have been returned inside of botContent to avoid dupes
    const newlyUploadedFileUnique = (newlyUploadedCoachBotContent ?? []).filter((x) => {
      return x.uploadProgress !== 100 || !coachBotContent?.botContent?.some((y) => x.id === y?.id);
    });
    const coachBotContentUnique = (coachBotContent?.botContent ?? []).filter((x) => {
      return !newlyUploadedFileUnique.some((y) => x.id === y.id);
    });

    return [...newlyUploadedFileUnique, ...coachBotContentUnique];
  }, [coachBotContent?.botContent, newlyUploadedCoachBotContent]);

  // set refs so we can access data inside the table without remounting
  const tableDataRef = React.useRef(tableData);
  React.useEffect(() => {
    tableDataRef.current = tableData;
  }, [tableData]);
  const coachBotContentRef = React.useRef<GetAllCoachBotContentResponse>(coachBotContent);
  React.useEffect(() => {
    coachBotContentRef.current = coachBotContent;
  }, [coachBotContent]);

  const handleUpdateCoachBotContentFilenameLocal = async (rowId: string, newFileName: string) => {
    setLoadingFileId(rowId);
    await handleUpdateCoachBotContentFilename(rowId, newFileName);
    setLoadingFileId(null);
  };

  const handleDeleteCoachBotContentLocal = async (rowId: string) => {
    setLoadingFileId(rowId);
    await handleDeleteCoachBotContent(rowId);
    setLoadingFileId(null);
  };

  const handleSetCoachBotContentIsActiveLocal = async (contentId: string, isActive: boolean) => {
    setLoadingFileId(contentId);
    await handleSetCoachBotContentIsActive(contentId, isActive);
    setLoadingFileId(null);
  };

  const handleRemoveFile = async (fileId: string) => {
    handleDeleteCoachBotContentLocal(fileId).catch((er) => {
      console.error(`Error delete coachbot content file: ${er}`);
    });
  };

  const handleDownloadFile = async (fileId: string) => {
    const file = coachBotContentRef.current.botContent.find((x) => x.id === fileId);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.cssText = "display: block";
    a.href = file.signedUrl;
    a.click();
    document.body.removeChild(a);
  };

  const handleViewFile = (fileId: string) => {
    const file = coachBotContentRef.current.botContent.find((x) => x.id === fileId);
    if (file) {
      if (file.fileType?.includes("video") || file.fileType?.includes("audio")) {
        setLightboxURL(file.signedUrl);
      } else if (file.fileType.includes("pdf")) {
        setPdfUrl(file.signedUrl);
      } else {
        console.error(`Unsupported file type for viewing: ${file.fileType}`);
        return;
      }
    } else {
      console.error(`Could not find file with id: ${fileId}`);
      return;
    }
  };

  const handleResetViewFile = () => {
    setPdfUrl("");
    setLightboxURL("");
  };

  const renderFileNameCell = (info) => {
    const greyTitle =
      info.row.original.status === AnalyticProcessingState.PROCESSING ||
      !info.row.original.isActive;
    if (editingFileId === info.row.original.id) {
      return (
        <YoodliTextfield
          autoFocus
          value={newFileName}
          maxChars={100}
          onChange={(e) => {
            setNewFileName(e.target.value);
          }}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              if (newFileName !== info.row.original.filename) {
                await handleUpdateCoachBotContentFilenameLocal(info.row.original.id, newFileName);
                setEditingFileId(null);
              }
            } else if (e.key === "Escape") {
              setEditingFileId(null);
            }
          }}
          sx={{
            width: "100%",
            fontWeight: 600,
            px: 0,
          }}
          inputProps={{
            sx: {
              py: 1,
              px: 1.5,
            },
          }}
        />
      );
    }
    return (
      <Typography
        sx={{
          color: getDynamicColor(greyTitle ? "dark4" : "purple3"),
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {info.row.original.filename}
      </Typography>
    );
  };

  const handleSetEditingFileId = (fileId: string) => {
    // if this is called while already editing, save the current file name
    if (editingFileId) {
      setEditingFileId(null);
      handleUpdateCoachBotContentFilenameLocal(editingFileId, newFileName).catch((er) => {
        console.error(`Error updating coachbot content for org: ${er}`);
      });
    } else {
      setEditingFileId(fileId);
      setNewFileName(tableDataRef.current.find((x) => x.id === fileId)?.filename ?? "");
    }
  };

  const renderStatusCell = (info) => {
    if (editingFileId === info.row.original.id) {
      return null;
    }
    if (info.row.original.uploadStatus) {
      // render the loader here showing the GCS upload progress
      return (
        <Box
          sx={{
            minWidth: "200%",
          }}
        >
          <FileUploadProgress
            progress={info.row.original.uploadProgress}
            status={info.row.original.uploadStatus}
            handleRemoveFile={handleRemoveFile}
            fileId={info.row.original.id}
            editingFileId={editingFileId}
            handleSetEditingFileId={handleSetEditingFileId}
            // hide edit and remove form this context since the user already has the
            // dropdown menu to do these actions, and the buttons are removed when content is re-fetched
            hideEdit
            hideRemove
          />
        </Box>
      );
    } else if (
      !info.row.original.isActive &&
      info.row.original.status !== AnalyticProcessingState.PENDING
    ) {
      return (
        <Stack direction="row" alignItems="flex-end">
          <Typography
            sx={{
              color: getDynamicColor("dark4"),
              fontFamily: "poppins",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Disabled
          </Typography>
        </Stack>
      );
    }
    // TODO @dwiegand: check on the impact showing processing when pending might have. I think its a safe quality of life UI treatment, but needs testing
    else if (
      info.row.original.status === AnalyticProcessingState.PROCESSING ||
      info.row.original.status === AnalyticProcessingState.PENDING
    ) {
      return (
        <Stack direction="row" alignItems="flex-end">
          <Typography
            sx={{
              color: getDynamicColor("dark4"),
              fontFamily: "poppins",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Processing
          </Typography>
          <AnimatedEllipsis size={2} top={-6} marginLeft={6} />
        </Stack>
      );
    } else if (info.row.original.status === AnalyticProcessingState.ERROR) {
      return (
        <WarningAmberRoundedIcon
          sx={{
            color: getDynamicColor("redError"),
            height: 20,
            width: 20,
          }}
        />
      );
    } else if (info.row.original.status === AnalyticProcessingState.FINISHED) {
      return (
        <CheckCircleRoundedIcon
          sx={{
            color: getDynamicColor("greenSuccess"),
            height: 20,
            width: 20,
          }}
        />
      );
    }
  };

  const renderPreviewCell = (info) => {
    if (editingFileId === info.row.original.id || info.row.original.uploadStatus) {
      return null;
    } else if (
      !info.row.original.isActive ||
      info.row.original.status === AnalyticProcessingState.FINISHED
    ) {
      return (
        <Button
          variant="text"
          onClick={() => handleViewFile(info.row.original.id)}
          disableRipple
          sx={{
            fontSize: 12,
            p: 0,
            fontWeight: 600,
          }}
        >
          View file
        </Button>
      );
    } else if (info.row.original.status === AnalyticProcessingState.PROCESSING) {
      return (
        <Typography
          sx={{
            color: getDynamicColor("dark4"),
            fontFamily: "poppins",
            fontSize: 12,
            fontWeight: 600,
            display: { xs: "none", md: "initial" },
          }}
        >
          Pending
        </Typography>
      );
    } else if (info.row.original.status === AnalyticProcessingState.ERROR) {
      return (
        <Typography
          sx={{
            fontSize: 12,
            fontFamily: "poppins",
            color: getDynamicColor("redError"),
            px: 0,
            fontWeight: 600,
          }}
        >
          Error
        </Typography>
      );
    }
    return null;
  };

  const renderActionsCell = (info) => {
    // // if the row has an uploadStatus tht means it's a newlyUploaded file, and i shouldnt show the actions until it completes
    // // (besides the cancel button, which is always available for newly uploaded files)
    // if (info.row.original.uploadStatus) {
    //   return null;
    // }
    if (editingFileId === info.row.original.id) {
      return (
        <Button
          onClick={async () => {
            // save the new name
            if (newFileName !== info.row.original.filename) {
              await handleUpdateCoachBotContentFilenameLocal(info.row.original.id, newFileName);
            }
            setEditingFileId(null);
          }}
          disabled={newFileName.length === 0}
          variant="outlined"
          sx={{
            fontSize: 12,
          }}
        >
          Save
        </Button>
      );
    }
    const isUploading = !!info.row.original.uploadStatus;
    const menuItems = [
      {
        title: "Rename",
        onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation();
          setNewFileName(info.row.original.filename);
          setEditingFileId(info.row.original.id);
        },
      },
      {
        title: info.row.original.isActive ? "Disable" : "Enable",
        onClick: () =>
          handleSetCoachBotContentIsActiveLocal(info.row.original.id, !info.row.original.isActive),
      },
      {
        title: "Delete",
        onClick: () => handleDeleteCoachBotContentLocal(info.row.original.id),
        disabled: isUploading,
      },
      {
        title: "Download",
        onClick: () => handleDownloadFile(info.row.original.id),
        disabled: isUploading,
      },
    ];

    // Note: Inside this if clause is only intended for the internal release.
    // This does not get the updated state once it's at "generating" state.
    if (getEnabledFlag("autogen")) {
      const content = info.row.original as GetCoachBotContentResponse;
      if (content.preprocessState === AnalyticProcessingState.PROCESSING) {
        menuItems.push({
          title: "Preprocessing for auto generation",
          onClick: () => {
            return Promise.resolve();
          },
          disabled: true,
        });
      } else if (content.preprocessState === AnalyticProcessingState.FINISHED) {
        let scenarioGenerationTitle;
        let scenarioGenerationActionable = false;
        if (
          !content.scenarioGenerationState ||
          content.scenarioGenerationState === AutoGenerationState.None ||
          content.scenarioGenerationState === AutoGenerationState.Error
        ) {
          scenarioGenerationTitle =
            content.materialType === OrgFileContentType.Scenario
              ? "Generate scenarios"
              : "Generate scenarios (not recommended)";
          scenarioGenerationActionable = true;
        } else if (content.scenarioGenerationState === AutoGenerationState.Generating) {
          scenarioGenerationTitle = "Generating scenarios (revisit this page for update)";
        } else {
          scenarioGenerationTitle = "Scenarios generated";
        }
        const generateScenarioItem = {
          title: scenarioGenerationTitle,
          onClick: async (): Promise<void> => {
            if (generateScenarioItem.disabled) {
              return;
            }
            generateScenarioItem.disabled = true;
            await handleGenerateScenariosFromCoachBotContent(info.row.original.id);
            generateScenarioItem.title = "Generating scenarios (revisit this page for update)";
          },
          disabled: !scenarioGenerationActionable,
        };
        menuItems.push(generateScenarioItem);

        let goalGenerationTitle;
        let goalGenerationActionable = false;
        if (
          !content.goalGenerationState ||
          content.goalGenerationState === AutoGenerationState.None ||
          content.goalGenerationState === AutoGenerationState.Error
        ) {
          goalGenerationTitle =
            content.materialType === OrgFileContentType.Rubrics
              ? "Generate goals"
              : "Generate goals (not recommended)";
          goalGenerationActionable = true;
        } else if (content.goalGenerationState === AutoGenerationState.Generating) {
          goalGenerationTitle = "Generating goals (revisit this page for update)";
        } else {
          goalGenerationTitle = "Goals generated";
        }
        const generateGoalsItem = {
          title: goalGenerationTitle,
          onClick: async (): Promise<void> => {
            generateGoalsItem.disabled = true;
            await handleGenerateGoalsFromCoachBotContent(info.row.original.id);
            generateGoalsItem.title = "Generating goals (revisit this page for update)";
          },
          disabled: !goalGenerationActionable,
        };
        menuItems.push(generateGoalsItem);
      }
    }

    return (
      <IconMenu
        title="Manage File"
        hideCaret
        hideTooltip
        disableDrag
        menuItems={menuItems}
        iconButtonSx={{
          height: 24,
          width: 24,
          border: `1px solid ${getDynamicColor(isUploading ? "dark3" : "primary")}`,
          svg: {
            height: 22,
            width: 22,
            color: getDynamicColor(isUploading ? "dark3" : "primary"),
          },
        }}
        menuItemSx={{
          fontSize: 14,
          fontWeight: 600,
          fontFamily: "poppins",
          px: 2,
          py: 1.5,
          color: getDynamicColor("primary"),
        }}
        minWidth={"140px"}
        paperSx={{
          borderRadius: "12px",
        }}
        icon={
          <MoreHorizRoundedIcon
            sx={{
              color: getDynamicColor("primary"),
              height: 16,
              width: 16,
            }}
          />
        }
      />
    );
  };

  const columns = React.useMemo(() => {
    return [
      columnHelper.accessor((row) => row.filename, {
        id: "filename",
        header: () => <Typography>FILE NAME</Typography>,
        cell: renderFileNameCell,
      }),
      columnHelper.accessor("status", {
        id: "status",
        header: () => <Typography>STATUS</Typography>,
        cell: renderStatusCell,
      }),
      columnHelper.display({
        id: "preview",
        header: "PREVIEW",
        cell: renderPreviewCell,
      }),
      columnHelper.display({
        id: "actions",
        cell: renderActionsCell,
      }),
    ];
  }, [editingFileId, newFileName]);
  const { lightbox, setLightboxURL } = videoLightbox("");

  const renderViewFile = () => {
    return (
      <>
        {lightbox}
        <PdfExtractor pdfUrl={pdfUrl} useDialog onDialogClose={handleResetViewFile} />
      </>
    );
  };

  const table = useReactTable({
    data: tableData,
    columns: columns.filter(Boolean) as ColumnDef<
      GetCoachBotContentResponse | CoachBotUploadedFile,
      unknown
    >[],
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  return (
    <Box>
      {!showManageContent && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              fontWeight: 700,
              fontFamily: "poppins",
            }}
          >
            Content
          </Typography>
          <Button
            variant="outlined"
            sx={{
              width: 140,
              py: 0.5,
              fontSize: 14,
            }}
            onClick={() => setShowManageContent(true)}
          >
            Manage
          </Button>
        </Stack>
      )}
      <table
        style={{
          width: "100%",
          border: `1px solid ${getDynamicColor("dark2")}`,
          boxShadow: Y_SHADOWS.dark_elevation,
          borderCollapse: "collapse",
          // clutch css prop below to make the table not stretch outside of it;s bounds
          tableLayout: "fixed",
          maxWidth: showManageContent ? "1200px" : "100%",
          margin: "auto",
        }}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <Box
                component="tr"
                key={headerGroup.id}
                sx={{
                  width: "max-content",
                  borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                }}
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{
                      width: header.index === 0 ? "49%" : "17%",
                    }}
                  >
                    <Box
                      sx={{
                        color: getDynamicColor("dark5"),
                        fontFamily: "poppins",
                        fontSize: "12px",
                        fontWeight: 600,
                        width: "max-content",
                        my: 1.5,
                        px: 2,
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </Box>
                  </th>
                ))}
              </Box>
            );
          })}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Box
                component="tr"
                key={row.id}
                sx={{
                  width: "max-content",
                  borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                  position: "relative",
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      style={{
                        color: getDynamicColor("purple3"),
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 600,
                        padding: "0 16px",
                        height: 56,
                        textAlign: cell.column.columnDef.id === "actions" ? "center" : "left",
                      }}
                    >
                      <Fade in={loadingFileId === cell.row.original.id}>
                        <span>
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background: getDynamicColor("gradient.default"),
                              opacity: 0.025,
                              zIndex: 2,
                            }}
                          />
                        </span>
                      </Fade>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </Box>
            );
          })}
        </tbody>
      </table>
      {renderViewFile()}
    </Box>
  );
};
