import { IntegrationData } from "./db_runtypes_integration";
import * as db from "./index";
import { Doc, Ref, getRefPath } from "typesaurus";

/**
 * Extract document IDs from `path`.
 * @param path
 */
export function pathToIds(path: string): {
  siteId?: string;
  userId?: string;
  speechId?: string;
  speechAnalyticsId?: db.SpeechAnalyticsTypes;
} {
  const ids: { [key: string]: string } = {};
  const matches = path.match(pathRegExp);
  if (!matches) return ids;
  if (matches.length >= 2) {
    ids["siteId"] = matches[1];
    if (matches.length >= 3) {
      ids["userId"] = matches[2];
      if (matches.length >= 4) {
        ids["speechId"] = matches[3];
        if (matches.length >= 5) {
          ids["speechAnalyticsId"] = matches[4];
        }
      }
    }
  }
  return ids;
}
const pathRegExp =
  /(?:sites\/(?<siteId>\w+))?(?:\/users\/(?<userId>[\w|]+))?(?:\/speeches\/(?<speechId>\w+))?(?:\/analytics\/(?<speechAnalyticsId>\w+))?.*/;

/**
 * Recurse path to return the requested parent Site, User, Etc.
 */
export function speechIdFor(doc: Doc<unknown>): string {
  return speechIdForRef(doc.ref);
}
export function speechIdForRef(ref: Ref<unknown>): string {
  const prop = "speechId";
  const path = getRefPath(ref);
  const ids = pathToIds(path);
  if (ids[prop] === undefined) {
    throw Error(`Could not get ${prop} from path ${path}.`);
  }
  return ids[prop] as string;
}
export function userIdFor(doc: Doc<unknown>): string {
  return userIdForRef(doc.ref);
}
export function userIdForRef(ref: Ref<unknown>): string {
  const prop = "userId";
  const path = getRefPath(ref);
  const ids = pathToIds(path);
  if (ids[prop] === undefined) {
    throw Error(`Could not get ${prop} from path ${path}.`);
  }
  return ids[prop] as string;
}
export function siteIdFor(doc: Doc<unknown>): string {
  return siteIdForRef(doc.ref);
}
export function siteIdForRef(ref: Ref<unknown>): string {
  const prop = "siteId";
  const path = getRefPath(ref);
  const ids = pathToIds(path);
  if (ids[prop] === undefined) {
    throw Error(`Could not get ${prop} from path ${path}.`);
  }
  return ids[prop] as string;
}

export function orgIdFor(doc: Doc<unknown>): string {
  return orgIdForRef(doc.ref);
}
const regexOrgId = /^sites\/\w+\/orgs\/(\w+)/;
export function orgIdForRef(ref: Ref<unknown>): string {
  const path = getRefPath(ref);
  const match = path.match(regexOrgId);
  if (!match) {
    throw new Error(`Invalid org path: ${path}`);
  }
  return match[1];
}

/** Parse speech ref into individual component IDs */
export function parseSpeechRef(ref: Ref<db.Speech>): {
  siteId: string;
  userId: string;
  speechId: string;
} {
  return parseSpeechPath(getRefPath(ref));
}
/** Parse speech path into individual component IDs */
export function parseSpeechPath(path: string): {
  siteId: string;
  userId: string;
  speechId: string;
} {
  const matches = path.match(regexSpeechPath);
  if (!matches || matches.length != 4) {
    throw new Error(`Invalid speech path: ${path}`);
  }
  return {
    siteId: matches[1],
    userId: matches[2],
    speechId: matches[3],
  };
}
const regexSpeechPath = /^sites\/(\w+)\/users\/([\w|]+)\/speeches\/(\w+)$/;

/** Parse org ref into individual ID */
export function parseOrgRef(ref: Ref<db.Org>): {
  siteId: string;
  orgId: string;
} {
  return parseOrgPath(getRefPath(ref));
}
/** Parse org path into individual ID */
export function parseOrgPath(path: string): {
  siteId: string;
  orgId: string;
} {
  const matches = path.match(regexOrgPath);
  if (!matches || matches.length != 3) {
    throw new Error(`Invalid org path: ${path}`);
  }
  return {
    siteId: matches[1],
    orgId: matches[2],
  };
}
const regexOrgPath = /^sites\/(\w+)\/orgs\/(\w+)$/;

/** Parse org membership ref into individual component ID */
export function parseOrgMembershipRef(orgRef: Ref<db.OrgMembership>): {
  siteId: string;
  orgId: string;
  userId: string;
} {
  return parseOrgMembershipPath(getRefPath(orgRef));
}
/** Parse org membership path into individual component ID */
export function parseOrgMembershipPath(path: string): {
  siteId: string;
  orgId: string;
  userId: string;
} {
  const matches = path.match(regexOrgMembershipPath);
  if (!matches || matches.length != 4) {
    throw new Error(`Invalid orgMemberships path: ${path}`);
  }
  return {
    siteId: matches[1],
    orgId: matches[2],
    userId: matches[3],
  };
}
const regexOrgMembershipPath = /^sites\/(\w+)\/orgs\/(\w+)\/orgMemberships\/(\w+)$/;

/** Parse org invite ref into individual component ID */
export function parseOrgInviteV2Ref(orgRef: Ref<db.OrgInviteV2>): {
  siteId: string;
  orgId: string;
  email: string;
} {
  return parseOrgInviteV2Path(getRefPath(orgRef));
}
/** Parse org invite path into individual component ID */
export function parseOrgInviteV2Path(path: string): {
  siteId: string;
  orgId: string;
  email: string;
} {
  const matches = path.match(regexOrgInviteV2Path);
  if (!matches || matches.length != 4) {
    throw new Error(`Invalid hub invite path: ${path}`);
  }
  return {
    siteId: matches[1],
    orgId: matches[2],
    email: matches[3],
  };
}
const regexOrgInviteV2Path = /^sites\/(\w+)\/orgs\/(\w+)\/orgInvitesV2\/([^/]+)$/;

/** Parse content video path into individual component ID */
export function parseContentVideoRef(ref: Ref<db.ContentVideo>): {
  siteId: string;
  orgId: string;
  videoId: string;
} {
  return parseContentVideoPath(getRefPath(ref));
}
/** Parse content video path into individual component ID */
export function parseContentVideoPath(path: string): {
  siteId: string;
  orgId: string;
  videoId: string;
} {
  const matches = path.match(regexContentVideoPath);
  if (!matches || matches.length != 4) {
    throw new Error(`Invalid contentVideos path: ${path}`);
  }
  return {
    siteId: matches[1],
    orgId: matches[2],
    videoId: matches[3],
  };
}
const regexContentVideoPath = /^sites\/(\w+)\/orgs\/(\w+)\/contentVideos\/(\w+)$/;

/** Parse coachBot path into individual component IDs */
export function parseCoachBotPath(path: string): {
  siteId: string;
  orgId: string;
  botId: string;
} {
  const matches = path.match(regexCoachBotPath);
  if (!matches || matches.length != 4) {
    throw new Error(`Invalid coachBot path: ${path}`);
  }
  return {
    siteId: matches[1],
    orgId: matches[2],
    botId: matches[3],
  };
}
/** Parse coachBot ref into individual component ID */
export function parseCoachBotRef(ref: Ref<db.CoachBot>): {
  siteId: string;
  orgId: string;
  botId: string;
} {
  return parseCoachBotPath(getRefPath(ref));
}
const regexCoachBotPath = /^sites\/(\w+)\/orgs\/(\w+)\/coachBots\/(\w+)$/;

/** Parse botContent path into individual component IDs */
export function parseBotContentPath(path: string): {
  siteId: string;
  orgId: string;
  botId: string;
  contentId: string;
} {
  const matches = path.match(regexBotContentPath);
  if (!matches || matches.length != 5) {
    throw new Error(`Invalid coachBot path: ${path}`);
  }
  return {
    siteId: matches[1],
    orgId: matches[2],
    botId: matches[3],
    contentId: matches[4],
  };
}
/** Parse coachBot ref into individual component ID */
export function parseBotContentRef(ref: Ref<db.CoachBot>): {
  siteId: string;
  orgId: string;
  botId: string;
  contentId: string;
} {
  return parseBotContentPath(getRefPath(ref));
}
const regexBotContentPath = /^sites\/(\w+)\/orgs\/(\w+)\/coachBots\/(\w+)\/botContent\/(\w+)$/;

/** Parse hub ref into individual component ID */
export function parseHubRef(ref: Ref<db.Hub>): {
  siteId: string;
  orgId: string;
  hubId: string;
} {
  return parseHubPath(getRefPath(ref));
}
/** Parse hub path into individual component ID */
export function parseHubPath(path: string): {
  siteId: string;
  orgId: string;
  hubId: string;
} {
  const matches = path.match(regexHubPath);
  if (!matches || matches.length != 4) {
    throw new Error(`Invalid hub path: ${path}`);
  }
  return {
    siteId: matches[1],
    orgId: matches[2],
    hubId: matches[3],
  };
}
const regexHubPath = /^sites\/(\w+)\/orgs\/(\w+)\/hubs\/(\w+)$/;

/** Parse hubMembershipV2 ref into individual component ID */
export function parseHubMembershipV2Ref(ref: Ref<db.HubMembershipV2>): {
  siteId: string;
  orgId: string;
  hubId: string;
  userId: string;
} {
  return parseHubMembershipV2Path(getRefPath(ref));
}
/** Parse hubMembershipV2 path into individual component ID */
export function parseHubMembershipV2Path(path: string): {
  siteId: string;
  orgId: string;
  hubId: string;
  userId: string;
} {
  const matches = path.match(regexHubMembershipV2Path);
  if (!matches || matches.length != 5) {
    throw new Error(`Invalid hubMembershipV2 path: ${path}`);
  }
  return {
    siteId: matches[1],
    orgId: matches[2],
    hubId: matches[4],
    userId: matches[3],
  };
}
const regexHubMembershipV2Path =
  /^sites\/(\w+)\/orgs\/(\w+)\/orgMemberships\/(\w+)\/hubMembershipsV2\/(\w+)$/;

/* Parse program ref into individual component ID */
export function parseProgramRef(programRef: Ref<db.Program>): {
  siteId: string;
  orgId: string;
  programId: string;
} {
  const path = getRefPath(programRef);
  const matches = path.match(regexProgramPath);
  if (!matches || matches.length != 4) {
    throw new Error(`Invalid program path: ${path}`);
  }
  return {
    siteId: matches[1],
    orgId: matches[2],
    programId: matches[3],
  };
}
const regexProgramPath = /^sites\/(\w+)\/orgs\/(\w+)\/programs\/(\w+)$/;

/* Parse program record ref into individual component ID */
export function parseProgramRecordRef(programRecordRef: Ref<db.ProgramRecord>): {
  siteId: string;
  orgId: string;
  programId: string;
  userId: string;
} {
  const path = getRefPath(programRecordRef);
  const matches = path.match(regexProgramRecordPath);
  if (!matches || matches.length != 5) {
    throw new Error(`Invalid program record path: ${path}`);
  }
  return {
    siteId: matches[1],
    orgId: matches[2],
    programId: matches[3],
    userId: matches[4],
  };
}
const regexProgramRecordPath =
  /^sites\/(\w+)\/orgs\/(\w+)\/programs\/(\w+)\/programRecords\/(\w+)$/;

export function parseOrgIntegrationRef(ref: Ref<IntegrationData>): {
  siteId: string;
  orgId: string;
  integrationId: string;
} {
  const path = getRefPath(ref);
  const matches = path.match(regexOrgIntegrationPath);
  if (!matches || matches.length != 4) {
    throw new Error(`Invalid org integration path: ${path}`);
  }
  return {
    siteId: matches[1],
    orgId: matches[2],
    integrationId: matches[3],
  };
}
const regexOrgIntegrationPath = /^sites\/(\w+)\/orgs\/(\w+)\/integrations\/(\w+)$/;

/** Parse org scenario ref into individual component ID */
export function parseOrgScenarioRef(ref: Ref<db.OrgScenario>): {
  siteId: string;
  orgId: string;
  scenarioId: string;
} {
  const path = getRefPath(ref);
  const matches = path.match(regexpOrgScenarioPath);
  if (!matches || matches.length != 4) {
    throw new Error(`Invalid org scenario path: ${path}`);
  }
  return {
    siteId: matches[1],
    orgId: matches[2],
    scenarioId: matches[3],
  };
}
const regexpOrgScenarioPath = /^sites\/(\w+)\/orgs\/(\w+)\/scenarios\/(\w+)$/;

/** Parse user scenario ref into individual component ID */
export function parseUserScenarioRef(ref: Ref<db.OrgScenario>): {
  siteId: string;
  userId: string;
  scenarioId: string;
} {
  const path = getRefPath(ref);
  const matches = path.match(regexpUserScenarioPath);
  if (!matches || matches.length != 4) {
    throw new Error(`Invalid user scenario path: ${path}`);
  }
  return {
    siteId: matches[1],
    userId: matches[2],
    scenarioId: matches[3],
  };
}
const regexpUserScenarioPath = /^sites\/(\w+)\/users\/(\w+)\/scenarios\/(\w+)$/;
