import {
  Boolean,
  Dictionary,
  Null,
  Number,
  Optional,
  Array as RTArray,
  Record,
  Static,
  String,
  Union,
} from "runtypes";

import {
  AbstractDoc,
  ScenarioTypeId,
  RTScenarioTemplateSubType,
  CalendarEvent,
  RecallBotJoinMethod,
  CalendarAttendee,
  SpeakerLog,
  AggregateAnalyticTypes,
  SpeechLayout,
  SpeechSource,
  PromptType,
  SpeakerID,
} from "lib-fullstack/db";

import {
  RTHumanEvaluationState,
  RTAnalyticProcessingState,
  RecordingSupportedLanguage,
} from "lib-fullstack/utils/enums";
import { NullableOptional, RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

export enum LifecycleState {
  INCOMPLETE = "incomplete",
  CREATED = "created",
  REDACTED = "redacted",
  DELETED = "deleted",
  PURGED = "purged",
}
export const RTLifecycleState = RTStringEnum(LifecycleState);

export enum DeletedReason {
  USER = "user",
  DISPOSED_BY_RETRY = "disposed_by_retry",
  TOO_SHORT_POODLI = "too_short_poodli",
  FAILED_ZOODLI = "failed_zoodli",
  NAVIGATED_BEFORE_SAVING = "navigate_before_saving",
}

// note: likely to be changed soon
// also not complete
export enum AnalyicsFailureTypes {
  EMPTY_MEDIA = "rev_empty_media",
  FFMPEG_NO_AUDIO = "ffmpeg_no_audio_output",
  REV_DL_FAILURE = "rev_download_failure",
  NO_MONOLOGUES = "rev_no_monologues",
  REV_NO_AUDIO = "rev_no_transcript_audio",
  TOO_SHORT = "rev_duration_too_short",
}

export enum UploadState {
  INCOMPLETE = "incomplete",
  UPLOADED = "uploaded",
}
export const RTUploadState = RTStringEnum(UploadState);

export enum VideoTranscodingStatus {
  STARTED = "STARTED",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
}
export const RTVideoTranscodingStatus = RTStringEnum(VideoTranscodingStatus);

export const ResponseSection = Record({
  startS: Number,
  endS: Number,
});
export type ResponseSection = Static<typeof ResponseSection>;

export const Speaker = Record({
  zoomUserId: Optional(Number),
  emailAddress: Optional(Union(String, Null)),
  name: String,
  avatarUrl: Optional(Union(String, Null)),
});
export type Speaker = Static<typeof Speaker>;

export const SpeechPrompt = Record({
  text: String,
  startS: Optional(Number), // startS and endS optional because it's possible for users to stop/quit before getting through all prompts
  endS: Optional(Number),
  type: Optional(PromptType),
});
export type SpeechPrompt = Static<typeof SpeechPrompt>;

export const FeedbackRequest = Record({
  coachId: String,
  description: String,
  dateRequested: String,
});
export type FeedbackRequest = Static<typeof FeedbackRequest>;

export const PublicPersonaData = Record({
  personaId: String,
  name: String,
  jobTitle: String,
  demeanor: String,
  profilePictureId: Optional(String),
  signedUrl: Optional(String),
});
export type PublicPersonaData = Static<typeof PublicPersonaData>;

export const Speech = AbstractDoc.extend({
  slug: String, // Unique string used to lookup this document. Eg, DocumentId
  name: String,
  nameWasEdited: Optional(Boolean), // user edited the name
  nameWasGenerated: Optional(Boolean), // a name was generated using GPT

  lifecycleState: RTLifecycleState,
  lifecycleStateChangedDate: String,
  deletedDate: Optional(String),
  deletedReason: Optional(RTStringEnum(DeletedReason)),

  gcsUploaded: Union(String, Null), // Webm file which FPR and Poodli uploaded or any type of media file that a user uploaded
  gcsTranscoded: Union(String, Null), // Transcoded MP4 from uploaded file
  gcsLiveAudio: Union(String, Null), // WAV file which was made in real-time via web socket in FPR and Poodli
  gcsAudio: Union(String, Null), // Same as liveAudioUrl for FPR/Poodli, and extracted WAV file from user uploaded videos
  gcsThumbnail: Union(String, Null), // Webp file which was made by backend

  fixedDuration: Optional(Boolean), // default: false

  type: SpeechSource,
  subType: Optional(SpeechLayout),
  speakerNotes: Optional(String),
  targetTimeS: NullableOptional(Number),
  totalTimeS: NullableOptional(Number),
  analyticsFailureReason: Optional(Union(String, RTAnalyticProcessingState)), // union for now; once these are firmed up should be removed
  recordedBy: String,
  recordingStartDate: String,
  recordedDate: String, // end date, keeping naming like this for code compat
  saved: Optional(Boolean), // parameter is only relevant for LIVE speech type. Other types are always saved = true.
  uploadState: RTUploadState,
  commentsVisibility: Boolean, // Default: true
  analyticsVisibility: Optional(Boolean), // Default: true
  audioOnly: Optional(Boolean), // default: false
  screenShareActive: Optional(Boolean), // default: false
  transcodingJobId: Optional(String),
  videoTranscodingStatus: Optional(RTVideoTranscodingStatus),
  noCanvasRecording: Optional(Boolean), // default: false
  mirrored: Optional(Boolean), // default: false TODO migrate noCanvasRecording to mirrored
  videoTranscodingFailureReason: NullableOptional(String),
  syncRevJobId: NullableOptional(String),
  asyncRevJobId: Optional(String),
  asyncDeepgramJobId: Optional(String),
  revStartTime: Optional(String),
  deepgramStartTime: Optional(String),
  revSyncWasInterrupted: Optional(Boolean),
  asyncTranscriptState: Optional(RTAnalyticProcessingState),
  asyncTranscriptSkipped: Optional(Boolean), // default: false
  spritesheetFPS: Optional(Number),
  spritesheet: Optional(String),
  videoResolution: Optional(String),
  zoomBotRefPath: Optional(String),
  zoomBotJoinMethod: Optional(RecallBotJoinMethod),
  normative: Optional(Boolean),
  normativeData: Optional(Record({ location: String, snr: String, type: String })),
  normativeBookmark: Optional(Boolean),
  amfuqEnabled: Optional(Union(Boolean, Null)), // Default: false
  usageDecremented: Optional(Boolean), // Default: false

  speakers: Optional(Dictionary(Speaker, String)),
  attendees: Optional(Union(RTArray(CalendarAttendee), Null)),

  activeSpeakerLog: Optional(Union(RTArray(SpeakerLog), Null)),

  indexData: NullableOptional(
    Record({
      wpm: Optional(Union(Number, Null)),
      numFillerWords: Optional(Number),
      percentFillerWords: Optional(Number),
      percentTalkTime: Optional(Number),
      numWords: Optional(Number),
      recordedByName: Optional(Union(String, Null)),
      version: Optional(Number),
      lastUpdate: Optional(String),
      numMonologues: Optional(Number),
      isSync: Optional(Boolean),
    })
  ),
  personaData: NullableOptional(PublicPersonaData),

  prompts: Optional(RTArray(SpeechPrompt)), // Used when subType is impromptu prompt or job interview

  // Sharing
  linkSharing: Boolean,
  /**
   * Enable link sharing to all org members of the org of this speech.
   * Older speeches miss this filed which is considered as false.
   * Newer speeches have this.
   */
  orgLinkSharing: Optional(Boolean),
  // collabs is like: {'emailHash1': {email:"email1@example.com"}, ...} for now.
  //  if needed, we can build add'l properties into each {...}. (We need to do the
  //  hashing b/c you can't have dots in keys, and we want idempotent + atomic
  //  updates to this map.
  collabs: Dictionary(Record({ email: String }), String),

  // Video Journal List View (for Speech Progress). All optional
  disfluencies: Optional(Dictionary(Number, String)),

  // DEPRECATED: to remove in the future
  coachFeedbackRequest: Optional(RTArray(FeedbackRequest)),

  // Optional, only populated if speech is recorded through our calendar integration
  event: NullableOptional(CalendarEvent),

  // version of our product this speech was recorded under. Either a git commit hash or a semantic version for Poodli
  // Will be missing for older recordings
  recordedByVersion: Optional(String),

  // what version of each aggregate analytic was calculated for this speech, if it was calculated
  aggregateCalculatedVersions: Optional(Dictionary(Number, AggregateAnalyticTypes)),
  counted: Optional(Boolean), // default: false. Whether or not this speech has been counted in the user's total speech count nYoodlis
  speechGoalsAggregated: Optional(Boolean), // default: false. Whether or not this speech has goal results which have been aggregated
  isFTUX: Optional(Boolean), // default: false. Whether or not this speech is a first time user experience speech (defaults to AMFUQ with 2 questions)
  /**
   * Indicator that a specific migration script has been already applied.
   * The script may skip those speeches in follow up runs after initial run.
   */
  migrationVersion: Optional(String),
  // Those fields are for analytics and available even after the data purge,
  // but those are not necessarily accurate, e.g. not considering unshare or uncomments
  approximateNumCollabs: Number,
  approximateNumComments: Number,

  // This exists only in memory/code. This may be removed when we retire featured speech.
  // See additional comment in Home.tsx.
  featuredSpeechThumbnail: Optional(String),

  coachBotPath: Optional(String), // Path to the coach bot that was used to generate coach comments on the speech, if one was used
  personaId: Optional(String), // The persona ID for the conversation scenario of the speech
  scenarioPath: Optional(String), // The path to the scenario of the speech
  scenarioType: Optional(ScenarioTypeId), // The type of the scenario of the speech
  scenarioTemplateSubType: Optional(RTScenarioTemplateSubType), // The sub type of the scenario template of the speech, if it has one

  /**
   * Flag to indicate that this speech may contribute to a program attempt
   * and waits for composite score to be posted.
   */
  pendingScoreForProgram: Optional(Boolean),
  /**
   * Composite score is given after async analytics is done.
   * If analytics determines impossible to give a score, it is set as null.
   */
  compositeScore: NullableOptional(Number),
  /**
   * This is set after composite score is given and a speech
   * is associated to a specific program as one of attempts.
   * This program should belong to the org whose orgID is also stored in this speech doc.
   */
  programId: Optional(String),
  /**
   * If human evaluation is needed for the program, this is Incomplete or Completed
   * depending on whether the score is finalized.
   * If it's not needed, this is set to NotNeeded.
   * Note that this field is created only after determining which program to be contributed,
   * i.e. when pendingScoreForProgram is cleared and programId is set.
   */
  humanEvaluationState: Optional(RTHumanEvaluationState),
  /**
   * User ID who finalized the human evaluation score.
   */
  humanEvaluationFinalizedBy: Optional(String),
  /**
   * A flag to indicate that this speech is shared to evaluators, at least once.
   */
  sharedToEvaluators: Optional(Boolean),

  afterSpeechAnalysisProcessed: Optional(Boolean),

  /**
   * Time sections (start-end pairs) when TTS responses were recorded
   */
  responseSections: Optional(RTArray(ResponseSection)),
  /**
   * Whether or not this speech should be diarized by Rev.
   * Only relevant for Uploads currently.
   * Note Recall recordings are always diarized, but by the provider timestamps.
   * default: false
   */
  shouldDiarize: Optional(Boolean),
  /**
   * The org that this speech belongs to.
   * It is the default org of the user at the time of recording.
   * Never change after recording is created.
   * Older speeches miss this filed. Newer speeches have this as nullable.
   */
  orgId: NullableOptional(String),

  /**
   * Metric for measuring Deepgram sync quality--confidence score.
   */
  syncConfidence: Optional(Number),
  /**
   * Metric for measuring Deepgram sync quality--Word Error Rate between sync and async transcriptions.
   */
  syncAsyncWer: Optional(Number),

  language: Optional(RecordingSupportedLanguage),

  /**
   * The activity ID associated with the speech, if any.
   */
  activityId: Optional(String),

  /**
   * The ID of the speaker whose analytics and remarks should be shown by default. This is overridden if one speaker exactly matches the user's name.
   */
  defaultAnalyticsSpeaker: Optional(SpeakerID),
  /**
   * Whether or not to show AI feedback for this speech in the Speech Summary
   * This is set by the org's disableAiFeedback setting
   */
  disableAiFeedback: Optional(Boolean),

  /**
   * Whether or not the AI hung up on the speaker during the speech
   */
  aiHungUp: Optional(Boolean),

  /**
   * The number of times the audio was rerun through the audio processing pipeline after the first run.
   * If this number is large, there is something wrong.
   */
  nAudioReruns: Optional(Number),
});
export type Speech = Static<typeof Speech>;
