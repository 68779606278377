import firebase from "firebase/app";
import React from "react";
import { useNavigate } from "react-router";

// Components
import { ChecklistTasks } from "components/Home/OnboardingChecklist/ChecklistTasks";
import Confetti from "ui/Confetti";
import UploadModal from "components/Modals/UploadModal/UploadModal";
import videoLightbox from "ui/VideoLightbox";

// Utils
import { SampleSpeechesModal } from "./SampleSpeechesModal";
import { useOnboardingChecklistTasks } from "hooks";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { CustomEventEnum, subscribe, unsubscribe } from "lib-frontend/events";
import { inviteUserToHub } from "lib-frontend/modules/AxiosInstance";
import {
  markOnboardingTaskCompleteAndEmitEvent,
  OnboardingChecklistDoc,
} from "lib-frontend/utils/onboardingUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OnboardingChecklistTask } from "lib-fullstack/db";
import { HubRole } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { HomePageChecklistEvents } from "lib-fullstack/utils/productAnalyticEvents";

type OnboardingChecklistTaskElement = {
  onboardingChecklistDoc: OnboardingChecklistDoc;
  allCompleted: boolean;
  allTasks: OnboardingChecklistTask[];
  completedTaskIds: OnboardingChecklistTask[];
  dismissedTaskIds: OnboardingChecklistTask[];
  numCompletedTasks: number;
  numDismissedTasks: number;
};

export const OnboardingChecklistContainer = ({
  onboardingChecklistDoc, // Assumes onboardingChecklistDoc exists (otherwise don't render this component)
  allCompleted,
  allTasks,
  completedTaskIds,
  dismissedTaskIds,
  numCompletedTasks,
  numDismissedTasks,
}: OnboardingChecklistTaskElement): JSX.Element => {
  const navigate = useNavigate();

  const { isAnyOrgOwnerAdmin } = React.useContext(UserOrgContext);

  const demoVideoUrl = React.useRef<string>(undefined);
  const {
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);

  const [activeTaskIdx, setActiveTaskIdx] = React.useState<number>(-1);
  const [showUploadModal, setShowUploadModal] = React.useState(false);
  const [showSampleSpeechModal, setShowSampleSpeechModal] = React.useState(false);
  const [inviteUrl, setInviteUrl] = React.useState<string>(undefined);

  // #region Fffects

  React.useEffect(() => {
    const closeSampleSpeechModal = () => setShowSampleSpeechModal(false);
    subscribe(CustomEventEnum.PRICING_MODAL_OPEN, closeSampleSpeechModal);

    return () => {
      unsubscribe(CustomEventEnum.PRICING_MODAL_OPEN, closeSampleSpeechModal);
    };
  }, []);

  React.useEffect(() => {
    checkIfCompleted(OnboardingChecklistTask.FPR_INTERVIEW);
    // Automatically activate the next available task when one is completed.
    if (allCompleted) {
      setActiveTaskIdx(-1);
    } else {
      const firstAvailableTaskIdx = allTasks.findIndex((task) => !completedTaskIds?.includes(task));
      if (activeTaskIdx === null) {
        // On page load, immediately activate the first available task.
        setActiveTaskIdx(firstAvailableTaskIdx);
      } else {
        // Otherwise, wait a split second before automatically activating the next task.
        setTimeout(() => {
          setActiveTaskIdx(firstAvailableTaskIdx);
        }, 500);
      }
    }
  }, [numCompletedTasks, numDismissedTasks]);

  React.useEffect(() => {
    const getHubInviteLink = async () => {
      if (firebase.auth()?.currentUser && !inviteUrl && defaultOrg) {
        const adminHubs = defaultOrg.hubs
          .map((hub) => ({ id: hub.id, role: hub.hub_role }))
          .filter((hub) => hub.role === HubRole.ADMIN || isAnyOrgOwnerAdmin);
        if (adminHubs.length > 0) {
          const invite = await inviteUserToHub(adminHubs[0].id, null, HubRole.MEMBER);
          setInviteUrl(`${window.location.origin}/${invite.invite_path}`);
        }
      }
    };

    getHubInviteLink().catch((err) => console.error("Error fetching group data:", err));
  }, [completedTaskIds?.length, defaultOrg]);

  // #endregion

  // #region Handlers
  const openSampleSpeechesModal = () => {
    setShowSampleSpeechModal(true);
    markOnboardingTaskCompleteAndEmitEvent(OnboardingChecklistTask.VIEW_SAMPLE_SPEECH).catch(
      (err) => console.error(`Error marking view-sample-speech task complete: ${err}`)
    );
  };

  const onVideoPlayed = (seconds: number) => {
    Instrumentation.logYoodliChecklistDemoVideoPlayed(seconds, demoVideoUrl.current);
  };

  const onVideoPaused = (seconds: number) => {
    Instrumentation.logYoodliChecklistDemoVideoPaused(seconds, demoVideoUrl.current);
  };

  const onVideoSeeked = (seconds: number) => {
    Instrumentation.logYoodliChecklistDemoVideoSeeked(seconds, demoVideoUrl.current);
  };

  const onVideoPlaybackSpeedChanged = (speed: number) => {
    Instrumentation.logYoodliChecklistDemoVideoPlaybackSpeedChanged(
      `${speed}x`,
      demoVideoUrl.current
    );
  };

  const onDialogClosed = () => {
    Instrumentation.logAmplitudeEvent(
      HomePageChecklistEvents.YOODLI_CHECKLIST_DEMO_VIDEO_MODAL_CLOSED
    );
  };

  // Setup demo video lightbox, but keep the URL blank to not open (render) it until demo is clicked.
  const { lightbox, setLightboxURL } = videoLightbox(
    "",
    "",
    "",
    onVideoPlayed,
    onVideoPaused,
    onVideoSeeked,
    onVideoPlaybackSpeedChanged,
    onDialogClosed,
    true
  );

  // Controls what happens when a user clicks on the demo video prompt
  const onWatchDemoClicked = (videoUrl: string) => {
    try {
      const taskId: OnboardingChecklistTask = OnboardingChecklistTask.DEMO_VIDEO;
      demoVideoUrl.current = videoUrl;

      Instrumentation.logYoodliOnboardingChecklistCTAClicked(
        "Check out a demo video",
        taskId,
        allTasks.indexOf(taskId),
        checkIfCompleted(taskId),
        "Play video"
      );
      // Sets the URL & shows the video
      setLightboxURL(videoUrl, "Learn more about Yoodli's coaching capabilities");

      markOnboardingTaskCompleteAndEmitEvent(OnboardingChecklistTask.DEMO_VIDEO).catch((err) => {
        throw new Error(`Error marking demo-video onboarding task complete: ${err}`);
      });
    } catch (er) {
      // 2024-02-19: Sending this error to sentry to track how often this happens/why this might be happening
      console.error(
        `OnboardingChecklistContainer.onWatchDemoClicked: Error handling onWatchDemoClicked: ${er}`
      );
    }
  };

  const logPracticeTaskClicked = (
    url: string,
    taskId: OnboardingChecklistTask,
    name: string,
    buttonText: string
  ) => {
    Instrumentation.logYoodliOnboardingChecklistCTAClicked(
      name,
      taskId,
      allTasks.indexOf(taskId),
      checkIfCompleted(taskId),
      buttonText,
      url
    );
    navigate(url);
  };

  // #endregion

  // #region Checklist Hook
  const { taskLists, checkIfCompleted, checkIfDismissed } = useOnboardingChecklistTasks(
    allTasks,
    completedTaskIds,
    dismissedTaskIds,
    inviteUrl,
    {
      [OnboardingChecklistTask.DEMO_VIDEO]: () =>
        onWatchDemoClicked(
          "https://storage.googleapis.com/yoodli-public/misc-product-videos/yoodli_checklist_video_speech_interview_meetings.mp4"
        ),
      [OnboardingChecklistTask.VIEW_SAMPLE_SPEECH]: openSampleSpeechesModal,

      [OnboardingChecklistTask.UPLOAD_SPEECH]: () => setShowUploadModal(true),
      [OnboardingChecklistTask.FPR_INTERVIEW]: (name: string, buttonText: string) =>
        logPracticeTaskClicked(
          WebServerExternalPath.PRACTICE_INTERVIEW,
          OnboardingChecklistTask.FPR_INTERVIEW,
          name,
          buttonText
        ),
      [OnboardingChecklistTask.FPR_SPEECH]: (name: string, buttonText: string) =>
        logPracticeTaskClicked(
          WebServerExternalPath.PRACTICE_SPEECH,
          OnboardingChecklistTask.FPR_SPEECH,
          name,
          buttonText
        ),
      [OnboardingChecklistTask.FPR_CONVERSATION]: (name: string, buttonText: string) =>
        logPracticeTaskClicked(
          WebServerExternalPath.PRACTICE_CONVERSATION,
          OnboardingChecklistTask.FPR_CONVERSATION,
          name,
          buttonText
        ),
    }
  );
  const data = taskLists[onboardingChecklistDoc.checklistId];
  // #endregion

  // #region Render

  const renderChecklistModals = () => {
    return (
      <>
        <SampleSpeechesModal
          showGifDialog={showSampleSpeechModal}
          setShowGifDialog={setShowSampleSpeechModal}
        />
        {/* Consider removing these modals and using the top level UploadModal in the Layout in the future. */}
        <UploadModal open={showUploadModal} close={() => setShowUploadModal(false)} />
      </>
    );
  };

  return (
    <>
      <ChecklistTasks
        allTasks={allTasks}
        data={data}
        checkIfCompleted={checkIfCompleted}
        checkIfDismissed={checkIfDismissed}
        activeTaskIdx={activeTaskIdx}
        setActiveTaskIdx={setActiveTaskIdx}
        allCompleted={allCompleted}
      />
      {lightbox}
      {renderChecklistModals()}
      {allCompleted && (
        <Confetti
          loop
          autoPlay
          style={{
            zIndex: 1,
            width: "min(100%, 2000px)",
          }}
        />
      )}
    </>
  );

  // #endregion
};
