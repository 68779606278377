import React from "react";
import { useLocation, useNavigate } from "react-router";

// Components
import { Stack, Button } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgSettingsQueryParams } from "lib-fullstack/utils/queryParams";
import { OrgMembersTabEnum } from "./OrgMembers";
import { ORG_TABS_HEIGHT } from "lib-frontend/utils/orgUtils";

type OrgMembersTabsProps = {
  selectedTab: OrgMembersTabEnum;
  setSelectedTab: (tab: OrgMembersTabEnum) => void;
  shouldBlockNav: boolean;
  setShouldBlockNav: (val: boolean) => void;
};

export const OrgMembersTabs = ({
  selectedTab,
  setSelectedTab,
  shouldBlockNav,
  setShouldBlockNav,
}: OrgMembersTabsProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const qp = new URLSearchParams(location.search);

  const handleTabChange = (tab: OrgMembersTabEnum) => {
    if (
      !shouldBlockNav ||
      (shouldBlockNav &&
        window.confirm("Are you sure you want to change tabs? your changes might not be saved!"))
    ) {
      setSelectedTab(tab);
      setShouldBlockNav(false);
      qp.set(OrgSettingsQueryParams.TAB, tab);
      navigate({ search: qp.toString() });
    }
  };

  return (
    <Stack
      direction="row"
      gap={{ xs: 2, sm: 3, md: 4 }}
      px={{ xs: 2, md: 3, lg: 4, xl: 5 }}
      sx={{
        width: "100%",
        backgroundColor: getDynamicColor("purple3"),
        overflowX: "auto",
        height: ORG_TABS_HEIGHT,
      }}
    >
      {Object.entries(OrgMembersTabEnum).map(([tabKey, tabValue]) => {
        return (
          <Button
            key={tabKey}
            onClick={() => handleTabChange(tabValue)}
            sx={{
              py: 0.5,
              px: 1,
              textAlign: "left",
              fontSize: 14,
              color: getDynamicColor("light1"),
              borderRadius: 0,
              borderBottom: "3px solid",
              borderColor: selectedTab === tabValue ? getDynamicColor("light1") : "transparent",
              whiteSpace: "nowrap",
              fontWeight: 400,
              fontFamily: "Poppins",
              // prevent changing the fontWeight from causing the button width to change, shifting it's neighbors position
              "&::after": {
                position: "absolute",
                display: "block",
                content: `"${tabValue}"`,
                fontWeight: 500,
                height: 1,
                py: 0.5,
                px: 1,
                color: getDynamicColor("light1"),
                overflow: "hidden",
                opacity: selectedTab === tabValue ? 1 : 0,
              },
            }}
          >
            {OrgMembersTabEnum[tabKey]}
          </Button>
        );
      })}
    </Stack>
  );
};
