import firebase from "firebase/app";
import { db } from "lib-fullstack";
import _ from "lodash";
import React from "react";

// Components
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import {
  getEmailAddresses,
  hasAttendeesToShareWithStill,
} from "./SpeechSummary/SpeechSummaryUtils";
import { SPEECH_QUERY_KEY } from "./useSpeechQuery";
import { useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { updateSpeechShare } from "lib-frontend/modules/AxiosInstance";
import { currentUserEmail, currentUserName } from "lib-frontend/utils/AccountUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getSiteId, getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { getLiveUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { markOnboardingTaskCompleteAndEmitEvent } from "lib-frontend/utils/onboardingUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { isWhiteLabel } from "lib-frontend/utils/Utilities";
import { addOrUpdateContact, emailToHash } from "lib-fullstack/utils/contacts";
import {
  EventsKeyActionHows,
  KeyActionAnalyticsEvents,
} from "lib-fullstack/utils/productAnalyticEvents";
import isEmail from "validator/lib/isEmail";
import { OrgCustomerType } from "lib-fullstack/utils/enums";

export type Entry = { email: string; name?: string; label: string };

export function stringToEntry(value: string): Entry {
  return { email: value, label: value };
}

type ShareSpeechByEmailProps = {
  dbSpeech: db.Doc<db.Speech>;
  prePopulateShareEmails?: string[];
  onClose: (MouseEvent?) => void;
  open?: boolean;
};

export const ShareSpeechByEmail = ({
  dbSpeech,
  prePopulateShareEmails,
  onClose,
  open,
}: ShareSpeechByEmailProps): React.ReactElement => {
  const queryClient = useQueryClient();
  const isSmallScreen = useIsSmallScreen();

  const {
    defaultOrgId,
    isDefaultOrgOwnerAdmin,
    defaultOrgName,
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);

  const [inputResetKey, setInputResetKey] = React.useState("init_key");
  const [currentShareInputValue, setCurrentShareInputValue] = React.useState("");
  const [inputError, setInputError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [fieldEdited, setFieldEdited] = React.useState(false);
  const { data, ref } = dbSpeech;
  const { collabs, excludingCollabs } = getEmailAddresses({
    ...data,
    excludeMyEmail: currentUserEmail(),
  });
  const initialSelected = [...excludingCollabs];
  if (prePopulateShareEmails?.length) {
    initialSelected.concat(
      prePopulateShareEmails.map((email) => ({
        email,
        name: "",
        label: email,
      }))
    );
  }

  const prePopulatedEmailEntries = (prePopulateShareEmails ?? []).map((email) => ({
    email,
    name: "",
    label: email,
  }));

  const [selected, _setSelected] = React.useState<Entry[]>([
    ...initialSelected,
    ...prePopulatedEmailEntries,
  ]);
  // there are so many ways to add emails, it's safest to just manually filter the currentUser email here
  const autocompleteValue = React.useMemo(
    () => selected.filter((v) => v.email !== firebase.auth().currentUser?.email),
    [selected]
  );
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);
  const sendEnabled = !!autocompleteValue?.length || isEmail(currentShareInputValue);

  const contacts: { [emailHash: string]: db.Contact } = getLiveUserDocMain().contacts;
  const siteConf = getStaticFullSiteConf();

  for (const contact of siteConf?.defaultContacts ?? []) {
    contacts[emailToHash(contact.email)] = contact;
  }

  // collabs entry does not have name field on DB.
  // Inject it at runtime on memory from the user's contacts.
  Object.values(collabs).forEach((collab) => {
    const contact = contacts[emailToHash(collab.email)]; // undefined if not found
    if (contact?.name) {
      collab["name"] = contact.name;
    }
  });

  /** Clean up selection, just to be sure */
  const setSelected = (newSelected) => {
    // no bare strings
    newSelected = _.filter(newSelected, (v) => !(typeof v == "string"));
    // no duplicates
    newSelected = _.uniqBy(newSelected, "email");
    _setSelected(newSelected);
  };

  React.useEffect(() => {
    if (dbSpeech) {
      let nonAttendeesMsg = `Hi, check out the Yoodli AI coaching report for ${data.name} and feel free to leave comments. Thanks!`;
      if (isDefaultOrgOwnerAdmin) {
        nonAttendeesMsg = `Hello! Please take a look at this feedback on “${data.name}” from “${defaultOrgName}” AI Coach. Please leave comments in the Coaching tab if you have questions. Thanks!`;
      }
      const firstName = currentUserName();
      if (firstName) {
        nonAttendeesMsg += `\n\n- ${firstName}`;
      }

      const attendeesMsg =
        "Hi! Here’s the Yoodli from our call! Make sure to check out your speaking analytics and transcript!";

      const hasUnsharedAttendees = hasAttendeesToShareWithStill({
        ...data,
        excludeMyEmail: currentUserEmail(),
      });

      setMessage(hasUnsharedAttendees ? attendeesMsg : nonAttendeesMsg);
    }
  }, [dbSpeech?.ref?.id, defaultOrgId, open]);

  /** Hack to get autocomplete text to reset after we pretend to submit it
   * via e.g. space-bar. See
   * https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value
   */
  const resetInputText = () => {
    setInputResetKey(new Date().toISOString());
  };

  const clearShareFields = () => {
    setSelected([]);
    setMessage("");
    setInputError(false);
    resetInputText();
  };

  const options = _.values(contacts)
    .map((v) => ({
      email: v.email,
      name: v.name,
      label: v.name ? `${v.name} <${v.email}>` : v.email,
    }))
    .filter(
      (v) =>
        !_.includes(_.map(autocompleteValue, "email"), v.email) &&
        !(emailToHash(v.email) in (collabs ?? {})) &&
        !(v.email.includes("@yoodli.ai") && isWhiteLabel()) && // Filter out @yoodli.ai emails from whitelabels
        v.email !== firebase.auth().currentUser?.email
    );

  const tryToAcceptInput = (value: string) => {
    value = value.trim();
    value = value.replaceAll(/,/g, " ");
    if (isEmail(value)) {
      setInputError(false);
      setSelected([...selected, stringToEntry(value)]);
      resetInputText();
    } else {
      setInputError(true);
    }
  };

  const tryToAcceptSelection = (value: Entry[]) => {
    if (value?.length) {
      const lastEntry = _.last(value);
      if (typeof lastEntry == "string") {
        if (isEmail(lastEntry)) {
          setInputError(false);
          value[value.length - 1] = stringToEntry(lastEntry);
          resetInputText();
        } else {
          setInputError(true);
          return;
        }
      } else {
        setInputError(false);
      }
      setSelected(value);
    } else {
      clearShareFields();
    }
  };

  const handleShareButtonClicked = (_event) => {
    const addingEmails: string[] = [];
    for (const s of autocompleteValue) {
      const email = typeof s == "string" ? s : s.email; // can be email or an object with .email property
      addingEmails.push(email);

      // update address book
      addOrUpdateContact(getSiteId(), email).catch(console.error);
    }

    // call API to update
    updateSpeechShare(ref.id, { add_emails: addingEmails, invite_message: message })
      .then(() => {
        void queryClient.invalidateQueries({ queryKey: [SPEECH_QUERY_KEY, dbSpeech.ref.id] });
        Instrumentation.logKeyAction(KeyActionAnalyticsEvents.SHARE_SPEECH, {
          userId: firebase.auth().currentUser ? firebase.auth().currentUser.uid : null,
          slug: dbSpeech.data.slug,
          how: EventsKeyActionHows.SHARE_EMAIL,
        });
      })
      .catch(console.error);
    clearShareFields();

    markOnboardingTaskCompleteAndEmitEvent(db.OnboardingChecklistTask.SHARE_SPEECH).catch((err) =>
      console.error(`Error marking onboarding task SHARE_SPEECH completed: ${err}`)
    );

    // Putting close here instead of in the success callback makes the closing experience less jarring
    // Eventually, we'll need some kind of loading indicator while the email is sending out before closing this nicely
    onClose();
  };

  const removeCollab = (email: string) => {
    updateSpeechShare(ref.id, { remove_emails: [email] }).catch((error) =>
      console.error(`removeCollab failed: ${email}, ${error}`)
    );
  };

  const renderShareMessage = () => {
    return (
      <Collapse
        sx={{
          width: "100%",
          mt: 0,
        }}
        in={sendEnabled}
      >
        <YoodliTextfield
          id="outlined-multiline-static"
          label="Message"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          fullWidth
          rows={4}
          value={message}
          spellCheck={fieldEdited}
          inputProps={{
            sx: {
              p: 2,
            },
          }}
          sx={{
            fontSize: "16px",
            ".MuiInputBase-root": {
              p: 0,
            },
          }}
          onClick={(
            event: React.MouseEvent<HTMLInputElement> & {
              target?: { select?: unknown };
            }
          ) => {
            if (!fieldEdited && event.target && event.target.select) {
              (event.target as HTMLTextAreaElement).select();
            }
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (!fieldEdited) {
              setFieldEdited(true);
            }
            setMessage(event.target.value);
          }}
        />
      </Collapse>
    );
  };

  const isSharedWithHub = (emails: string[]) => {
    return emails.every(
      (email) =>
        Object.values(collabs).find(({ email: collabEmail }) => collabEmail === email) ||
        autocompleteValue.find((s) => s.email === email)
    );
  };

  const renderHubSelection = () => {
    if (defaultOrg?.customer_type !== OrgCustomerType.COACH) {
      return null;
    }
    return (
      <Stack direction="row" gap={1} sx={{ flexWrap: "wrap" }}>
        {defaultOrg?.hubs.map((hub) => (
          <Button
            key={hub.id}
            disabled={isSharedWithHub(hub.admin_emails)}
            onClick={() =>
              tryToAcceptSelection(hub.admin_emails.map((email) => stringToEntry(email)))
            }
            endIcon={
              isSharedWithHub(hub.admin_emails) ? (
                <CheckCircleIcon sx={{ color: getDynamicColor("greenSuccess") }} />
              ) : (
                <AddCircleOutlineIcon sx={{ color: getDynamicColor("primary") }} />
              )
            }
            sx={{
              borderRadius: "100px",
              border: `1px solid ${getDynamicColor(
                isSharedWithHub(hub.admin_emails) ? "dark4" : "primary"
              )}`,
              px: 1.5,
              py: 1,
              alignItems: "center",
              width: "fit-content",
              flexWrap: "wrap",
              color: getDynamicColor(isSharedWithHub(hub.admin_emails) ? "dark4" : "dark5"),
              fontFamily: "poppins",
              fontSize: "12px",
              fontWeight: 500,
              cursor: isSharedWithHub(hub.admin_emails) ? "auto" : "pointer",
            }}
          >
            {hub.name}
          </Button>
        ))}
      </Stack>
    );
  };

  return (
    <Stack direction="column">
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        justifyContent="center"
        gap={2}
        mb={2}
      >
        <>
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              flexGrow: 1,
              width: { xs: "100%", md: "auto" },
            }}
            variant="standard"
          >
            <Autocomplete
              value={autocompleteValue}
              key={inputResetKey}
              onInputChange={(_, value) => {
                if (value.endsWith(" ") || value.endsWith(",")) {
                  tryToAcceptInput(value.replaceAll(/,/g, " "));
                }
              }}
              onChange={(_, value: Entry[]) => {
                tryToAcceptSelection(value);
              }}
              multiple
              freeSolo
              options={options}
              sx={{ flexGrow: 1, maxWidth: "100%" }}
              id="email-addr-input"
              renderInput={(params) => (
                <YoodliTextfield
                  {...params}
                  autoFocus
                  value={currentShareInputValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCurrentShareInputValue(event.target.value);
                  }}
                  error={!!inputError}
                  onBlur={(event: React.FocusEvent<HTMLTextAreaElement>) => {
                    if (event?.target?.value) {
                      tryToAcceptInput(event?.target?.value);
                    }
                  }}
                  helperText={inputError ? "Please enter a valid email" : undefined}
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          {isSmallScreen && renderShareMessage()}

          <Tooltip
            title="Select at least one recipient"
            disableFocusListener={sendEnabled}
            disableHoverListener={sendEnabled}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
            open={tooltipOpen && !sendEnabled}
          >
            <Button
              disabled={!sendEnabled}
              variant="contained"
              onClick={handleShareButtonClicked}
              size="large"
              sx={{
                color: getDynamicColor("light1"),
                width: { xs: "100%", md: "auto" },
                cursor: !sendEnabled ? "not-allowed" : "pointer",
              }}
            >
              Share
            </Button>
          </Tooltip>
        </>
      </Stack>

      {!isSmallScreen && sendEnabled ? renderShareMessage() : renderHubSelection()}
      <Collapse in={!sendEnabled && !_.isEmpty(collabs)}>
        <>
          <Paper style={{ maxHeight: 200, overflow: "auto" }} elevation={0}>
            <Typography
              sx={{
                mt: 1,
                fontWeight: 600,
                position: "sticky",
                zIndex: 1,
                top: 0,
                paddingY: 1,
                backgroundColor: "white",
              }}
            >
              Collaborators
            </Typography>
            <List sx={{ overflowY: "auto", maxHeight: "128px" }}>
              {Object.entries(collabs).map(
                ([_k, v]: [_k: string, v: { email: string; name?: string }]) => {
                  const [primary, secondary] = v?.name ? [v?.name, v.email] : [v.email, null];
                  return (
                    <ListItem
                      disablePadding
                      key={v.email}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => removeCollab(v.email)}
                        >
                          <PersonRemoveIcon />
                        </IconButton>
                      }
                    >
                      <ListItemIcon sx={{ minWidth: "30px" }}>
                        <AccountCircleIcon />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          ".MuiListItemText-secondary": {
                            fontSize: "12px",
                            color: "text.gray",
                          },
                        }}
                        primary={primary}
                        secondary={secondary}
                      />
                    </ListItem>
                  );
                }
              )}
            </List>
          </Paper>
        </>
      </Collapse>
    </Stack>
  );
};
