import firebase from "firebase/app";
import { db } from "lib-fullstack";
import _ from "lodash";
import { useState } from "react";

// Components
// UI Utilities
import { Link, Typography, Tooltip } from "@mui/material";

// Utils
import { useOnQuery } from "@typesaurus/react";
import { currentUserEmail } from "lib-frontend/utils/AccountUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { VideoListingItem } from "lib-frontend/utils/sharedTableUtils";
import { LifecycleState } from "lib-fullstack/db";
import { AnalyticProcessingState } from "lib-fullstack/utils/enums";
import {
  getEnumKey,
  isCoach,
  getCoachDemoSlug,
  getCoachTutorialSlug,
  getEndUserDemoSlug,
  getEndUserTutorialSlug,
} from "utils/Utilities";
import { SPEECH_SUBTYPES, SPEECH_TYPES, SpeechDisplayType } from "webclient/src/utils/Enums";

export const LIBRARY = "library";
export const SHARED = "shared_with_me";
export const GRADING = "grading";
export const GRID = "grid";
export const LIST = "list";

export const analyticsProcessingIsComplete = (data: db.Speech): boolean => {
  return _.includes(
    [AnalyticProcessingState.FINISHED, AnalyticProcessingState.ERROR],
    data.asyncTranscriptState
  );
};

export const videoIsDisplayable = (data: db.Speech): boolean => {
  return (
    data.lifecycleState === LifecycleState.CREATED ||
    data.lifecycleState === LifecycleState.REDACTED
  );
};

export const getNewShares = (): number => {
  if (firebase.auth().currentUser) {
    const [dbShares, dbSharesMeta] = useOnQuery<db.Share>(
      db.userShares([getSiteId(), firebase.auth().currentUser.uid]),
      [db.where("isRead", "==", false), db.where("hidden", "!=", true), db.limit(100)]
    ); // badge says "99+" if > 99 sharesÏ

    if (dbSharesMeta.error) {
      console.error(dbSharesMeta.error);
      return 0;
    }
    return _.uniqBy(dbShares ?? [], (s) => s.data.path).length;
  } else {
    useState();
  }
  return 0;
};

export const analyticsProcessingErrorMessage = (data: db.Speech): string | undefined => {
  if (
    data.analyticsFailureReason &&
    _.includes([AnalyticProcessingState.ERROR], data.asyncTranscriptState)
  ) {
    if (
      data.analyticsFailureReason === db.AnalyicsFailureTypes.EMPTY_MEDIA ||
      data.analyticsFailureReason === db.AnalyicsFailureTypes.FFMPEG_NO_AUDIO ||
      data.analyticsFailureReason === db.AnalyicsFailureTypes.REV_DL_FAILURE
    )
      return "Error: Speech has no audio";
    else if (
      data.analyticsFailureReason === db.AnalyicsFailureTypes.NO_MONOLOGUES ||
      data.analyticsFailureReason === db.AnalyicsFailureTypes.REV_NO_AUDIO
    )
      return "Error: No speaking detected";
    else if (data.analyticsFailureReason === db.AnalyicsFailureTypes.TOO_SHORT)
      return "Error: Speech was too short for transcription";
    return "Error, please retry.";
  }
};

// Intersection of db.Speech and specific speech and analytics parameters SpeechListView
export type VideoJournalSpeech = db.Speech & {
  recordedDate: string;
  recordedByName: string;
  type: string;
  totalTimeString: number;
  percentFillerWords: number;
  wpm: number;
  className: string;
  rowId: string;
};

export type ListViewColumns = {
  field: string; // Variable the column is pulling the source value from
  headerName?: string; // Name of column
  flex?: number;
};

export type DisplayOptionSpec = {
  displayType: string;
  nLimit: number;
};

export const CellTooltip = (props: {
  value: string;
  emptyFieldPlaceholder: string;
}): JSX.Element => {
  const { value, emptyFieldPlaceholder } = props;

  return (
    <>
      {value === emptyFieldPlaceholder ? (
        <Tooltip placement="top" title="Record a speech longer than 30 seconds for more analytics">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>{value}</span>
          </div>
        </Tooltip>
      ) : (
        <span>{value}</span>
      )}
    </>
  );
};

export const getSpeechTypeStringFromEnum = (data: db.Speech): string => {
  const speechType = data.type;
  const speechSubtype = data.subType;
  if (speechType === getEnumKey(SPEECH_TYPES, SPEECH_TYPES.UPLOADED)) {
    return SPEECH_TYPES.UPLOADED;
  } else if (speechType === getEnumKey(SPEECH_TYPES, SPEECH_TYPES.ZOOM_RECALL)) {
    return SPEECH_TYPES.ZOOM_RECALL;
  } else if (speechType === getEnumKey(SPEECH_TYPES, SPEECH_TYPES.POODLI)) {
    return SPEECH_TYPES.POODLI;
  } else if (speechType === getEnumKey(SPEECH_TYPES, SPEECH_TYPES.LIVE)) {
    if (speechSubtype === getEnumKey(SPEECH_SUBTYPES, SPEECH_SUBTYPES.IMPROMPTU_PROMPT)) {
      return "Impromptu";
    } else if (speechSubtype === getEnumKey(SPEECH_SUBTYPES, SPEECH_SUBTYPES.JOB_INTERVIEW)) {
      return SPEECH_SUBTYPES.JOB_INTERVIEW;
    } else if (speechSubtype === getEnumKey(SPEECH_SUBTYPES, SPEECH_SUBTYPES.CONVERSATION)) {
      return SPEECH_SUBTYPES.CONVERSATION;
    } else {
      return "Speech";
    }
  } else {
    return "-";
  }
};

export const getSpeechLabelFromEnum = (data: db.Speech): SpeechDisplayType | SPEECH_SUBTYPES => {
  const speechType = data.type;
  if (speechType === getEnumKey(SPEECH_TYPES, SPEECH_TYPES.UPLOADED)) {
    return SpeechDisplayType.UPLOADED;
  } else if (
    speechType === getEnumKey(SPEECH_TYPES, SPEECH_TYPES.ZOOM_RECALL) ||
    speechType === getEnumKey(SPEECH_TYPES, SPEECH_TYPES.POODLI)
  ) {
    return SpeechDisplayType.LIVE;
  } else if (speechType === getEnumKey(SPEECH_TYPES, SPEECH_TYPES.LIVE)) {
    if (data.subType === getEnumKey(SPEECH_SUBTYPES, SPEECH_SUBTYPES.IMPROMPTU_PROMPT)) {
      return SPEECH_SUBTYPES.IMPROMPTU_PROMPT;
    } else if (data.subType === getEnumKey(SPEECH_SUBTYPES, SPEECH_SUBTYPES.JOB_INTERVIEW)) {
      return SPEECH_SUBTYPES.JOB_INTERVIEW;
    } else if (data.subType === getEnumKey(SPEECH_SUBTYPES, SPEECH_SUBTYPES.CONVERSATION)) {
      return SPEECH_SUBTYPES.CONVERSATION;
    } else {
      return SPEECH_SUBTYPES.SPEECH;
    }
    return SpeechDisplayType.PRACTICE;
  } else {
    return SpeechDisplayType.OTHER;
  }
};

export const isVideoListingShareAccessible = (item: VideoListingItem): boolean => {
  return (
    item.dbSpeech?.data &&
    item.dbSpeech.data.lifecycleState === LifecycleState.CREATED &&
    ((item.dbSpeech.data.linkSharing && !item.dbShare?.data?.collabStatusRequired) ||
      _.includes(
        _.map(item.dbSpeech.data.collabs ?? {}, (e) => e?.email?.toLowerCase()),
        currentUserEmail()
      ))
  );
};

// Grabs the speeches to filter for Home/Shared with me based on OBQ1.
// If coach, don't grab end user demo video. If end user, don't grab coach demo video and coach tutorial.
function obq1DemosToFilter(): string[] {
  return isCoach()
    ? [getEndUserDemoSlug(), getEndUserTutorialSlug()]
    : [getCoachDemoSlug(), getCoachTutorialSlug()];
}

export function isVideoListingADemoSpeechForADiffObq1(item: VideoListingItem): boolean {
  return !obq1DemosToFilter().includes(item.dbSpeech?.data && item.dbSpeech.data.slug);
}

export const renderSnackbar = (
  message: string,
  subTitle?: string,
  messageOnClick?: () => void,
  hyperlinkText?: string
): React.ReactElement => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography style={{ display: "inline", fontWeight: 500 }}>
        <Typography style={{ display: "inline", marginRight: "4px" }}>👋 {message}</Typography>
        {messageOnClick && (
          <Link
            style={{
              color: getDynamicColor("primary"),
              fontWeight: 600,
              cursor: "pointer",
            }}
            underline="none"
            onClick={messageOnClick}
          >
            {hyperlinkText}
          </Link>
        )}
      </Typography>
      {subTitle && (
        <Typography sx={{ fontWeight: 400, color: getDynamicColor("purple3") }}>
          {subTitle}
        </Typography>
      )}
    </div>
  );
};
