import React, { useRef } from "react";

// Components
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Popper from "@mui/material/Popper";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

/**
 * This simple 'Popper' UI control shows `msg` for `durationMilliS`.
 *   It appears when the user clicks a UI control, and it is positioned
 *   just below the clicked element. It fades in and out smoothly. Try
 *   the 'share link' button to see in action.
 *
 * Feel free to add new arguments to customize further.
 *
 *   Usage requires 3 steps:
 *   1) Call e.g. `popper = makeSimplePopper('Whatsup')
 *   2) Wire up `popper.handleClick(event)` to an onClick event.
 *   3) Add `{popper.el}` somewhere in the JSX code you're rendering,
 *      preferably near where you are going to call it.
 * If your `event` won't have a good target element, then assign `ref` to
 *   the element you want to use as an anchor element.
 * Note that zIndex is hardcoded ... would be great to get rid of that if possible.
 *
 * @param msg - Message to display
 * @param durationMilliS - How long to display
 * @returns {{el: JSX.Element, handleClick: handleClick, ref: MutableRefObject<null>}}
 */
export function makeSimplePopper(
  msg: string,
  durationMilliS = 1000
): {
  el: JSX.Element;
  handleClick: (event) => void;
  ref: React.MutableRefObject<Element>;
} {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const ref = useRef(null);

  // callback
  const handleClick = (event) => {
    setAnchorEl(ref.current || event.currentTarget);
    // timer to close the popper
    setTimeout(() => {
      setAnchorEl(null);
    }, durationMilliS);
  };

  const el = (
    <Popper open={!!anchorEl} anchorEl={anchorEl} style={{ zIndex: 3000 }} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={durationMilliS / 4}>
          <Box
            sx={{
              padding: "10px 20px 10px 20px",
              borderRadius: "10px",
              backgroundColor: getDynamicColor("dark1"),
            }}
          >
            {msg}
          </Box>
        </Fade>
      )}
    </Popper>
  );

  // Callers needs both of these to use the popper.
  return { el, handleClick, ref };
}
