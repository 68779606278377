import React from "react";

// Components
import { MailOutlineRounded as MailIcon } from "@mui/icons-material";
import { InsertLinkRounded as LinkIcon } from "@mui/icons-material";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ActionCard from "lib-frontend/components/YoodliComponents/ActionCard";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";
import { defaultHeaderSx, ADMIN_HEADER_HEIGHT } from "lib-frontend/ui/Theme";

// Assets
import { ReactComponent as CsvIcon } from "../../../images/icons/pdf-icon.svg";

// Utils
import { BulkInviteByCSVWizard } from "../BulkInviteByCSVWizard/BulkInviteByCSVWizard";
import { InviteMembers, MAX_EMAIL_INVITE_COUNT } from "../InviteMembers";
import { OrgLoading } from "../OrgLoading";
import { TableActionBar, TableTab } from "../TableActionBar";
import { TrialEnded } from "../TrialEnded";
import { TrialEndedBanner } from "../TrialEndedBanner";
import OrgInvitesTable from "./InvitesTable";
import { ManageRole } from "./ManageRole";
import { MemberDashboard } from "./MemberDashboard";
import MembersTable, { MemberInfo } from "./MembersTable";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { useQueryParamState } from "hooks/useQueryParamState";
import { YoodliNotificationOptions } from "lib-frontend/contexts/NotificationContext";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { inviteUserToHub } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  getOrgInviteCount,
  getOrgUserCount,
  isOrgTrialEnded,
  ORG_TABS_HEIGHT,
  TableTabLabel,
} from "lib-frontend/utils/orgUtils";
import { OrgHubsQueryParams } from "lib-fullstack/utils/queryParams";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { copyToMyClipboard } from "lib-frontend/utils/Utilities";
import { OrgMemberResponse } from "lib-fullstack/api/orgApiTypes";
import { HubRole } from "lib-fullstack/utils/enums";
import { OrgMembersTabs } from "./OrgMembersTabs";
import { ReportsTab } from "../Reports/ReportsTab";
import { ReportsTabType } from "../Reports/reportsUtils";
import { OrgInviteTypes } from "lib-fullstack/utils/productAnalyticEvents";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import ReassignGroupsWizard from "./ReassignGroupsWizard";

export const MEMBER_COUNT_HEIGHT = 22;

export enum OrgMembersSectionStatus {
  Default = "Default",
  ManageRole = "ManageRole",
  Dashboard = "Dashboard",
  InviteMembers = "InviteMembers",
  BulkCsvInvite = "InviteCSV",
  ReassignGroups = "ReassignGroups",
}

export enum OrgMembersTabEnum {
  Activity = "Activity",
  Reports = "Reports",
}

export enum OrgMembersQueryKeys {
  OrgMembers = "orgMembers",
  OrgInvites = "orgInvites",
  OrgEmailBranding = "orgEmailBranding",
  OrgUserCounts = "orgUserCounts",
  HubUserCounts = "hubUserCounts",
}

export default function OrgMembers(): JSX.Element {
  const [selectedTab, setSelectedTab] = useQueryParamState(
    OrgHubsQueryParams.TAB,
    OrgMembersTabEnum.Activity
  );

  const topContentRef = React.useRef<HTMLDivElement>(null);
  const [topContentHeight, setTopContentHeight] = React.useState<number>(
    topContentRef?.current?.clientHeight ?? 0
  );
  const [notificationData, setNotificationData] = React.useState<{
    message: string | JSX.Element;
    options: YoodliNotificationOptions;
  }>(null);
  const [customBackButtonState, setCustomBackButtonState] = React.useState<{
    handleBack: () => void;
    copy: string;
  }>(null);
  const { showNotificationBanner, notifAnchorRef } = useNotification();

  const {
    defaultOrgId,
    loading: contextLoading,
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const [inviteLinkCopied, setInviteLinkCopied] = React.useState<boolean>(false);
  const [selectedTabLabel, setSelectedTabLabel] = useQueryParamState(
    OrgHubsQueryParams.TABLE,
    TableTabLabel.Members
  );
  const [sectionStatus, setSectionStatus] = useQueryParamState(
    OrgHubsQueryParams.SECTION,
    OrgMembersSectionStatus.Default
  );
  const [searchText, setSearchText] = React.useState<string>("");
  const [selectedUser, setSelectedUser] = React.useState<OrgMemberResponse>(null);
  const [bulkActionUserList, setBulkActionUserList] = React.useState<MemberInfo[]>([]);

  React.useEffect(() => {
    if (notificationData) {
      showNotificationBanner(notificationData.message, notificationData.options);
    }
  }, [notificationData, showNotificationBanner]);

  // Handle reverting to Default if the state doesnt match the page requirements
  React.useEffect(() => {
    if (
      (sectionStatus === OrgMembersSectionStatus.ManageRole ||
        sectionStatus === OrgMembersSectionStatus.Dashboard) &&
      !selectedUser
    ) {
      setSectionStatus(OrgMembersSectionStatus.Default);
    }
    if (
      sectionStatus === OrgMembersSectionStatus.ReassignGroups &&
      bulkActionUserList?.length > 0
    ) {
      setSectionStatus(OrgMembersSectionStatus.Default);
    }
  }, [sectionStatus, selectedUser, bulkActionUserList]);

  React.useEffect(() => {
    if (defaultOrgId) {
      setBulkActionUserList([]);
    }
  }, [defaultOrgId]);

  const { data: tableTabs, isPending: userCountLoading } = useApiQuery({
    queryKey: [OrgMembersQueryKeys.OrgUserCounts, defaultOrgId],
    queryFn: async () => {
      const invitesCount = await getOrgInviteCount(defaultOrgId);
      const membersCount = await getOrgUserCount(defaultOrgId);
      return [
        {
          label: TableTabLabel.Members,
          count: membersCount,
        },
        {
          label: TableTabLabel.Invites,
          count: invitesCount,
        },
      ] as TableTab[];
    },
    enabled: !!defaultOrgId,
  });

  const handleOpenManageRole = (user: OrgMemberResponse, fromDashboard?: boolean) => {
    setSelectedUser(user);
    setSectionStatus(OrgMembersSectionStatus.ManageRole);
    if (fromDashboard) {
      setCustomBackButtonState({
        handleBack: () => {
          setSectionStatus(OrgMembersSectionStatus.Dashboard);
          setCustomBackButtonState(null);
        },
        copy: `User Dashboard`,
      });
    }
  };

  const handleOpenDashboard = (user: OrgMemberResponse) => {
    setSelectedUser(user);
    setSectionStatus(OrgMembersSectionStatus.Dashboard);
  };

  const handleCopyInviteLink = () => {
    if (!inviteLinkCopied) {
      inviteUserToHub(defaultOrg.default_hub_id, null, HubRole.MEMBER)
        .then((invite) => {
          setInviteLinkCopied(true);
          copyToMyClipboard(`${window.location.origin}/${invite.invite_path}`);
          showNotificationBanner("Invite link copied to clipboard", {
            variant: YoodliNotificationBannerVariant.Success,
          });
          Instrumentation.logOrgInvitationSent(defaultOrgId, OrgInviteTypes.LINK);
        })
        .catch((err) => {
          console.error("Error getting invite url", err);
          showNotificationBanner("There was a problem getting your invite URL.", {
            variant: YoodliNotificationBannerVariant.Danger,
          });
        });
      setTimeout(() => {
        setInviteLinkCopied(false);
      }, 2000);
    }
  };

  const recalcTopContentHeight = () => {
    setTopContentHeight(topContentRef.current.clientHeight);
  };

  const isSmallScreen = useIsSmallScreen();
  const verticalCrop = isSmallScreen ? 0 : topContentHeight + MEMBER_COUNT_HEIGHT;

  const renderTable = () => {
    let content = (
      <OrgInvitesTable
        searchText={searchText}
        setSearchText={setSearchText}
        recalcTopContentHeight={recalcTopContentHeight}
        bulkActionUserList={bulkActionUserList}
        setBulkActionUserList={setBulkActionUserList}
      />
    );
    if (selectedTabLabel === TableTabLabel.Members) {
      content = (
        <MembersTable
          searchText={searchText}
          bulkActionUserList={bulkActionUserList}
          setBulkActionUserList={setBulkActionUserList}
          openManageRoleWizard={handleOpenManageRole}
          openDashboard={handleOpenDashboard}
          recalcTopContentHeight={recalcTopContentHeight}
        />
      );
    }
    return (
      <Box
        sx={{
          position: "relative",
          height: `calc(100% - ${verticalCrop}px)`,
        }}
      >
        {content}
      </Box>
    );
  };

  const actionCards = [
    {
      icon: <MailIcon sx={{ width: 24, height: 24 }} />,
      title: "Invite members",
      subtitle: `Send invitations via email`,
      handler: () => {
        setSectionStatus(OrgMembersSectionStatus.InviteMembers);
      },
    },
    {
      icon: <LinkIcon sx={{ width: 24, height: 24 }} />,
      title: "Copy invite link",
      subtitle: "Copy a link to the default group",
      handler: handleCopyInviteLink,
    },
    {
      icon: <CsvIcon style={{ width: 24, height: 24 }} />,
      title: "Bulk invite by CSV",
      subtitle: "Upload a CSV to bulk invite members",
      handler: () => setSectionStatus(OrgMembersSectionStatus.BulkCsvInvite),
    },
  ];

  const loading = contextLoading || !defaultOrg;

  if (loading) {
    return <OrgLoading prompts={["Loading organization members..."]} />;
  }

  const renderHeader = () => {
    return (
      <Stack
        direction="column"
        sx={{
          backgroundColor: getDynamicColor("purple3"),
          ...defaultHeaderSx,
        }}
        ref={notifAnchorRef}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={{ xs: 1, sm: 3 }}
          alignItems={{ xs: "unset", sm: "center" }}
          justifyContent={{ xs: "center", sm: "space-between" }}
          sx={{
            ...defaultHeaderSx,
            px: { xs: 2, md: 3, lg: 4, xl: 5 },
            width: "100%",
          }}
        >
          <Stack gap={1} direction="row" alignItems="center">
            <Typography
              color={getDynamicColor("light1")}
              sx={{ fontFamily: "Poppins", fontSize: 16, fontWeight: 600 }}
            >
              Organization members
            </Typography>
            {isOrgTrialEnded(defaultOrg) && <TrialEndedBanner />}
          </Stack>
        </Stack>
        <OrgMembersTabs
          selectedTab={selectedTab as OrgMembersTabEnum}
          setSelectedTab={setSelectedTab}
          shouldBlockNav={false}
          setShouldBlockNav={() => null}
        />
      </Stack>
    );
  };
  const renderTab = () => {
    switch (selectedTab) {
      case OrgMembersTabEnum.Activity:
        return (
          <>
            <Stack
              gap={4}
              sx={{
                height: "100%",
              }}
            >
              <Stack gap={4} ref={topContentRef} onLoad={recalcTopContentHeight}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  gap={{ xs: 2, lg: 4 }}
                  flexWrap={{ xs: "nowrap", sm: "wrap" }}
                  justifyContent={{ xs: "unset", sm: "center" }}
                  overflow={{ xs: "visible", sm: "unset" }}
                >
                  {actionCards.map((card) => (
                    <ActionCard
                      key={card.title}
                      icon={card.icon}
                      title={card.title}
                      subtitle={card.subtitle}
                      handler={card.handler}
                    />
                  ))}
                </Stack>
                {!userCountLoading && (
                  <TableActionBar
                    origin="org"
                    tableTabs={tableTabs}
                    selectedTabLabel={selectedTabLabel as TableTabLabel}
                    setSelectedTabLabel={setSelectedTabLabel}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    bulkActionUserList={bulkActionUserList}
                    setBulkActionUserList={setBulkActionUserList}
                    setSectionStatus={setSectionStatus}
                  />
                )}
              </Stack>
              {!userCountLoading && !contextLoading && defaultOrg && renderTable()}
              {userCountLoading && (
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </Stack>
              )}
            </Stack>
          </>
        );
      case OrgMembersTabEnum.Reports:
        return (
          <ReportsTab
            type={ReportsTabType.Members}
            wrapperSx={{
              p: "0px !important",
              pb: "48px !important",
            }}
          />
        );
    }
  };

  const renderPageContent = () => {
    switch (sectionStatus) {
      case OrgMembersSectionStatus.ManageRole:
        return (
          <ManageRole
            handleShowNotificationBanner={setNotificationData}
            org={defaultOrg}
            handleBack={() => {
              if (customBackButtonState?.handleBack) {
                customBackButtonState.handleBack();
              } else {
                setSectionStatus(OrgMembersSectionStatus.Default);
              }
            }}
            backCopy={customBackButtonState?.copy ?? "Members"}
            user={selectedUser}
          />
        );
      case OrgMembersSectionStatus.Dashboard:
        if (!selectedUser) {
          return <OrgLoading prompts={["Loading user dashboard..."]} />;
        }
        return (
          <MemberDashboard
            handleBack={() => setSectionStatus(OrgMembersSectionStatus.Default)}
            backCopy="Members"
            handleOpenManageRole={handleOpenManageRole}
            user={selectedUser}
          />
        );

      case OrgMembersSectionStatus.ReassignGroups:
        return (
          <ReassignGroupsWizard
            origin="org"
            bulkActionUserList={bulkActionUserList}
            setBulkActionUserList={setBulkActionUserList}
            backCopy="Members"
            setSectionStatus={setSectionStatus}
            handleShowNotificationBanner={setNotificationData}
          />
        );

      case OrgMembersSectionStatus.InviteMembers:
        return (
          <InviteMembers
            title="Invite people to join your organization"
            subtitle={`Add up to ${MAX_EMAIL_INVITE_COUNT} emails separated by commas, select one role for all new users, and add them to at least one group. Yoodli will send invitations to all new users and exclude any already-existing members. `}
            backCopy="Members"
            handleBack={() => {
              setSectionStatus(OrgMembersSectionStatus.Default);
            }}
            org={defaultOrg}
          />
        );
      case OrgMembersSectionStatus.BulkCsvInvite:
        return (
          <BulkInviteByCSVWizard
            org={defaultOrg}
            handleBack={() => setSectionStatus(OrgMembersSectionStatus.Default)}
          />
        );
      case OrgMembersSectionStatus.Default:
      default:
        return (
          <Stack
            direction="column"
            sx={{
              background: getDynamicColor("light1"),
              minHeight: "100dvh",
            }}
          >
            {renderHeader()}
            <Stack
              sx={{
                p: 4,
                pt: { xs: 4, md: 6 },
                position: "relative",
                height: `calc(100dvh - ${ADMIN_HEADER_HEIGHT + ORG_TABS_HEIGHT}px)`,
                width: "100%",
                maxWidth: "xxl",
                mx: "auto",
              }}
            >
              {isOrgTrialEnded(defaultOrg) ? <TrialEnded org={defaultOrg} /> : renderTab()}
            </Stack>
          </Stack>
        );
    }
  };

  return renderPageContent();
}
