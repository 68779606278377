// Components
import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import {
  CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS,
  CUSTOM_GOAL_OVERALL_AI_EXPLANATION_MAX_CHARS,
  CUSTOM_GOAL_MAX_SCORE,
  CUSTOM_GOAL_MIN_SCORE,
  CUSTOM_GOAL_TEXTFIELD_MIN_ROWS,
  GoalHooks,
} from "components/ConvoScenarios/convoScenarioUtils";

// Utils
import { ConvoScenarioStepWrapper } from "./ConvoScenarioStepWrapper";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { MinMaxScoreSelector } from "./MinMaxScoreSelector";
import { RephraseOrGenerateButton } from "../../RephraseOrGenerateButton";
import { getGoalRephrase } from "utils/GPTUtils";
import { ScoreGoal } from "lib-fullstack/db";
import { CreateScoreGoalRequest } from "lib-fullstack/api/scenarioApiTypes";

export type RatedGoalEvaluationStepProps = {
  ratedGoal: CreateScoreGoalRequest;
  setRatedGoal: React.Dispatch<React.SetStateAction<CreateScoreGoalRequest>>;
  goalEditId: string;
  goalHooks: GoalHooks;
};

export const RatedGoalEvaluationStep = ({
  ratedGoal,
  setRatedGoal,
  goalEditId,
  goalHooks,
}: RatedGoalEvaluationStepProps): JSX.Element => {
  const handleUpdateRatedGoal = (key: keyof ScoreGoal, val: string | number) => {
    setRatedGoal((prev) => ({ ...prev, [key]: val }));
  };

  return (
    <ConvoScenarioStepWrapper
      title={goalEditId ? "Update Rated Goal" : "Create a Rated Goal"}
      subTitle={
        <>
          <Typography component="div">
            Explain the goal to the AI, then set and define the scoring scale.
          </Typography>
          <Typography component="div">All fields are required.</Typography>
        </>
      }
    >
      <Stack gap={4} sx={{ color: getDynamicColor("purple3") }}>
        <YoodliLabeledInput
          label={
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "poppins",
                  color: getDynamicColor("dark5"),
                  mb: 0.5,
                }}
              >
                Explanation
              </Typography>
              <RephraseOrGenerateButton
                lengthToCheck={ratedGoal.aiDescription.length}
                onClick={async () => {
                  goalHooks.setGoalDescriptionLoading(true);
                  const rephrase = await getGoalRephrase(
                    ratedGoal.aiDescription,
                    "description",
                    ratedGoal.name,
                    ""
                  );
                  handleUpdateRatedGoal("aiDescription", rephrase);
                  goalHooks.setGoalDescriptionLoading(false);
                }}
              />
            </Stack>
          }
          labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
          inputEl={
            <Box sx={{ position: "relative" }}>
              {goalHooks.goalDescriptionLoading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <YoodliTextfield
                multiline
                minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                inputProps={{
                  className: "blockEnterToNavigate",
                }}
                maxChars={CUSTOM_GOAL_OVERALL_AI_EXPLANATION_MAX_CHARS}
                placeholder="e.g. Seeking feedback can consist of asking follow-up questions, asking open-ended questions, or asking for examples where something was done well or poorly."
                InputLabelProps={{ shrink: true }}
                value={ratedGoal.aiDescription}
                onChange={(e) => handleUpdateRatedGoal("aiDescription", e.target.value)}
                disabled={goalHooks.goalDescriptionLoading}
              />
            </Box>
          }
        />
        <Stack>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            Score
          </Typography>
          <Stack direction="row" gap={3} sx={{ justifyContent: "space-between", flexWrap: "wrap" }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: 400,
                color: getDynamicColor("dark5"),
                maxWidth: { sm: "100%", md: "400px" },
              }}
            >
              Set the max score members can achieve for this goal (max 10). Define failure (lowest
              possible score) and success (highest possible score) below.
            </Typography>
            <MinMaxScoreSelector
              min={{ value: CUSTOM_GOAL_MIN_SCORE, disabled: true }}
              max={{
                value: ratedGoal.maxScore,
                minValue: CUSTOM_GOAL_MIN_SCORE,
                maxValue: CUSTOM_GOAL_MAX_SCORE,
                onChange: (value: string) => {
                  if (value) {
                    localStorage.setItem("customGoalMaxScore", value);
                  }
                  handleUpdateRatedGoal("maxScore", value ? Number(value) : undefined);
                },
              }}
            />
          </Stack>
        </Stack>
        <YoodliLabeledInput
          label={
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "poppins",
                  color: getDynamicColor("dark5"),
                  mb: 0.5,
                }}
              >
                {`Define a score of ${CUSTOM_GOAL_MIN_SCORE} (minimum score)`}
              </Typography>
              <RephraseOrGenerateButton
                lengthToCheck={ratedGoal.lowScoreDescription.length}
                onClick={async () => {
                  goalHooks.setGoalLowScoreLoading(true);
                  const rephrase = await getGoalRephrase(
                    ratedGoal.aiDescription,
                    "low",
                    ratedGoal.name,
                    ratedGoal.aiDescription
                  );
                  handleUpdateRatedGoal("lowScoreDescription", rephrase);
                  goalHooks.setGoalLowScoreLoading(false);
                }}
              />
            </Stack>
          }
          labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
          inputEl={
            <Box sx={{ position: "relative" }}>
              {goalHooks.goalLowScoreLoading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <YoodliTextfield
                multiline
                minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                maxChars={CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS}
                inputProps={{
                  className: "blockEnterToNavigate",
                }}
                placeholder="e.g. The member never asks any open-ended or follow-up questions asking for feedback, and does not receive direction and advice."
                InputLabelProps={{ shrink: true }}
                value={ratedGoal.lowScoreDescription}
                onChange={(e) => handleUpdateRatedGoal("lowScoreDescription", e.target.value)}
                disabled={goalHooks.goalLowScoreLoading}
              />
            </Box>
          }
        />
        <YoodliLabeledInput
          label={
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "poppins",
                  color: getDynamicColor("dark5"),
                  mb: 0.5,
                }}
              >
                {`Define a score of ${ratedGoal.maxScore ?? ""} (maximum score)`}
              </Typography>
              <RephraseOrGenerateButton
                lengthToCheck={ratedGoal.highScoreDescription.length}
                onClick={async () => {
                  goalHooks.setGoalHighScoreLoading(true);
                  const rephrase = await getGoalRephrase(
                    ratedGoal.aiDescription,
                    "high",
                    ratedGoal.name,
                    ratedGoal.aiDescription
                  );
                  handleUpdateRatedGoal("highScoreDescription", rephrase);
                  goalHooks.setGoalHighScoreLoading(false);
                }}
              />
            </Stack>
          }
          labelSx={{ fontWeight: 600, color: getDynamicColor("purple3") }}
          inputEl={
            <Box sx={{ position: "relative" }}>
              {goalHooks.goalHighScoreLoading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <YoodliTextfield
                multiline
                minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
                maxChars={CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS}
                inputProps={{
                  className: "blockEnterToNavigate",
                }}
                placeholder="e.g. The member asked open-ended and/or follow-up questions asking for feedback, and receives direction and advice."
                InputLabelProps={{ shrink: true }}
                value={ratedGoal.highScoreDescription}
                onChange={(e) => handleUpdateRatedGoal("highScoreDescription", e.target.value)}
                disabled={goalHooks.goalHighScoreLoading}
              />
            </Box>
          }
        />
      </Stack>
    </ConvoScenarioStepWrapper>
  );
};
