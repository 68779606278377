import React from "react";

// Components
import { Stack, Typography } from "@mui/material";
import { defaultHeaderSx } from "lib-frontend/ui/Theme";

// Utils
import { OrgLoading } from "../OrgLoading";
import { TrialEnded } from "../TrialEnded";
import { TrialEndedBanner } from "../TrialEndedBanner";
import { CreateProgram } from "./CreateProgram";
import { NoPrograms } from "./NoPrograms";
import { ProgramInfo } from "./ProgramInfo";
import { ProgramsLibrary } from "./ProgramsLibrary";
import { ProgramUser } from "./ProgramUser";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { useQueryParamState } from "hooks/useQueryParamState";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { listOrgPrograms } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { isOrgTrialEnded } from "lib-frontend/utils/orgUtils";
import { ProgramProvisioningState, ProgramState } from "lib-fullstack/utils/enums";
import { OrgProgramsQueryParams } from "lib-fullstack/utils/queryParams";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";

export enum OrgProgramsSectionStatus {
  Default = "Default",
  CreateProgram = "CreateProgram",
  ProgramInfo = "ProgramInfo",
  ProgramUser = "ProgramUser",
}

export enum OrgProgramsQueryKeys {
  OrgPrograms = "OrgPrograms",
  HubData = "HubData",
  ScenarioList = "ScenarioList",
  ScenarioInfo = "ScenarioInfo",
  GoalList = "GoalList",
  ProgramUsers = "ProgramUsers",
  ProgramUser = "ProgramUser",
  ProgramDashboardReports = "ProgramDashboardReports",
  ProgramStep = "ProgramStep",
  MemberView = "MemberView",
}

export enum ScenarioSubQueryKeys {
  Member = "Member",
  Org = "Org",
}

export default function OrgPrograms(): JSX.Element {
  const {
    adminInfo: { defaultOrg, defaultOrgLoading },
    defaultOrgId,
  } = React.useContext(UserOrgContext);
  const { notifAnchorRef } = useNotification();

  const [sectionStatus, setSectionStatus] = useQueryParamState(
    OrgProgramsQueryParams.SECTION,
    OrgProgramsSectionStatus.Default
  );
  const [programId, setProgramId] = useQueryParamState(
    OrgProgramsQueryParams.PROGRAM_ID,
    undefined
  );
  const [userId, setUserId] = useQueryParamState(OrgProgramsQueryParams.USER_ID, undefined);

  const orgProgramsQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.OrgPrograms, defaultOrgId],
    queryFn: () => listOrgPrograms(defaultOrgId),
    enabled: !!defaultOrg,
    refetchInterval: (query) =>
      query?.state?.data?.some(
        (program) => program.provisioning_state === ProgramProvisioningState.InProgress
      )
        ? 1000
        : 0,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    if (!programId && sectionStatus === OrgProgramsSectionStatus.ProgramInfo) {
      setSectionStatus(OrgProgramsSectionStatus.Default);
    }
  }, [programId]);

  React.useEffect(() => {
    if (!userId && sectionStatus === OrgProgramsSectionStatus.ProgramUser) {
      setSectionStatus(OrgProgramsSectionStatus.ProgramInfo);
    }
  }, [userId]);

  const renderHeader = () => {
    return (
      <Stack
        direction="row"
        gap={1}
        ref={notifAnchorRef}
        sx={{
          ...defaultHeaderSx,
          px: { xs: 3, lg: 4, xl: 5 },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
          <Typography
            color={getDynamicColor("light1")}
            sx={{ fontFamily: "Poppins", fontSize: 16, fontWeight: 600 }}
          >
            Programs
          </Typography>
          {isOrgTrialEnded(defaultOrg) && <TrialEndedBanner />}
        </Stack>
      </Stack>
    );
  };

  const renderDefaultContent = () => {
    if (isOrgTrialEnded(defaultOrg)) {
      return <TrialEnded org={defaultOrg} />;
    } else if (orgProgramsQuery.data?.length > 0) {
      return (
        <ProgramsLibrary
          handleCreateProgram={() => setSectionStatus(OrgProgramsSectionStatus.CreateProgram)}
          orgPrograms={orgProgramsQuery.data}
          handleSelectProgram={(programId) => {
            setSectionStatus(OrgProgramsSectionStatus.ProgramInfo);
            setProgramId(programId);
          }}
        />
      );
    } else {
      return (
        <NoPrograms
          handleCreateProgram={() => setSectionStatus(OrgProgramsSectionStatus.CreateProgram)}
        />
      );
    }
  };

  const renderLayout = () => {
    if (defaultOrgLoading || orgProgramsQuery.isPending) {
      return <OrgLoading prompts={["Loading organization programs..."]} />;
    } else {
      switch (sectionStatus) {
        case OrgProgramsSectionStatus.CreateProgram:
          return (
            <CreateProgram handleBack={() => setSectionStatus(OrgProgramsSectionStatus.Default)} />
          );
        case OrgProgramsSectionStatus.ProgramInfo:
          return (
            <ProgramInfo
              program={orgProgramsQuery.data?.find((program) => program.id === programId)}
              handleBack={() => {
                setSectionStatus(OrgProgramsSectionStatus.Default);
                setProgramId(undefined);
              }}
              anyProgramPublished={orgProgramsQuery.data?.some(
                (program) => program.state === ProgramState.Published
              )}
            />
          );
        case OrgProgramsSectionStatus.ProgramUser:
          return (
            <ProgramUser
              program={orgProgramsQuery.data?.find((program) => program.id === programId)}
              userId={userId}
              handleBack={() => {
                setSectionStatus(OrgProgramsSectionStatus.ProgramInfo);
                setUserId(undefined);
              }}
            />
          );
        case OrgProgramsSectionStatus.Default:
        default:
          return (
            <Stack
              gap={{ xs: 3, md: 5 }}
              sx={{
                background: getDynamicColor("light1"),
                pb: 14,
                minHeight: { xs: `calc(100vh - ${WEBCLIENT_TOP_NAVBAR_HEIGHT})`, md: "100dvh" },
              }}
            >
              {renderHeader()}
              {renderDefaultContent()}
            </Stack>
          );
      }
    }
  };

  return renderLayout();
}
