import React from "react";

// Components
import { Box, Pagination, Stack, Typography } from "@mui/material";
import { TemplateSubTypeChip } from "components/ConvoScenarios/TemplateSubTypeChip";

// Utils
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsMediumScreen } from "lib-frontend/utils/themeUtils";
import { GetScenarioResponse } from "lib-fullstack/api/scenarioApiTypes";
import { ScenarioTemplateSubType } from "lib-fullstack/db";

type CoachBotCardScenarioTableProps = {
  scenarios: GetScenarioResponse[];
};

type CoachBotCardScenarioTableRow = {
  scenarioCompact: GetScenarioResponse;
  name: string;
  type: string;
  persona: string;
};

export const CoachBotCardScenarioTable = ({
  scenarios,
}: CoachBotCardScenarioTableProps): JSX.Element => {
  const isMediumScreen = useIsMediumScreen();
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });

  const columnHelper = createColumnHelper<CoachBotCardScenarioTableRow>();

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("scenarioCompact", {
        id: "scenarioCompact",
        cell: (info) => (
          <Stack direction="column" sx={{ gap: 1 }}>
            <Typography
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                minWidth: { xs: "unset", md: 200 },
                maxWidth: 400,
              }}
            >
              {info.getValue().title}
            </Typography>
            <Stack direction="row" sx={{ alignItems: "center", gap: 2 }}>
              <TemplateSubTypeChip
                sx={{ fontWeight: 400, maxWidth: "200px" }}
                templateSubType={info.getValue().templateSubType as ScenarioTemplateSubType}
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textTransform: "uppercase",
                  minWidth: { xs: "unset", md: 200 },
                  maxWidth: 300,
                }}
              >
                {(info.getValue().persona?.name ?? "custom") === "custom"
                  ? "Custom Persona"
                  : `${info.getValue().persona?.name ?? "Custom Persona"}`}
              </Typography>
            </Stack>
          </Stack>
        ),
      }),
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => (
          <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              minWidth: { xs: "unset", md: 200 },
              maxWidth: 400,
            }}
          >
            {info.getValue()}
          </Typography>
        ),
      }),
      columnHelper.accessor("type", {
        id: "type",
        cell: (info) => (
          <TemplateSubTypeChip
            sx={{ fontWeight: 400, maxWidth: "200px" }}
            templateSubType={info.getValue() as ScenarioTemplateSubType}
          />
        ),
      }),
      columnHelper.accessor("persona", {
        id: "persona",
        cell: (info) => (
          <Typography
            sx={{
              fontWeight: 400,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textTransform: "uppercase",
              minWidth: { xs: "unset", md: 200 },
              maxWidth: 300,
            }}
          >
            {info.getValue() === "custom"
              ? "Custom Persona"
              : `Default Persona: ${info.getValue()}`}
          </Typography>
        ),
      }),
    ],
    [scenarios]
  );

  const rowData = React.useMemo(
    () =>
      (scenarios ?? []).map((scenario) => ({
        name: scenario.title,
        type: scenario.templateSubType,
        persona: scenario?.persona?.name ?? "custom",
        scenarioCompact: scenario,
      })),
    [scenarios]
  );

  const table = useReactTable({
    data: rowData,
    columns: columns,
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
      columnVisibility: {
        scenarioCompact: isMediumScreen,
        name: !isMediumScreen,
        type: !isMediumScreen,
        persona: !isMediumScreen,
      },
    },
    getCoreRowModel: getCoreRowModel(),
  });

  const renderTable = () => (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        borderSpacing: "0px",
      }}
    >
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <Box
              component="tr"
              key={row.id}
              sx={{
                width: "max-content",
                borderTop: `1px solid ${getDynamicColor("dark2")}`,
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <Box
                  component="td"
                  key={cell.id}
                  sx={{
                    color: getDynamicColor("dark4"),
                    fontFamily: "poppins",
                    fontSize: "12px",
                    py: 1.5,
                    fontWeight: 600,
                    maxWidth: { xs: "200px", md: "500px" },
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Box>
              ))}
            </Box>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <Stack gap={3} direction="column" sx={{ alignItems: "center" }}>
      {renderTable()}
      {table.getPageCount() > 1 && (
        <Pagination
          sx={{ justifyContent: "center" }}
          count={table.getPageCount()}
          showFirstButton
          showLastButton
          page={pagination.pageIndex + 1}
          onChange={(_, page) => table.setPageIndex(page - 1)}
        />
      )}
    </Stack>
  );
};
