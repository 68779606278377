// Utils
import { AuthorizationAPIHeaders, getAxiosInstance } from "./AxiosInstance";
import {
  CreateOrgFileResponse,
  CreateOrgFileRequest,
  GetOrgFilesResponse,
  GetOrgFilesQueryParams,
  DeleteOrgFilesQueryParams,
  UpdateOrgFileRequest,
} from "lib-fullstack/api/orgFileApiTypes";

/**
 * Create a new file for an organization.
 * @param orgId The ID of the organization.
 * @param body The request body.
 * @returns The response containing the upload url of the created file.
 * @throws Error if the request fails.
 */
export async function createOrgFile(
  orgId: string,
  body: CreateOrgFileRequest
): Promise<CreateOrgFileResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await getAxiosInstance().post(`/v2/orgs/${orgId}/files`, body, {
      headers: headers,
    });
    return CreateOrgFileResponse.check(ret.data);
  } catch (err) {
    throw Error("Bad createOrgFile request, status " + err.response?.status);
  }
}

/**
 * Get a list of files for an organization.
 * @param orgId The ID of the organization.
 * @param params The query parameters.
 * @returns The response containing the list of files.
 * @throws Error if the request fails.
 */
export async function listOrgFiles(
  orgId: string,
  params: GetOrgFilesQueryParams
): Promise<GetOrgFilesResponse> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const ret = await getAxiosInstance().get(`/v2/orgs/${orgId}/files`, {
      headers: headers,
      params: params,
    });
    return ret.data;
  } catch (err) {
    throw Error("Bad listOrgFiles request, status " + err.response?.status);
  }
}

/**
 * Delete a file for an organization.
 * @param orgId The ID of the organization.
 * @param fileId The ID of the file to delete.
 * @param params The query parameters.
 * @throws Error if the request fails.
 */
export async function deleteOrgFile(
  orgId: string,
  fileId: string,
  params: DeleteOrgFilesQueryParams
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    await getAxiosInstance().delete(`/v2/orgs/${orgId}/files/${fileId}`, {
      headers: headers,
      params: params,
    });
  } catch (err) {
    throw Error("Bad deleteOrgFile request, status " + err.response?.status);
  }
}

/**
 * Update a file for an organization.
 * @param orgId The ID of the organization.
 * @param fileId The ID of the file to update.
 * @param body The request body.
 * @throws Error if the request fails.
 */
export async function patchOrgFile(
  orgId: string,
  fileId: string,
  body: UpdateOrgFileRequest
): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    await getAxiosInstance().patch(`/v2/orgs/${orgId}/files/${fileId}`, body, {
      headers: headers,
    });
  } catch (err) {
    throw Error("Bad patchOrgFile request, status " + err.response?.status);
  }
}
