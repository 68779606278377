import React from "react";
import { useDropzone } from "react-dropzone";

// Components
import {
  WarningAmberRounded as WarningIcon,
  Delete as TrashIcon,
  Close as CloseIcon,
  EditOutlined as EditOutlinedIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, Link, Stack, Typography } from "@mui/material";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Assets
import { ReactComponent as PDFIcon } from "images/icons/icon-coachbot-upload-pdf.svg";
import { ReactComponent as LinkIcon } from "images/icons/icon-link.svg";

// Utils
import { AdditionalContextDetails } from "./ScenarioAdditionalContext";
import {
  AuthorizationAPIHeaders,
  getJobDescriptionUploadUrl,
} from "lib-frontend/modules/AxiosInstance";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { uploadBlobToResumableUploadUrl } from "lib-frontend/utils/resumableUpload";
import { GetInterviewContextFromJobDescriptionResponse } from "lib-fullstack/api/analyticsApiTypes";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { ScenarioTemplateSubType, ScenarioTypeIdEnum } from "lib-fullstack/db";
import { getInterviewContextFromJobDescription } from "utils/GPTUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { EventsJobDescriptionUploadHows } from "lib-fullstack/utils/productAnalyticEvents";
import { YoodliCtaModal } from "lib-frontend/components/YoodliComponents/YoodliCtaModal";

type ScenarioJobDescriptionProps = {
  jobDetailStepState: JobDetailStepState;
  setJobDetailStepState: (state: JobDetailStepState) => void;
  templateSubType: ScenarioTemplateSubType;
  scenarioId: ScenarioTypeIdEnum;
  jobRole: string;
  companyName: string;
  handleSetAdditionalContext: (key: keyof AdditionalContextDetails, value: string) => void;
  handleSetJobRole: (value: string) => void;
  handleSetCompanyName: (value: string) => void;
  setExtraQuestionSuggestions: (questions: string[]) => void;
  loading: boolean;
};

export enum JobDetailStepState {
  ManualEntry = "manual_entry",
  Upload = "upload",
}

enum JobDetailUploadType {
  PDF = "pdf",
  LINK = "link",
}

enum JobDetailUploadState {
  NotStarted = "not_started",
  Uploading = "uploading",
  Completed = "completed",
  Error = "error",
}

enum JobDetailUploadError {
  InvalidUrl = "Invalid URL",
  BlockedUrl = "This URL is blocked and unable to be used, please try a different URL",
  ImportFailed = "Import failed, please try again or use a different URL",
}

type EditableFieldProps = {
  value: string;
  onChange: (value: string) => void;
};

const EditableField = ({ value, onChange }: EditableFieldProps): JSX.Element => {
  const [updatedValue, setUpdatedValue] = React.useState<string>(undefined);
  return (
    <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
      {updatedValue !== undefined ? (
        <YoodliTextfield
          value={updatedValue}
          onChange={(e) => setUpdatedValue(e.target.value)}
          placeholder="e.g. Hooli sales training"
          maxChars={100}
          hideCharCount
          InputProps={{ sx: { height: "35px", fontWeight: 600 } }}
          fullWidth
        />
      ) : (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: getDynamicColor("purple3"),
          }}
        >
          {value}
        </Typography>
      )}
      <IconButton
        sx={{
          color: getDynamicColor("primary"),
          width: 20,
          height: 20,
        }}
        onClick={() => {
          if (updatedValue !== undefined) {
            if (updatedValue !== value && updatedValue.length > 0) {
              onChange(updatedValue);
            }
            setUpdatedValue(undefined);
          } else {
            setUpdatedValue(value);
          }
        }}
      >
        {updatedValue !== undefined ? (
          <CheckIcon sx={{ width: 18, height: 18 }} />
        ) : (
          <EditOutlinedIcon sx={{ width: 18, height: 18 }} />
        )}
      </IconButton>
    </Stack>
  );
};

export const ScenarioJobDescription = ({
  jobDetailStepState,
  setJobDetailStepState,
  handleSetAdditionalContext,
  jobRole,
  companyName,
  handleSetJobRole,
  handleSetCompanyName,
  setExtraQuestionSuggestions,
}: ScenarioJobDescriptionProps): JSX.Element => {
  const [uploadType, setUploadType] = React.useState<JobDetailUploadType>(JobDetailUploadType.PDF);
  const [uploadStatePdf, setUploadStatePdf] = React.useState<JobDetailUploadState>(
    jobDetailStepState === JobDetailStepState.Upload && jobRole && companyName
      ? JobDetailUploadState.Completed
      : JobDetailUploadState.NotStarted
  );
  const [uploadStateLink, setUploadStateLink] = React.useState<JobDetailUploadState>(
    jobDetailStepState === JobDetailStepState.Upload && jobRole && companyName
      ? JobDetailUploadState.Completed
      : JobDetailUploadState.NotStarted
  );
  const [linkErrorText, setLinkErrorText] = React.useState<JobDetailUploadError>(undefined);
  const [fileName, setFileName] = React.useState<string>(undefined);
  const [fileUrl, setFileUrl] = React.useState<string>(undefined);
  const [jobDescriptionText, setJobDescriptionText] = React.useState<string>(undefined);
  const [copyPasteModalOpen, setCopyPasteModalOpen] = React.useState<boolean>(false);
  const [showingCopyPasteDesc, setShowingCopyPasteDesc] = React.useState<boolean>(false);

  const uploadCancelled = React.useRef(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      uploadCancelled.current = false;
      if (acceptedFiles?.length) {
        setUploadStatePdf(JobDetailUploadState.Uploading);
        try {
          const { url, fileUrl } = await getJobDescriptionUploadUrl();
          if (!url) {
            return;
          }
          setFileName(acceptedFiles[0].name);
          await uploadBlobToResumableUploadUrl(
            acceptedFiles[0],
            url,
            null,
            "upload job description"
          );
          const interviewContext = await getInterviewContextFromJobDescription(fileUrl);
          Instrumentation.logScenarioJobDescriptionAdded(EventsJobDescriptionUploadHows.UPLOAD);
          handleSetCompanyName(interviewContext.company);
          handleSetJobRole(interviewContext.jobRole);
          handleSetAdditionalContext("userProvidedContext", interviewContext.context);
          setUploadStatePdf(JobDetailUploadState.Completed);
          setExtraQuestionSuggestions(interviewContext.questions);
        } catch (err) {
          console.log(`Error uploading files: ${err}`);
          setUploadStatePdf(JobDetailUploadState.Error);
        }
      }
    },
    accept: "application/pdf",
    maxFiles: 1,
  });

  const handleUploadLink = async () => {
    setUploadStateLink(JobDetailUploadState.Uploading);
    try {
      const response = await fetch(
        `${
          getClientEnvConfig().url.PYTHON_SERVER
        }/analytics_api/authed/get_job_description_from_url`,
        {
          method: "POST",
          headers: await AuthorizationAPIHeaders(),
          body: JSON.stringify({
            url: fileUrl,
          }),
        }
      );
      if (Math.floor(response.status / 100) !== 2) {
        setUploadStateLink(JobDetailUploadState.Error);
        if (response.status === 400) {
          setLinkErrorText(JobDetailUploadError.InvalidUrl);
        } else if (response.status === 403) {
          setLinkErrorText(JobDetailUploadError.BlockedUrl);
        } else {
          setLinkErrorText(JobDetailUploadError.ImportFailed);
        }
        return;
      }
      const data: GetInterviewContextFromJobDescriptionResponse = await response.json();
      handleSetCompanyName(data.company);
      handleSetJobRole(data.jobRole);
      handleSetAdditionalContext("userProvidedContext", data.context);
      Instrumentation.logScenarioJobDescriptionAdded(EventsJobDescriptionUploadHows.LINK);
      setExtraQuestionSuggestions(data.questions);
      setUploadStateLink(JobDetailUploadState.Completed);
    } catch (err) {
      setUploadStateLink(JobDetailUploadState.Error);
      if (err.status === 403) {
        setLinkErrorText(JobDetailUploadError.BlockedUrl);
      } else {
        setLinkErrorText(JobDetailUploadError.ImportFailed);
      }
      return;
    }
  };

  const handleUploadTextDescription = async () => {
    setUploadStateLink(JobDetailUploadState.Uploading);
    try {
      const response = await fetch(
        `${
          getClientEnvConfig().url.PYTHON_SERVER
        }/analytics_api/authed/get_job_description_from_text`,
        {
          method: "POST",
          headers: await AuthorizationAPIHeaders(),
          body: JSON.stringify({
            text: jobDescriptionText,
          }),
        }
      );
      if (Math.floor(response.status / 100) !== 2) {
        setUploadStateLink(JobDetailUploadState.Error);
        setLinkErrorText(JobDetailUploadError.ImportFailed);
        return;
      }
      const data: GetInterviewContextFromJobDescriptionResponse = await response.json();
      handleSetCompanyName(data.company);
      handleSetJobRole(data.jobRole);
      handleSetAdditionalContext("userProvidedContext", data.context);
      setExtraQuestionSuggestions(data.questions);
      setUploadStateLink(JobDetailUploadState.Completed);
      setShowingCopyPasteDesc(true);
      setCopyPasteModalOpen(false);
    } catch (_err) {
      setUploadStateLink(JobDetailUploadState.Error);
      setLinkErrorText(JobDetailUploadError.ImportFailed);
      return;
    }
  };

  const renderRoleAndCompany = (): JSX.Element => {
    return (
      <Stack direction="column" sx={{ gap: 3, fontSize: 12, fontFamily: "Poppins" }}>
        {uploadType === JobDetailUploadType.PDF && (
          <Stack direction="column" sx={{ gap: 1 }}>
            <Typography>File</Typography>
            <Typography sx={{ fontWeight: 600, color: getDynamicColor("dark4") }}>
              {fileName}
            </Typography>
            <Link
              sx={{
                fontSize: 12,
                color: getDynamicColor("primary"),
                fontWeight: 600,
                cursor: "pointer",
              }}
              onClick={() => {
                uploadCancelled.current = true;
                setUploadStatePdf(JobDetailUploadState.NotStarted);
                setFileName(undefined);
              }}
            >
              Upload a different file
            </Link>
          </Stack>
        )}
        {uploadType === JobDetailUploadType.LINK && showingCopyPasteDesc && (
          <Typography>Generated from copied job description</Typography>
        )}
        <Stack direction="column" sx={{ gap: 1 }}>
          <Typography>Default Role</Typography>
          <EditableField value={jobRole} onChange={(value) => handleSetJobRole(value)} />
        </Stack>

        <Stack direction="column" sx={{ gap: 1 }}>
          <Typography>Default Company</Typography>
          <EditableField value={companyName} onChange={(value) => handleSetCompanyName(value)} />
        </Stack>
      </Stack>
    );
  };

  const renderManualEntryContent = (): JSX.Element => {
    if (jobDetailStepState === JobDetailStepState.ManualEntry) {
      return (
        <Stack
          direction="column"
          sx={{
            width: "100%",
            gap: 2,
            borderRadius: 0.5,
            border: `1px solid ${getDynamicColor("primary")}`,
            p: 3,
            fontFamily: "Poppins",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontSize: 14,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Enter manually
          </Typography>
          <YoodliLabeledInput
            label="Default company"
            labelSx={{ fontWeight: 400, color: getDynamicColor("dark5") }}
            inputEl={
              <YoodliTextfield
                InputLabelProps={{ shrink: true }}
                placeholder="e.g. Acme Corp"
                value={companyName ?? ""}
                onChange={(e) => handleSetCompanyName(e.target.value)}
              />
            }
          />
          <YoodliLabeledInput
            label="Default role"
            labelSx={{ fontWeight: 400, color: getDynamicColor("dark5") }}
            inputEl={
              <YoodliTextfield
                InputLabelProps={{ shrink: true }}
                placeholder="e.g. Senior Project Manager"
                value={jobRole ?? ""}
                onChange={(e) => handleSetJobRole(e.target.value)}
              />
            }
          />
        </Stack>
      );
    }
    return (
      <Button
        onClick={() => {
          setJobDetailStepState(JobDetailStepState.ManualEntry);
        }}
        sx={{
          width: "100%",
          borderRadius: 0.5,
          border: `1px solid ${getDynamicColor("primary")}`,
          "&:hover": {
            boxShadow: Y_SHADOWS.box_shadow_1,
          },
          p: 3,
        }}
      >
        Enter manually
      </Button>
    );
  };

  const renderPdfUpload = (): JSX.Element => {
    switch (uploadStatePdf) {
      case JobDetailUploadState.Uploading: {
        return (
          <Stack direction="column" sx={{ gap: 1, mx: "auto", alignItems: "center" }}>
            <CircularProgress size={16} />
            <Typography sx={{ fontSize: 14, color: getDynamicColor("dark4") }}>
              {fileName}
            </Typography>
            <Button
              variant="text"
              sx={{ fontSize: 12 }}
              onClick={() => {
                uploadCancelled.current = true;
                setFileName(undefined);
                setUploadStatePdf(JobDetailUploadState.NotStarted);
              }}
            >
              Cancel upload
            </Button>
          </Stack>
        );
      }
      case JobDetailUploadState.Completed: {
        return renderRoleAndCompany();
      }
      case JobDetailUploadState.Error: {
        return (
          <Stack direction="column" sx={{ gap: 1, mx: "auto", alignItems: "center" }}>
            <Stack
              direction="row"
              sx={{ gap: 1, alignItems: "center", color: getDynamicColor("redError") }}
            >
              <WarningIcon sx={{ height: 16, width: 16 }} />
              <Typography sx={{ fontSize: 12, fontWeight: 700 }}>Upload failed</Typography>
            </Stack>
            <Typography sx={{ fontSize: 14, color: getDynamicColor("dark4") }}>
              {fileName}
            </Typography>
            <Button
              variant="text"
              sx={{ fontSize: 12 }}
              onClick={() => {
                setFileName(undefined);
                setUploadStatePdf(JobDetailUploadState.NotStarted);
              }}
            >
              Try again or upload a different file
            </Button>
          </Stack>
        );
      }
      default:
      case JobDetailUploadState.NotStarted: {
        return (
          <Stack
            {...getRootProps()}
            sx={{
              width: "100%",
              gap: 2,
              p: 3,
              mx: "auto",
              alignItems: "center",
              border: `1px dashed ${getDynamicColor("dark4")}`,
            }}
          >
            <Typography sx={{ fontSize: 14, color: getDynamicColor("dark4") }}>
              Drag PDF files here
            </Typography>
            <Button
              variant="outlined"
              onClick={(e) => {
                getRootProps()?.onClick(e);
                e.stopPropagation();
              }}
            >
              Upload from device
            </Button>
            <input type="file" accept="application/pdf" {...getInputProps()} />
          </Stack>
        );
      }
    }
  };

  const renderLinkUpload = (): JSX.Element => {
    const renderActionButton = () => {
      switch (uploadStateLink) {
        case JobDetailUploadState.NotStarted: {
          return (
            <Button
              variant="contained"
              sx={{ fontSize: 14 }}
              onClick={async () => {
                await handleUploadLink();
              }}
            >
              Submit
            </Button>
          );
        }
        case JobDetailUploadState.Uploading: {
          return (
            <Stack direction="row" sx={{ gap: 0.5, alignItems: "center" }}>
              <Button
                sx={{ fontSize: 12 }}
                onClick={() => {
                  uploadCancelled.current = true;
                  setFileUrl(undefined);
                  setUploadStateLink(JobDetailUploadState.NotStarted);
                }}
              >
                Cancel
              </Button>
              {uploadStateLink === JobDetailUploadState.Uploading && <CircularProgress size={14} />}
            </Stack>
          );
        }
        case JobDetailUploadState.Completed: {
          return (
            <IconButton
              onClick={() => {
                setFileUrl(undefined);
                setUploadStateLink(JobDetailUploadState.NotStarted);
              }}
            >
              <TrashIcon sx={{ color: getDynamicColor("redError") }} />
            </IconButton>
          );
        }
        case JobDetailUploadState.Error: {
          return (
            <Stack direction="row" sx={{ gap: 0.5, alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setFileUrl(undefined);
                  setUploadStateLink(JobDetailUploadState.NotStarted);
                }}
              >
                <CloseIcon sx={{ color: getDynamicColor("purple3") }} />
              </IconButton>
            </Stack>
          );
        }
      }
    };

    return (
      <Stack direction="column" sx={{ gap: 2 }}>
        {showingCopyPasteDesc ? (
          <Link
            sx={{ fontSize: "14px", color: getDynamicColor("primary"), cursor: "pointer" }}
            onClick={() => {
              setShowingCopyPasteDesc(false);
              setJobDescriptionText(undefined);
              setUploadStateLink(JobDetailUploadState.NotStarted);
            }}
          >
            Use a different link
          </Link>
        ) : (
          <YoodliTextfield
            error={uploadStateLink === JobDetailUploadState.Error}
            value={fileUrl ?? ""}
            placeholder="e.g. acmecorp.com/careers/open-position"
            onChange={(e) => {
              setFileUrl(e.target.value);
            }}
            InputProps={{
              endAdornment: <Box sx={{ pl: 1 }}>{renderActionButton()}</Box>,
            }}
            helperText={uploadStateLink === JobDetailUploadState.Error ? linkErrorText : undefined}
            inputProps={{
              readOnly: [
                JobDetailUploadState.Uploading,
                JobDetailUploadState.Completed,
                JobDetailUploadState.Error,
              ].includes(uploadStateLink),
            }}
          />
        )}
        {uploadStateLink === JobDetailUploadState.Error &&
          linkErrorText === JobDetailUploadError.BlockedUrl && (
            <Button
              sx={{ mx: "auto" }}
              variant="outlined"
              onClick={() => {
                setUploadStateLink(JobDetailUploadState.NotStarted);
                setCopyPasteModalOpen(true);
              }}
            >
              Copy + paste job description
            </Button>
          )}
        {uploadStateLink === JobDetailUploadState.Completed && renderRoleAndCompany()}
      </Stack>
    );
  };

  const renderUploadContent = (): JSX.Element => {
    if (jobDetailStepState === JobDetailStepState.Upload) {
      return (
        <Stack
          direction="column"
          sx={{
            width: "100%",
            gap: 2,
            borderRadius: 0.5,
            border: `1px solid ${getDynamicColor("primary")}`,
            p: 3,
            fontFamily: "Poppins",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontSize: 14,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Automatically add from a job description
          </Typography>
          <Stack direction="row" sx={{ width: "100%", gap: 2, alignItems: "center" }}>
            <Button
              onClick={() => {
                setUploadType(JobDetailUploadType.PDF);
              }}
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                color: getDynamicColor("purple3"),
                px: 1,
                mx: 1,
                borderRadius: 0,
                borderBottom:
                  uploadType === JobDetailUploadType.PDF
                    ? `2px solid ${getDynamicColor("purple3")}`
                    : "none",
                top: uploadType === JobDetailUploadType.PDF ? 0 : -1,
                transition: "none",
                flex: 1,
              }}
              startIcon={<PDFIcon height={18} width={18} />}
            >
              Upload a PDF
            </Button>
            <Button
              onClick={() => {
                setUploadType(JobDetailUploadType.LINK);
              }}
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                color: getDynamicColor("purple3"),
                px: 1,
                mx: 1,
                borderRadius: 0,
                borderBottom:
                  uploadType === JobDetailUploadType.LINK
                    ? `2px solid ${getDynamicColor("purple3")}`
                    : "none",
                top: uploadType === JobDetailUploadType.LINK ? 0 : -1,
                transition: "none",
                flex: 1,
              }}
              startIcon={<LinkIcon height={16} width={16} />}
            >
              Add a link
            </Button>
          </Stack>
          {uploadType === JobDetailUploadType.PDF ? renderPdfUpload() : renderLinkUpload()}
        </Stack>
      );
    }
    return (
      <Button
        onClick={() => {
          setJobDetailStepState(JobDetailStepState.Upload);
        }}
        sx={{
          width: "100%",
          borderRadius: 0.5,
          border: `1px solid ${getDynamicColor("primary")}`,
          "&:hover": {
            boxShadow: Y_SHADOWS.box_shadow_1,
          },
          p: 3,
        }}
      >
        Automatically add from a job description
      </Button>
    );
  };

  return (
    <Stack direction="column" sx={{ gap: 4, width: "100%", maxWidth: "500px", mx: "auto" }}>
      {renderManualEntryContent()}
      <Typography sx={{ textAlign: "center", fontSize: 14, color: getDynamicColor("purple3") }}>
        or
      </Typography>
      {renderUploadContent()}
      <YoodliCtaModal
        open={copyPasteModalOpen}
        close={() => setCopyPasteModalOpen(false)}
        wrapperSx={{ gap: 1, pb: { xs: 2, md: 2 }, height: `min(600px, 100%)` }}
        bodyComponent={
          <Box sx={{ display: "flex", pt: 2, flex: 1 }}>
            <YoodliTextfield
              multiline
              rows={20}
              placeholder="Copy and paste a job description here"
              value={jobDescriptionText}
              onChange={(e) => setJobDescriptionText(e.target.value)}
              helperText={
                uploadStateLink === JobDetailUploadState.Error ? linkErrorText : undefined
              }
            />
          </Box>
        }
        buttonsComponent={
          <Box>
            <Button
              onClick={async () => {
                await handleUploadTextDescription();
              }}
              disabled={uploadStateLink === JobDetailUploadState.Uploading}
              endIcon={
                uploadStateLink === JobDetailUploadState.Uploading && (
                  <Box sx={{ pl: 1 }}>
                    <CircularProgress size={14} />
                  </Box>
                )
              }
            >
              Upload
            </Button>
          </Box>
        }
      />
    </Stack>
  );
};
