// Components
import { Box, Button } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

// todo styling - https://github.com/Yoodli/yoodli/issues/14014

type CreateContentSpaceCardProps = {
  onClick: () => void;
};

const CreateContentSpaceCard = ({ onClick }: CreateContentSpaceCardProps): JSX.Element => {
  return (
    <Box
      sx={{
        border: `1px solid ${getDynamicColor("dark3")}`,
        borderRadius: "4px",
        width: "424px",
        height: "256px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        sx={{
          color: getDynamicColor("primary"),
          fontFamily: "poppins",
          fontSize: "14px",
          fontWeight: 700,
          minWidth: "100%",
          minHeight: "100%",
          borderRadius: 0,
        }}
        onClick={onClick}
      >
        + Create new content space
      </Button>
    </Box>
  );
};

export default CreateContentSpaceCard;
