import firebase from "firebase/app";
import { db } from "lib-fullstack";
import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Assets
import PlaceholderThumbnail from "images/graphics/video-placeholder.svg";
import { ReactComponent as NoSpeechIcon } from "images/icons/NoSpeechIcon.svg";

// Utils
import "../../App.css";
import { handleEditSpeechName } from "../../utils/EditVideoItemUtilities";
import { generateSpeechSharePath } from "../../utils/Utilities";
// UI Utilities/Functions
import ShareSpeechWindow from "../VideoSummary/ShareSpeechWindow";
import { MoreOptionsDropdown } from "./MoreOptionsDropdown";
import {
  analyticsProcessingIsComplete,
  analyticsProcessingErrorMessage,
  SHARED,
  LIBRARY,
  getSpeechTypeStringFromEnum,
} from "./VideoJournalUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { formatDateShort } from "lib-fullstack/utils/dateUtils";

type MobileSpeechRowProps = {
  dbSpeech: db.Doc<db.Speech>;
  dbShare?: db.Doc<db.Share>;
  thumbnailUrl: string | null;
  journalView?: string;
  disallowShare?: boolean;
};

/**
 * SpeechItem displays high-level information about a single speech. Each item is displayed on the Video Journal.
 */
const MobileSpeechRow = ({
  dbSpeech,
  dbShare,
  thumbnailUrl,
  journalView,
  disallowShare,
}: MobileSpeechRowProps): React.ReactElement => {
  const [showShareRecordingWindow, setShowShareRecordingWindow] = React.useState(false);
  const [editingName, setEditingName] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const speech = dbSpeech.data;
  const [speechName, setSpeechName] = React.useState(speech.name);
  const navigate = useNavigate();

  function visitSpeech() {
    if (!editingName) {
      navigate(generateSpeechSharePath(speech));
    }
  }

  const userIdToUse = {
    [SHARED]: dbShare,
    [LIBRARY]: dbSpeech,
  };
  const videoAuthorId = userIdToUse[journalView] ? userIdToUse[journalView]?.recordedBy : null;

  const complete = analyticsProcessingIsComplete(dbSpeech.data);
  const errorMessage = analyticsProcessingErrorMessage(dbSpeech.data);
  const editable = firebase.auth().currentUser.uid == videoAuthorId && complete;

  /**
   * Show the Share Recording Window giving users the ability to choose visibility permissions for comments.
   * If the Speech a default speech, copy the Feedback link to the Clipboard and show a small alert communicating this to the user.
   */
  function handleShareRecordingClick() {
    setShowShareRecordingWindow(true);
  }

  function hideShareRecordingWindow() {
    setShowShareRecordingWindow(false);
  }

  function renderShareRecordingWindow() {
    if (firebase.auth().currentUser) {
      return (
        <ShareSpeechWindow
          open={showShareRecordingWindow}
          dbSpeechRef={dbSpeech.ref}
          onClose={hideShareRecordingWindow}
        />
      );
    }
  }

  function handleEdit() {
    setEditingName(true);
    // Delay focusing the element to allow the menu to close first.
    setTimeout(() => inputRef.current.focus(), 0);
  }

  function doNameEdit(newName: string) {
    handleEditSpeechName(dbSpeech, newName).catch(console.error);
    // Delay setEditingName to prevent clicking outside the text field from visiting the speech immediately.
    setTimeout(() => setEditingName(false), 0);
  }

  function handleNameEnterKey(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      doNameEdit(speechName);
    }
  }

  function handleNameBlur() {
    doNameEdit(speechName);
  }

  return (
    <Stack
      direction="row"
      gap={2}
      sx={{
        color: getDynamicColor("purple3"),
        width: "100%",
        pt: 1,
        userSelect: "none",
        position: "relative",
      }}
    >
      <Stack
        direction="row"
        gap={2}
        onClick={visitSpeech}
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        {dbSpeech?.data?.lifecycleState === db.LifecycleState.REDACTED ? (
          <Box
            sx={{
              mt: 1,
              width: "85px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: `1px solid ${getDynamicColor("dark3")}`,
              borderRadius: "4px",
            }}
          >
            <Box
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "30px",
                background: getDynamicColor("dark2"),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 0.5,
              }}
            >
              <NoSpeechIcon />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              width: { xs: "90px !important", sm: "unset" },
              minWidth: { xs: "90px !important", sm: "unset" },
              pt: 1,
              background: `url(${thumbnailUrl ?? PlaceholderThumbnail})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              border: `1px solid ${getDynamicColor("dark3")}`,
              borderRadius: "4px",
            }}
          />
        )}
        <Stack
          direction="column"
          style={{
            width: "calc(100% - 154px)",
            position: "relative",
            justifyContent: errorMessage ? "start" : "space-evenly",
            minHeight: 72,
          }}
          gap={errorMessage ? "2px" : 0}
        >
          {editingName ? (
            <YoodliTextfield
              sx={{
                width: "100%",
                marginBottom: "8px",
                paddingLeft: "4px",
                fontWeight: "500",
                boxShadow:
                  "0px 5.00741px 7.96296px rgba(0, 0, 0, 0.04), 0px 2.6px 10px rgba(0, 0, 0, 0.05)",
                outline: 0,
                paddingTop: "2px",
                paddingRight: "0px",
                ".MuiInputBase-input": {
                  py: "1px",
                  px: "5px",
                },
              }}
              InputProps={{
                sx: {
                  fontSize: "16px",
                },
              }}
              onKeyDown={(e) => {
                handleNameEnterKey(e);
              }}
              onBlur={() => {
                handleNameBlur();
              }}
              inputRef={inputRef}
              value={speechName}
              onChange={(e) => {
                setSpeechName(e.target.value);
              }}
            />
          ) : (
            <Tooltip title={speech.name}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "18px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}
                onClick={visitSpeech}
              >
                {speech.name}
              </Typography>
            </Tooltip>
          )}
          <Typography
            style={{ fontSize: "16px", marginTop: "-1px", lineHeight: 1.3 }}
            onClick={visitSpeech}
          >
            {formatDateShort(speech.recordedDate)} •{" "}
            <span style={{ whiteSpace: "nowrap" }}>{getSpeechTypeStringFromEnum(speech)}</span>
          </Typography>
          <Typography
            sx={{
              color: getDynamicColor("redError"),
              fontStyle: "italic",
              fontSize: "12px",
              lineHeight: 1.3,
            }}
          >
            {errorMessage}
          </Typography>
        </Stack>
      </Stack>
      {!disallowShare && (
        <Box style={{ position: "absolute", right: 0 }}>
          <MoreOptionsDropdown
            dbSpeech={dbSpeech}
            dbShare={dbShare}
            complete={complete}
            editable={editable}
            handleEdit={handleEdit}
            handleShareRecordingClick={handleShareRecordingClick}
          />
        </Box>
      )}
      {showShareRecordingWindow && renderShareRecordingWindow()}
    </Stack>
  );
};

MobileSpeechRow.defaultProps = {
  journalView: LIBRARY,
};

export default MobileSpeechRow;
