import React from "react";

// Components
import { Grid, Button, Divider, Stack, Typography, Box } from "@mui/material";
import {
  CustomizePracticeQueryKey,
  DEFAULT_PERSONA,
} from "components/ConvoScenarios/convoScenarioUtils";
import { CreatePersonaForm } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/CreatePersonaForm";
import { AnimatedGradientText } from "lib-frontend/components/AnimatedGradientText";

// Utils
import { WizardType } from "../Scenarios/CreateConvoScenarioWizard/CreateConvoScenarioWizard";
import { PersonaCard } from "./PersonaCard";
import { WizardFormFooter } from "webclient/src/components/Orgs/ManageContent/WizardFormFooter";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { createPersona, deletePersona, updatePersona } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { useIsExtraSmallScreen } from "lib-frontend/utils/themeUtils";
import {
  CreatePersonaRequest,
  PersonaResponse,
  UpdatePersonaRequest,
} from "lib-fullstack/api/scenarioApiTypes";
import { WIZARD_FOOTER_HEIGHT } from "utils/Constants";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";

type PersonasTabProps = {
  setPersonaIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  renderLoader: () => JSX.Element;
  showBackHeader: boolean;
};
export const PersonasTab = ({
  setPersonaIsSelected,
  renderLoader,
  showBackHeader,
}: PersonasTabProps): JSX.Element => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);

  const queryClient = useQueryClient();
  const personasQueryState = queryClient.getQueryState([
    CustomizePracticeQueryKey.Personas,
    defaultOrgId,
  ]);
  const isExtraSmallScreen = useIsExtraSmallScreen();

  const [saving, setSaving] = React.useState<boolean>(false);
  const [newPersona, setNewPersona] = React.useState<PersonaResponse | null>(null);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const showSplash =
    !getLiveUserDocMain().hasCreatedScenarioPersona ||
    (personasQueryState?.data as PersonaResponse[])?.length === 0;

  const deletePersonaMutation = useMutation({
    mutationFn: (personaId: string) => deletePersona(defaultOrgId, personaId),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.Personas, defaultOrgId],
      });
      Instrumentation.logOrgPersonaDeleted(defaultOrgId);
    },
  });

  const handleEditPersona = (persona: PersonaResponse) => {
    handleSetNewPersona(persona);
    setIsEditing(true);
  };

  const handleUpdateNewPersona = (key: keyof UpdatePersonaRequest, value: string | string[]) => {
    setNewPersona((prev) => ({ ...prev, [key]: value }));
  };

  const handleSetNewPersona = (persona: PersonaResponse | null) => {
    setPersonaIsSelected(!!persona);
    setNewPersona(persona);
  };

  const handleSavePersona = async () => {
    try {
      if (!getLiveUserDocMain().hasCreatedScenarioPersona) {
        updateThisUserDocMain({ hasCreatedScenarioPersona: true }).catch((er) => {
          console.log(`Error updating hasCreatedScenarioPersona in user doc main: ${er}`);
        });
      }
      setSaving(true);
      let personaId = newPersona.id;
      if (isEditing) {
        // handle updating persona
        await updatePersona(defaultOrgId, personaId, newPersona as UpdatePersonaRequest);
        Instrumentation.logOrgPersonaUpdated(defaultOrgId);
      } else {
        // handle creating new persona
        const personaRequest: CreatePersonaRequest = {
          ...newPersona,
          space_id: curSpaceId,
        };
        personaId = await createPersona(defaultOrgId, personaRequest);
        Instrumentation.logOrgPersonaCreated(defaultOrgId);
      }
      void queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.OrgProfilePictures, defaultOrgId],
      });
      await queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.Personas, defaultOrgId],
      });

      // reset state upon completion
      setIsEditing(false);
      handleSetNewPersona(null);
    } catch (e) {
      console.error(`Error saving persona ${newPersona.id}: ${e}`);
    } finally {
      setSaving(false);
    }
  };
  const renderAddButton = () => {
    return (
      <Button
        variant="contained"
        size="xlarge"
        sx={{ whiteSpace: "nowrap" }}
        onClick={() => {
          handleSetNewPersona({ ...DEFAULT_PERSONA, id: "", is_template: false, is_active: true });
          setIsEditing(false);
        }}
      >
        Create{isExtraSmallScreen ? " New" : " Persona"}
      </Button>
    );
  };

  // dont show loader when refetching because we want it to just update the list in place
  if (
    personasQueryState?.status === "pending" ||
    (personasQueryState?.fetchStatus === "fetching" && personasQueryState?.status !== "success")
  ) {
    return renderLoader();
  }
  if (newPersona && showBackHeader) {
    return (
      <Box
        sx={{
          maxWidth: "lg",
          pb: WIZARD_FOOTER_HEIGHT,
        }}
      >
        <Stack
          gap={3}
          sx={{
            fontFamily: "poppins",
            p: { xs: 2, sm: 3, md: 0 },
          }}
        >
          <span>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: { xs: 16, md: 18 },
                fontWeight: 700,
              }}
            >
              Customize a persona
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              Change the persona details to make it your own
            </Typography>
          </span>
          <CreatePersonaForm
            newPersona={newPersona}
            handleUpdateNewPersona={handleUpdateNewPersona}
            wizardType={WizardType.Org}
          />
        </Stack>
        <WizardFormFooter
          saving={saving}
          disabled={
            saving ||
            !newPersona.name ||
            !newPersona.job_title ||
            !newPersona.demeanor ||
            !newPersona.voice_id ||
            !newPersona.profile_picture_id
          }
          handleSave={handleSavePersona}
        />
      </Box>
    );
  }

  return (
    <Stack
      sx={{
        fontFamily: "poppins",
        maxWidth: "xxl",
        p: { xs: 2, sm: 3, md: 0 },
        pt: { xs: 2, md: 0 },
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
        <Stack
          direction="column"
          justifyContent="flex-start"
          sx={{
            fontFamily: "poppins",
            py: showSplash ? { xs: 0, md: 4 } : 0,
          }}
        >
          {showSplash && (
            <AnimatedGradientText
              sx={{
                fontSize: { xs: 16, md: 18 },
                fontWeight: 700,
              }}
            >
              Create New Persona
            </AnimatedGradientText>
          )}
          {showSplash && (
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontSize: 12,
                fontWeight: 600,
                maxWidth: 600,
              }}
            >
              Currently, you only have default personas active. Enhance your scenarios by creating
              customizable personas
            </Typography>
          )}
        </Stack>
        {showSplash && renderAddButton()}
      </Stack>
      {showSplash && (
        <Divider
          sx={{
            width: "100vw",
            ml: { xs: -2, md: -6 },
            my: 4,
          }}
        />
      )}
      <Stack direction="column" gap={2}>
        <Stack
          sx={{ justifyContent: "space-between", alignItems: { xs: "flex-start", md: "top" } }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <Stack>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: { xs: 14, md: 16 },
                fontWeight: 700,
              }}
            >
              My Personas
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontSize: 12,
                fontWeight: 600,
                maxWidth: 600,
              }}
            >
              Enhance your scenarios by creating customizable personas. Personas that are in use
              cannot be deleted.
            </Typography>
          </Stack>

          {!showSplash && renderAddButton()}
        </Stack>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {(personasQueryState?.data as PersonaResponse[])
            ?.sort((a, b) => (a.used_by_n_scenarios > b.used_by_n_scenarios ? -1 : 1))
            .map((persona) => {
              return (
                <Grid item xs={12} sm={6} lg={4} key={persona.id}>
                  <PersonaCard
                    persona={persona}
                    handleEditPersona={handleEditPersona}
                    handleDeletePersona={(personaId) => deletePersonaMutation.mutate(personaId)}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Stack>
    </Stack>
  );
};
