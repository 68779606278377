// Careful to not import things like db or SiteConfig here, as this is shared on nextjs
// and we want to prevent bundle size bloat
/***
 *
 * In the future, we should move all the enums from lib-frontend/utils/ProductAnalyticsUtils to this doc.
 * Note that LandingPageAnalyticEvents appear only in nextjs to keep them seperated as that usage needs to be leaner
 *
 ***/
export enum AnalyticsAnalyticEvents {
  CONCISENESS_CARD_TIP_CLICKED = "conciseness_card_tip_clicked",
  ANALYTIC_CARD_EXPANDED = "analytic_card_expanded",
}

// Key actions - do not remove elements without considering what cleanup efforts may be necessary
// as all are tied to DB records on user main docs
export enum KeyActionAnalyticsEvents {
  SPEECH_RECORD_VIEW = "ka_record", // emitted when, on Speech Recorder, user clicks "View Analysis"/"Save" to view Speech Summary
  POODLI_RECORD = "ka_poodli_record", // emitted when, from Poodli app, user clicks "See all insights" to view Speech Summary
  ZOODLI_RECORD_VIEW = "ka_zoodli_record", // emitted when, from Zoodli page, user clicks on "View last recording" to view Speech Summary right after Zoodli recording
  UPLOAD = "ka_upload", // upload is initiated (regardless of outcome)
  GAME = "ka_game", // game is successfully played
  COMMENT = "ka_comment", // a human comment, comment reply, etc on speech summary or Zoodli control panel
  AUTOREMARK = "ka_autoremark", // an autoremark click, interaction, expand
  SHARE_GAME = "ka_share_game", // share a game
  SHARE_SPEECH = "ka_share_rec", // share a speech/video
  INVITE = "ka_invite", // [deprecated] invite a user to the platform or to view a speech, by email
  ENGAGE_PASSIVE = "ka_engage_passive", // play video on speech summary or engage with Courses
  TOUR_CLICK = "ka_tour_click", // click on a tour point on speech summmary
  CONTEXT_TAG_UPDATED = "context_tag_updated",
}

export enum EventsKeyActionHows {
  // Shares
  SHARE_LINK = "share_link", // Share speech/game via copy link
  SHARE_EMAIL = "share_email", // Share speech/game via email flow
  // Remarks
  AUTOREMARK_SELECTED = "autoremark_selected",
  AUTOREMARK_INTERACTION = "autoremark_interaction", // time spent on remark
  AUTOREMARK_EXPANDED = "autoremark_expanded",
  AUTOREMARK_COLLAPSED = "autoremark_collapsed",
  AUTOREMARK_LIKED = "autoremark_liked",
  AUTOREMARK_UNLIKED = "autoremark_unliked",
  AUTOREMARK_DISMISSED = "autoremark_dismissed",
  AUTOREMARK_UNDISMISSED = "autoremark_undismissed",
  // Comments
  COMMENT_SPEECH_SUMMARY = "comment_speech_summary", // comment created
  COMMENT_ZOODLI = "comment_zoodli",

  // Bookmarks
  BOOKMARK_SPEECH_SUMMARY = "bookmark_speech_summary", // bookmark created
  BOOKMARK_ZOODLI = "bookmark_zoodli",
}

export enum SpeechSummaryAnalyticsEvents {
  SUMMARY_TOURPOINT_VISITED = "summary_tourpoint_visited",
  SEGMENT_REPHRASE = "gpt3_segment_rephrase",
  SPEECH_SUMMARY_VIEWED = "speech_summary_viewed",
  SPEECH_SUMMARY_BANNER_CTA_CLICKED = "speech_summary_banner_cta_clicked",
  SPEECH_SUMMARY_RECORDING_DOWNLOADED = "speech_summary_recording_downloaded",
  SPEECH_SUMMARY_RECORDING_SEEKED = "speech_summary_recording_seeked",
  SPEECH_SUMMARY_RECORDING_PLAYED = "speech_summary_recording_played",
  SPEECH_SUMMARY_RECORDING_PAUSED = "speech_summary_recording_paused",
  SPEECH_SUMMARY_RECORDING_PLAYBACK_SPEED_CHANGED = "speech_summary_recording_playback_speed_changed",
}

export enum SelfConfidenceEvalAnalyticsEvents {
  CONFIDENCE_LEVEL_QUESTION_VIEWED = "self_confidence_eval_confidence_level_question_viewed",
  CONFIDENCE_LEVEL_CTA_CLICKED = "self_confidence_eval_confidence_level_cta_clicked",
  REASON_QUESTION_VIEWED = "self_confidence_eval_reason_question_viewed",
  REASON_OPTIONS_SAVED = "self_confidence_eval_reason_options_saved",
  CONFIRMATION_VIEWED = "self_confidence_eval_confirmation_viewed",
  CONFIRMATION_CLOSE_BUTTON_CLICKED = "self_confidence_eval_confirmation_close_button_clicked",
  SUBMITTED = "self_confidence_eval_submitted",
}

export enum CommentAnalyticsEvents {
  UPDATED = "comment_updated",
  DELETED = "comment_deleted",
  EXPANDED = "comment_expanded",
  COLLAPSED = "comment_collapsed",
  REPLY_CREATED = "comment_reply_created",
  REMARK_REPLY_CREATED = "remark_reply_created",
  REMARK_PRACTICE_FUQ_CTA_CLICKED = "remark_practice_fuq_cta_clicked",
  LIKED = "comment_liked",
  UNLIKED = "comment_unliked",
}

export enum AuthAnalyticsEvents {
  SIGN_UP = "auth_sign_up",
  SIGN_IN = "auth_sign_in",
  SIGN_OUT = "auth_sign_out",
  SIGNUP_PAGE_LOADED = "signup_page_loaded",
  SIGNIN_PAGE_LOADED = "signin_page_loaded",
  SIGNUP_GOOGLE_OPTION_CLICKED = "signup_google_option_clicked",
  SIGNUP_OUTLOOK_OPTION_CLICKED = "signup_outlook_option_clicked",
  SIGNUP_CUSTOM_SSO_OPTION_CLICKED = "signup_custom_sso_option_clicked",
  SIGNIN_GOOGLE_OPTION_CLICKED = "signin_google_option_clicked",
  SIGNIN_OUTLOOK_OPTION_CLICKED = "signin_outlook_option_clicked",
  SIGNIN_CUSTOM_SSO_OPTION_CLICKED = "signin_custom_sso_option_clicked",
  SIGNUP_EMAIL_PASSWORD_OPTION_EXPANDED = "signup_email_password_option_expanded",
  SIGNUP_EMAIL_PASSWORD_OPTION_CLICKED = "signup_email_password_option_clicked",
  SIGNIN_EMAIL_PASSWORD_OPTION_CLICKED = "signin_email_password_option_clicked",
  EMAIL_VERIFICATION_PAGE_LOADED = "email_verification_page_loaded",
  ONBOARDING_PAGE_LOADED = "onboarding_page_loaded",
  FORGOT_PASSWORD_PAGE_LOADED = "forgot_password_page_loaded",
  FORGOT_PASSWORD_RESET_LINK_CLICKED = "forgot_password_reset_link_clicked",
}

export enum NavigationAnalyticsEvents {
  SPEECH = "record_speech",
  CONVERSATION = "record_conversation",
  INTERVIEW = "record_interview",
  ZOODLI = "record_zoodli",
  LIBRARY_CLICK_SPEECH = "videojournal_click_speech_item",
}

export enum PracticeRecorderWhere {
  CUSTOMIZATION_DRAWER = "customization_drawer",
  PRACTICE_RECORDER = "practice_recorder",
  CUSTOM_QUESTIONS_LIST = "custom_questions_list",
  CUSTOM_QUESTIONS_EDIT_ICON = "custom_questions_edit_icon",
  STARTER_QUESTIONS_ERROR_COPY = "starter_questions_error_copy",
}
export enum PracticeRecorderAnalyticsEvents {
  PRACTICE_RECORDER_LOADED = "practice_recorder_loaded",
  PRACTICE_RECORDER_PROMPT_GENERATED = "practice_recorder_prompt_generated",
  PRACTICE_RECORDER_RECORDING_STARTED = "practice_recorder_recording_started",
  PRACTICE_RECORDER_RECORDING_PAUSED = "practice_recorder_recording_paused",
  PRACTICE_RECORDER_RECORDING_RESUMED = "practice_recorder_recording_resumed",
  PRACTICE_RECORDER_RECORDING_PREVIEWED = "practice_recorder_recording_previewed",
  PRACTICE_RECORDER_RECORDING_ENDED = "practice_recorder_recording_ended",
  PRACTICE_RECORDER_RECORDING_SAVED = "practice_recorder_recording_saved",
  PRACTICE_RECORDER_RECORDING_RESTART = "practice_recorder_recording_restart",
  PRACTICE_RECORDER_FOLLOWUP_QUESTION_REQUESTED = "practice_recorder_followup_question_requested",
  PRACTICE_RECORDER_CUSTOMIZATION_DRAWER_OPENED = "practice_recorder_customization_drawer_opened",
  PRACTICE_RECORDER_PERSONA_SELECTED = "practice_recorder_persona_selected",
  PRACTICE_RECORDER_TOPIC_SELECTED = "practice_recorder_topic_selected",
  PRACTICE_RECORDER_COMPANY_SELECTED = "practice_recorder_company_selected",
  PRACTICE_RECORDER_ROLE_SELECTED = "practice_recorder_role_selected",
  PRACTICE_RECORDER_ADD_QUESTIONS_BUTTON_CLICKED = "practice_recorder_add_questions_button_clicked",
  PRACTICE_RECORDER_COUNTDOWN_ENABLED_CHANGED = "practice_recorder_countdown_enabled_changed",
  PRACTICE_RECORDER_TTS_ENABLED_CHANGED = "practice_recorder_tts_enabled_changed",
  PRACTICE_RECORDER_AUTO_RESPOND_MODE_ENABLED_CHANGED = "practice_recorder_auto_respond_mode_enabled_changed",
  PRACTICE_RECORDER_VIDEO_MIRRORED_CHANGED = "practice_recorder_video_mirrored_changed",
  PRACTICE_RECORDER_HAS_POSITIONING_GUIDE_CHANGED = "practice_recorder_has_positioning_guide_changed",
  PRACTICE_RECORDER_TOURPOINT_VISITED = "practice_recorder_tourpoint_visited",
  PRACTICE_RECORDER_DEVICE_PERMISSIONS_GRANTED = "practice_recorder_device_permissions_granted",
  PRACTICE_RECORDER_HELPFUL_HINT_DISMISSED = "practice_recorder_helpful_hint_dismissed",
  PRACTICE_RECORDER_AI_RESPONSE_INTERRUPTED = "practice_recorder_ai_response_interrupted",
  PRACTICE_RECORDER_SCREEN_SHARED = "practice_recorder_screen_shared",
  PRACTICE_RECORDER_LANGUAGE_CHANGED = "practice_recorder_language_changed",
  PRACTICE_RECORDER_TOGGLE_VIDEO_PLAYER = "practice_recorder_toggle_video_player",
}

export enum CalendarAnalyticsEvents {
  CONNECTED = "cal_connected",
  SKIPPED = "cal_skipped",
  DISCONNECTED = "cal_disconnected",
  SWITCH_POODLI = "meetings_cal_switch_poodli", // Switching cal mode from Zoodli to Poodli (on Meetings page)
  STAY_ZOODLI = "meetings_cal_stay_zoodli", // When user clicks on "Stay on Team Yoodli" button to keep their calendar connection to Zoodli
  CAL_SWITCH = "cal_switch",
  AUTOSTART_TOGGLED = "cal_meeting_autostart_toggled", // When user switches live coaching settings on an invidual meeting
}

export enum PoodliAnalyticsEvents {
  POODLI_DOWNLOAD_PAGE_VIEWED = "poodli_download_page_viewed",
  DOWNLOADED = "poodli_downloaded", // only use via logPoodliDownload below
  ONBOARDING_ZOOM_SETUP_VIEWED = "poodli_onboarding_zoom_setup_viewed",
  ONBOARDING_ZOOM_SETUP_SKIPPED = "poodli_onboarding_zoom_setup_skipped",
  ONBOARDING_ZOOM_SETUP = "poodli_onboarding_zoom_setup",
  ONBOARDING_COMPLETED = "poodli_onboarding_completed",
  START_CLICK = "poodli_start_click",
  STOP_CLICK = "poodli_stop_click",
  START_CAL = "poodli_start_cal",
  START_AUTO = "poodli_start_auto",
  RTAS_ENABLED = "poodli_rtas_enabled",
  RTAS_DISABLED = "poodli_rtas_disabled",
  AUTOSTART_ENABLED = "poodli_auto_start_enabled",
  AUTOSTART_DISABLED = "poodli_auto_start_disabled",
  MEETING_DETECTION_ENABLED = "poodli_meeting_detection_enabled",
  MEETING_DETECTION_DISABLED = "poodli_meeting_detection_disabled",
  LAUNCH_ON_RESTART_ENABLED = "poodli_launch_on_restart_enabled",
  LAUNCH_ON_RESTART_DISABLED = "poodli_launch_on_restart_disabled",
  NOTIFICATIONS_ENABLED = "poodli_notifications_enabled",
  NOTIFICATIONS_DISABLED = "poodli_notifications_disabled",
  NOTIFICATIONS_START_RECORDING = "poodli_notification_start_recording",
  NOTIFICATIONS_SKIP_RECORDING = "poodli_notification_skip_recording",
  NOTIFICATIONS_EXTEND_RECORDING = "poodli_notification_extend_recording",
  NOTIFICATIONS_VIEW_ANALYTICS = "poodli_notification_view_analytics",
  NOTIFICATIONS_CLOSE = "poodli_notification_close",
  NOTIFICATIONS_CONTINUE_RECORDING = "poodli_notification_continue_recording",
  TEST_MIC = "poodli_test_microphone",
  APP_SIGN_IN = "poodli_sign_in",
  APP_SIGN_OUT = "poodli_sign_out",
  APP_QUIT = "poodli_app_quit",
  HIDE_FROM_SCREEN_SHARE_ENABLED = "poodli_hide_from_screen_share_enabled",
  HIDE_FROM_SCREEN_SHARE_DISABLED = "poodli_hide_from_screen_share_disabled",
  START_FAILED = "poodli_start_failed",
  LIVE_FEEDBACK_EXPANDED = "poodli_live_feedback_expanded",
  LIVE_FEEDBACK_COLLAPSED = "poodli_live_feedback_collapsed",
  POODLI_LIVE_ANALYTICS_LOADED = "poodli_live_analytics_loaded",
  LIVE_ANALYTICS_UPDATED = "poodli_live_analytics_updated",

  // Talking Points
  TALKING_POINTS_ENABLED = "poodli_talking_points_enabled",
  TALKING_POINTS_DISABLED = "poodli_talking_points_disabled",
  TALKING_POINTS_EXPANDED = "poodli_talking_points_expanded",
  TALKING_POINTS_COLLAPSED = "poodli_talking_points_collapsed",
  TALKING_POINTS_TEMPLATE_STARTED = "talking_points_template_started",
  TALKING_POINTS_TEMPLATE_SAVED = "talking_points_template_saved",
  TALKING_POINTS_ENTRY_ADDED = "talking_points_entry_added",
  TALKING_POINTS_IN_RECORDING_STATE_CHANGED = "talking_points_in_recording_state_changed",
  TALKING_POINTS_ENTRY_CHECKED_OFF = "talking_points_entry_checked_off",

  INCOMPATIBLE_MACOS = "poodli_incompatible_macos",
  TOO_SHORT_MEETING_DELETED = "poodli_too_short_meeting_deleted",

  POODLI_LATEST_YOODLI_INSIGHT_CARD_CLICKED = "poodli_latest_yoodli_insight_card_clicked",
  POODLI_LATEST_YOODLI_INSIGHT_CARD_CHANGED = "poodli_latest_yoodli_insight_card_changed",
  POODLI_LATEST_SEE_ALL_INSIGHTS = "poodli_latest_yoodli_see_all_insights",
  POODLI_AGGREGATE_INSIGHTS_CARD_CHANGED = "poodli_aggregate_insights_card_changed",
  POODLI_OPEN_ITEMS_CTA_CLICKED = "poodli_open_items_cta_clicked",

  POODLI_FOCUS_AREA_LEARN_MORE = "poodli_focus_area_learn_more",
  POODLI_FOCUS_AREA_TOURPOINT_DISMISSED = "poodli_focus_area_tourpoint_dismissed",
  POODLI_FOCUS_AREA_QUALITATIVE_PROGRESS_CTA_CLICKED = "poodli_focus_area_qualitative_progress_cta_clicked",
  POODLI_FOCUS_AREA_CALL_PROGRESS_HOVERED = "poodli_focus_area_call_progress_hovered",
  POODLI_COACHING_TAB_SAMPLE_INSIGHTS_VIEWED = "poodli_coaching_tab_sample_insights_viewed",

  POODLI_COACHING_TAB_VIEWED = "poodli_coaching_tab_viewed",
  POODLI_DISCOVER_TAB_VIEWED = "poodli_discover_tab_viewed",
  POODLI_CALENDAR_TAB_VIEWED = "poodli_calendar_tab_viewed",
  POODLI_TALKING_POINTS_TAB_VIEWED = "poodli_talking_points_tab_viewed",

  POODLI_TRAY_ICON_CLICKED = "poodli_tray_icon_clicked",
}

export enum HubAnalyticEvents {
  HUB_MEMBER_STATUS_UPDATED = "hub_member_status_updated",
  ORG_USER_ADDED = "org_user_added",
  DEFAULT_ORG_CHANGED = "default_org_changed",
}

export enum EndUserScenarioAnalyticsEvents {
  END_USER_SCENARIO_CREATED = "end_user_scenario_created",
  END_USER_SCENARIO_UPDATED = "end_user_scenario_updated",
  END_USER_SCENARIO_DELETED = "end_user_scenario_deleted",
  END_USER_PERSONA_CREATED = "end_user_persona_created",
  END_USER_PERSONA_UPDATED = "end_user_persona_updated",
  END_USER_PERSONA_DELETED = "end_user_persona_deleted",
}

export enum OrgAnalyticsEvents {
  ORG_GROUP_CREATED = "org_group_created",
  ORG_GROUP_DELETED = "org_group_deleted",
  ORG_INVITATION_SENT = "org_invitation_sent",
  ORG_MEMBER_REMOVED = "org_member_removed",
  ORG_SCENARIO_CREATED = "org_scenario_created",
  ORG_SCENARIO_UPDATED = "org_scenario_updated",
  ORG_SCENARIO_DELETED = "org_scenario_deleted",
  ORG_PERSONA_CREATED = "org_persona_created",
  ORG_PERSONA_UPDATED = "org_persona_updated",
  ORG_PERSONA_DELETED = "org_persona_deleted",
  ORG_CUSTOM_GOAL_CREATED = "org_custom_goal_created",
  ORG_CUSTOM_GOAL_UPDATED = "org_custom_goal_updated",
  ORG_CUSTOM_GOAL_DELETED = "org_custom_goal_deleted",
  ORG_COACHBOT_CREATED = "org_coachbot_created",
  ORG_COACHBOT_UPDATED = "org_coachbot_updated",
  ORG_COACHBOT_DELETED = "org_coachbot_deleted",
  ORG_INTERVIEW_BANK_CREATED = "org_interview_bank_created",
  ORG_INTERVIEW_BANK_UPDATED = "org_interview_bank_updated",
  ORG_INTERVIEW_BANK_DELETED = "org_interview_bank_deleted",
  ORG_WELCOME_VIDEO_CREATED = "org_welcome_video_created",
  ORG_WELCOME_VIDEO_UPDATED = "org_welcome_video_updated",
  ORG_WELCOME_VIDEO_DELETED = "org_welcome_video_deleted",
  ORG_COURSE_CREATED = "org_course_created",
  ORG_COURSE_UPDATED = "org_course_updated",
  ORG_COURSE_DELETED = "org_course_deleted",
  ORG_REPORT_GENERATED = "org_report_generated",
  SCENARIO_JOB_DESCRIPTION_ADDED = "scenario_job_description_added",
}

export enum OrgProgramsAnalyticsEvents {
  PROGRAM_CREATED = "program_created",
  PROGRAM_PUBLISHED = "program_published",
  PROGRAM_ARCHIVED = "program_archived",
  PROGRAM_DELETED = "program_deleted",
  PROGRAM_SCENARIO_ADDED = "program_scenario_added",
  PROGRAM_GROUP_ADDED = "program_group_added",
  PROGRAM_ADMIN_OVERVIEW_VIEWED = "program_admin_overview_viewed",
  LEARNING_PAGE_VIEWED = "learning_page_viewed",
  PROGRAM_LEARNING_PAGE_VIEWED = "program_learning_page_viewed",
  PROGRAM_MEMBER_NEXT_STEP_CTA_CLICKED = "program_member_next_step_cta_clicked",
}

export enum DashboardAnalyticsEvents {
  VIEWED = "dashboard_page_viewed",
  INSIGHT_CARD_CLICKED = "dashboard_insight_card_clicked",
  ANALYTIC_TAB_SELECTED = "dashboard_analytics_tab_selected",
  CHART_POINT_CLICKED = "dashboard_chart_point_clicked",
  ANALYTIC_TOOLTIP_DISPLAYED = "dashboard_analytic_tooltip_displayed",
  FOCUS_AREA_TOOLTIP_DISPLAYED = "dashboard_focus_area_tooltip_displayed",
  ANALYTICS_SELECTOR_VIEWED = "dashboard_analytics_selector_viewed", // analytic selector opened
  UPDATE_HOISTED_ANALYTICS_CLICKED = "dashboard_update_hoisted_analytics_clicked",
  EMPTY_STATE_CTA_CLICKED = "dashboard_empty_state_cta_clicked",
}

export enum OnboardingAnalyticsEvents {
  EVENTS_FOCUS_SELECTED = "onboarding_events_focus_selected",
  SLIDE_CHANGED = "onboarding_slide_changed",
  COMPLETE = "onboarding_complete",
  POODLI_PRE_DOWNLOAD_PAGE_DOWNLOAD_LATER_CLICKED = "poodli_pre_download_page_download_later_clicked",
  POODLI_DOWNLOAD_PAGE_SET_UP_LATER_CLICKED = "poodli_download_page_setup_later_clicked",
  POODLI_CHECK_EMAIL_FOR_DOWNLOAD_PAGE_CONTINUE_MOBILE_CLICKED = "poodli_check_email_for_download_page_continue_mobile_clicked",
  TOS_ACCEPTED = "tos_accepted",
}

export enum InterviewAnalyticsEvents {
  TOPIC_SELECTED = "interview_topic_selected",
  STYLE_SELECTED = "interview_style_selected",
  QUESTION_STARTED = "interview_question_started",
  AMFUQ_ENABLED = "amfuq_enabled",
  AMFUQ_DISABLED = "amfuq_disabled",
}

export enum RecordingAnalyticsEvents {
  SPEECHREC_START = "speechrec_start_click",
  SPEECHREC_STOP = "speechrec_stop_click",
}

export enum LibrarySnackbarAnalyticsEvents {
  VIEWED = "cta_snackbar_viewed",
  CLICKED = "cta_snackbar_clicked",
}

export enum HomePageChecklistEvents {
  VIEWED = "home_page_viewed",
  YOODLI_ONBOARDING_CHECKLIST_ITEM_SELECTED = "yoodli_onboarding_checklist_item_selected",
  YOODLI_ONBOARDING_CHECKLIST_CTA_CLICKED = "yoodli_onboarding_checklist_cta_clicked",
  YOODLI_ONBOARDING_CHECKLIST_ITEM_COMPLETED = "yoodli_onboarding_checklist_item_completed",
  YOODLI_CHECKLIST_DEMO_VIDEO_MODAL_VIEWED = "yoodli_checklist_demo_video_modal_viewed",
  YOODLI_CHECKLIST_DEMO_VIDEO_MODAL_CLOSED = "yoodli_checklist_demo_video_modal_closed",
  YOODLI_CHECKLIST_DEMO_VIDEO_PLAYED = "yoodli_checklist_demo_video_played",
  YOODLI_CHECKLIST_DEMO_VIDEO_PAUSED = "yoodli_checklist_demo_video_paused",
  YOODLI_CHECKLIST_DEMO_VIDEO_SEEKED = "yoodli_checklist_demo_video_seeked",
  YOODLI_CHECKLIST_DEMO_VIDEO_PLAYBACK_SPEED_CHANGED = "yoodli_checklist_demo_video_playback_speed_changed",
  YOODLI_CHECKLIST_ALL_ITEMS_COMPLETED = "yoodli_checklist_all_items_completed",
  YOODLI_ONBOARDING_CHECKLIST_ITEM_DISMISSED = "yoodli_onboarding_checklist_item_dismissed",
}

export enum HomePageProductTipEvents {
  USAGE_TIPS_APPEARED = "usage_tips_appeared",
  USAGE_TIP_GIF_PLAYED = "usage_tip_gif_played",
  USAGE_TIP_CTA_CLICKED = "usage_tip_cta_clicked",
  USAGE_TIP_CAROUSEL_SLIDE_MANUALLY_CHANGED = "usage_tip_carousel_slide_manually_changed",
}

export enum HomePageReportCardEvents {
  REPORT_CARD_DATE_RANGE_CHANGED = "report_card_date_range_changed",
  REPORT_CARD_VIDEO_BUTTON_CLICKED = "report_card_video_button_clicked",
  REPORT_CARD_EMPTY_PRACTICE_BUTTON_CLICKED = "report_card_empty_practice_button_clicked",
}

export enum ProductTipEventWheres {
  WEBCLIENT_HOME = "webclient_home",
  POODLI_HOME = "poodli_home",
}

export enum SettingsPageAnalyticsEvents {
  VIEWED = "settings_page_viewed",
}

export enum PricingAnalyticEvents {
  // From LP
  PRICING_LP_CTA_CLICKED = "pricing_lp_cta_clicked",

  // From Pricing Modal
  PRICING_PLAN_MODAL_VIEWED = "pricing_plan_modal_viewed",
  PRICING_PLAN_MODAL_UPGRADE_BUTTON_CLICKED = "pricing_plan_modal_upgrade_button_clicked",
  PRICING_PLAN_MODAL_DOWNGRADE_BUTTON_CLICKED = "pricing_plan_modal_downgrade_button_clicked",

  // From Plan & Billing
  SETTINGS_PRICING_PLAN_CANCEL_SCHEDULED_CHANGE_CLICKED = "settings_pricing_plan_cancel_scheduled_change_clicked", //
  SETTINGS_PRICING_PLAN_UPGRADE_BUTTON_CLICKED = "settings_pricing_plan_upgrade_button_clicked", //

  // Plan Change Complete
  PRICING_PLAN_UPGRADE_COMPLETE = "pricing_plan_upgrade_complete",
  PRICING_PLAN_DOWNGRADE_COMPLETE = "pricing_plan_downgrade_complete",
  PRICING_PLAN_CANCEL_SCHEDULED_CHANGE_COMPLETE = "pricing_plan_cancel_scheduled_change_complete", //

  // From Poodli
  PRICING_POODLI_UPGRADE_BUTTON_CLICKED = "pricing_poodli_upgrade_button_clicked",

  PRICING_REIMBURSEMENT_LETTER_CLICKED = "pricing_reimbursement_letter_clicked",

  PLAN_SWITCHED = "plan_switched",
  NAV_PRICING_PROMO_CODE_CLICKED = "nav_pricing_promo_code_clicked",
}

export enum PricingAnalyticEvents {
  PRICING_PLAN_RECORDING_MAX_HIT = "pricing_plan_recording_max_hit",
  PRICING_PLAN_RECORDING_MAX_ALMOST_HIT = "pricing_plan_recording_max_almost_hit",
  PRICING_PLAN_RECORDING_QUOTA_CHANGED = "pricing_plan_recording_quota_changed",
}

export enum RecordingProcessingEvents {
  SPEECHREC_PROCESSING_COMPLETE = "speechrec_processing_complete",
  POODLI_PROCESSING_COMPLETE = "poodli_processing_complete",
  ZOODLI_PROCESSING_COMPLETE = "zoodli_processing_complete",
  FILE_UPLOAD_PROCESSING_COMPLETE = "file_upload_processing_complete",
}

export const speechSourceToRecordingProcessingEvents = {
  LIVE: RecordingProcessingEvents.SPEECHREC_PROCESSING_COMPLETE,
  CHROME_EXTENSION: RecordingProcessingEvents.SPEECHREC_PROCESSING_COMPLETE,
  POODLI: RecordingProcessingEvents.POODLI_PROCESSING_COMPLETE,
  ZOOM_RECALL: RecordingProcessingEvents.ZOODLI_PROCESSING_COMPLETE,
  UPLOADED: RecordingProcessingEvents.FILE_UPLOAD_PROCESSING_COMPLETE,
};

export enum ZoodliAnalyticsEvents {
  MANUAL_JOIN = "zoodli_manual_join",
  STOP = "zoodli_stop",
  INVITE_AGAIN = "zoodli_invite_bot_again",
  ZOODLI_START_CAL = "zoodli_start_cal",
}

export enum PoodliOnboardingEvents {
  POODLI_COACHING_TAB_CHECKLIST_DEMO_VIDEO_CLICKED = "poodli_coaching_tab_checklist_demo_video_clicked",
  POODLI_COACHING_TAB_CHECKLIST_POODLI_START_CLICKED = "poodli_coaching_tab_checklist_poodli_start_clicked",
  POODLI_DEMO_VIDEO_MODAL_VIEWED = "poodli_demo_video_modal_viewed",
  POODLI_DEMO_VIDEO_MODAL_CLOSED = "poodli_demo_video_modal_closed",
  POODLI_DEMO_VIDEO_PLAYED = "poodli_demo_video_played",
  POODLI_DEMO_VIDEO_PAUSED = "poodli_demo_video_paused",
  POODLI_DEMO_VIDEO_SEEKED = "poodli_demo_video_seeked",
  POODLI_DEMO_VIDEO_PLAYBACK_SPEED_CHANGED = "poodli_demo_video_playback_speed_changed",
  POODLI_LIVE_COACHING_FTUX_INSTRUCTION_VIEW_CLOSED = "poodli_live_coaching_ftux_instruction_view_closed",
  POODLI_FIRST_CONGRATS_CONNECT_CAL_CLICKED = "poodli_first_congrats_connect_cal_clicked",
  POODLI_FIRST_CONGRATS_DISMISSED = "poodli_first_congrats_dismissed",
}

export enum DataRedactionEvents {
  USER_DATA_REDACTION_DAYS_UPDATED = "user_data_redaction_days_updated",
  ORG_DATA_REDACTION_DAYS_UPDATED = "org_data_redaction_days_updated",
}

export enum EventsCalHows {
  GOOGLE = "google",
  OUTLOOK = "outlook",
}

export enum EventsCalWheres {
  HOME = "home",
  ONBOARDING = "onboarding",
  POODLI = "poodli",
  SETTINGS = "settings",
  MEETINGS = "meetings",
}

export enum EventsRecordWheres {
  SPEECH_SUMMARY = "speechsummary", // This should be snake_case, leaving for historical reasons
  SIDENAV = "sidenav",
  APPBAR = "appbar",
  POODLI = "poodli",
}

export enum EventsPoodliWheres {
  RECORDING = "recording",
  MAIN = "main",
  SETTINGS = "settings",
  TALKING_POINTS_TAB = "poodli_talking_points_tab",
}

export enum TalkingPointsInRecordingState {
  AD_HOC = "ad_hoc",
  TEMPLATE = "template",
  NONE = "none",
}

export enum EventsJobDescriptionUploadHows {
  UPLOAD = "upload",
  LINK = "link",
}

export enum EventsOnboardingSlideHows {
  OBQ1_OPTION_SELECTED = "obq1_option_selected",
  CLICK = "click",
  KEYBOARD = "keyboard",
  SWIPE = "swipe",
}

export enum EventsNotificationTypes {
  MEETING_STARTED = "meeting_started",
  MEETING_ENDING = "meeting_ending",
  UPCOMING_MEETING = "upcoming_meeting",
  AUTO_DELETE = "auto_delete",
  MEETING_ENDING_EARLY = "meeting_ending_early",
  MEETING_DETECTED = "meeting_detected",
  UNKNOWN = "unknown_type",
}

export enum RecurringEventSettings {
  THIS_EVENT = "this_event",
  THIS_AND_FOLLOWING_EVENTS = "this_and_following_events",
  ALL_EVENTS = "all_events",
  NA = "na",
}

export enum PoodliCoachingQuickInsights {
  QUANTITATIVE_ANALYTICS = "quantitative_analytics",
  FOLLOW_UP_QUESTIONS = "follow_up_questions",
  SENTENCE_STARTER = "sentence_starter",
  CONCISENESS = "conciseness",
}

export enum PoodliOpenItemsCtas {
  CONNECT_CAL = "connect_cal",
  DISMISS_CALENDAR = "dismiss_calendar",
  RESTART_YOODLI = "restart_yoodli",
  REQUEST_COACH = "request_coach",
  DISMISS_COACH = "dismiss_coach",
  CREATE_TALKING_POINT = "create_talking_point",
  SCREEN_SHARE = "screen_share",
}

export enum PricingModalCtaLocations {
  SPEECH_RECORDER = "speech_recorder",
  SPEECH_RECORDER_THRESHOLD_REACHED_MODAL = "threshold_reached_modal",

  PRACTICE_RECORDER = "practice_recorder",
  PRACTICE_RECORDER_THRESHOLD_REACHED_MODAL = "practice_recorder_threshold_reached_modal",

  UPLOAD = "upload",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  UPLOAD_THRESHOLD_REACHED_MODAL = "threshold_reached_modal",

  SIDE_NAV = "side_nav",
  PLAN_AND_BILLING = "plan_and_billing",
  ZOODLI = "zoodli", // "Yoodli for your team" section on Home Page
}

export enum PoodliUpgradeCtaLocations {
  MAIN_WINDOW_PRIMARY_CTA = "main_window_primary_cta",
  MAIN_WINDOW_BANNER = "main_window_banner",
  NOTIF_WINDOW = "notif_window",
}

export enum PricingReimbursementLetterLinkLocation {
  LP = "lp",
  WEBCLIENT_PRICING_MODAL = "webclient_pricing_modal",
}

export enum PoodliDownloadedWheres {
  LANDING_PAGE_BANNER = "landingpage_banner",
  LANDING_PAGE_MAIN_CONTENT = "landingpage_main_content",
  ONBOARDING = "onboarding",
  SETTINGS = "settings",
  SPEECH_SUMMARY = "speech_summary",
  HOME_PAGE = "home_page",
  LIBRARY_PAGE = "library_page",
  YOODLI_ONBOARDING_CHECKLIST = "yoodli_onboarding_checklist",
}

export enum EventHubsWheres {
  HUBS = "hubs",
  ORG_SETTINGS = "org_settings",
  ORG_MANAGE_CONTENT = "org_manage_content",
  SCENARIO_WIZARD = "scenario_wizard",
}

export enum ProgramMemberNextStepCtaWheres {
  HOME = "home",
  LEARNING = "learning",
}

export enum EndUserScenarioCreationWheres {
  PRACTICE = "practice",
  BUILDER = "builder",
}

export enum OrgInviteTypes {
  EMAIL = "email",
  LINK = "link",
  CSV = "csv",
}

export enum OrgCoachbotType {
  DEFAULT = "default",
  SCENARIO = "scenario",
}

export enum OrgReportWheres {
  ORG = "org",
  GROUP = "group",
  PROGRAM = "program",
  SCENARIO = "scenario",
}

export enum AnalyticsProperties {
  ORG_ID = "org_id",
  USER_ID = "user_id",
}

export enum GeneralAnalyticsEvents {
  TALK_TO_SALES_NUDGE_CLICKED = "talk_to_sales_nudge_clicked",
}

export enum WhatsNewAnnouncementEvents {
  POPUP_VIEWED = "whats_new_popup_viewed",
  POPUP_CLICKED = "whats_new_popup_clicked",
  POPUP_DISMISSED = "whats_new_popup_dismissed",
  BANNER_VIEWED = "whats_new_banner_viewed",
  BANNER_CLICKED = "whats_new_banner_clicked",
  BANNER_DISMISSED = "whats_new_banner_dismissed",
}

export enum LMSActivityEvents {
  PRACTICE_ACTIVITY_RETRY = "practice_activity_retry",
  PRACTICE_ACTIVITY_COMPLETE = "practice_activity_complete",
}
export enum WhatsNewAnnouncementType {
  USER = "user",
  ADMIN = "admin",
}

export type ProductAnalyticEvents =
  | AnalyticsAnalyticEvents
  | AuthAnalyticsEvents
  | CalendarAnalyticsEvents
  | CommentAnalyticsEvents
  | DashboardAnalyticsEvents
  | DataRedactionEvents
  | EndUserScenarioAnalyticsEvents
  | GeneralAnalyticsEvents
  | HomePageChecklistEvents
  | HomePageProductTipEvents
  | HomePageReportCardEvents
  | HubAnalyticEvents
  | InterviewAnalyticsEvents
  | KeyActionAnalyticsEvents
  | LibrarySnackbarAnalyticsEvents
  | LMSActivityEvents
  | NavigationAnalyticsEvents
  | OnboardingAnalyticsEvents
  | OrgAnalyticsEvents
  | OrgProgramsAnalyticsEvents
  | PoodliAnalyticsEvents
  | PoodliOnboardingEvents
  | PracticeRecorderAnalyticsEvents
  | PricingAnalyticEvents
  | RecordingAnalyticsEvents
  | SelfConfidenceEvalAnalyticsEvents
  | SettingsPageAnalyticsEvents
  | SpeechSummaryAnalyticsEvents
  | WhatsNewAnnouncementEvents
  | ZoodliAnalyticsEvents;
