// Components
import { Stack, Button, SxProps } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

type YoodliHeaderTabsProps = {
  selectedTab: string;
  allTabs: string[];
  handleTabChange: (tab: string) => void;
  tabLabels: Record<string, string>;
  containerSx?: SxProps;
  color?: string;
};

const getTabLabelStyles = (
  label: string,
  isSelected: boolean,
  color = getDynamicColor("light1")
) => {
  return {
    py: 0.5,
    px: 1,
    textAlign: "left",
    color: color,
    borderRadius: 0,
    borderBottom: "3px solid",
    borderColor: isSelected ? color : "transparent",
    whiteSpace: "nowrap",
    fontWeight: 400,
    fontFamily: "Poppins",
    transition: "none",
    fontSize: 14,
    // prevent changing the fontWeight from causing the button width to change, shifting it's neighbors position
    "&::after": {
      position: "absolute",
      display: "block",
      content: `"${label}"`,
      fontWeight: 500,
      height: 1,
      py: 0.5,
      px: 1,
      color,
      overflow: "hidden",
      visibility: isSelected ? "visible" : "hidden",
    },
  };
};

export default function YoodliHeaderTabs({
  selectedTab,
  allTabs,
  handleTabChange,
  tabLabels,
  color,
  containerSx,
}: YoodliHeaderTabsProps): JSX.Element {
  return (
    <Stack
      direction="row"
      sx={{
        gap: { xs: 2, sm: 3, md: 4 },
        px: { xs: 2, md: 3, lg: 4, xl: 5 },
        width: "100%",
        backgroundColor: getDynamicColor("purple3"),
        overflowX: "auto",
        ...containerSx,
      }}
    >
      {allTabs.map((tab) => {
        return (
          <Button
            key={tab}
            onClick={() => handleTabChange(tab)}
            sx={{
              ...getTabLabelStyles(tabLabels[tab], selectedTab === tab, color),
            }}
          >
            {tabLabels[tab]}
          </Button>
        );
      })}
    </Stack>
  );
}
