import { getSsoOptions } from "lib-frontend/modules/AxiosInstance";
import { SsoOption } from "lib-fullstack/api/authApiTypes";
import { SsoPopupRedirectOption } from "lib-fullstack/utils/enums/ssoPopupRedirectOption";

/**
 * Parameter set which represents a Firebase auth provider,
 * both built-in providers (Google, Microsoft) and custom SSO providers (SAML).
 * This is a subset of database SsoOption + a flag to show only this provider or not.
 */
export type AuthProviderConfig = {
  /**
   * If true, UI should render this SSO option only
   * If false, UI should render together with other options.
   * This is ignored for built-in providers.
   */
  showOnlyThis: boolean;
  /* Firebase auth provider ID (name) */
  firebaseId: string;
  /* User visible name */
  displayName: string;
  /**
   * Option for SSO pop-up or redirect behavior during sign-in.
   */
  popupRedirectOption: SsoPopupRedirectOption;
};

export const googleProviderConfig: AuthProviderConfig = {
  /** This is ignored */
  showOnlyThis: false,
  /**
   * This is not a real provider ID on Firebase/GCP.
   * It is still used to identify Google SSO in our code.
   */
  firebaseId: "google",
  displayName: "Google",
  popupRedirectOption: SsoPopupRedirectOption.USE_REDIRECT,
};

export const microsoftProviderConfig: AuthProviderConfig = {
  /** This is ignored */
  showOnlyThis: false,
  /**
   * This is not a real provider ID on Firebase/GCP.
   * It is still used to identify Microsoft SSO in our code.
   */
  firebaseId: "microsoft",
  displayName: "Microsoft",
  popupRedirectOption: SsoPopupRedirectOption.USE_REDIRECT,
};

/**
 * Create a new ProviderConfig from sso_options in API response.
 * If the response has an entry, return a new ProviderConfig.
 * If the response has no entry, return null.
 * If the response has 2+ entries, throw an error because API supports it
 * but database should not have such state under current code assumption.
 * @param ssoOptions API response about SSO options
 */
export function createProviderConfigFromSsoOptions(
  ssoOptions: SsoOption[] | null,
  showOnlyThis: boolean
): AuthProviderConfig | null {
  if (ssoOptions?.length > 1) {
    throw new Error("Multiple SSO options are not supported");
  } else if (ssoOptions?.length == 1) {
    const sso = ssoOptions[0];
    return {
      showOnlyThis: showOnlyThis,
      firebaseId: sso.firebase_id,
      displayName: sso.display_name,
      popupRedirectOption: sso.popup_redirect_option,
    };
  } else {
    return null;
  }
}

/**
 * Use by sign-in page to get SSO option based on Firebase ID.
 * This is for test purposes only and real production code does not use this.
 * showOnlyThis is set to true
 */
export async function getProviderConfigFromFirebaseId(
  firebaseId: string
): Promise<AuthProviderConfig | null> {
  const response = await getSsoOptions({ firebaseId: firebaseId });
  if (response.sso_options.length > 1) {
    throw new Error("Multiple SSO options are not supported");
  }
  if (response.sso_options.length == 1) {
    return {
      showOnlyThis: true,
      firebaseId: response.sso_options[0].firebase_id,
      displayName: response.sso_options[0].display_name,
      popupRedirectOption: response.sso_options[0].popup_redirect_option,
    };
  } else {
    return null;
  }
}
