import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import "firebase/analytics";

// Utilities/Functions
import { LOGIN_SESSION_ACTIVE } from "../utils/Constants";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { getWebServerExternalUrl, WebServerExternalPath } from "lib-fullstack/utils/paths";
import { signOutEverywhere } from "lib-frontend/modules/AxiosInstance";
import { AuthQueryParams } from "lib-fullstack/utils/queryParams";

/**
 * Gets current user ID (if any) from Firebase. Returns `undefined`
 * if none.
 */
export function getCurrentUserId(): string | undefined {
  return firebase.auth().currentUser?.uid;
}

/**
 * Sign out from our application and firebase
 * @userInOrg - whether the user is in an org
 * @orgId - users current (default) org id, if they're in an org
 * @param signOutEverywhere - Whether to sign out from all devices
 */
export const doLogout = async (
  userInOrg: boolean,
  orgId: string,
  shouldSignOutEverywhere: boolean
): Promise<void> => {
  const clientEnv = getClientEnvConfig();

  if (shouldSignOutEverywhere) {
    await signOutEverywhere();
  }

  return firebase
    .auth()
    .signOut()
    .then(() => {
      let urlAppend = "";
      if (userInOrg && orgId) {
        const urlParams = new URLSearchParams();
        urlParams.append(AuthQueryParams.ORG_ID, orgId);
        urlAppend = `?${urlParams.toString()}`;
      }

      localStorage.removeItem(LOGIN_SESSION_ACTIVE);
      window.location.href = isToastmasters()
        ? `${clientEnv.url.WEB_SERVER.replace("app", "toastmasters")}${
            WebServerExternalPath.SIGN_IN
          }`
        : `${getWebServerExternalUrl(clientEnv, WebServerExternalPath.SIGN_IN)}${urlAppend}`;
      console.log("User logged out.");
    });
};
