import { ProductAnalyticEvents } from "lib-fullstack/utils/productAnalyticEvents";
import { ALL_BILLABLE_ACTIONS } from "lib-fullstack/utils/billableActions";
import firebase from "firebase/app";
import { patchUser } from "lib-frontend/modules/AxiosInstance";

/**
 * Minimum interval between updates to the user's last activity.
 */
export const UPDATE_LAST_ACTIVITY_INTERVAL_MS = 10 * 60 * 1000; // 10 min

/**
 * Utility class for billable actions.
 */
export class BillableAction {
  /**
   * Last event date (in milliseconds) for the current user.
   */
  static lastEventDate = 0;

  /**
   * Check to see if a given action is billable.
   * @param action Action to check.
   * @returns True if billable, false otherwise.
   */
  static isBillableAction(action: ProductAnalyticEvents): boolean {
    return ALL_BILLABLE_ACTIONS.has(action);
  }

  /**
   * Update the user's last activity if necessary.
   * @param now Current time in milliseconds.
   * @param user User to update.
   * @param action Action that triggered the update.
   * @returns True if the user's last activity was updated, false otherwise.
   */
  static async updateUserLastActivity(
    now: number,
    user: firebase.User,
    action: ProductAnalyticEvents
  ): Promise<boolean> {
    // If we don't have a user or their email is not verified, take no action.
    if (!user || !user.emailVerified) {
      return false;
    }

    // If the action is not billable, take no action.
    if (!BillableAction.isBillableAction(action)) {
      return false;
    }

    const userLastEventDate = BillableAction.lastEventDate;

    // If the last update for this user was less than the update interval,
    // take no action.
    if (userLastEventDate > 0 && now < userLastEventDate + UPDATE_LAST_ACTIVITY_INTERVAL_MS) {
      return false;
    }

    try {
      await patchUser({ date_last_activity: "", activity_action: action });
      // Update last event date for this user.
      BillableAction.lastEventDate = now;
    } catch (err) {
      console.log("Failed to update user dateLastActivity", err);
    }

    return true;
  }
}
