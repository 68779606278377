import { ReactElement } from "react";
import { useNavigate } from "react-router";

// Components
import { Typography, Button, Stack, CircularProgress } from "@mui/material";
import { VIDEO_GRID_HEADER_HEIGHT } from "components/VideoJournal/VideoGridHeader";
import { JournalView } from "components/VideoJournal/VideoListing";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getLiveUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { getIntFromString } from "lib-frontend/utils/Utilities";
import { OBQ1Option } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { PracticeRecorderQueryParams } from "lib-fullstack/utils/queryParams";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";

export default function NoSpeechesYetScreen({
  journalView,
  simpleTable,
  loading,
}: {
  journalView: string;
  simpleTable?: boolean;
  loading?: boolean;
}): ReactElement {
  const navigate = useNavigate();

  const obq1Answer = getLiveUserDocMain()?.onboardingAnswers?.eventsFocus;

  let ctaCopy;
  let ctaClickHandler;
  if (obq1Answer === OBQ1Option.INTERVIEW) {
    ctaCopy = "interview";
    ctaClickHandler = () => navigate(WebServerExternalPath.PRACTICE_INTERVIEW);
  } else if (obq1Answer === OBQ1Option.MEETINGS || obq1Answer === OBQ1Option.EXTERNAL_DOWNLOAD) {
    ctaCopy = "conversation";
    ctaClickHandler = () => navigate(WebServerExternalPath.PRACTICE_CONVERSATION);
  } else if (obq1Answer === OBQ1Option.COACH) {
    ctaCopy = "speech";
    ctaClickHandler = () => navigate(WebServerExternalPath.PRACTICE_SPEECH);
  } else if (obq1Answer === OBQ1Option.SALES) {
    ctaCopy = "conversation";
    ctaClickHandler = () =>
      navigate({
        pathname: WebServerExternalPath.PRACTICE_CONVERSATION,
        search: new URLSearchParams({
          [PracticeRecorderQueryParams.TOPIC]: OBQ1Option.SALES,
        }).toString(),
      });
  } else {
    ctaCopy = "presentation";
    ctaClickHandler = () => navigate(WebServerExternalPath.PRACTICE_SPEECH);
  }

  const renderNoSpeechesYetContent = () => {
    switch (journalView) {
      case JournalView.SharedWithMe:
      case JournalView.Library:
        return (
          <>
            <Typography sx={{ fontWeight: 700, color: getDynamicColor("dark5") }}>
              Your {journalView === JournalView.SharedWithMe ? "shared " : ""}library is empty
            </Typography>
            <Typography sx={{ fontWeight: 400, color: getDynamicColor("dark5") }}>
              Record your first practice {ctaCopy}
            </Typography>
            <Button
              variant="contained"
              onClick={ctaClickHandler}
              sx={{ fontSize: "16px", px: 3, mt: 2.5 }}
            >
              Start practice
            </Button>
          </>
        );
      case JournalView.SharedWithOrg:
        return (
          <>
            <Typography sx={{ fontWeight: 700, color: getDynamicColor("dark5") }}>
              This user hasn't shared any speeches with your organization yet
            </Typography>
          </>
        );
      case JournalView.Grading:
        return (
          <Typography sx={{ fontWeight: 700, color: getDynamicColor("dark5") }}>
            You currently have no scenarios to grade
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <Stack
      sx={{
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        mt: 0,
        ml: 0,
        width: "100%",
        py: { xs: 4, md: 6 },
        ...(!simpleTable && {
          height: {
            xs: `calc(100vh - ${
              getIntFromString(VIDEO_GRID_HEADER_HEIGHT) +
              getIntFromString(WEBCLIENT_TOP_NAVBAR_HEIGHT) +
              32
            }px)`,
            md: `calc(100vh - ${getIntFromString(VIDEO_GRID_HEADER_HEIGHT) + 62}px)`,
          },
        }),
        border: `1px solid ${getDynamicColor("dark3")}`,
      }}
      gap={1.5}
    >
      {loading ? <CircularProgress /> : renderNoSpeechesYetContent()}
    </Stack>
  );
}
