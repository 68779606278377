import React from "react";

// Components
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  InfoOutlined as InfoIcon,
} from "@mui/icons-material";
import { Box, Checkbox, MenuItem, Stack, Typography } from "@mui/material";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Assets
import { ReactComponent as BarChartIcon } from "images/icons/bar-chart-square.svg";
import { ReactComponent as RadarChartIcon } from "images/icons/radar-chart.svg";

// Utils
import { OrgProgramsQueryKeys } from "../OrgPrograms";
import { OVERVIEW_SECTION_SUBTITLE_SX, OVERVIEW_SECTION_TITLE_SX } from "../ProgramOverview";
import { GoalImprovementChart } from "./GoalImprovementChart";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { listReports } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { DashboardType, ReportParameter, ReportState } from "lib-fullstack/utils/enums";

export enum GoalImprovementChartType {
  Radar = "Radar",
  Bar = "Bar",
}

export const GOAL_CHART_LEGEND_BOX_SIZE = 20;
export const MIN_BAR_CHART_HEIGHT = 200;
export const MIN_RADAR_CHART_HEIGHT = 300;

const MAX_GOALS_ALLOWED = 10;

const LEGEND_TEXT_SX = {
  color: getDynamicColor("purple3"),
  fontSize: 12,
  fontWeight: 400,
  whiteSpace: "nowrap",
};

export const GoalImprovementCard = ({ programId }: { programId: string }): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const [chartType, setChartType] = React.useState<GoalImprovementChartType>(
    GoalImprovementChartType.Radar
  );
  const [selectedGoalIds, setSelectedGoalIds] = React.useState<string[]>([]);
  const [selectedGoalIdsOpen, setSelectedGoalIdsOpen] = React.useState<boolean>(false);

  const reportsQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.ProgramDashboardReports, defaultOrgId, programId],
    queryFn: async () => {
      try {
        const reports = await listReports(defaultOrgId, {
          reportType: DashboardType.ProgramOverallImprovement,
          states: [ReportState.Rendered],
          parameters: JSON.stringify({ [ReportParameter.ProgramId]: programId }),
        });
        return reports;
      } catch (e) {
        console.log("Error fetching goal improvement reports inn ProgramOverview", e);
      }
    },
    enabled: !!defaultOrgId,
  });

  const parsedRecentReport = React.useMemo(() => {
    const reportWithData = reportsQuery?.data?.reports?.find((report) => report.data);
    if (!reportWithData) {
      return null;
    }
    return {
      ...reportWithData,
      data: JSON.parse(reportWithData?.data ?? ""),
    };
  }, [reportsQuery?.data]);

  const goalSelectorOptions = React.useMemo(() => {
    if (parsedRecentReport?.data?.length) {
      return parsedRecentReport?.data.map((goal) => {
        return {
          label: goal.goalName,
          value: goal.goalId,
        };
      });
    }
    return [];
  }, [parsedRecentReport?.data]);

  const preSelectedGoalsSet = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (!reportsQuery?.isLoading && parsedRecentReport?.data?.length) {
      const preSelectedGoals = parsedRecentReport?.data.slice(
        0,
        Math.min(5, parsedRecentReport?.data.length)
      );
      setSelectedGoalIds(preSelectedGoals.map((goal) => goal.goalId));
      preSelectedGoalsSet.current = true;
      if (preSelectedGoals.length < 3) {
        setChartType(GoalImprovementChartType.Bar);
      }
    }
  }, [parsedRecentReport?.data, reportsQuery?.isLoading]);

  // switch to bar if the goals ever fall below 3
  React.useEffect(() => {
    if (preSelectedGoalsSet.current && selectedGoalIds.length < 3) {
      setChartType(GoalImprovementChartType.Bar);
    }
  }, [selectedGoalIds]);

  const subTitle =
    "Compare the average of your members’ first score with their average last score to see how they’ve improved in their goals";
  const placeholder = "placeholder";
  const hasGoalsValue = "hasGoalsValue";

  const renderChartTypeSelector = () => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        flexBasis="50%"
        gap="2px"
        sx={{
          border: `1px solid ${getDynamicColor("dark2")}`,
          borderRadius: "3px",
          backgroundColor: getDynamicColor("light2"),
          maxWidth: 80,
          ml: "auto",
          minHeight: 36,
          maxHeight: 36,
          position: "relative",
          zIndex: 2,
          svg: {
            display: "block",
          },
          ".chart-option": {
            width: 38,
            height: 38,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <Box
          sx={{
            minHeight: 38,
            minWidth: 40,
            border: `1px solid ${getDynamicColor("purple2")}`,
            borderRadius: "3px",
            position: "absolute",
            top: -2,
            left: chartType === GoalImprovementChartType.Radar ? -1 : 39,
            transition: "all 0.3s ease-out",
            "&::before": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              borderRadius: "3px",
              backgroundColor: getDynamicColor("light1"),
              mixBlendMode: "overlay",
              boxShadow: Y_SHADOWS.chart_type_selector,
            },
          }}
        />
        <YoodliTooltip
          enterDelay={250}
          title={
            selectedGoalIds.length < 3
              ? "You must have at least 3 goals selected to view data as a radar chart"
              : ""
          }
        >
          <Box
            className="chart-option"
            onClick={() => {
              if (selectedGoalIds.length >= 3) {
                setChartType(GoalImprovementChartType.Radar);
              }
            }}
            sx={{
              cursor: selectedGoalIds.length < 3 ? "default" : "pointer",
              color:
                chartType === GoalImprovementChartType.Radar
                  ? getDynamicColor("primary")
                  : getDynamicColor(selectedGoalIds.length >= 3 ? "purple3" : "dark3"),
            }}
          >
            <RadarChartIcon />
          </Box>
        </YoodliTooltip>
        <Box
          className="chart-option"
          onClick={() => {
            setChartType(GoalImprovementChartType.Bar);
          }}
          sx={{
            cursor: "pointer",
            color:
              chartType === GoalImprovementChartType.Bar
                ? getDynamicColor("primary")
                : getDynamicColor("purple3"),
          }}
        >
          <BarChartIcon />
        </Box>
      </Stack>
    );
  };

  const renderCustomLegend = () => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={{ xs: "start", lg: "space-evenly" }}
        flexBasis="70%"
        flexWrap="wrap"
        gap={1}
        sx={{
          fontFamily: "poppins",
        }}
      >
        <YoodliTooltip title="The average first score achieved for all users that have practiced each goal">
          <Stack direction="row" alignItems="center" gap={1}>
            <Box
              sx={{
                height: GOAL_CHART_LEGEND_BOX_SIZE,
                width: GOAL_CHART_LEGEND_BOX_SIZE,
                backgroundColor: getDynamicColor("programChart.purple"),
                border: `1px solid ${getDynamicColor("programChart.purpleDark")}`,
              }}
            />
            <Typography sx={LEGEND_TEXT_SX}>First score</Typography>
          </Stack>
        </YoodliTooltip>
        <YoodliTooltip title="The average maximum score achieved for all users that have practiced each goal">
          <Stack direction="row" alignItems="center" gap={1} flexBasis="30%">
            <Box
              sx={{
                height: GOAL_CHART_LEGEND_BOX_SIZE,
                width: GOAL_CHART_LEGEND_BOX_SIZE,
                backgroundColor: getDynamicColor("programChart.blue"),
                border: `1px solid ${getDynamicColor("programChart.blueDark")}`,
              }}
            />
            <Typography sx={LEGEND_TEXT_SX}>Max score</Typography>
          </Stack>
        </YoodliTooltip>
      </Stack>
    );
  };

  const renderChart = () => {
    if (!selectedGoalIds.length) {
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "relative",
            width: "100%",
            minHeight:
              chartType === GoalImprovementChartType.Bar
                ? MIN_BAR_CHART_HEIGHT
                : MIN_RADAR_CHART_HEIGHT,

            pb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              px: 4,
              height: "100%",
              width: "100%",
              flexGrow: 1,
              backgroundColor: getDynamicColor("dark1", 0.25),
              borderRadius: "8px",
              border: `1px solid ${getDynamicColor("purple1")}`,
            }}
          >
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                textAlign: "center",
                fontSize: { xs: 12, md: 14 },
              }}
            >
              Select a goal to see improvement metrics
            </Typography>
          </Box>
        </Stack>
      );
    }
    return (
      <GoalImprovementChart
        goals={selectedGoalIds}
        data={parsedRecentReport?.data}
        chartType={chartType}
      />
    );
  };

  return (
    <Stack
      gap={{ xs: 3, lg: 5 }}
      direction={{ xs: "column", lg: "row" }}
      sx={{
        p: 3,
        flex: 1,
        borderRadius: "8px",
        border: `1px solid ${getDynamicColor("dark3")}`,
        minHeight: { xs: "120px", md: "175px" },
        position: "relative",
        fontFamily: "poppins",
      }}
    >
      <Stack
        gap={2}
        sx={{
          flexBasis: "50%",
        }}
      >
        <Stack
          gap={1}
          direction={{ xs: "row", md: "column" }}
          alignItems={{ xs: "center", md: "unset" }}
        >
          <Typography sx={OVERVIEW_SECTION_TITLE_SX}>Overall goal improvement</Typography>

          {isSmallScreen ? (
            <YoodliTooltip title={subTitle}>
              <Box
                sx={{
                  height: 24,
                  width: 24,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InfoIcon
                  sx={{
                    color: getDynamicColor("dark5"),
                    height: 16,
                    width: 16,
                    display: "block",
                  }}
                />
              </Box>
            </YoodliTooltip>
          ) : (
            <Typography sx={OVERVIEW_SECTION_SUBTITLE_SX}>{subTitle}</Typography>
          )}
        </Stack>
        <YoodliSelect
          open={selectedGoalIdsOpen}
          onOpen={() => setSelectedGoalIdsOpen(true)}
          onClose={() => setSelectedGoalIdsOpen(false)}
          value={selectedGoalIds?.length ? hasGoalsValue : placeholder}
          renderValue={() => {
            return selectedGoalIds?.length ? (
              `${selectedGoalIds.length} goals selected`
            ) : (
              <Typography sx={{ width: "fit-content", py: 1 }}>
                Select up to {MAX_GOALS_ALLOWED} goals
              </Typography>
            );
          }}
          sx={{
            flexGrow: "unset",
          }}
        >
          <MenuItem
            key={hasGoalsValue}
            value={hasGoalsValue}
            sx={{
              display: "none",
            }}
          />
          <MenuItem
            key={placeholder}
            value={placeholder}
            sx={{
              display: "none",
            }}
          />

          {goalSelectorOptions?.length > 0 ? (
            goalSelectorOptions.map((goal) => {
              const maxGoalsReached = selectedGoalIds.length >= MAX_GOALS_ALLOWED;
              const isSelected = !!selectedGoalIds.find(
                (selectedGoalId) => selectedGoalId === goal.value
              );
              return (
                <MenuItem
                  key={goal.value}
                  value={goal.value}
                  disabled={maxGoalsReached && !isSelected}
                >
                  {/* put the click handler in a cusotm element so i can stop propagation here, preventing the menu from closing on selection */}
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    onClick={(e) => {
                      e.stopPropagation();

                      if (selectedGoalIds.find((selectedGoalId) => selectedGoalId === goal.value)) {
                        setSelectedGoalIds(
                          selectedGoalIds.filter((selectedGoalId) => selectedGoalId !== goal.value)
                        );
                      } else if (!maxGoalsReached) {
                        setSelectedGoalIds([...selectedGoalIds, goal.value]);
                      }
                    }}
                    sx={{
                      px: 1.5,
                      py: 0.75,
                      width: "100%",
                      color:
                        isSelected || !maxGoalsReached
                          ? getDynamicColor("purple3")
                          : getDynamicColor("dark4"),
                      cursor:
                        !maxGoalsReached || (isSelected && maxGoalsReached) ? "pointer" : "default",
                    }}
                  >
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      checked={
                        !!selectedGoalIds.find((selectedGoalId) => selectedGoalId === goal.value)
                      }
                    />
                    <Typography
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        lineHeight: 1.3,
                      }}
                    >
                      {goal.label}
                    </Typography>
                  </Stack>
                </MenuItem>
              );
            })
          ) : (
            <MenuItem
              value={"noGoalsAvailable"}
              sx={{
                pointerEvents: "none",
              }}
            >
              <Typography sx={{ width: "fit-content", py: 1 }}>No goals available</Typography>
            </MenuItem>
          )}
        </YoodliSelect>
      </Stack>
      <Stack
        sx={{
          flexBasis: "50%",
          minHeight: { xs: "unset", lg: 400 },
          position: "relative",
          mb: { xs: 2, lg: 0 },
        }}
        gap={2}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
          {renderCustomLegend()}
          {renderChartTypeSelector()}
        </Stack>
        {renderChart()}
      </Stack>
      <Typography
        sx={{
          color: getDynamicColor("dark4"),
          fontSize: 10,
          position: "absolute",
          left: { xs: 0, lg: 24 },
          bottom: { xs: 16, lg: 24 },
          width: { xs: "100%", lg: "fit-content" },
          textAlign: { xs: "center", lg: "left" },
        }}
      >
        Prepared nightly, last updated{" "}
        {getHumanReadableDate(parsedRecentReport?.renderDate, {
          month: "numeric",
          day: "numeric",
          year: "2-digit",
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        }).replace(",", " at")}
      </Typography>
    </Stack>
  );
};
