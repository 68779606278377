// Components
import { TableFilter, LabelledValue } from "./TableComponents/TableFilter";
import { TableSortServer } from "./TableComponents/TableSortServer";
import { Stack, Box, TablePagination, SxProps } from "@mui/material";

// Utils
import { flexRender, PaginationState, Table } from "@tanstack/react-table";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import {
  CheckboxTableElement,
  dataRowStyles,
  headerRowStyles,
  tableHeaderStyles,
  TableRow,
} from "lib-frontend/utils/sharedTableUtils";

type YoodliTableProps = {
  table: Table<TableRow>;
  emptyState: JSX.Element;
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  sortProps?: {
    sortAvailableColumns: string[];
    setSortFilter: (columnId: string) => void;
  };
  filterProps?: {
    filterAvailableColumns: string[];
    setFilterState: (columnId: string) => void;
    getFilterNames: (headerId) => string[] | LabelledValue[];
    anyFilterEnabled: boolean;
  };
  bulkActionProps?: {
    showBulkAction: boolean;
    allChecked: boolean;
    someChecked: boolean;
    onHeaderChecked: () => void;
    isRowItemChecked: (id: string) => boolean;
    handleRowItemChecked: (id: string, checked: boolean) => void;
  };
  dataRowSxOverride?: SxProps;
};

export const YoodliTable = ({
  table,
  emptyState,
  pagination,
  setPagination,
  sortProps,
  filterProps,
  bulkActionProps,
  dataRowSxOverride,
}: YoodliTableProps): JSX.Element => {
  const handlePageChange = (page: number) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: Math.max(page, 0) };
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: 0, pageSize: parseInt(event.target.value) };
    });
  };
  return (
    <Stack
      gap={1}
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          minHeight: "unset",
          overflowY: "auto",
          border: `1px solid ${getDynamicColor("dark2")}`,
          borderRadius: 2,
        }}
      >
        <table
          style={{
            width: "100%",
            boxShadow: Y_SHADOWS.dark_elevation,
            borderCollapse: "collapse",
            borderRadius: 2,
            borderSpacing: "0px",
          }}
        >
          <thead
            style={{
              backgroundColor: getDynamicColor("light1"),
              borderRadius: "8px",
              height: "40px",
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <Box component="tr" key={headerGroup.id} sx={headerRowStyles}>
                {bulkActionProps?.showBulkAction && (
                  <CheckboxTableElement
                    component="th"
                    checked={bulkActionProps.allChecked}
                    indeterminate={!bulkActionProps.allChecked && bulkActionProps.someChecked}
                    onChange={bulkActionProps.onHeaderChecked}
                  />
                )}
                {/* Sub headers */}
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    <Stack direction="row" gap={1} alignItems="center" sx={tableHeaderStyles}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {sortProps && sortProps.sortAvailableColumns.includes(header.id) && (
                        <TableSortServer
                          columnId={header.id}
                          setSortFilter={sortProps.setSortFilter}
                        />
                      )}
                      {filterProps && filterProps.filterAvailableColumns.includes(header.id) && (
                        <TableFilter
                          setFilterState={filterProps.setFilterState}
                          names={filterProps.getFilterNames(header.id)}
                          searchable={false}
                        />
                      )}
                    </Stack>
                    {/* Note: the reason the <TanbleFilters> are here instead of as a columnHelper is because when placed in a columnHelper,
                                      it would re-render the menu opening every time the filtering changed, which is undesirable. No re-render problems here.
                                  */}
                  </th>
                ))}
              </Box>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Box
                  component="tr"
                  key={row.id}
                  sx={{
                    ...dataRowStyles,
                    ...dataRowSxOverride,
                  }}
                >
                  {bulkActionProps?.showBulkAction && (
                    <CheckboxTableElement
                      component="td"
                      checked={bulkActionProps.isRowItemChecked(row.original.id)}
                      onChange={(e) =>
                        bulkActionProps.handleRowItemChecked(row.original.id, e.target.checked)
                      }
                    />
                  )}
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        color: getDynamicColor("purple3"),
                        fontFamily: "poppins",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </Box>
              );
            })}
            {table.getRowCount() === 0 && !filterProps?.anyFilterEnabled && emptyState}
          </tbody>
        </table>
      </Box>
      <Stack alignItems="center">
        {table.getPageCount() > 1 && (
          <TablePagination
            component="div"
            sx={{ m: "auto", fontFamily: "poppins !important" }}
            count={table.getRowCount() ?? 0}
            rowsPerPageOptions={[5, 10, 20, 50]}
            page={pagination.pageIndex}
            onPageChange={(e, pageNum) => handlePageChange(pageNum)}
            rowsPerPage={pagination.pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Stack>
    </Stack>
  );
};
