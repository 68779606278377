import firebase from "firebase/app";
import { db } from "lib-fullstack";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import {
  AuthAnalyticsEvents,
  KeyActionAnalyticsEvents,
  PracticeRecorderAnalyticsEvents,
} from "lib-fullstack/utils/productAnalyticEvents";

/**
 * All events that are logged to BigQuery.
 */
export const ALL_BIGQUERY_LOGGED_EVENTS = new Set<string>([
  AuthAnalyticsEvents.SIGN_IN,
  AuthAnalyticsEvents.SIGN_OUT,
  AuthAnalyticsEvents.SIGN_UP,
  KeyActionAnalyticsEvents.COMMENT,
  PracticeRecorderAnalyticsEvents.PRACTICE_RECORDER_RECORDING_SAVED,
]);

/**
 * Payload type when event_type is GA_EVENT.
 */
export type EventPayload = {
  eventName: string;
  eventParams?: { [k: string]: unknown };
};

/**
 * Utility class for logging events to BigQuery via Firestore buffer.
 */
export class BQStreamingLogger {
  /**
   * Uses firestore as a bus/buffer to stream events to BigQuery. This lets us,
   *  e.g., make sure the events are not lost due to adblockers, minimize latency,
   *  and have more control/redundancy.
   */
  static log(
    event_type: "GA_EVENT" | "USER_PROPERTIES",
    payload: EventPayload | firebase.analytics.CustomParams
  ): void {
    if (event_type === "GA_EVENT" && !ALL_BIGQUERY_LOGGED_EVENTS.has(payload.eventName)) {
      return;
    }

    const data = {
      ts: db.value("serverDate") as unknown as string, // will become string after insert
      userId: firebase.auth().currentUser?.uid,
      rowType: event_type,
      jsonPayload: JSON.stringify(payload),
      siteId: getSiteId("unknown-site-id"),
    };

    db.add(db.bqRows(getSiteId("unknown-site-id")), data).catch((error) => {
      try {
        const firebaseError = error as firebase.FirebaseError;
        if (firebaseError?.message?.indexOf("Document already exists") >= 0) {
          // this is an expected error on occasion, so surpress it
          console.log("BQStreamingLogger.log failed", error);
        } else {
          // if we don't recognize this error, still treat it as a full error as we may be losing analytics data
          console.error("BQStreamingLogger.log failed", error);
        }
      } catch (err2) {
        // if for some reason we fail to log/read the error, log a special error
        console.error("BQStreamingLogger.log failed to log", error, err2);
      }
    });
  }
}
