import { analyticsApiTypes, db } from "lib-fullstack";

// Utils
import { AuthorizationAPIHeaders } from "lib-frontend/modules/AxiosInstance";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { GetInterviewContextFromJobDescriptionResponse } from "lib-fullstack/api/analyticsApiTypes";

/**
 * @param gpt_prompt - the label of the prompt to be used for the GPT-3 model.
 * @param user_segment - the user's input to be included in the prompt
 **/
export const getUnstructuredPDF = async (url: string): Promise<string> => {
  const payload = { url };
  try {
    const headers = await AuthorizationAPIHeaders();
    const rephraseResponse = await fetch(
      `${getClientEnvConfig().url.PYTHON_SERVER}/analytics_api/authed/get_job_description`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(payload),
      }
    );
    const code = rephraseResponse.status;
    if (code !== 200) {
      return "We’ve encountered an error, please try again!";
    }
    return await rephraseResponse.text();
  } catch (er) {
    console.log("Error rephrasing", er);
  }
};

/**
 * @param gpt_prompt - the label of the prompt to be used for the GPT-3 model.
 * @param user_segment - the user's input to be included in the prompt
 **/
export const getGPTCompletion = async (
  gpt_prompt: string,
  user_segment: string
): Promise<string> => {
  const payload = { prompt: gpt_prompt, user_segment, site_id: getSiteId() };
  try {
    const headers = await AuthorizationAPIHeaders();
    const rephraseResponse = await fetch(
      `${getClientEnvConfig().url.PYTHON_SERVER}/analytics_api/authed/get_ai_suggestion`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(payload),
      }
    );
    const code = rephraseResponse.status;
    if (code !== 200) {
      return "We’ve encountered an error, please try again!";
    }
    return await rephraseResponse.text();
  } catch (er) {
    console.log("Error rephrasing", er);
  }
};

export const getGPTInterviewQuestion = async (
  prompts: db.SpeechPrompt[],
  responses: db.RevTranscriptElement[],
  persona: db.AIPersona,
  topic?: db.InterviewTopic
): Promise<string | "Server Error"> => {
  const payload: analyticsApiTypes.AIInterviewRequest = {
    prompts,
    responses,
    persona,
  };
  if (topic) {
    payload.topic = topic;
  } else {
    payload.topic = "general";
  }

  const rephraseResponse = await fetch(
    `${getClientEnvConfig().url.PYTHON_SERVER}/analytics_api/authed/get_ai_interview_question`,
    {
      method: "POST",
      headers: await AuthorizationAPIHeaders(),
      body: JSON.stringify(payload),
    }
  );
  const code = rephraseResponse.status;
  if (code !== 200) {
    return "Server Error";
  }
  return await rephraseResponse.text();
};

export const getGPTFirstInterviewQuestion = async (
  persona: db.AIPersona,
  topic: db.InterviewTopic
): Promise<string | "Server Error"> => {
  const payload: analyticsApiTypes.AIInterviewFirstQuestionRequest = {
    persona,
    topic,
  };
  const rephraseResponse = await fetch(
    `${
      getClientEnvConfig().url.PYTHON_SERVER
    }/analytics_api/authed/get_ai_interview_first_question`,
    {
      method: "POST",
      headers: await AuthorizationAPIHeaders(),
      body: JSON.stringify(payload),
    }
  );
  const code = rephraseResponse.status;
  if (code !== 200) {
    return "Server Error";
  }
  return await rephraseResponse.text();
};

export const getAIStoryRating = async (
  story: string[],
  aiStarts: boolean
): Promise<string | "Server Error"> => {
  const payload = {
    story,
    aiStarts,
  };
  const response = await fetch(
    `${getClientEnvConfig().url.PYTHON_SERVER}/analytics_api/authed/get_ai_story_rating`,
    {
      method: "POST",
      headers: await AuthorizationAPIHeaders(),
      body: JSON.stringify(payload),
    }
  );
  const code = response.status;
  if (code !== 200) {
    return "Server Error";
  }
  return await response.text();
};

export const getScenarioContextRephrase = async (
  originalText: string,
  scenarioId: string,
  templateDetails: string
): Promise<string | "Server Error"> => {
  const payload = {
    originalText: originalText ?? "",
    scenarioId,
    templateDetails,
  };
  const response = await fetch(
    `${getClientEnvConfig().url.PYTHON_SERVER}/analytics_api/authed/get_scenario_context_rephrase`,
    {
      method: "POST",
      headers: await AuthorizationAPIHeaders(),
      body: JSON.stringify(payload),
    }
  );
  const code = response.status;
  if (code !== 200) {
    return "Server Error";
  }
  return await response.text();
};

export const getGoalRephrase = async (
  originalText: string,
  rephraseType: "description" | "high" | "low",
  name: string,
  description: string
): Promise<string | "Server Error"> => {
  const payload = {
    originalText,
    rephraseType,
    name,
    description,
  };
  const response = await fetch(
    `${getClientEnvConfig().url.PYTHON_SERVER}/analytics_api/authed/get_goal_rephrase`,
    {
      method: "POST",
      headers: await AuthorizationAPIHeaders(),
      body: JSON.stringify(payload),
    }
  );
  const code = response.status;
  if (code !== 200) {
    return "Server Error";
  }
  return await response.text();
};

export const getInterviewContextFromJobDescription = async (
  fileUrl: string
): Promise<GetInterviewContextFromJobDescriptionResponse> => {
  const payload = {
    pdfURI: fileUrl,
    mimeType: "application/json",
  };
  const response = await fetch(
    `${getClientEnvConfig().url.PYTHON_SERVER}/analytics_api/authed/get_job_description`,
    {
      method: "POST",
      headers: await AuthorizationAPIHeaders(),
      body: JSON.stringify(payload),
    }
  );
  const code = response.status;
  if (code !== 200) {
    throw new Error("Failed to upload file");
  }
  return await response.json();
};
