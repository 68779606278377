import { ScenarioTypeIdEnum } from "lib-fullstack/db";
import { ScenarioType } from "lib-fullstack/utils/enums";

const interviewScenarios = new Set([
  ScenarioTypeIdEnum.BEHAVIORAL_INTERVIEW,
  ScenarioTypeIdEnum.TECHNICAL_INTERVIEW,
]);

// a mapping between a scenario type id and the actual scenario type
export const scenarioTypeMap: Record<ScenarioTypeIdEnum, ScenarioType> = Object.values(
  ScenarioTypeIdEnum
).reduce(
  (acc, id) => {
    acc[id] = interviewScenarios.has(id) ? ScenarioType.Interview : ScenarioType.Roleplay;
    return acc;
  },
  {} as Record<ScenarioTypeIdEnum, ScenarioType>
);

/**
 * Get the default email branding messages for an organization.
 * @param orgName Organization name. If undefined, defaults to "Yoodli".
 * @param orgOwnerEmail Organization owner email. If undefined, defaults to "support@yoodli.ai".
 * @returns Tuple of the default welcome and goodbye messages.
 */
export const getDefaultOrgEmailBrandingMessages = (
  orgName: string,
  orgOwnerEmail: string,
  isCoach: boolean
): { welcomeMessage: string; goodbyeMessage: string } => {
  const safeOrgName = orgName ?? "Yoodli";
  const safeOrgOwnerEmail = orgOwnerEmail ?? "support@yoodli.ai";
  return {
    welcomeMessage: `Use ${safeOrgName} AI Coach ${
      isCoach ? "" : "(powered by Yoodli)"
    } to practice your speaking skills and see insights (all automated!) on your talking points, content, and learning path rubric.`,
    goodbyeMessage: `Thanks for using ${safeOrgName}! You no longer have access to this organization. Please contact ${safeOrgOwnerEmail} if you believe this message was sent in error.`,
  };
};
