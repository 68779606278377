/**
 * Program API types
 */
import {
  Array as RTArray,
  Union,
  Optional,
  Null,
  Boolean,
  Number,
  Record,
  Static,
  String,
} from "runtypes";
import {
  RTProgramState,
  RTProgramProvisioningState,
  RTProgramRecordState,
  RTPlanStepType,
  RTPlanStepState,
  RTTalkingPointResult,
} from "lib-fullstack/utils/enums";
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

export const CreateProgramRequest = Record({
  name: String,
  description: Optional(String),
  program_id_to_copy: Optional(String),
  space_id: Optional(String),
});
export type CreateProgramRequest = Static<typeof CreateProgramRequest>;

export const ProgramScenarioStep = Record({
  type: RTPlanStepType,
  scenario_id: String,
  completion_score: Number,
  completion_attempts: Number,
  completion_share_by_email: Boolean,
  completion_human_evaluation: Boolean,
});
export type ProgramScenarioStep = Static<typeof ProgramScenarioStep>;

export const ProgramResponse = Record({
  id: String,
  name: String,
  description: String,
  created_date: String,
  state: RTProgramState,
  provisioning_state: RTProgramProvisioningState,
  hub_ids: RTArray(String),
  plan_steps: RTArray(ProgramScenarioStep),
  user_activity: RTArray(Record({ state: RTProgramRecordState, num_users: Number })),
  leaders: RTArray(Record({ id: String, display_name: String, email: String })),
});
export type ProgramResponse = Static<typeof ProgramResponse>;

export const ProgramListResponse = Record({
  programs: RTArray(ProgramResponse),
});
export type ProgramListResponse = Static<typeof ProgramListResponse>;

export const UpdateProgramRequest = Record({
  name: Optional(String),
  description: Optional(String),
  state: Optional(RTProgramState),
  plan_steps: Optional(RTArray(ProgramScenarioStep)),
  hub_ids: Optional(RTArray(String)),
  leader_emails: Optional(RTArray(String)),
});
export type UpdateProgramRequest = Static<typeof UpdateProgramRequest>;

export const UpdateProgramResponse = Record({
  failed_leader_emails: Optional(RTArray(String)),
});
export type UpdateProgramResponse = Static<typeof UpdateProgramResponse>;

export enum ListProgramUsersSortOption {
  EmailAsc = "email",
  EmailDesc = "-email",
  LastProgressDateAsc = "last_progress_date",
  LastProgressDateDesc = "-last_progress_date",
  TotalSecondsAttemptsAsc = "total_seconds_attempts",
  TotalSecondsAttemptsDesc = "-total_seconds_attempts",
  TotalCountAttemptsAsc = "total_count_attempts",
  TotalCountAttemptsDesc = "-total_count_attempts",
}
export const ListProgramUsersSortOptionType = RTStringEnum(ListProgramUsersSortOption);

export const ListProgramUsersQueryParams = Record({
  state: Optional(RTProgramRecordState),
  hub_id: Optional(String),
  sort: Optional(ListProgramUsersSortOptionType),
  start: Optional(String),
  limit: Optional(String),
  prefix: Optional(String),
});
export type ListProgramUsersQueryParams = Static<typeof ListProgramUsersQueryParams>;

export const ProgramUserItem = Record({
  user_id: String,
  name: String,
  email: String,
  state: RTProgramRecordState,
  last_progress_date: Union(String, Null),
  completion_date: Union(String, Null),
  num_steps_completed: Number,
  total_seconds_attempts: Number,
  total_count_attempts: Number,
  num_attempt_started: Number,
});
export type ProgramUserItem = Static<typeof ProgramUserItem>;

export const ListProgramUsersResponse = Record({
  users: RTArray(ProgramUserItem),
  next: Boolean,
  total: Number,
});
export type ListProgramUsersResponse = Static<typeof ListProgramUsersResponse>;

export const PlanStepResult = Record({
  scenario_id: String,
  state: RTPlanStepState,
  completion_score: Number,
  completion_attempts: Number,
  completion_human_evaluation: Boolean,
  attempts: RTArray(
    Record({
      speech_id: String,
      speech_slug: String,
      speech_name: String,
      speech_collabs: RTArray(String), // Array of user emails
      score: Union(Number, Null),
      total_time_sec: Number,
      completed_by_this_speech: Boolean,
      org_link_sharing: Boolean,
    })
  ),
});
export type PlanStepResult = Static<typeof PlanStepResult>;

export const GetProgramUserResponse = ProgramUserItem.extend({
  plan_step_results: RTArray(PlanStepResult),
});
export type GetProgramUserResponse = Static<typeof GetProgramUserResponse>;

export enum SendProgramReminderAction {
  Send = "send",
  SendPreview = "send_preview",
}
export const SendProgramReminderActionType = RTStringEnum(SendProgramReminderAction);

export const SendProgramReminderRequest = Record({
  action: SendProgramReminderActionType,
  hub_id: Optional(String),
});
export type SendProgramReminderRequest = Static<typeof SendProgramReminderRequest>;

export const ProgramMemberViewItem = Record({
  id: String,
  name: String,
  description: String,
  state: RTProgramRecordState,
  last_progress_date: Union(String, Null),
  completion_date: Union(String, Null),
  plan_step_results: RTArray(PlanStepResult),
});
export type ProgramMemberViewItem = Static<typeof ProgramMemberViewItem>;

export const ListProgramsMemberViewResponse = Record({
  programs: RTArray(ProgramMemberViewItem),
});
export type ListProgramsMemberViewResponse = Static<typeof ListProgramsMemberViewResponse>;

export const EvaluateSpeechRequest = Record({
  finalize: Boolean,
  scores: RTArray(
    Record({
      goal_id: String,
      text: Optional(String),
      achieved: Optional(Boolean),
      score_numerator: Optional(Number),
      talking_points: Optional(
        RTArray(
          Record({
            description: String,
            result: RTTalkingPointResult,
          })
        )
      ),
      sub_scores: Optional(
        RTArray(
          Record({
            name: String,
            score_numerator: Number,
          })
        )
      ),
    })
  ),
});
export type EvaluateSpeechRequest = Static<typeof EvaluateSpeechRequest>;
