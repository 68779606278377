import React from "react";

// Components
import { Box, Container, SxProps } from "@mui/material";
import { RouteLeavingGuard } from "components/RouteLeavingGuard";
import { Controls } from "components/Wizard/Controls";

// Utils
import { ProgressTracker } from "./ProgressTracker/ProgressTracker";
import { WizardStep } from "./WizardTypes";
import { CustomEventEnum, publish } from "lib-frontend/events";
import { initBeforeUnLoad } from "utils/componentUtils";

type WizardProps = {
  steps: WizardStep[];
  routeLeavingGuardUIProps: {
    title: string;
    body: string;
    when: boolean;
    okText?: string;
    cancelText?: string;
    onNavigate?: () => void;
  };
  containerSx?: SxProps;
  controlsSx?: SxProps;
  loading?: boolean;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  currStepIndex: number;
  setCurrStepIndex: React.Dispatch<React.SetStateAction<number>>;
  shouldUseNavDrawerOffset?: boolean;
  headerComponent?: JSX.Element;
};

export const Wizard = ({
  steps,
  routeLeavingGuardUIProps,
  loading,
  setLoading,
  currStepIndex,
  setCurrStepIndex,
  containerSx,
  controlsSx,
  shouldUseNavDrawerOffset,
  headerComponent,
}: WizardProps): JSX.Element => {
  const step = steps[currStepIndex];

  const [routeLeavingModalVisible, setRouteLeavingModalVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (routeLeavingModalVisible) {
      // fire custom close nav event
      publish(CustomEventEnum.NAV_DRAWER_CLOSE);
    }
  }, [routeLeavingModalVisible]);

  // show the confirm modal when refreshing after coachbot has been created
  window.onload = () => {
    initBeforeUnLoad(routeLeavingGuardUIProps?.when);
  };
  // re-Initialize the onbeforeunload event listener
  React.useEffect(() => {
    initBeforeUnLoad(routeLeavingGuardUIProps?.when);
  }, [routeLeavingGuardUIProps?.when]);
  // remove it on unmount
  React.useEffect(() => {
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  React.useEffect(() => {
    // allow enter to move to the next slide if the current slide is valid
    // and if it is "turned on" i.e. enterToNavigate is set to true
    const handleKeyDown = async (e: KeyboardEvent) => {
      if (step.validate() && step.enterToNavigate) {
        const userEnterOnTextfield =
          document.activeElement?.className.includes("blockEnterToNavigate");
        if (e.key === "Enter" && !userEnterOnTextfield) {
          await nextStep();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currStepIndex, steps]);

  if (!step) {
    return null;
  }

  const nextStep = async () => {
    setLoading?.(true);
    let stepHadError = false;
    try {
      const error = await step.next?.();
      if (error) {
        stepHadError = true;
      }
      if (!stepHadError) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !step.skipNextIndexUpdate && setCurrStepIndex((prev) => prev + 1);
      }
    } catch (e) {
      console.error("Something went wrong going to the next step: ", e);
    }
    setLoading?.(false);
    return stepHadError;
  };

  const prevStep = async () => {
    setLoading?.(true);
    await step.prev?.();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !step.skipPrevIndexUpdate && step.stepIdx !== 0 && setCurrStepIndex((prev) => prev - 1);
    setLoading?.(false);
  };
  const renderRouteLeavingGuard = () => {
    return (
      <RouteLeavingGuard
        {...routeLeavingGuardUIProps}
        // Use as "message" prop of Prompt of React-Router
        shouldBlockNavigation={() => {
          if (!routeLeavingModalVisible && routeLeavingGuardUIProps?.when) {
            return true;
          }
          return false;
        }}
        okText={routeLeavingGuardUIProps.okText ?? "Ok"}
        cancelText={routeLeavingGuardUIProps.cancelText ?? "Cancel"}
        modalVisible={routeLeavingModalVisible}
        setModalVisible={setRouteLeavingModalVisible}
        onNavigate={routeLeavingGuardUIProps?.onNavigate}
      />
    );
  };

  const extraHeaderSx: SxProps = {
    p: headerComponent ? { xs: 0, md: 4, lg: 5 } : undefined,
  };

  return (
    <Box
      sx={{
        maxWidth: "100vw",
        overflow: "auto",
        position: "relative",
        ...containerSx,
      }}
    >
      {headerComponent && headerComponent}
      {renderRouteLeavingGuard()}
      <Container
        maxWidth="lg"
        sx={{
          height: "100%",
          px: { xs: 1, md: 6 },
          display: "flex",
          flexDirection: "column",
          width: "100%",
          ...extraHeaderSx,
        }}
      >
        {step.showProgressTracker && (
          <ProgressTracker currStepId={step.id} currStepIndex={currStepIndex} steps={steps} />
        )}
        {step.component}
      </Container>
      {!step?.hideFooter && (
        <Controls
          {...step}
          // override the default step's next so i can set loading state here
          next={nextStep}
          prev={prevStep}
          loading={loading}
          totalSteps={steps.length}
          controlsSx={controlsSx}
          shouldUseNavDrawerOffset={shouldUseNavDrawerOffset}
        />
      )}
    </Box>
  );
};
