import React from "react";

// Utils
import { useQuery as useApiQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { listOrgSpaces, createOrgSpace } from "lib-frontend/modules/axiosOrgSpaces";
import { CreateOrgSpaceRequest, OrgSpaceItem } from "lib-fullstack/api/orgSpaceApiTypes";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";

export const LIST_ORG_SPACES_QUERY_KEY = "orgContentSpaces";

interface IContentSpacesContext {
  spaces: OrgSpaceItem[];
  isWorking: boolean;
  curSpaceId: string;
  selectSpace: (spaceId: string) => Promise<void>;
  createSpace: (name: string) => Promise<string>;
}

export const ContentSpacesContext = React.createContext<IContentSpacesContext>({
  spaces: [],
  isWorking: false,
  curSpaceId: "",
  selectSpace: (_: string) => {
    return new Promise(() => {});
  },
  createSpace: (_: string) => {
    return new Promise(() => {});
  },
});

export const ContentSpacesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { defaultOrgId } = React.useContext(UserOrgContext);
  const [curSpaceId, setCurSpaceId] = React.useState<string>(
    getLiveUserDocMain()?.currentOrgSpaceIds?.[defaultOrgId]
  );

  const queryClient = useQueryClient();

  const { data, isLoading } = useApiQuery({
    queryKey: [LIST_ORG_SPACES_QUERY_KEY, defaultOrgId],
    queryFn: async () => {
      try {
        const result = await listOrgSpaces(defaultOrgId);
        return { result };
      } catch (e) {
        console.log(e.toString());
        throw e;
      }
    },
    enabled: !!defaultOrgId,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const spaces = data?.result?.spaces ?? [];

  React.useEffect(() => {
    if (!defaultOrgId) {
      return;
    }

    const userDocSpaceIds = getLiveUserDocMain()?.currentOrgSpaceIds;
    const orgSpaceId = userDocSpaceIds?.[defaultOrgId];

    if (orgSpaceId) {
      // We already have the space id populated in the user doc.
      setCurSpaceId(orgSpaceId);
      return;
    }

    // If there is no current space id on the user doc, then the default space is selected.
    const defaultSpace = spaces?.find((space) => space.is_default);

    if (!defaultSpace?.id) {
      // Do not try to write the user doc if we don't have a valid defaultSpace
      return;
    }

    void selectSpace(defaultSpace.id);
  }, [spaces, defaultOrgId]);

  const selectSpace = async (spaceId: string): Promise<void> => {
    const userDocSpaceIds = getLiveUserDocMain()?.currentOrgSpaceIds;
    const newUserDocEntry = { ...userDocSpaceIds, [defaultOrgId]: spaceId };

    try {
      await updateThisUserDocMain({ currentOrgSpaceIds: newUserDocEntry });
      setCurSpaceId(spaceId);
    } catch (e) {
      console.error(e.toString());
    }
  };

  const createOrgContentSpaceMutation = useMutation({
    mutationFn: async ({ name }: CreateOrgSpaceRequest) => {
      const { org_space_id: orgSpaceId } = await createOrgSpace(defaultOrgId, { name });
      return orgSpaceId;
    },
    onSuccess: async (orgSpaceId: string) => {
      // previous org spaces lists are now invalid.
      await queryClient.invalidateQueries({ queryKey: [LIST_ORG_SPACES_QUERY_KEY, defaultOrgId] });
      await selectSpace(orgSpaceId);
    },
  });

  const createSpace = async (name: string): Promise<string> => {
    try {
      return await createOrgContentSpaceMutation.mutateAsync({ name });
    } catch (e) {
      console.error(e.toString());
    }
  };

  const isWorking = isLoading || createOrgContentSpaceMutation.isPending;

  const contextValue: IContentSpacesContext = {
    spaces,
    isWorking,
    curSpaceId,
    selectSpace,
    createSpace,
  };

  return (
    <ContentSpacesContext.Provider value={contextValue}>{children}</ContentSpacesContext.Provider>
  );
};
