import React from "react";

// Components
import { Box, Button, Stack, Typography } from "@mui/material";
import { AnimatedGradientText } from "lib-frontend/components/AnimatedGradientText";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { InterviewBankResponse, UpdateInterviewBankRequest } from "lib-fullstack/api/hubApiTypes";
import { CreateQuestionBankForm } from "../Scenarios/CreateConvoScenarioWizard/CreateQuestionBankForm";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  CustomizePracticeQueryKey,
  DEFAULT_INTERVIEW_BANK,
} from "components/ConvoScenarios/convoScenarioUtils";
import { WIZARD_FOOTER_HEIGHT } from "utils/Constants";
import { WizardFormFooter } from "webclient/src/components/Orgs/ManageContent/WizardFormFooter";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createOrgInterviewBank, updateOrgInterviewBank } from "lib-frontend/modules/AxiosInstance";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { EventHubsWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { InterviewBankCard } from "./InterviewBankCard";
import firebase from "firebase/app";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";

type InterviewsTabProps = {
  interviewBanks: InterviewBankResponse[];
  renderLoader?: () => JSX.Element;
  setInterviewBankIsSelected?: (val: boolean) => void;
  showBackHeader: boolean;
};

export function InterviewsTab({
  interviewBanks,
  renderLoader,
  showBackHeader,
  setInterviewBankIsSelected,
}: InterviewsTabProps): JSX.Element {
  const { defaultOrgId, defaultOrgName } = React.useContext(UserOrgContext);
  const queryClient = useQueryClient();
  const { curSpaceId } = React.useContext(ContentSpacesContext);

  const [selectedBank, setSelectedBank] = React.useState<InterviewBankResponse>(undefined);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const { mutateAsync: handleInterviewBankUpdate, isPending: interviewBankUpdatePending } =
    useMutation({
      mutationFn: async () => {
        if (isEditing) {
          await updateOrgInterviewBank(
            defaultOrgId,
            selectedBank.id,
            selectedBank.name,
            selectedBank.description,
            selectedBank.interview_questions,
            selectedBank.available_hubs
          );
          return selectedBank.id;
        }
        const createdBankId = await createOrgInterviewBank(
          defaultOrgId,
          curSpaceId,
          selectedBank.name,
          selectedBank.interview_questions,
          selectedBank.available_hubs,
          firebase.auth().currentUser.email,
          selectedBank.description
        );
        return createdBankId;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.InterviewBanks, defaultOrgId],
        });
        if (selectedBank.id) {
          Instrumentation.logOrgInterviewBankUpdated(
            defaultOrgId,
            EventHubsWheres.ORG_MANAGE_CONTENT
          );
        } else {
          Instrumentation.logOrgInterviewBankCreated(
            defaultOrgId,
            EventHubsWheres.ORG_MANAGE_CONTENT
          );
        }
        // reset state upon completion
        setIsEditing(false);
        setSelectedBank(undefined);
      },
      onError: (error) => {
        console.error("Error creating/updating question bank: ", error);
      },
    });

  const handleUpdateInterviewBank = (
    key: keyof UpdateInterviewBankRequest,
    value: string | string[]
  ) => {
    setSelectedBank((prev) => ({ ...prev, [key]: value }));
  };

  const handleSetInterviewBank = (bank: InterviewBankResponse | null) => {
    setInterviewBankIsSelected(!!bank);
    setIsEditing(!!bank);
    setSelectedBank(bank);
  };

  const renderAddNewTemplateButton = () => {
    return (
      <Button
        onClick={() => {
          handleSetInterviewBank({
            ...DEFAULT_INTERVIEW_BANK,
            org_name: defaultOrgName,
          });
          setIsEditing(false);
        }}
        variant="contained"
        sx={{ whiteSpace: "nowrap" }}
        size="xlarge"
      >
        Create new
      </Button>
    );
  };

  if (!interviewBanks && renderLoader) {
    return renderLoader();
  }

  if (selectedBank && showBackHeader) {
    return (
      <Box
        sx={{
          maxWidth: "lg",
          pb: WIZARD_FOOTER_HEIGHT,
        }}
      >
        <CreateQuestionBankForm
          selectedBank={selectedBank}
          handleUpdateInterviewBank={handleUpdateInterviewBank}
        />
        <WizardFormFooter
          disabled={
            !selectedBank.name ||
            !selectedBank.interview_questions.length ||
            interviewBankUpdatePending ||
            !selectedBank.description
          }
          saving={interviewBankUpdatePending}
          handleSave={() => {
            handleInterviewBankUpdate().catch((e) => {
              console.error(`Error saving interview bank ${selectedBank.id}: ${e}`);
            });
          }}
        />
      </Box>
    );
  }

  return (
    <Stack
      gap={3}
      sx={{ alignItems: "center", maxWidth: "xxl", p: { xs: 3, md: 0 } }}
      direction="column"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        sx={{
          width: "100%",
        }}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          sx={{
            fontFamily: "poppins",
            py: interviewBanks?.length === 0 ? { xs: 0, md: 4 } : 0,
          }}
        >
          {interviewBanks?.length === 0 ? (
            <AnimatedGradientText
              sx={{
                fontSize: { xs: 16, md: 18 },
                fontWeight: 700,
              }}
            >
              Create New Question Bank
            </AnimatedGradientText>
          ) : (
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontSize: { xs: 16, md: 18 },
                fontWeight: 700,
              }}
            >
              My Question Banks
            </Typography>
          )}

          {interviewBanks?.length === 0 && (
            <Typography
              sx={{
                color: getDynamicColor("dark5"),
                fontSize: 12,
                fontWeight: 600,
                maxWidth: 600,
              }}
            >
              {`When members of ${defaultOrgName} start an interview practice in Yoodli, they will be able to
        choose a question bank defined here.`}
            </Typography>
          )}
        </Stack>
        {renderAddNewTemplateButton()}
      </Stack>
      <Stack sx={{ width: "100%", gap: 2 }}>
        {interviewBanks.map((bank, index) => (
          <InterviewBankCard
            key={index}
            interviewBank={bank}
            handleSetInterviewBank={handleSetInterviewBank}
          />
        ))}
      </Stack>
    </Stack>
  );
}
