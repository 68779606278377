import React from "react";

// Components
import { Stack } from "@mui/material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { WizardSubTitle, WizardTitle } from "../../../../../Wizard/WizardTitles";
import { TEXTFIELD_WIDTH } from "../CoachBotUploadContent/CoachBotUploadContent";
import { CoachBotWithScenarioInfo } from "lib-fullstack/api/coachBotApiTypes";

type CoachBotNamingProps = {
  setCoachBotName: React.Dispatch<React.SetStateAction<string>>;
  coachBotName: string;
  coachBotNameCollision: boolean;
  setCoachBotNameCollision: React.Dispatch<React.SetStateAction<boolean>>;
  coachbotsList: CoachBotWithScenarioInfo[];
};

export const CoachBotNaming = ({
  coachBotName,
  setCoachBotName,
  coachBotNameCollision,
  setCoachBotNameCollision,
  coachbotsList,
}: CoachBotNamingProps): JSX.Element => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        position: "relative",
        px: { xs: 2, md: 0 },
        pt: { xs: 3, md: 6 },
        pb: { xs: 6, md: 10 },
      }}
      gap={2}
    >
      <Stack
        sx={{
          width: { xs: "unset", md: TEXTFIELD_WIDTH },
        }}
        gap={0.5}
      >
        <WizardTitle
          overrideSx={{
            px: { xs: 1, md: 2 },
          }}
        >
          Name your Coach Bot
        </WizardTitle>
        <WizardSubTitle
          overrideSx={{
            px: { xs: 1, md: 2 },
          }}
        >
          This will only be visible to you and members of your organization
        </WizardSubTitle>
      </Stack>
      <YoodliTextfield
        error={coachBotNameCollision}
        value={coachBotName}
        helperText={coachBotNameCollision ? "Coach Bot name already exists" : ""}
        autoFocus
        maxChars={30}
        placeholder="e.g. My Custom AI CoachBot"
        onChange={(e) => {
          const collision = coachbotsList.some((bot) => bot.name === e.target.value);
          if (collision) {
            setCoachBotNameCollision(true);
          } else if (coachBotNameCollision && !collision) {
            setCoachBotNameCollision(false);
          }
          setCoachBotName(e.target.value);
        }}
        sx={{
          width: "100%",
          maxWidth: TEXTFIELD_WIDTH,
        }}
      />
    </Stack>
  );
};
