import { PersonaMemberViewResponse } from "lib-fullstack/api/scenarioApiTypes";
import { Box, Divider, IconButton, Stack } from "@mui/material";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Check as CheckIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import React from "react";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";

type PersonaListProps = {
  allPersonas: PersonaMemberViewResponse[];
  selectedPersonaIds: string[];
  handlePersonaSelectionChange: (personaId: string, action: "add" | "remove") => void;
  renderPersonaValue: (persona?: PersonaMemberViewResponse) => JSX.Element;
  showSearchBar?: boolean;
  handleEditPersona: (persona: PersonaMemberViewResponse) => void;
};

export const PersonaList = ({
  allPersonas,
  selectedPersonaIds,
  handlePersonaSelectionChange,
  showSearchBar,
  renderPersonaValue,
  handleEditPersona,
}: PersonaListProps): JSX.Element => {
  const [hoveringIconId, setHoveringIconId] = React.useState<string | null>(null);
  const [blockIconHover, setBlockIconHover] = React.useState<Record<string, boolean>>({});
  const [searchText, setSearchText] = React.useState<string>("");

  const getIconBackgroundColor = (personaId: string, isSelected: boolean) => {
    if (hoveringIconId === personaId && isSelected && !blockIconHover[personaId]) {
      return getDynamicColor("dark4");
    }
    if (isSelected) {
      return getDynamicColor("greenSuccess");
    }
    return getDynamicColor("primary");
  };

  const renderAddRemoveIcons = (personaId: string, isSelected: boolean) => {
    if (hoveringIconId === personaId && isSelected && !blockIconHover[personaId]) {
      return <RemoveIcon />;
    }
    if (isSelected) {
      return <CheckIcon />;
    }
    return <AddIcon />;
  };

  const searchFilteredPersonas = React.useMemo(() => {
    return allPersonas.filter((persona) => {
      return persona.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [allPersonas, searchText]);

  return (
    <Stack
      sx={{
        borderRadius: "12px",
        pt: 1,
        gap: 2,
        width: "800px",
        maxWidth: "100%",
      }}
    >
      {showSearchBar && (
        <YoodliSearchBar
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          clearSearch={() => setSearchText("")}
          placeholder="Search and select from your library"
          InputProps={{
            sx: {
              height: "40px",
            },
          }}
          sx={{
            width: "100%",
            maxWidth: "unset",
            px: 1,
          }}
        />
      )}
      <Stack
        sx={{
          gap: 1.5,
          overflow: "auto",
          maxHeight: "500px",
          transform: "max-height 0.3s ease-out",
          pb: 2,
        }}
      >
        {searchFilteredPersonas.map((persona, index) => {
          const isSelected = selectedPersonaIds?.includes(persona.id);
          return (
            <React.Fragment key={persona.id}>
              <Stack direction="column" sx={{ width: "100%", px: 2 }}>
                <Stack
                  direction="row"
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    px: 2,
                  }}
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    isSelected
                      ? handlePersonaSelectionChange(persona.id, "remove")
                      : handlePersonaSelectionChange(persona.id, "add");
                  }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      width: "100%",
                      gap: 2,
                      alignItems: "center",
                      overflow: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        height: 28,
                        width: 28,
                        cursor: "pointer",
                      }}
                      onMouseEnter={() => {
                        setHoveringIconId(persona.id);
                      }}
                      onMouseLeave={() => {
                        setHoveringIconId(null);
                        setBlockIconHover({ ...blockIconHover, [persona.id]: false });
                      }}
                      onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        isSelected
                          ? setBlockIconHover({ ...blockIconHover, [persona.id]: false })
                          : setBlockIconHover({ ...blockIconHover, [persona.id]: true });
                      }}
                    >
                      <Box
                        sx={{
                          height: 20,
                          width: 20,
                          left: 4,
                          top: 4,
                          borderRadius: "50%",
                          position: "relative",
                          backgroundColor: getIconBackgroundColor(persona.id, isSelected),
                          color: getDynamicColor("light1"),
                          transition: "background-color 0.2s ease-out",
                          svg: {
                            position: "absolute",
                            display: "block",
                            top: 1,
                            left: 1,
                            height: 18,
                            width: 18,
                            strokeWidth: 0.5,
                            stroke: getDynamicColor("light1"),
                          },
                        }}
                      >
                        {renderAddRemoveIcons(persona.id, isSelected)}
                      </Box>
                    </Box>
                    {renderPersonaValue(persona)}
                  </Stack>
                  <IconButton
                    sx={{
                      color: getDynamicColor("primary"),
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleEditPersona(persona);
                    }}
                  >
                    <EditIcon sx={{ width: 24, height: 24 }} />
                  </IconButton>
                </Stack>
              </Stack>
              {index < searchFilteredPersonas.length - 1 && (
                <Divider sx={{ color: getDynamicColor("dark3"), mx: 4 }} />
              )}
            </React.Fragment>
          );
        })}
      </Stack>
    </Stack>
  );
};
