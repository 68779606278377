import firebase from "firebase/app";
import { db } from "lib-fullstack";
import React, { useEffect, useState, useContext } from "react";

// Components
import CloseIcon from "@mui/icons-material/Close";
import { List, ListItem, ListItemText, ListItemIcon, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

// Utils
import { subscribeToSpeechSnapshot } from "lib-frontend/utils/DbUtils";
import { AnalyticProcessingState } from "lib-fullstack/utils/enums";
import { UploadedRecordingIdsContext } from "contexts/UploadedRecordingIdsContext";
import { CurrentlyUploadingFilesContext } from "contexts/CurrentlyUploadingFilesContext";

/**
 * Renders file uploading status, and video analysis status in a closable modal
 * TODO: Make this component render on any page, make an alert pop up if a user is about to close the window
 * during upload, and make the processing file states rely on the global uploadedRecordingIds state instead of the state
 * from the VideoJournal page
 */
const AnalyticsProcessingModal = (): React.ReactElement => {
  const [uploadingFileList, setUploadingFileList] = useState([]);
  const [processingFileList, setProcessingFileList] = useState<{
    [k: string]: {
      documentId: string;
      asyncTranscriptState: AnalyticProcessingState;
      name: string;
    };
  }>({});
  const [isUploading, toggleIsUploading] = useState(false);
  const [isProcessing, toggleIsProcessing] = useState(false);

  const { uploadedRecordingIds } = useContext(UploadedRecordingIdsContext);
  const { currentlyUploadingFiles } = useContext(CurrentlyUploadingFilesContext);

  useEffect(() => {
    const user = firebase.auth().currentUser?.uid;

    if (user !== undefined) {
      if (uploadedRecordingIds && uploadedRecordingIds.length > 0) {
        uploadedRecordingIds.forEach((id) => {
          console.log(
            `Subscribing to snapshot for async-ly uploaded video_recording with id: ${id}`
          );

          subscribeToSpeechSnapshot(id, user, (snapshot: db.Doc<db.Speech>) => {
            if (
              snapshot.data.asyncTranscriptState === AnalyticProcessingState.PROCESSING ||
              (snapshot.data.asyncTranscriptState === AnalyticProcessingState.FINISHED &&
                snapshot.data.videoTranscodingStatus === "STARTED")
            ) {
              console.log(`Analytics processing status is PROCESSING for this id: ${id}`);

              // Refresh processing files list
              setProcessingFileList({
                ...processingFileList,
                [id]: {
                  documentId: id,
                  asyncTranscriptState: snapshot.data.asyncTranscriptState,
                  name: snapshot.data.name,
                },
              });
              toggleIsProcessing(true);
            } else if (
              snapshot.data.asyncTranscriptState === AnalyticProcessingState.FINISHED &&
              snapshot.data.videoTranscodingStatus === "FINISHED"
            ) {
              console.log(`Analytics completed for this id: ${id}`);

              // Clear processing files
              toggleIsProcessing(false);
              setProcessingFileList({});
            } else {
              console.log(
                `asyncTranscriptState is ${snapshot.data.asyncTranscriptState} for this id: ${id}`
              );

              // Clear processing files
              toggleIsProcessing(false);
              setProcessingFileList({});
            }
          });
        });
      }
    }
  }, [uploadedRecordingIds]);

  // Listens for upload status from UploadModal
  useEffect(() => {
    if (currentlyUploadingFiles && currentlyUploadingFiles.length > 0) {
      setUploadingFileList(currentlyUploadingFiles);
      toggleIsUploading(true);
    } else {
      setUploadingFileList([]);
      toggleIsUploading(false);
    }
  }, [currentlyUploadingFiles]);

  const handleClose = (event: React.MouseEvent, reason?: string) => {
    if (reason !== "backdropClick") {
      toggleIsUploading(false);
      toggleIsProcessing(false);
      setProcessingFileList({});
    }
  };

  const VideoProcessingRow = (props) => {
    const { name, variant, progress } = props;

    return (
      <ListItem>
        <ListItemIcon>
          {variant === "processing" && (
            <CircularProgress variant="indeterminate" sx={{ padding: "10px", color: "#6966FD" }} />
          )}
          {variant === "uploading" && (
            <CircularProgress
              variant="determinate"
              value={progress}
              sx={{ padding: "10px", color: "#6966FD" }}
            />
          )}
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    );
  };

  return (
    <div>
      {isProcessing && (
        <Box
          sx={{
            minWidth: "300px",
            position: "fixed",
            bottom: "3rem",
            right: "3rem",
            bgcolor: "#fff",
            boxShadow: "0px 2px 16px rgba(33, 37, 41, 0.16)",
            border: "0px solid #fff",
            borderRadius: "8px",
            zIndex: "1500",
          }}
        >
          <List>
            <ListItem>
              <ListItemText primary="Analyzing video" />
              <ListItemIcon>
                <CloseIcon onClick={(event) => handleClose(event)} sx={{ margin: "0 auto" }} />
              </ListItemIcon>
            </ListItem>
            {Object.values(processingFileList).length > 0 &&
              Object.values(processingFileList).map((video, i) => {
                return <VideoProcessingRow key={i} name={video.name} variant="processing" />;
              })}
          </List>
        </Box>
      )}
      {isUploading && (
        <Box
          sx={{
            minWidth: "300px",
            position: "fixed",
            bottom: "3rem",
            right: "3rem",
            bgcolor: "#fff",
            boxShadow: "0px 2px 16px rgba(33, 37, 41, 0.16)",
            border: "0px solid #fff",
            borderRadius: "8px",
            zIndex: "1500",
          }}
        >
          <List>
            <ListItem>
              <ListItemText primary="Uploading video" />
              <ListItemIcon>
                <CloseIcon onClick={(event) => handleClose(event)} sx={{ margin: "0 auto" }} />
              </ListItemIcon>
            </ListItem>
            {uploadingFileList.length > 0 &&
              uploadingFileList.map((video, i) => {
                return (
                  <VideoProcessingRow
                    key={i}
                    name={video.file.name}
                    variant="uploading"
                    progress={video.percentComplete}
                  />
                );
              })}
          </List>
        </Box>
      )}
    </div>
  );
};

export default AnalyticsProcessingModal;
