import React from "react";

// Utils
import { SortStatus, TableSortBase } from "./TableSortBase";

export type TableSortServerProps = {
  columnId: string;
  setSortFilter?: (columnId: string) => void;
};

export const TableSortServer = ({ columnId, setSortFilter }: TableSortServerProps): JSX.Element => {
  const [sortCreated, setSortCreated] = React.useState<SortStatus>(SortStatus.NONE);
  const handleSortCreated = () => {
    if (sortCreated === SortStatus.NONE) {
      setSortCreated(SortStatus.ASC);
      setSortFilter(columnId);
    } else if (sortCreated === SortStatus.ASC) {
      setSortCreated(SortStatus.DESC);
      setSortFilter("-" + columnId);
    } else {
      setSortCreated(SortStatus.NONE);
      setSortFilter(undefined);
    }
  };

  return (
    <TableSortBase
      sortCreated={sortCreated}
      setSortCreated={setSortCreated}
      handleSortCreated={handleSortCreated}
    />
  );
};
