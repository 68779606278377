import firebase from "firebase/app";
import React from "react";

// Components
import { Box, Stack, Typography, CircularProgress, Link, TablePagination } from "@mui/material";
import { TableFilter } from "lib-frontend/components/YoodliComponents/TableComponents/TableFilter";
import { TableSortServer } from "lib-frontend/components/YoodliComponents/TableComponents/TableSortServer";
import { YoodliAvatar } from "lib-frontend/components/YoodliComponents/YoodliAvatar";
import { EventSeat } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import {
  YoodliMenu,
  YoodliMenuButtonType,
  YoodliMenuItemType,
} from "lib-frontend/components/YoodliComponents/YoodliMenu";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";

// Utils
import {
  CheckboxTableElement,
  dataRowStyles,
  defaultTableDataStyles,
  headerRowStyles,
  tableHeaderStyles,
} from "lib-frontend/utils/sharedTableUtils";
import { OrgMembersQueryKeys } from "./OrgMembers";
import {
  keepPreviousData,
  useQuery as useApiQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
  PaginationState,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  deleteOrgUserV2,
  listOrgUsersV2,
  updateOrgUserV2,
} from "lib-frontend/modules/AxiosInstance";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { SupportPages } from "lib-frontend/utils/paths";
import { useIsMediumScreen } from "lib-frontend/utils/themeUtils";
import {
  GetOrgMemberListField,
  GetOrgMemberListSortOption,
  HubMemberResponse,
  OrgMemberResponse,
} from "lib-fullstack/api/orgApiTypes";
import {
  DEFAULT_ORG_MEMBER_FETCH_COUNT,
  ORG_MEMBER_FETCH_LIMIT,
} from "lib-fullstack/utils/constants";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { HubRole, OrgRole, EffectiveRole } from "lib-fullstack/utils/enums";
import { useNavigate } from "react-router";
import { WebServerInternalPath } from "utils/paths";
import { OrgHubsQueryParams } from "lib-fullstack/utils/queryParams";
import { getTimeDifference } from "lib-frontend/utils/orgUtils";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";

export type MemberInfo = {
  id?: string;
  name?: string;
  email: string;
  groupIds?: string[];
};

type OrgMembersTableProps = {
  searchText: string;
  openManageRoleWizard: (user: OrgMemberResponse) => void;
  openDashboard: (user: OrgMemberResponse) => void;
  recalcTopContentHeight: () => void;
  bulkActionUserList: MemberInfo[];
  setBulkActionUserList: React.Dispatch<React.SetStateAction<MemberInfo[]>>;
};

enum MenuAction {
  OpenDashboard = "open_dashboard",
  ManageRole = "manage_role",
  TransferOwnership = "transfer_ownership",
  RemoveFromOrg = "remove_from_org",
}

export type OrgMembersTableRow = {
  id: string;
  name: string;
  role: string;
  joined_hubs: HubMemberResponse[];
  email: string;
  date_joined: string;
  num_started_speeches: number;
  date_last_activity: string;
  action: null;
  seat_inactive: boolean;
};

const parseOrgRole = (role?: string): string => {
  switch (role) {
    case OrgRole.OWNER:
      return "Org owner";
    case OrgRole.ADMIN:
      return "Org admin";
    case HubRole.ADMIN:
      return "Group admin";
    default:
      return "Member";
  }
};

const parseDateString = (dateStr: string) => {
  return getHumanReadableDate(dateStr, {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  });
};

const FILTER_NAMES = {
  role: [
    { label: "Org owner", value: "org_owner" },
    { label: "Org admin", value: "org_admin" },
    { label: "Group admin", value: "hub_admin" },
    { label: "Member", value: "hub_member" },
  ],
};

export default function MembersTable({
  searchText,
  openManageRoleWizard,
  openDashboard,
  recalcTopContentHeight,
  bulkActionUserList,
  setBulkActionUserList,
}: OrgMembersTableProps): JSX.Element {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    defaultOrgId,
    invalidateDefaultOrgQuery,
    isDefaultOrgOwnerAdmin,
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const isMediumScreen = useIsMediumScreen();
  const columnHelper = createColumnHelper<OrgMembersTableRow>();
  const [openRemoveUserModal, setOpenRemoveUserModal] = React.useState<boolean>(false);
  const [openTransferOwnershipModal, setOpenTransferOwnershipModal] =
    React.useState<boolean>(false);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_ORG_MEMBER_FETCH_COUNT,
  });
  const [sortFilter, setSortFilter] = React.useState<string>(
    GetOrgMemberListSortOption.EMAIL_ASC as string
  );
  const [roleFilters, setRoleFilters] = React.useState<string>(undefined);
  const paginationStartInd = (pagination.pageIndex * pagination.pageSize).toString();
  const queryLimit = Math.min(pagination.pageSize, ORG_MEMBER_FETCH_LIMIT).toString();

  const { showNotificationBanner } = useNotification();

  const anyFilterEnabled = React.useMemo(() => {
    return roleFilters?.length > 0 || searchText?.length > 0;
  }, [roleFilters, searchText]);

  React.useEffect(() => {
    setPagination((prev) => {
      return { ...prev, pageIndex: 0 };
    });
  }, [roleFilters, searchText]);

  const membersQueryResult = useApiQuery({
    queryKey: [
      OrgMembersQueryKeys.OrgMembers,
      defaultOrgId,
      {
        start: paginationStartInd,
        limit: queryLimit,
        sort: searchText
          ? GetOrgMemberListSortOption.EMAIL_ASC
          : (sortFilter ?? GetOrgMemberListSortOption.EMAIL_ASC),
        effective_roles: roleFilters,
        prefix: searchText,
      },
    ],
    queryFn: async () =>
      listOrgUsersV2(defaultOrgId, {
        start: paginationStartInd,
        limit: queryLimit,
        sort: searchText
          ? GetOrgMemberListSortOption.EMAIL_ASC
          : ((sortFilter as GetOrgMemberListSortOption) ?? GetOrgMemberListSortOption.EMAIL_ASC),
        effective_roles: roleFilters,
        fields: GetOrgMemberListField.HUBS,
        prefix: searchText,
      }),
    placeholderData: keepPreviousData,
  });

  // redefine the member list whenever the data changes
  const membersList = React.useMemo(
    () => membersQueryResult.data?.users ?? [],
    [membersQueryResult.data?.users]
  );

  const bulkActionUserIdSet = new Set(bulkActionUserList.map((user) => user.id));

  React.useEffect(() => {
    recalcTopContentHeight();
  }, [membersList]);

  const [selectedUser, setSelectedUser] = React.useState<OrgMemberResponse>(null);

  const handleMenuItemClick = async (row: Row<OrgMembersTableRow>, action: MenuAction) => {
    const user = membersList.find((user) => user.email === row.original.email);
    setSelectedUser({ ...user });
    switch (action) {
      case MenuAction.ManageRole:
        openManageRoleWizard(user);
        break;
      case MenuAction.OpenDashboard:
        openDashboard(user);
        break;
      case MenuAction.TransferOwnership:
        setOpenTransferOwnershipModal(true);
        break;
      case MenuAction.RemoveFromOrg:
        setOpenRemoveUserModal(true);
        break;
    }
  };

  const transferOwnershipMutation = useMutation({
    mutationFn: async () => {
      if (selectedUser.user_id !== firebase.auth().currentUser?.uid) {
        await updateOrgUserV2(defaultOrgId, selectedUser.user_id, {
          role: OrgRole.OWNER,
        });
        await updateOrgUserV2(defaultOrgId, "me", { role: OrgRole.ADMIN });
      }
    },
    onSuccess: () => {
      showNotificationBanner(
        <>
          Ownership transferred to <strong>{selectedUser.name}</strong>
        </>,
        {
          variant: YoodliNotificationBannerVariant.Success,
        }
      );
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.OrgMembers, defaultOrgId],
      });
    },
    onError: (e: Error) => {
      showNotificationBanner(
        <>
          Failed to transfer ownership to <strong>{selectedUser.name}</strong>
        </>,
        {
          variant: YoodliNotificationBannerVariant.Danger,
        }
      );
      console.log("Error transfering org ownership: ", e);
    },
  });

  const removeMemberMutation = useMutation({
    mutationFn: async () => {
      await deleteOrgUserV2(defaultOrgId, selectedUser.user_id);
    },
    onSuccess: () => {
      void invalidateDefaultOrgQuery();
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.OrgMembers, defaultOrgId],
      });
      void queryClient.invalidateQueries({
        queryKey: [OrgMembersQueryKeys.OrgUserCounts, defaultOrgId],
      });
      Instrumentation.logOrgMemberRemoved(defaultOrgId);
    },
    onError: (e: Error) => {
      console.log("Error removing org member: ", e);
    },
  });

  const rowData = React.useMemo(
    () =>
      (membersList ?? []).map((user) => ({
        id: user.user_id,
        name: user.name,
        role: parseOrgRole(user.effective_role ?? user.role),
        joined_hubs: user.hubs,
        email: user.email,
        date_joined: user.date_joined,
        num_started_speeches: user.num_started_speeches,
        date_last_activity: user.date_last_activity,
        action: null,
        seat_inactive: user.seat_inactive,
      })),
    [membersList]
  );

  const allChecked = React.useMemo(() => {
    return membersList.every((member) => bulkActionUserIdSet.has(member.user_id));
  }, [rowData, bulkActionUserIdSet]);

  const someChecked = React.useMemo(() => {
    return membersList.some((member) => bulkActionUserIdSet.has(member.user_id));
  }, [rowData, bulkActionUserIdSet]);

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        header: () => <Typography sx={{ ml: { xs: 3, md: 5 } }}>Members</Typography>,
        cell: (info) => {
          const titleSx = {
            fontSize: 14,
            fontWeight: 700,
            color: getDynamicColor("primary"),
            overflow: "hidden",
            width: "fit-content",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          };

          return (
            <Stack
              onClick={async () => {
                await handleMenuItemClick(info.row, MenuAction.OpenDashboard);
              }}
              direction="row"
              gap={2}
              sx={{ alignItems: "center", pr: 0.5, cursor: "pointer", width: "fit-content" }}
            >
              <YoodliAvatar
                name={info.getValue()}
                sx={{
                  my: 2.5,
                  height: 35,
                  width: 35,
                }}
              />
              <Stack
                sx={{
                  maxWidth: { xs: "150px", md: "300px" },
                }}
              >
                <Typography sx={titleSx} className="column-text">
                  {info.getValue()}
                </Typography>
                <Typography
                  sx={{
                    ...titleSx,
                    ...defaultTableDataStyles,
                    lineHeight: 1.2,
                  }}
                  className="column-text"
                >
                  {info.row.original.email}
                </Typography>
              </Stack>
            </Stack>
          );
        },
      }),
      ...(!isMediumScreen
        ? [
            columnHelper.accessor("num_started_speeches", {
              id: "num_started_speeches",
              header: () => (
                <Stack direction="row" gap={0.5} sx={{ alignItems: "center" }}>
                  <Typography># of Yoodlis</Typography>
                  <YoodliTooltip title="# of Yoodlis = number of uploads and recordings started">
                    <InfoOutlinedIcon sx={{ width: 16, height: 16 }} />
                  </YoodliTooltip>
                </Stack>
              ),
              cell: (info) => <Typography className="column-text">{info.getValue()}</Typography>,
            }),
            columnHelper.accessor("date_last_activity", {
              id: "date_last_activity",
              header: () => <>Last active</>,
              cell: (info) => (
                <YoodliTooltip title={info.getValue() && getHumanReadableDate(info.getValue(), {})}>
                  <Stack direction="column">
                    <Typography sx={defaultTableDataStyles} className="column-text">
                      {getTimeDifference(info.getValue())}
                    </Typography>
                    {info.row.original.seat_inactive && (
                      <Stack direction="row" sx={{ gap: 0.5 }}>
                        <EventSeat
                          sx={{ width: 13, height: 13, color: getDynamicColor("dark4") }}
                        />
                        <Typography
                          sx={{
                            color: getDynamicColor("dark4"),
                            fontFamily: "poppins",
                            fontSize: "10px",
                            fontWeight: 600,
                          }}
                        >
                          Inactive
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </YoodliTooltip>
              ),
            }),
            columnHelper.accessor("date_joined", {
              id: "date_joined",
              header: () => <>Joined</>,
              cell: (info) => (
                <Typography sx={defaultTableDataStyles} className="column-text">
                  {parseDateString(info.getValue())}
                </Typography>
              ),
            }),
            columnHelper.accessor("role", {
              id: "role",
              header: () => <>Role</>,
              cell: (info) => (
                <Typography className="column-text" sx={defaultTableDataStyles}>
                  {info.getValue()}
                </Typography>
              ),
              filterFn: "arrIncludesSome",
            }),
            columnHelper.accessor("joined_hubs", {
              id: "joined_hubs",
              header: () => <>Groups</>,
              cell: (info) => (
                <Typography
                  sx={{
                    maxWidth: "300px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    color: getDynamicColor("primary"),
                  }}
                >
                  {info.getValue().map((hub, index) => (
                    <React.Fragment key={`${hub.hub_id}-${index}`}>
                      <Link
                        onClick={() =>
                          navigate(
                            `${WebServerInternalPath.ORG_GROUPS}?${OrgHubsQueryParams.GROUP_ID}=${hub.hub_id}`
                          )
                        }
                        sx={{ cursor: "pointer" }}
                      >
                        {hub.name}
                      </Link>
                      {index !== info.getValue().length - 1 && <>, </>}
                    </React.Fragment>
                  ))}
                </Typography>
              ),
            }),
          ]
        : []),
      columnHelper.accessor("action", {
        id: "action",
        header: () => <div style={{ width: "36px" }} />,
        cell: (info) =>
          info.row.original.email !== firebase.auth().currentUser.email &&
          info.row.original.role !== parseOrgRole(OrgRole.OWNER) && (
            <YoodliMenu
              type={YoodliMenuButtonType.Icon}
              menuItems={[
                {
                  title: "View dashboard",
                  onClick: () => handleMenuItemClick(info.row, MenuAction.OpenDashboard),
                  type: YoodliMenuItemType.Default,
                },
                {
                  title: "Manage role",
                  onClick: () => handleMenuItemClick(info.row, MenuAction.ManageRole),
                  type: YoodliMenuItemType.Default,
                },
                defaultOrg.effective_role === EffectiveRole.ORG_OWNER && {
                  title: "Transfer org ownership",
                  onClick: () => handleMenuItemClick(info.row, MenuAction.TransferOwnership),
                  type: YoodliMenuItemType.Warning,
                },
                {
                  title: "Remove from organization",
                  onClick: () => handleMenuItemClick(info.row, MenuAction.RemoveFromOrg),
                  type: YoodliMenuItemType.Warning,
                },
              ].filter(Boolean)}
            />
          ),
      }),
    ],
    [membersList, isMediumScreen]
  );

  const filteredColumns = columns.filter(Boolean);

  const table = useReactTable({
    data: rowData,
    columns: filteredColumns,
    state: {
      pagination,
    },
    rowCount: membersQueryResult.data?.total,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  const loading = [transferOwnershipMutation, removeMemberMutation].some(
    (mutation) => mutation.isPending
  );

  const handlePageChange = (page: number) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: Math.max(page, 0) };
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPagination((oldPagination) => {
      return { ...oldPagination, pageIndex: 0, pageSize: parseInt(event.target.value) };
    });
  };

  if (membersQueryResult.isPending) {
    return <CircularProgress sx={{ m: "auto" }} />;
  }

  return (
    <Stack
      gap={1}
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          minHeight: membersList?.length > 4 ? 400 : "unset",
          overflowY: "auto",
          border: `1px solid ${getDynamicColor("dark2")}`,
          borderRadius: 2,
        }}
      >
        <table
          style={{
            width: "100%",
            boxShadow: Y_SHADOWS.dark_elevation,
            borderCollapse: "collapse",
            borderRadius: 2,
            borderSpacing: "0px",
          }}
        >
          <thead
            style={{
              backgroundColor: getDynamicColor("light1"),
              borderRadius: "8px",
              height: "40px",
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <Box component="tr" key={headerGroup.id} sx={headerRowStyles}>
                {isDefaultOrgOwnerAdmin && (
                  <CheckboxTableElement
                    component="th"
                    checked={allChecked}
                    indeterminate={!allChecked && someChecked}
                    onChange={() => {
                      // If some or all are selected, deselect everything
                      if (someChecked) {
                        const newBulkActionUserList = bulkActionUserList.filter((userList) => {
                          return !rowData.map((row) => row.id).includes(userList.id);
                        });
                        setBulkActionUserList(newBulkActionUserList);
                      }
                      // If none are selected, select everything
                      else {
                        const newBulkActionUserList = [...bulkActionUserList];
                        for (const row of rowData) {
                          if (!bulkActionUserIdSet.has(row.id)) {
                            newBulkActionUserList.push({
                              id: row.id,
                              name: row.name,
                              email: row.email,
                              groupIds: row?.joined_hubs.map((hub) => hub.hub_id),
                            });
                          }
                        }
                        setBulkActionUserList(newBulkActionUserList);
                      }
                    }}
                  />
                )}
                {/* Sub headers */}
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    <Stack direction="row" gap={1} alignItems="center" sx={tableHeaderStyles}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {[
                        "name",
                        "last_active",
                        "date_joined",
                        "num_started_speeches",
                        "date_last_activity",
                      ].includes(header.id) && (
                        <TableSortServer columnId={header.id} setSortFilter={setSortFilter} />
                      )}
                      {["role"].includes(header.id) && (
                        <TableFilter
                          setFilterState={setRoleFilters}
                          names={FILTER_NAMES[header.id]}
                          searchable={false}
                        />
                      )}
                    </Stack>
                    {/* Note: the reason the <TanbleFilters> are here instead of as a columnHelper is because when placed in a columnHelper,
                                  it would re-render the menu opening every time the filtering changed, which is undesirable. No re-render problems here.
                              */}
                  </th>
                ))}
              </Box>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Box
                  component="tr"
                  key={row.id}
                  sx={{
                    ...dataRowStyles,
                    "& .column-text": {
                      color: row.original.seat_inactive && getDynamicColor("dark4"),
                    },
                  }}
                >
                  {isDefaultOrgOwnerAdmin && (
                    <CheckboxTableElement
                      component="td"
                      checked={bulkActionUserIdSet.has(row.original.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setBulkActionUserList([
                            ...bulkActionUserList,
                            {
                              id: row.original.id,
                              name: row.original.name,
                              email: row.original.email,
                              groupIds: (row.original.joined_hubs ?? []).map((hub) => hub.hub_id),
                            },
                          ]);
                        } else {
                          setBulkActionUserList(
                            bulkActionUserList.filter((user) => user.id !== row.original.id)
                          );
                        }
                      }}
                    />
                  )}
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        color: getDynamicColor("purple3"),
                        fontFamily: "poppins",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </Box>
              );
            })}
            {table.getRowCount() === 0 && !anyFilterEnabled && (
              <tr>
                <td colSpan={columns.length}>
                  <Stack
                    direction="column"
                    sx={{
                      mx: "auto",
                      width: "100%",
                      color: getDynamicColor("dark4"),
                      minHeight: "250px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontWeight: 700 }}>No members yet</Typography>
                    <Typography sx={{ textAlign: "center" }}>
                      Invite members to join this organization using the buttons above
                    </Typography>
                  </Stack>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>

      <Stack alignItems="center">
        {table.getPageCount() > 1 && (
          <TablePagination
            component="div"
            sx={{ m: "auto", fontFamily: "poppins !important" }}
            count={table.getRowCount() ?? 0}
            rowsPerPageOptions={[5, 10, 20, 50]}
            page={pagination.pageIndex}
            onPageChange={(e, pageNum) => handlePageChange(pageNum)}
            rowsPerPage={pagination.pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Stack>
      <YoodliCtaModal
        ctaBody={{
          title: (
            <>
              Are you sure you want to remove{" "}
              <strong style={{ color: getDynamicColor("primary") }}>{selectedUser?.name}</strong>{" "}
              from <strong style={{ color: getDynamicColor("primary") }}>{defaultOrg?.name}</strong>
              ?
            </>
          ),
          subtitle:
            "This will remove them from all groups they are part of and will release an organization seat.",
        }}
        open={openRemoveUserModal}
        theme={YoodliCtaModalTheme.Danger}
        loading={loading}
        hideCloseButton={true}
        close={() => setOpenRemoveUserModal(false)}
        buttons={
          {
            primary: { text: "Remove from org", handler: removeMemberMutation.mutateAsync },
            secondary: { text: "Cancel", handler: () => setOpenRemoveUserModal(false) },
          } as CtaButtonHandlers
        }
      />
      <YoodliCtaModal
        ctaBody={{
          title: (
            <>
              Would you like to transfer ownership of this organization to{" "}
              <strong style={{ color: getDynamicColor("primary") }}>{selectedUser?.name}</strong>?
            </>
          ),
          subtitle:
            "They will be granted full administrative permissions including managing admin and members and managing content.",
        }}
        open={openTransferOwnershipModal}
        theme={YoodliCtaModalTheme.Primary}
        hideCloseButton={true}
        loading={loading}
        close={() => {
          setOpenTransferOwnershipModal(false);
        }}
        buttons={
          {
            primary: { text: "Confirm", handler: transferOwnershipMutation.mutate },
            secondary: {
              text: "Learn more about ownership",
              handler: () => {
                window.open(SupportPages.ORG_OVERVIEW_OWNERSHIP, "_blank");
              },
              variant: "text",
              buttonSx: { color: getDynamicColor("primary") },
            },
          } as CtaButtonHandlers
        }
      />
    </Stack>
  );
}
