import React from "react";

interface IVideoBlobContext {
  videoBlobObjectMapRef: React.MutableRefObject<{ [k: string]: Blob }>;
  recordedSpeechIdRef: React.MutableRefObject<string>;
  recordedSpeechDurationRef: React.MutableRefObject<number>;
}

export const VideoBlobContext = React.createContext<IVideoBlobContext>({
  videoBlobObjectMapRef: null,
  recordedSpeechIdRef: null,
  recordedSpeechDurationRef: null,
});
