/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export enum CustomEventEnum {
  NAV_DRAWER_OPEN = "navDrawerOpen",
  NAV_DRAWER_CLOSE = "navDrawerClose",
  PRICING_MODAL_OPEN = "pricingModalOpen",
  SHOW_USER_GUIDE = "showUserGuide",
  HIDE_USER_GUIDE = "hideUserGuide",
}

export enum FprUserGuideStepId {
  GetStarted = "getStarted",
  LearnHowToGrantAccess = "learnHowToGrantAccess",
  AllowAccess = "allowAccess",
  StartPractice = "startPractice",
  PracticeStarted = "practiceStarted",
  EnterQaMode = "enterQaMode",
  FinishPractice = "finishPractice",
  ViewAnalysis = "viewAnalysis",
  PersonalizeInterview = "personalizeInterview",
  FinishPracticePresentation = "finishPracticePresentation",
}

function subscribe(eventName: CustomEventEnum, listener: EventListener): void {
  document.addEventListener(eventName, listener);
}

function unsubscribe(eventName: CustomEventEnum, listener: EventListener): void {
  document.removeEventListener(eventName, listener);
}

function publish(eventName: CustomEventEnum, data?: any): void {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

export { publish, subscribe, unsubscribe };
