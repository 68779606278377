import React from "react";

// Components
import { Divider, Stack, Typography } from "@mui/material";
import { SelectHub } from "components/Orgs/BulkInviteByCSVWizard/SelectHub";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { WizardAddDraggableItems } from "./WizardAddDraggableItems";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { UpdateInterviewBankRequest } from "lib-fullstack/api/hubApiTypes";
import { RouteLeavingGuard } from "components/RouteLeavingGuard";

type CreateQuestionBankFormProps = {
  selectedBank: UpdateInterviewBankRequest;
  handleUpdateInterviewBank: (
    key: keyof UpdateInterviewBankRequest,
    value: string | string[]
  ) => void;
  hideGroupSelector?: boolean;
};

const MAX_QUESTIONS_IN_INTERVIEW_BANK = 20;
const MAX_QUESTION_INPUT_LENGTH = 350;

export const CreateQuestionBankForm = ({
  selectedBank,
  handleUpdateInterviewBank,
  hideGroupSelector,
}: CreateQuestionBankFormProps): JSX.Element => {
  const {
    adminInfo: { defaultOrg },
  } = React.useContext(UserOrgContext);
  const [currQuestions, setCurrQuestions] = React.useState<string[]>(
    selectedBank?.interview_questions ?? []
  );
  const [routeLeavingModalVisible, setRouteLeavingModalVisible] = React.useState<boolean>(false);

  // scroll to top on mount
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderRouteLeavingGuard = () => {
    return (
      <RouteLeavingGuard
        title="Exit question bank creation?"
        body="Any progress you have made so far will not be saved. Are you sure you want to exit?"
        okText="Yes, I'm sure"
        when={true}
        // Use as "message" prop of Prompt of React-Router
        shouldBlockNavigation={() => {
          if (!routeLeavingModalVisible) {
            return true;
          }
          return false;
        }}
        modalVisible={routeLeavingModalVisible}
        setModalVisible={setRouteLeavingModalVisible}
      />
    );
  };

  return (
    <Stack
      sx={{
        fontFamily: "poppins",
        p: { xs: 2, sm: 3, md: 0 },
        gap: { xs: 2, md: 2.5, lg: 5 },
      }}
    >
      {renderRouteLeavingGuard()}
      <Stack sx={{ gap: 1 }}>
        <Typography
          sx={{
            color: getDynamicColor("dark5"),
            fontSize: 18,
            fontWeight: 700,
          }}
        >
          Create a question bank
        </Typography>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          Create this bank and add a short description for use with interview scenarios and practice
        </Typography>
      </Stack>
      <Stack sx={{ gap: 4 }}>
        <YoodliLabeledInput
          label="Name"
          labelSx={{
            color: getDynamicColor("dark5"),
            fontSize: 14,
            fontWeight: 600,
          }}
          inputEl={
            <YoodliTextfield
              placeholder="e.g. Project Manager Behavioral"
              value={selectedBank.name ?? ""}
              onChange={(e) => handleUpdateInterviewBank("name", e.target.value)}
              sx={{
                "*": {
                  borderWidth: "1px !important",
                },
              }}
              maxChars={100}
            />
          }
        />
        <YoodliLabeledInput
          label="Description"
          labelSx={{
            color: getDynamicColor("dark5"),
            fontSize: 14,
            fontWeight: 600,
          }}
          inputEl={
            <YoodliTextfield
              placeholder="e.g. Practice questions for a behavioral interview for project manager"
              value={selectedBank.description ?? ""}
              onChange={(e) => handleUpdateInterviewBank("description", e.target.value)}
              sx={{
                "*": {
                  borderWidth: "1px !important",
                },
              }}
              maxChars={5000}
              multiline
              minRows={2}
            />
          }
        />
      </Stack>
      <Divider sx={{ mx: -5 }} />
      <Stack sx={{ gap: { xs: 2, md: 4, lg: 6 } }}>
        <Stack sx={{ gap: 1 }}>
          <Typography
            sx={{
              color: getDynamicColor("dark5"),
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            Add questions
          </Typography>
          <Typography
            sx={{
              color: getDynamicColor("purple3"),
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            Add up to {MAX_QUESTIONS_IN_INTERVIEW_BANK} questions to this question bank
          </Typography>
        </Stack>
        <WizardAddDraggableItems
          items={currQuestions}
          disableAutoFocus
          disallowDuplicates
          handleUpdateItems={(items) => {
            setCurrQuestions(items);
            handleUpdateInterviewBank("interview_questions", items);
          }}
          inputPlaceholder="e.g. Tell me about your background"
          maxItems={MAX_QUESTIONS_IN_INTERVIEW_BANK}
          maxInputLength={MAX_QUESTION_INPUT_LENGTH}
          itemLabel="interview questions"
          scrollBehaviorOptions={{ inline: "nearest", block: "nearest" }}
        />
      </Stack>
      {!hideGroupSelector && (
        <>
          <Divider sx={{ mx: -5 }} />
          <Stack sx={{ gap: { xs: 2, md: 4, lg: 6 } }}>
            <Stack sx={{ gap: 1 }}>
              <Typography
                sx={{
                  color: getDynamicColor("dark5"),
                  fontSize: 18,
                  fontWeight: 700,
                }}
              >
                Assign to groups (optional)
              </Typography>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                Select / deselect groups you want to assign this scenario to. You can also do this
                later from your library
              </Typography>
            </Stack>
            <SelectHub
              hideTitle
              hubSelectorSx={{
                width: "100%",
              }}
              allHubs={defaultOrg?.hubs}
              selectedHubIds={selectedBank.available_hubs ?? []}
              setSelectedHubIds={(selectedHubs) =>
                handleUpdateInterviewBank("available_hubs", selectedHubs)
              }
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};
