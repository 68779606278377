/**
 * List of available voices
 */

import { Language, RecordingSupportedLanguage } from "./enums";

/**
 * Metadata of individual voices
 * This type definition helps automatic interpolation in IDE.
 */
export type VoiceMetadata = {
  voiceId: string;
  name: string;
  sampleUrl: string;
  defaultProfilePictureId: string;
  vibe: string[];
  /**
   * Alternative voices for different languages. Currently, this is unused.
   * TODO, when we decide how to handle language voices, use or delete
   */
  altLanguageVoices?: Map<RecordingSupportedLanguage, string>;
};

/**
 * List of voices as a map
 * Current implementation uses the same ID for our ID (key of the map)
 * and the provider's voice ID (stored in the metadata)
 */
export const voices = new Map<string, VoiceMetadata>([
  [
    "mZ8K1MPRiT5wDQaasg3i",
    {
      voiceId: "mZ8K1MPRiT5wDQaasg3i",
      name: "Alex",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/alex.mp3",
      defaultProfilePictureId: "avatar_person11",
      vibe: ["calm", "british"],
      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "W5JElH3dK1UYYAiHH7uh", // Martin Osborne 2
        ],
        [
          Language.French,
          "j9RedbMRSNQ74PyikQwD", // Louis Boutin
        ],
      ]),
    },
  ],
  [
    "iP95p4xoKVk53GoZ742B",
    {
      voiceId: "iP95p4xoKVk53GoZ742B",
      name: "Chris",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/chris.mp3",
      defaultProfilePictureId: "avatar_person12",
      vibe: ["upbeat", "american"],
      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "Nh2zY9kknu6z4pZy6FhD", // David Martin 1
        ],
        [
          Language.French,
          "aQROLel5sQbj1vuIVi6B", // Nicolas
        ],
      ]),
    },
  ],
  [
    "OYTbf65OHHFELVut7v2H",
    {
      voiceId: "OYTbf65OHHFELVut7v2H",
      name: "Hope",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/hope.mp3",
      defaultProfilePictureId: "avatar_person1",
      vibe: ["casual", "american"],

      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "2Lb1en5ujrODDIqmp7F3", // Jhenny Antiques
        ],
        [
          Language.French,
          "FvmvwvObRqIHojkEGh5N", // Adina
        ],
      ]),
    },
  ],
  [
    "J5iaaqzR5zn6HFG4jV3b",
    {
      voiceId: "J5iaaqzR5zn6HFG4jV3b",
      name: "Joseph",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/joseph.mp3",
      defaultProfilePictureId: "avatar_person12",
      vibe: ["hesitant", "american"],

      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "Nh2zY9kknu6z4pZy6FhD", // David Martin 1
        ],
        [
          Language.French,
          "aQROLel5sQbj1vuIVi6B", // Nicolas
        ],
      ]),
    },
  ],
  [
    "pFZP5JQG7iQjIQuC4Bku",
    {
      voiceId: "pFZP5JQG7iQjIQuC4Bku",
      name: "Lily",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/lily.mp3",
      defaultProfilePictureId: "agnesPotts",
      vibe: ["narrative", "british"],

      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "2Lb1en5ujrODDIqmp7F3", // Jhenny Antiques
        ],
        [
          Language.French,
          "FvmvwvObRqIHojkEGh5N", // Adina
        ],
      ]),
    },
  ],
  [
    "2zRM7PkgwBPiau2jvVXc",
    {
      voiceId: "2zRM7PkgwBPiau2jvVXc",
      name: "Monika",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/monika.mp3",
      defaultProfilePictureId: "avatar_person4",
      vibe: ["pleasant", "indian english"],

      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "KHCvMklQZZo0O30ERnVn", // Sara Martin 1
        ],
        [
          Language.French,
          "McVZB9hVxVSk3Equu8EH", // Audrey
        ],
      ]),
    },
  ],
  [
    "owsLoyJNU4K7ctU6NF7F",
    {
      voiceId: "owsLoyJNU4K7ctU6NF7F",
      name: "Mun",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/mun.mp3",
      defaultProfilePictureId: "avatar_person8",
      vibe: ["professional", "singaporean"],

      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "Nh2zY9kknu6z4pZy6FhD", // David Martin 1
        ],
        [
          Language.French,
          "aQROLel5sQbj1vuIVi6B", // Nicolas
        ],
      ]),
    },
  ],
  [
    "h2sm0NbeIZXHBzJOMYcQ",
    {
      voiceId: "h2sm0NbeIZXHBzJOMYcQ",
      name: "Natasha",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/natasha.mp3",
      defaultProfilePictureId: "avatar_person2",
      vibe: ["thoughtful", "american"],
      altLanguageVoices: new Map([]),
    },
  ],
  [
    "EXAVITQu4vr4xnSDxMaL",
    {
      voiceId: "EXAVITQu4vr4xnSDxMaL",
      name: "Sarah",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/sarah.mp3",
      defaultProfilePictureId: "avatar_person8",
      vibe: ["formal", "american"],

      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "KHCvMklQZZo0O30ERnVn", // Sara Martin 1
        ],
        [
          Language.French,
          "McVZB9hVxVSk3Equu8EH", // Audrey
        ],
      ]),
    },
  ],
  [
    "x3gYeuNB0kLLYxOZsaSh",
    {
      voiceId: "x3gYeuNB0kLLYxOZsaSh",
      name: "Shrey",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/shrey.mp3",
      defaultProfilePictureId: "kevinBrown",
      vibe: ["confident", "indian english"],

      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "W5JElH3dK1UYYAiHH7uh", // Martin Osborne 2
        ],
        [
          Language.French,
          "j9RedbMRSNQ74PyikQwD", // Louis Boutin
        ],
      ]),
    },
  ],
  [
    "wsHQ9kO98zFXw3oQAHpK",
    {
      voiceId: "wsHQ9kO98zFXw3oQAHpK",
      name: "Ty",
      sampleUrl: "https://storage.googleapis.com/yoodli-public/voice_samples/ty.mp3",
      defaultProfilePictureId: "mikeMcMillan.png",
      vibe: ["smooth", "american"],

      altLanguageVoices: new Map([
        [
          Language.Spanish,
          "Nh2zY9kknu6z4pZy6FhD", // David Martin 1
        ],
        [
          Language.French,
          "aQROLel5sQbj1vuIVi6B", // Nicolas
        ],
      ]),
    },
  ],
]);
