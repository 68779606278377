import firebase from "firebase/app";
import { db } from "lib-fullstack";

// Utils
import { GetSpeechDownloadRedirectorUrl } from "lib-frontend/modules/AxiosInstance";
import { deleteSpeech } from "lib-frontend/utils/SpeechHandlers";

/**
 * Downloads speech locally to the user's Downloads folder
 */
export async function handleDownloadFile(dbSpeech: db.Doc<db.Speech>): Promise<void> {
  const downloadUrl = await GetSpeechDownloadRedirectorUrl(dbSpeech.ref.id);

  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.cssText = "display: block";
  a.href = downloadUrl;
  a.click();
  document.body.removeChild(a);
}

/**
 * Makes API call to delete the speech object. Upon successful completion, shows the Video Journal after
 */
export async function handleDeleteSpeech(dbSpeech: db.Doc<db.Speech>): Promise<void> {
  if (firebase.auth().currentUser) {
    if (db.userIdFor(dbSpeech) === firebase.auth().currentUser.uid) {
      console.log(`Soft-deleting recording: ${db.getRefPath(dbSpeech.ref)}`);
      await deleteSpeech(dbSpeech.ref, db.DeletedReason.USER);
      console.log(`Successfully soft deleted speech: ${db.getRefPath(dbSpeech.ref)}`);
    } else {
      throw new Error("Unauthorized - Can't delete speech because current user != speech owner");
    }
  } else {
    throw new Error("Unauthorized - can't find user");
  }
}

/**
 * Updates the title of the speech.
 */
export async function handleEditSpeechName(
  dbSpeech: db.Doc<db.Speech>,
  newName: string
): Promise<void> {
  // double-check ownership just to be safe.
  if (!firebase.auth().currentUser) {
    throw new Error("Unauthorized - can't find user");
  }
  if (db.userIdFor(dbSpeech) !== firebase.auth().currentUser?.uid) {
    throw new Error("Unauthorized - Can't rename speech because user != speech owner");
  }
  if (newName.trim().length === 0) {
    console.log("Ignoring empty speech name");
    return;
  }
  return db.update(dbSpeech.ref, { name: newName, nameWasEdited: true });
}

// if a user starts to edit the speech name, we consider that they have edited it for purposes of the nameWasEdited flag
// this means we won't overwrite the name with an AI title, even if the user hasn't changed the name
export async function handleStartEditSpeechName(dbSpeech: db.Doc<db.Speech>): Promise<void> {
  // double-check ownership just to be safe.
  if (!firebase.auth().currentUser) {
    throw new Error("Unauthorized - can't find user");
  }
  if (db.userIdFor(dbSpeech) !== firebase.auth().currentUser?.uid) {
    throw new Error("Unauthorized - Can't rename speech because user != speech owner");
  }
  return db.update(dbSpeech.ref, { nameWasEdited: true });
}
