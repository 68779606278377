import React from "react";
import { NavLink } from "react-router-dom";

// Components
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { NavigationItem } from "utils/Enums";

type NavItemProps = {
  item: NavigationItem;
  navOpen: boolean;
  hideIcon?: boolean;
  isBottomNavItem?: boolean;
  handleDrawerClose?: () => void;
};

// Item on navbar, loads in from enums
export const NavItem = React.memo(
  ({ item, hideIcon, isBottomNavItem, navOpen, handleDrawerClose }: NavItemProps): JSX.Element => {
    const isSmallScreen = useIsSmallScreen();
    const siteConf = getStaticFullSiteConf();

    const isNavBarSelectedUnderlined = siteConf?.featureDiffs?.underlineSelectedNavBarItem ?? false;
    const navBarSelectedTextColor =
      siteConf?.colors?.specialAccessibility2 ?? getDynamicColor("primary");
    if (!item) {
      return null;
    }
    return (
      <YoodliTooltip
        title={!navOpen && (item?.tooltip ?? item?.text)}
        placement="right"
        enterDelay={500}
      >
        {/* @ts-ignore  ... cant' get this forwardRef to play nice. Can we implement another way? */}
        <ListItemButton
          component={React.forwardRef((props, _ref) => (
            <NavLink
              {...props}
              to={{
                pathname: item.path.split("?")[0],
                search: item.path.split("?")[1],
              }}
              onClick={() => {
                // if the current path equals the item.path, close the nav bar
                if (window.location.pathname?.split("?")[0] === item.path.split("?")[0]) {
                  handleDrawerClose?.();
                }
              }}
              // 'renderKey', below, forces route to re-render as if navigated
              //  to from a different page. Without renderKey, clicking on
              //  the current page would be a no-op.
              state={{ renderKey: new Date().toISOString() }}
              style={({ isActive }) => ({
                color: isActive ? getDynamicColor("primary") : undefined,
                fill: isActive ? getDynamicColor("primary") : undefined,
              })}
            />
          ))}
          sx={{
            display: "flex",
            py: isSmallScreen ? 0 : "auto",
            "&.active span.MuiTypography-root": {
              color: navBarSelectedTextColor,
              textDecoration: isNavBarSelectedUnderlined ? "underline" : "none",
            },
            color: isToastmasters() ? getDynamicColor("light1") : getDynamicColor("purple3"),
            height: { xs: 42, md: isBottomNavItem ? "50px" : "54px" },
            minWidth: "100%",
            justifyContent: "center",
            alignItems: "center",
            px: 0,
          }}
        >
          {!hideIcon && (
            <ListItemIcon
              sx={{
                color: "currentColor",
                fill: "currentColor",
                minWidth: "unset",
                svg: {
                  mx: "auto",
                  display: "block",
                  fill: "currentColor",
                  path: {
                    fill: "currentColor",
                  },
                },
                transition: "none !important",
                width: 74,
                flexGrow: !open ? 1 : "unset",
                ...item.iconOverrideSx,
              }}
            >
              {item.icon}
            </ListItemIcon>
          )}
          <ListItemText
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: isSmallScreen ? "32px" : "24px",
              display: "flex",
              alignItems: "center",
              fontFamily: "poppins",
              flexGrow: 1,
              whiteSpace: "nowrap",
            }}
            data-testid={item.dataTestId}
          >
            {item.text}
          </ListItemText>
        </ListItemButton>
      </YoodliTooltip>
    );
  }
);
