import firebase from "firebase/app";
import { db } from "lib-fullstack";
import React from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router";

// Components
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GOOGLE_SCOPE, initAuthFlow } from "utils/calendarUtils";
import { OrganizationSettings } from "components/Orgs/OrgSettings/OrganizationSettings";
import PageContent from "components/PageContent";
import { ContainedLoadingAnimation } from "lib-frontend/components/Animations/LoadingAnimation";

// Assets
import GoogleIcon from "../../../images/icons/GoogleCalendarIcon.svg";
import OutlookIcon from "../../../images/icons/OutlookCalendarIcon.svg";

// Utils
import { IOSSwitch } from "../Settings";
import { AccountInfo } from "./AccountInfo";
import { DownloadDesktopApp } from "./DownloadDesktopApp";
import { NotifyMeWhen } from "./NotifyMeWhen";
import { PrivacySection } from "./PrivacySection";
import { WhenRecordSpeech } from "./WhenRecordSpeech";
import { hasGrantedAllScopesGoogle, useGoogleLogin } from "@react-oauth/google";
import { useOnAll } from "@typesaurus/react";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  disconnectCalendar,
  addGoogleCalendar,
  addOutlookCalendar,
  syncCalendarEvents,
  setCredentials,
  changeDefaultJoin,
  changeCalendarMode,
} from "lib-frontend/modules/AxiosInstance";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { isWhiteLabel } from "lib-frontend/utils/Utilities";
import { UITestId } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { EventsCalHows, EventsCalWheres } from "lib-fullstack/utils/productAnalyticEvents";

const GeneralSettings = (): JSX.Element => {
  const location = useLocation();
  const { orgModuleAccess, fullOrgList } = React.useContext(UserOrgContext);
  // State
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [calendarPreferenceMenuOpen, setCalendarPreferenceMenuOpen] =
    React.useState<boolean>(false);
  const [dbCalendars, dbCalendarsMeta] = useOnAll(
    db.userCalendars([getSiteId(), firebase.auth()?.currentUser?.uid])
  );

  if (dbCalendarsMeta.error) {
    console.error(dbCalendarsMeta.error);
    throw new Error(dbCalendarsMeta.error.toString());
  }

  React.useEffect(() => {
    if (location.search.includes("code")) {
      const code = new URLSearchParams(location.search).get("code");
      const state = new URLSearchParams(location.search).get("state");
      setLoading(true);
      addOutlookCalendar(
        code,
        location.pathname,
        (state as db.CalendarMode) ?? db.CalendarMode.POODLI
      )
        .then(async () => {
          setLoading(false);
          await syncCalendarEvents();
          Instrumentation.logCalConnectedCheckpoint(
            EventsCalHows.OUTLOOK,
            EventsCalWheres.SETTINGS
          );
          setError(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
          console.error(err);
        });
    }
  }, []);

  // Event Handlers
  const handleDisconnectCalendar = async () => {
    setLoading(true);
    await disconnectCalendar(dbCalendars[0].ref);
    Instrumentation.logCalDisconnectedCheckpoint(EventsCalWheres.SETTINGS);
    setLoading(false);
  };
  const handleChangeDefaultJoin = async (e) => {
    setLoading(true);
    const dbCalendar = dbCalendars[0];
    await changeDefaultJoin(dbCalendar.ref, e.target.value);
    setLoading(false);
  };
  const handleSuccess = async (code) => {
    setLoading(true);
    await addGoogleCalendar(code, db.CalendarMode.POODLI);
    Instrumentation.logCalConnectedCheckpoint(EventsCalHows.GOOGLE, EventsCalWheres.SETTINGS);
    setLoading(false);
    await syncCalendarEvents();
  };

  const auth = async (codeResponse) => {
    if (!hasGrantedAllScopesGoogle(codeResponse, GOOGLE_SCOPE)) {
      await setCredentials(codeResponse.code);
      googleLogin();
    } else {
      await handleSuccess(codeResponse);
    }
  };

  const toggleCalendarMode = async () => {
    setLoading(true);
    const switchToMode =
      dbCalendars[0].data.mode === db.CalendarMode.ZOODLI
        ? db.CalendarMode.POODLI
        : db.CalendarMode.ZOODLI;

    // log to amplitude first as the actual swap can take a while
    Instrumentation.logCalSwitchCheckpoint(switchToMode, EventsCalWheres.SETTINGS);
    await changeCalendarMode(dbCalendars[0].ref, switchToMode);
    setLoading(false);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => auth(codeResponse),
    onError: (errorResponse) => console.error(errorResponse),
    flow: "auth-code",
    scope: GOOGLE_SCOPE,
  });

  // Render
  return (
    <PageContent
      wrapperSx={{
        backgroundColor: getDynamicColor("light1"),
      }}
    >
      <Box
        px={{ sm: 2 }}
        sx={{
          mx: "auto",
          maxWidth: "xl",
        }}
      >
        <Box pt={4} pb={{ xs: 6, md: 10 }} px={{ xs: 2 }}>
          <Stack direction="column" spacing={2} overflow="hidden">
            {orgModuleAccess.desktopAppEnabled && !isMobile && !isWhiteLabel() && (
              <>
                <DownloadDesktopApp />
                <Divider style={{ margin: "32px 0", zIndex: 2 }} />
              </>
            )}
            <Stack spacing={2} zIndex={2}>
              <Typography variant="h2" sx={{ fontWeight: 700, fontFamily: "Poppins" }}>
                Calendar
              </Typography>
              {!loading ? (
                dbCalendars && dbCalendars.length ? (
                  <Stack spacing={2}>
                    <Typography fontWeight={600} zIndex={2}>
                      Your calendar is connected to{" "}
                      {dbCalendars[0].data.mode === db.CalendarMode.POODLI
                        ? "the desktop app"
                        : "Team Yoodli"}
                    </Typography>
                    {!isWhiteLabel() && dbCalendars[0].data.mode === db.CalendarMode.ZOODLI && (
                      <Grid container alignItems="center">
                        <Grid item xs={12} md={6} sx={{ mt: { xs: 3 } }}>
                          <Typography>Switch Mode</Typography>
                        </Grid>
                        <Grid item sm={12} md={6} sx={{ mt: { xs: 2 } }}>
                          <Stack direction="row" gap={2} alignItems="center">
                            <Typography
                              sx={{
                                fontWeight: 600,
                              }}
                            >
                              Team Yoodli
                            </Typography>
                            <IOSSwitch onClick={toggleCalendarMode} checked={false} />
                            <Typography>Desktop App</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    )}
                    <Grid container alignItems="center" sx={{ mt: { xs: 4 } }}>
                      <Grid item xs={12} md={6} sx={{ mt: { xs: 3 } }}>
                        <Typography>Auto-join Meetings</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} sx={{ mt: { xs: 2 } }}>
                        <FormControl
                          sx={{
                            width: { xs: "100%", md: "auto" },
                          }}
                        >
                          <Select
                            inputProps={{
                              "data-testid": UITestId.DefaultPreference,
                            }}
                            value={
                              calendarPreferenceMenuOpen &&
                              dbCalendars[0].data.defaultJoin === "smart"
                                ? "smart-hidden"
                                : dbCalendars[0].data.defaultJoin
                            }
                            onChange={handleChangeDefaultJoin}
                            onOpen={() => setCalendarPreferenceMenuOpen(true)}
                            onClose={() => setCalendarPreferenceMenuOpen(false)}
                          >
                            <MenuItem value="smart-hidden" sx={{ display: "none" }}>
                              Smart Detection
                            </MenuItem>
                            <MenuItem value="smart">
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                width="100%"
                                gap={1}
                              >
                                <Typography>Smart Detection</Typography>
                                <Tooltip
                                  title="Yoodli will only join meetings during working hours (8am - 6pm) where you are the organizer"
                                  placement="bottom-end"
                                  PopperProps={{
                                    sx: {
                                      "& .MuiTooltip-tooltip": {
                                        backgroundColor: getDynamicColor("light1"),
                                        boxShadow: Y_SHADOWS.box_shadow_1,
                                        color: getDynamicColor("purple3"),
                                        px: 3,
                                        py: 1.5,
                                      },
                                    },
                                  }}
                                >
                                  <InfoOutlinedIcon sx={{ color: getDynamicColor("dark4") }} />
                                </Tooltip>
                              </Stack>
                            </MenuItem>
                            <MenuItem value="all">All Meetings</MenuItem>
                            <MenuItem value="organizer_only">
                              Only meetings where you are organizer
                            </MenuItem>
                            <MenuItem value="none">None</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid xs={12} md={6} item sx={{ mt: { xs: 3 } }}>
                        <Typography>{`Connected calendar: ${
                          dbCalendars[0].data.email ?? dbCalendars[0].data.calendarId
                        } (${
                          dbCalendars[0].data.service === "gcal"
                            ? "Google Calendar"
                            : "Outlook Calendar"
                        })`}</Typography>
                      </Grid>
                      <Grid sm={12} md={6} item sx={{ my: { xs: 4 }, mx: { xs: "auto" } }}>
                        <Button
                          color="error"
                          variant="contained"
                          onClick={handleDisconnectCalendar}
                        >
                          Disconnect Calendar
                        </Button>
                      </Grid>
                    </Grid>
                  </Stack>
                ) : (
                  <Grid container alignItems="center" justifyContent="space-between" zIndex={2}>
                    <Grid
                      item
                      sx={{ mb: { xs: 4, md: 2 } }}
                      flexWrap={"wrap"}
                      xs={10}
                      md={6}
                      lg={6}
                    >
                      <Typography className="font-size-label">No calendar connected</Typography>
                    </Grid>
                    <Grid item lg={6} sx={{ mx: { xs: "auto" }, mb: 4 }}>
                      <Stack direction="column" spacing={{ xs: 4, md: 2 }} maxWidth="250px">
                        <Button
                          onClick={() => googleLogin()}
                          variant="outlined"
                          sx={{
                            width: "100%",
                            ".MuiButton-startIcon": {
                              height: "28px",
                              width: "28px",
                            },
                          }}
                          startIcon={<img src={GoogleIcon} />}
                        >
                          Google Calendar
                        </Button>

                        <Button
                          onClick={() => initAuthFlow(WebServerExternalPath.ACCOUNT)}
                          variant="outlined"
                          sx={{
                            width: "100%",
                            ".MuiButton-startIcon": {
                              height: "28px",
                              width: "28px",
                            },
                          }}
                          startIcon={<img src={OutlookIcon} alt="Microsoft icon" />}
                        >
                          Outlook Calendar
                        </Button>
                      </Stack>
                    </Grid>
                    {error && (
                      <Typography
                        sx={{
                          color: getDynamicColor("redError"),
                          mx: "auto",
                        }}
                      >
                        Error connecting calendar
                      </Typography>
                    )}
                  </Grid>
                )
              ) : (
                <Box width="100%" justifyContent="center" display="flex">
                  <Box
                    width={{ xs: "100%", lg: "200px" }}
                    height="auto"
                    data-testid={UITestId.LoadingAnimation}
                  >
                    <ContainedLoadingAnimation showPrompt={false} />
                  </Box>
                </Box>
              )}
            </Stack>
            <Divider style={{ margin: "32px 0", zIndex: 2 }} />
            <NotifyMeWhen />
            <Divider style={{ margin: "32px 0", zIndex: 2 }} />
            <WhenRecordSpeech />
            <Divider style={{ margin: "32px 0", zIndex: 2 }} />
            {fullOrgList?.length > 0 && (
              <Stack spacing={2}>
                <OrganizationSettings />
                <Divider style={{ margin: "32px 0", zIndex: 2 }} />
              </Stack>
            )}
            <PrivacySection />
            <Divider style={{ margin: "32px 0", zIndex: 2 }} />
            <AccountInfo />
          </Stack>
        </Box>
      </Box>
    </PageContent>
  );
};

export default GeneralSettings;
