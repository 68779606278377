import firebase from "firebase/app";
import { db } from "lib-fullstack";
import React from "react";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { getCoachBotContent } from "lib-frontend/modules/AxiosInstance";
// Components

import {
  Accordion,
  AccordionActions,
  Box,
  Link,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Launch as LaunchIcon, ExpandLess as ExpandLessIcon } from "@mui/icons-material";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import { RouteLeavingGuard } from "components/RouteLeavingGuard";
import { YoodliAutocomplete } from "lib-frontend/components/YoodliComponents/YoodliAutocomplete";
import { YoodliSelectOption } from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Assets
import { ReactComponent as FlagIcon } from "images/icons/icon-flag.svg";
import { ReactComponent as ThumbsUpIcon } from "images/icons/icon-thumbs-up.svg";

// Utils
import { SUPPORT_ARTICLES } from "lib-fullstack/utils/constants";
import { useUpdateCoachBot } from "./useUpdateCoachBot";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getCoachBotFeedback } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getSiteId } from "lib-frontend/utils/LiveSiteDocs";
import { GetCoachBotFeedbackResponse } from "lib-fullstack/api/analyticsApiTypes";
import { LifecycleState } from "lib-fullstack/db";
import { AnalyticProcessingState } from "lib-fullstack/utils/enums";

const COACHBOT_STYLE_GUIDE_QUERY_KEY = "coachbotStyleGuideSpeeches";

export const CoachBotStyleGuide = ({
  coachBotId,
  styleGuideContent,
}: {
  coachBotId: string;
  styleGuideContent: string;
}): JSX.Element => {
  const { handleUpdateCoachBot } = useUpdateCoachBot();

  const { defaultOrgId } = React.useContext(UserOrgContext);

  const [styleGuideSaveState, setStyleGuideSaveState] = React.useState<"saving" | "saved" | null>(
    null
  );

  const [routeLeavingModalVisible, setRouteLeavingModalVisible] = React.useState<boolean>(false);

  const [loadingFeedback, setLoadingFeedback] = React.useState<boolean>(false);

  const [selectedSpeech, setSelectedSpeech] = React.useState<YoodliSelectOption | null>(null);
  const [speechInputValue, setSpeechInputValue] = React.useState<string>("");
  const [styleGuideVal, setStyleGuideVal] = React.useState<string>(styleGuideContent ?? "");
  const [coachBotPreviewError, setCoachBotPreviewError] = React.useState<string | null>(null);

  const [showingGrowthAccordion, setShowingGrowthAccordion] = React.useState<boolean>(false);

  const coachBotPath = `sites/${getSiteId()}/orgs/${defaultOrgId}/coachBots/${coachBotId}`;

  const { data: hasBotContent } = useApiQuery({
    queryKey: ["coachBot", defaultOrgId, coachBotId],
    queryFn: async () => {
      const res = await getCoachBotContent(defaultOrgId, coachBotId);
      console.log(res);
      return (
        res?.botContent?.filter(
          (content) => content?.isActive && content?.status !== AnalyticProcessingState.ERROR
        )?.length > 0
      );
    },
    enabled: !!defaultOrgId && !!coachBotId,
  });

  const validSpeechesQuery = useApiQuery({
    queryKey: [COACHBOT_STYLE_GUIDE_QUERY_KEY, getSiteId(), firebase.auth().currentUser?.uid],
    queryFn: async () => {
      const speeches = await db.query<db.Speech>(
        db.userSpeeches([getSiteId(), firebase.auth().currentUser?.uid]),
        [
          db.where("lifecycleState", "==", LifecycleState.CREATED),
          db.order("recordedDate", "desc"), // Order by recordedDate to get the most recent
          db.limit(100), // query 100 so i can filter down to 50 and reliably have enough speeches
        ]
      );
      // filter to only speeches >29 totalTimeS
      // this cannot be done in the same query because firebase requires the first orderBy to match any range filter field (and also sucks)
      return speeches.filter((speech) => speech.data.totalTimeS > 29).slice(0, 50);
    },
  });
  const { data: speeches, isLoading: loadingSpeeches } = validSpeechesQuery;

  const selectedSpeechObj = React.useMemo(() => {
    return speeches?.find((speech) => speech.ref.id === selectedSpeech?.value);
  }, [speeches, selectedSpeech]);

  // when the styl;e guide changes, reset saved state
  React.useEffect(() => {
    setStyleGuideSaveState(null);
  }, [styleGuideVal]);

  // if the speech ever changes, reset the feedback
  // but save the results of the feedback along with the speech id in a cache state object so if i go back to this speech, we dont have to refetch
  // but allow refetching by clicking "Update" again
  const [feedbackCache, setFeedbackCache] = React.useState<{
    [key: string]: GetCoachBotFeedbackResponse;
  } | null>(null);

  const feedback = React.useMemo(() => {
    return feedbackCache?.[selectedSpeechObj?.ref?.id];
  }, [feedbackCache, selectedSpeechObj]);

  // clear the cache if the style guide ever changes
  React.useEffect(() => {
    setFeedbackCache(null);
  }, [styleGuideVal]);

  const updateCoachBotStyleGuide = React.useCallback(
    async (value: string) => {
      await handleUpdateCoachBot({
        orgId: defaultOrgId,
        botId: coachBotId,
        updateOptions: {
          styleGuide: {
            botId: coachBotId,
            content: value,
            createdAt: new Date().toISOString(),
            isActive: !!value,
          },
        },
      });
    },
    [defaultOrgId, coachBotId, handleUpdateCoachBot]
  );

  const handleGetCoachingFeedback = async () => {
    try {
      setLoadingFeedback(true);
      setCoachBotPreviewError(null);
      const ret = await getCoachBotFeedback(
        coachBotPath,
        db.getRefPath(selectedSpeechObj?.ref),
        styleGuideVal
      );
      setFeedbackCache({
        ...feedbackCache,
        [selectedSpeechObj?.ref?.id]: ret,
      });
    } catch (er) {
      setCoachBotPreviewError("Error generating feedback, please try again");
      console.error("Error generating example coachbot feedback", er);
    } finally {
      setLoadingFeedback(false);
    }
  };

  const renderSpeechSelector = () => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={{ xs: 1, md: 2 }}
      >
        <YoodliAutocomplete
          sx={{
            flexGrow: 1,
          }}
          disabled={loadingSpeeches}
          placeholder="Choose a speech"
          value={selectedSpeech}
          onChange={(event, newValue: YoodliSelectOption | null) => {
            setSelectedSpeech(newValue);
          }}
          inputValue={speechInputValue}
          onInputChange={(event, newInputValue) => {
            setSpeechInputValue(newInputValue);
          }}
          options={[
            ...(speeches ?? []).map((speech) => ({
              value: speech?.ref?.id,
              label: speech?.data?.name,
            })),
          ]}
        />
        <Stack direction="row" gap={{ xs: 0, md: 1 }}>
          <YoodliTooltip
            title={
              hasBotContent
                ? ""
                : "This Coach Bot doesn't have any uploaded content enabled, so a preview cannot be generated"
            }
          >
            <Button
              disabled={!hasBotContent || !selectedSpeech || loadingFeedback || loadingSpeeches}
              variant="contained"
              onClick={handleGetCoachingFeedback}
              sx={{
                fontSize: 14,
                height: 40,
              }}
            >
              {feedback && selectedSpeech ? "Update" : "Preview"}
            </Button>
          </YoodliTooltip>
          {feedback && !loadingFeedback && selectedSpeech && (
            // this button opens the speech in a new tab so the user can view the speech summary
            <YoodliTooltip title="Visit the speech summary">
              <IconButton
                target="_blank"
                href={`/share/${selectedSpeechObj?.data.slug}`}
                sx={{
                  color: getDynamicColor("primary"),
                }}
              >
                <LaunchIcon />
              </IconButton>
            </YoodliTooltip>
          )}
        </Stack>
      </Stack>
    );
  };

  const renderGenerateNotesSection = () => {
    return (
      <Stack
        direction="column"
        gap={{ xs: 2, md: 3 }}
        sx={{
          pb: { xs: 2, md: 4, lg: 6 },
        }}
      >
        <Stack direction="column" gap={1}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            Generate feedback preview
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: getDynamicColor("dark4"),
            }}
          >
            Choose a speech to review example coaching feedback using the guide above.
          </Typography>
        </Stack>
        {renderSpeechSelector()}
        <Fade
          in={
            !!coachBotPreviewError ||
            loadingFeedback ||
            (!coachBotPreviewError && !loadingFeedback && !!feedback)
          }
        >
          <Box
            component="span"
            sx={{
              mt: loadingFeedback ? { xs: 2, md: 4 } : 0,
            }}
          >
            <Stack
              direction="column"
              alignItems="center"
              gap={2}
              sx={{
                transitionDelay: !loadingFeedback ? "800ms" : "0ms",
                height: loadingFeedback ? "auto" : 0,
                overflow: "hidden",
              }}
            >
              <CircularProgress />
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: 14,
                  fontWeight: 600,
                  color: getDynamicColor("dark4"),
                }}
              >
                This may take up to a minute to load!
              </Typography>
            </Stack>
            {renderFeedback()}
          </Box>
        </Fade>
      </Stack>
    );
  };

  const feedbackCardStyles = {
    border: `1px solid ${getDynamicColor("dark3")}`,
    borderRadius: "12px",
    p: 2.5,
  };

  const renderFeedback = () => {
    if (coachBotPreviewError) {
      return (
        <Typography
          sx={{
            color: getDynamicColor("redError"),
            fontWeight: 600,
            mx: "auto",
            textAlign: "center",
          }}
        >
          {coachBotPreviewError}
        </Typography>
      );
    }
    if (!feedback || loadingFeedback) {
      return null;
    }
    return (
      <Stack direction="column" gap={3}>
        {feedback.strength && (
          <Stack direction="column" sx={feedbackCardStyles} gap={2}>
            <Stack direction="row">
              <ThumbsUpIcon />
              <Typography
                sx={{
                  color: getDynamicColor("greenSuccess"),
                  fontWeight: 500,
                  fontFamily: "poppins",
                }}
              >
                Strength
              </Typography>
            </Stack>
            <Typography
              sx={{
                fontSize: 16,
              }}
            >
              {feedback.strength}
            </Typography>
          </Stack>
        )}
        {feedback?.growthArea && (
          <Stack direction="column" sx={feedbackCardStyles} gap={2}>
            <Stack direction="row">
              <FlagIcon />
              <Typography
                sx={{
                  color: getDynamicColor("redError"),
                  fontWeight: 500,
                  fontFamily: "poppins",
                }}
              >
                Growth area
              </Typography>
            </Stack>
            <Stack gap={1}>
              <Typography>{feedback.growthArea}</Typography>
              <Accordion
                expanded={showingGrowthAccordion}
                sx={{
                  border: "none",
                  boxShadow: "none",
                  mt: "0px !important",
                  "&::before": {
                    display: "none",
                  },
                }}
              >
                <AccordionActions
                  sx={{
                    p: 0,
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowingGrowthAccordion(!showingGrowthAccordion);
                    }}
                    variant="text"
                    disableRipple
                    endIcon={
                      <ExpandLessIcon
                        sx={{
                          transform: !showingGrowthAccordion ? "rotate(180deg)" : "rotate(0deg)",
                          transition: "transform 0.3s",
                          height: 18,
                          width: 18,
                        }}
                      />
                    }
                    sx={{
                      mr: "auto",
                      fontSize: 12,
                      fontWeight: 500,
                      px: 0,
                      pb: 1,
                    }}
                  >
                    See {showingGrowthAccordion ? "less" : "more"}
                  </Button>
                </AccordionActions>
                <Typography>{feedback.growthAreaShowMore}</Typography>
              </Accordion>
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  };

  return (
    <Stack direction="column" gap={{ xs: 3, md: 5 }}>
      <RouteLeavingGuard
        when={styleGuideVal !== styleGuideContent}
        shouldBlockNavigation={() => {
          if (styleGuideVal !== styleGuideContent || styleGuideSaveState === "saving") {
            return true;
          }
          return false;
        }}
        title="You are leaving this page"
        body="Heads up! If you leave before saving your changes will be lost. Are you sure you want to leave?"
        modalVisible={routeLeavingModalVisible}
        setModalVisible={setRouteLeavingModalVisible}
        okButtonSx={{ width: 90 }}
        cancelButtonSx={{ width: 90 }}
      />
      <Stack direction="column" gap={{ xs: 2, md: 3 }}>
        <Stack direction="column" gap={1}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            Add coaching notes
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: getDynamicColor("dark4"),
            }}
          >
            Add notes to guide the AI in delivering coaching in your preferred style.{" "}
            <Link
              href={SUPPORT_ARTICLES.COACHBOT}
              target="_blank"
              sx={{
                fontWeight: 600,
              }}
            >
              More Info
            </Link>
          </Typography>
        </Stack>
        <Stack gap={2}>
          <YoodliTextfield
            multiline
            placeholder="You should provide helpful coaching feedback in a friendly voice, focused on the speaker's content"
            maxChars={5000}
            minRows={12}
            value={styleGuideVal}
            onChange={(event) => {
              setStyleGuideVal(event.target.value);
            }}
          />
          <Button
            variant="contained"
            disabled={
              !["saving", "saved"].includes(styleGuideSaveState) &&
              styleGuideVal === styleGuideContent
            }
            sx={{
              fontSize: 14,
              ml: "auto",
              minWidth: 120,
              height: 40,
            }}
            onClick={async () => {
              if (["saved", "saving"].includes(styleGuideSaveState)) {
                return;
              }
              setStyleGuideSaveState("saving");
              await updateCoachBotStyleGuide(styleGuideVal)
                .then(() => {
                  setStyleGuideSaveState("saved");
                })
                .catch((err) => {
                  console.error("Error updating coachbot style guide", err);
                  setStyleGuideSaveState(null);
                });
            }}
          >
            {styleGuideSaveState === "saving"
              ? "Saving..."
              : styleGuideSaveState === "saved"
                ? "Saved!"
                : "Save"}
          </Button>
        </Stack>
      </Stack>
      {renderGenerateNotesSection()}
    </Stack>
  );
};
