import React from "react";

interface ICurrentlyUploadingFilesContext {
  currentlyUploadingFiles: File[];
  setCurrentlyUploadingFiles: (f: File[]) => void;
}

export const CurrentlyUploadingFilesContext = React.createContext<ICurrentlyUploadingFilesContext>({
  currentlyUploadingFiles: [],
  setCurrentlyUploadingFiles: () => {
    return;
  },
});
