import { isValidUrl } from "utils/navUtils";

import type { Editor } from "@tiptap/react" with { "resolution-mode": "import" };
import type { Extensions } from "@tiptap/core" with { "resolution-mode": "import" };

let useEditor;
import("@tiptap/react")
  .then((module) => {
    useEditor = module.useEditor;
  })
  .catch((error) => console.error(`useWYSIWYG: failed to import @tiptap/react: ${error}`));

let StarterKit;
import("@tiptap/starter-kit")
  .then((module) => {
    StarterKit = module.StarterKit;
  })
  .catch((error) => console.error(`useWYSIWYG: failed to import @tiptap/starter-kit: ${error}`));

let CharacterCount;
import("@tiptap/extension-character-count")
  .then((module) => {
    CharacterCount = module.CharacterCount;
  })
  .catch((error) =>
    console.error(`useWYSIWYG: failed to import @tiptap/extension-character-count: ${error}`)
  );

let Link;
import("@tiptap/extension-link")
  .then((module) => {
    Link = module.Link;
  })
  .catch((error) => console.error(`useWYSIWYG: failed to import @tiptap/extension-link: ${error}`));

let TextStyle;
import("@tiptap/extension-text-style")
  .then((module) => {
    TextStyle = module.TextStyle;
  })
  .catch((error) =>
    console.error(`useWYSIWYG: failed to import @tiptap/extension-text-style: ${error}`)
  );

let Underline;
import("@tiptap/extension-underline")
  .then((module) => {
    Underline = module.Underline;
  })
  .catch((error) =>
    console.error(`useWYSIWYG: failed to import @tiptap/extension-underline: ${error}`)
  );

let Placeholder;
import("@tiptap/extension-placeholder")
  .then((module) => {
    Placeholder = module.Placeholder;
  })
  .catch((error) =>
    console.error(`useWYSIWYG: failed to import @tiptap/extension-placeholder: ${error}`)
  );

interface UseWYSIWYGProps {
  content?: string;
  characterLimit?: number;
  placeholder?: string;
  onUpdate?: (updated: string) => void;
}

export const useWYSIWYG = ({
  content = "",
  characterLimit,
  placeholder,
  onUpdate,
}: UseWYSIWYGProps = {}): {
  editor: Editor | null;
  characterCount: number;
  isAtCharacterLimit: boolean;
} => {
  if (
    !useEditor ||
    !StarterKit ||
    !CharacterCount ||
    !Link ||
    !TextStyle ||
    !Underline ||
    !Placeholder
  ) {
    console.log("useWYSIWYG: one of modules is not yet loaded");
    return null;
  }

  const extensions: Extensions = [
    StarterKit,
    CharacterCount.configure({
      limit: characterLimit,
    }),
    TextStyle,
    Underline,
    Link.configure({
      openOnClick: false,
      linkOnPaste: false,
      validate: (href) => isValidUrl(href),
      autolink: false,
    }),
    // Add this conditional extension
    ...(placeholder ? [Placeholder.configure({ placeholder })] : []),
  ];

  const editor = useEditor({
    content,
    extensions,
    onUpdate: ({ editor }) => {
      onUpdate?.(editor.getHTML());
    },
    editorProps: {
      attributes: {
        // WYSIWYG editors should not navigate when enter is pressed
        class: "blockEnterToNavigate",
      },
    },
  });

  return {
    editor,
    characterCount: editor?.storage.characterCount.characters() ?? 0,
    isAtCharacterLimit: characterLimit
      ? (editor?.storage.characterCount.characters() ?? 0) >= characterLimit
      : false,
  };
};
