// Components
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/system";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

export const classes = {
  closeIcon: "closeIcon",
  headerIconContainer: "headerIconContainer",
  headerLabel: "headerLabel",
  sendInviteButton: "sendInviteButton",
  sendInviteButtonTypography: "sendInviteButtonTypography",
  privacySettingsContainer: "privacySettingsContainer",
  linkIcon: "linkIcon",
  copyLinkButton: "copyLinkButton",
  copyLinkTypography: "copyLinkTypography",
  formControl: "formControl",
  checkboxContainer: "checkboxContainer",
  checkbox: "checkbox",
};

export const ShareSpeechWindowWrapper = styled(Dialog)({
  "& .MuiDialogContent-root": {
    overflow: "hidden",
  },
  ".closeIcon": {
    width: "35px",
    height: "35px",
    color: getDynamicColor("dark6"),
    borderRadius: "15px",
  },
  ".headerIconContainer": {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    color: getDynamicColor("light1"),
    backgroundColor: getDynamicColor("secondary"),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "15px",
  },
  ".headerLabel": {
    fontSize: "18px",
  },
  ".sendInviteButton": {
    backgroundColor: getDynamicColor("primary"),
    // marginLeft: "15px",
    // marginBottom: "15px",

    "&:hover": {
      background: getDynamicColor("secondary"),
    },
  },
  ".sendInviteButtonTypography": {
    color: "white",
    textTransform: "none",
    fontSize: "14px",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  ".privacySettingsContainer": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "32px",
    paddingRight: "25px",
    backgroundColor: `${getDynamicColor("light2")}`,
  },

  ".linkIcon": {
    color: `${getDynamicColor("purple3")}`,
    marginRight: "10px",
    width: "30px",
    height: "30px",
  },
  ".copyLinkButton": {
    border: `1px solid ${getDynamicColor("dark3")}`,
    "&.MuiButton-root:hover": {
      border: `1px solid ${getDynamicColor("dark5")}`,
    },
    "&.MuiButton-root .MuiButton-startIcon svg": {
      fill: "#000000",
    },
    px: 2.75,
    py: 1,
  },
  ".formControl": {
    marginBottom: "10px",
    fontSize: "14px",
    borderRadius: "2%",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
  },
  ".checkboxContainer": {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    fontSize: "13px",
    alignSelf: "center",
    marginTop: "-10px",
  },
  ".checkbox": {
    width: "30px",
    height: "30px",
  },
});
