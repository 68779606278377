import firebase from "firebase/app";
import React from "react";
import { useNavigate, useLocation } from "react-router";

// Components
import { Box, Stack, Typography } from "@mui/material";
import {
  CreateConvoScenarioStepId,
  MAX_AI_CONCERNS,
  MAX_DND_INPUT_LENGTH,
  TEMPLATE_SUB_TYPE_DATA,
  GoalTalkTime,
  convertGoalTalkTimeToS,
  CustomizePracticeQueryKey,
  WizardState,
  binaryGoalBase,
  compoundGoalBase,
  ratedGoalBase,
  CUSTOM_GOAL_FLOW_LENGTH,
  GoalHooks,
  FPRScenarioQueryKey,
  MAX_SCENARIO_DESCRIPTION_LENGTH,
} from "components/ConvoScenarios/convoScenarioUtils";
import { SelectHub } from "components/Orgs/BulkInviteByCSVWizard/SelectHub";
import { GoalSelector } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/GoalSelector";
import { PersonaSelector } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/PersonaSelector";
import {
  AdditionalContextDetails,
  ScenarioAdditionalContext,
} from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/ScenarioAdditionalContext";
import {
  ScenarioDescriptors,
  ScenarioPreview,
} from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/ScenarioPreview";
import { WizardAddDraggableItems } from "components/Orgs/ManageContent/CustomizePractice/Scenarios/CreateConvoScenarioWizard/WizardAddDraggableItems";
import { YoureAllSetCopy } from "components/Orgs/ManageContent/ManageCoachBotTab/SelfServeCoachBotWizard/CoachBotDone/ProcessingStateComponents/YoureAllSet";
import {
  OrgProgramsQueryKeys,
  OrgProgramsSectionStatus,
} from "components/Orgs/Programs/OrgPrograms";
import { ProgramInfoTab } from "components/Orgs/Programs/ProgramInfo";
import { Wizard } from "components/Wizard/Wizard";
import { WizardStep } from "components/Wizard/WizardTypes";
import { WYSIWYGEditor } from "components/WYSIWYGEditor";
import { YoodliAutocomplete } from "lib-frontend/components/YoodliComponents/YoodliAutocomplete";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import { YoodliSelectOption } from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { useUpdateCoachBot } from "../../../ManageCoachBotTab/useUpdateCoachBot";
import { ConvoScenarioStepWrapper } from "./ConvoScenarioStepWrapper";
import { CreateBinaryGoalSteps } from "./CreateBinaryGoalSteps";
import { CreateCompoundGoalSteps } from "./CreateCompoundGoalSteps";
import { CreateRatedGoalSteps } from "./CreateRatedGoalSteps";
import { InterviewQuestionsSelector } from "./InterviewQuestionsSelector";
import { JobDetailStepState, ScenarioJobDescription } from "./ScenarioJobDescription";
import { useQuery as useApiQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useWYSIWYG } from "hooks/useWYSIWYG";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  createCustomGoal,
  createOrgInterviewBank,
  createPersona,
  createUserPersona,
  getAllCoachBots,
  getAllCustomGoals,
  getCustomGoalbyId,
  listOrgInterviewBanks,
  patchScenario,
  patchUserScenario,
  updateCustomGoal,
  updateOrgInterviewBank,
  updatePersona,
  updateUserPersona,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getScenarioPracticePath } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { isEqual } from "lib-frontend/utils/Utilities";
import { GetScenarioResponse, PatchScenarioRequest } from "lib-fullstack/api/scenarioApiTypes";
import { InterviewBankResponse } from "lib-fullstack/api/hubApiTypes";
import {
  CreatePersonaRequest,
  PersonaMemberViewResponse,
  CreateBinaryGoalRequest,
  CustomGoalItemWithUsage,
  CreateScoreGoalRequest,
  CreateCompoundGoalRequest,
  CustomGoalResponse,
  CreateCustomGoalRequest,
  UpdatePersonaRequest,
  PersonaResponse,
} from "lib-fullstack/api/scenarioApiTypes";
import {
  DefaultGoal,
  GoalKind,
  ScenarioTemplateSubType,
  ScenarioTypeIdEnum,
} from "lib-fullstack/db";
import { GoalLabels } from "lib-fullstack/utils/defaultGoals";
import { ApiErrorCode, ProgramState } from "lib-fullstack/utils/enums";
import { ScenarioType } from "lib-fullstack/utils/enums";
import { scenarioTypeMap } from "lib-fullstack/utils/orgUtils";
import {
  EndUserScenarioCreationWheres,
  EventHubsWheres,
} from "lib-fullstack/utils/productAnalyticEvents";
import {
  BuilderQueryParams,
  OrgContentQueryParams,
  OrgProgramsQueryParams,
} from "lib-fullstack/utils/queryParams";
import { WebServerInternalPath } from "utils/paths";
import { BuilderQueryKeys } from "lib-frontend/utils/BuilderTypes";
import { ContentSpacesContext } from "lib-frontend/contexts/ContentSpacesContext";

export enum WizardType {
  Org = "org",
  User = "user",
}

type CreateConvoScenarioWizardProps = {
  closeWizard: () => void;
  wizardState: WizardState;
  templateSelected: GetScenarioResponse;
  handleTemplateSelected: (scenario: GetScenarioResponse, scenarioId?: string) => void;
  setShouldBlockNav?: React.Dispatch<React.SetStateAction<boolean>>;
  wizardType: WizardType;
};

export const CreateConvoScenarioWizard = ({
  closeWizard,
  wizardState,
  templateSelected,
  handleTemplateSelected,
  setShouldBlockNav,
  wizardType,
}: CreateConvoScenarioWizardProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const qp = new URLSearchParams(location.search);

  const queryClient = useQueryClient();
  const { handleUpdateCoachBot } = useUpdateCoachBot();
  const {
    adminInfo: { defaultOrg, defaultOrgLoading },
  } = React.useContext(UserOrgContext);
  const { curSpaceId } = React.useContext(ContentSpacesContext);
  const templateSubType = templateSelected.templateSubType as ScenarioTemplateSubType;
  const [error, _setError] = React.useState<string | null>(null);
  const [currStepIndex, setCurrStepIndex] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [goalDescriptionLoading, setGoalDescriptionLoading] = React.useState(false);
  const [goalHighScoreLoading, setGoalHighScoreLoading] = React.useState(false);
  const [goalLowScoreLoading, setGoalLowScoreLoading] = React.useState(false);

  const goalHooks: GoalHooks = {
    goalDescriptionLoading,
    setGoalDescriptionLoading,
    goalHighScoreLoading,
    setGoalHighScoreLoading,
    goalLowScoreLoading,
    setGoalLowScoreLoading,
  };

  // Coachbots query
  const { data: coachbotsData, isPending: coachbotsDataPending } = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.Coachbots, defaultOrg?.id],
    queryFn: () => getAllCoachBots(defaultOrg?.id),
    enabled: wizardType === WizardType.Org && !!defaultOrg?.id,
    refetchOnWindowFocus: false,
  });

  // Interview banks query
  const { data: interviewBanksData } = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.InterviewBanks, defaultOrg?.id],
    queryFn: async () => listOrgInterviewBanks(defaultOrg?.id),
    enabled: wizardType === WizardType.Org && !!defaultOrg?.id,
    refetchOnWindowFocus: false,
  });

  const defaultCoachbot = coachbotsData?.coachBots.find((bot) => bot.isDefault);

  const perScenarioCoachbots = coachbotsData?.coachBots.filter((bot) => !bot.isDefault);
  // Temporary variable to enable the "See Example" functionality once it's built
  const exampleEnabled = false;

  // weird hacky way of identifying if its a new scenario (from template) that needs to vary
  // due to differences between how end user scenarios and new scenarios are created/passed in
  const [isNewScenario, setIsNewScenario] = React.useState<boolean>(!templateSelected.enabled);

  // Job Details Step
  const [companyName, setCompanyName] = React.useState<string>(
    isNewScenario ? undefined : templateSelected.defaultCompany
  );
  const [jobRole, setJobRole] = React.useState<string>(
    isNewScenario ? undefined : templateSelected.defaultRole
  );
  const [jobDetailStepState, setJobDetailStepState] = React.useState<JobDetailStepState>(undefined);
  const [extraQuestionSuggestions, setExtraQuestionSuggestions] = React.useState<string[]>([]);

  React.useEffect(() => {
    const qp = new URLSearchParams(location.search);
    const scenarioTemplateType = qp.get(BuilderQueryParams.SCENARIO_WIZARD);
    if (scenarioTemplateType) {
      setIsNewScenario(true);
      setJobRole(undefined);
      setCompanyName(undefined);
      setJobDetailStepState(JobDetailStepState.Upload);
    }
  }, []);

  // Prompt Step
  const { editor: promptEditor } = useWYSIWYG({
    content: templateSelected.description,
    characterLimit: MAX_SCENARIO_DESCRIPTION_LENGTH,
    placeholder: "Start typing or paste text here",
  });

  // Preview / "Describe Your Scenario" Step
  const [scenarioDescriptors, setScenarioDescriptors] = React.useState<ScenarioDescriptors>({
    title: templateSelected.title,
    description: templateSelected.description,
  });
  // Goals Step
  const [goals, setGoals] = React.useState<(DefaultGoal | string)[]>(
    (templateSelected.goalIds as (DefaultGoal | string)[]) ?? [DefaultGoal.ActiveListening]
  );
  const [talkingPoints, setTalkingPoints] = React.useState<string[]>(
    templateSelected.talkingPoints ?? []
  );

  const [goalTalkTime, setGoalTalkTime] = React.useState<GoalTalkTime>(
    templateSelected.targetTimeS
      ? {
          minutes: Math.floor(templateSelected.targetTimeS / 60),
          seconds: templateSelected.targetTimeS % 60,
        }
      : {
          minutes: 3,
          seconds: 0,
        }
  );
  const [specialGoalIdSelected, setSpecialGoalIdSelected] = React.useState<string>(undefined);
  // Custom Goal Creation Steps (if they exist)
  const [customGoalType, setCustomGoalType] = React.useState<GoalKind>(undefined);
  const [discardGoalModalOpen, setDiscardGoalModalOpen] = React.useState<boolean>(false);
  const [goalEditId, setGoalEditId] = React.useState<string>(undefined);
  const [binaryGoal, setBinaryGoal] = React.useState<CreateBinaryGoalRequest>(binaryGoalBase);
  const [ratedGoal, setRatedGoal] = React.useState<CreateScoreGoalRequest>(ratedGoalBase);
  const [compoundGoal, setCompoundGoal] =
    React.useState<CreateCompoundGoalRequest>(compoundGoalBase);
  // Goal Custom Weights Steps (if more than one goal is selected)
  const [goalWeights, setGoalWeights] = React.useState<{ [key: string]: number }>(
    templateSelected.goalWeights ?? {}
  );
  const [lockedWeightGoalIds, setLockedWeightGoalIds] = React.useState<string[]>(
    templateSelected.lockedWeightGoalIds ?? []
  );
  const [goalOrder, setGoalOrder] = React.useState<string[]>(
    templateSelected.goalRenderOrder ?? []
  );
  // Objections / AI Concerns Step
  const [showCreateQuestionBank, setShowCreateQuestionBank] = React.useState(false);
  const [questionBankToCreate, setQuestionBankToCreate] =
    React.useState<InterviewBankResponse | null>(null);
  const [questionBankIds, setQuestionBankIds] = React.useState<string[]>(
    templateSelected?.interviewBankIds ?? []
  );
  const [aiConcerns, setAiConcerns] = React.useState<string[]>(templateSelected.aiConcerns ?? []);
  // Additional Context Step
  const [additionalContext, setAdditionalContext] = React.useState<AdditionalContextDetails>({
    userProvidedContext: templateSelected.userProvidedContext,
    templateDefiningPromptDetails: templateSelected.templateDefiningPromptDetails,
  });
  // Persona Step
  const [showCreatePersona, setShowCreatePersona] = React.useState(false);

  const personasQueryState =
    wizardType === WizardType.Org
      ? queryClient.getQueryState([CustomizePracticeQueryKey.Personas, defaultOrg?.id])
      : queryClient.getQueryState([
          BuilderQueryKeys.UserPersonas,
          firebase.auth().currentUser?.uid,
        ]);
  const personas = personasQueryState?.data as PersonaMemberViewResponse[] | undefined;
  const [personaIds, setPersonaIds] = React.useState<string[]>(templateSelected.personaIds ?? []);
  const [autoEndEnabled, setAutoEndEnabled] = React.useState<boolean>(
    templateSelected.autoEndEnabled ?? false
  );
  const [aiTimeLimitS, setAiTimeLimitS] = React.useState<number>(
    templateSelected.aiTimeLimitS ?? undefined
  );
  const [autoEndPrompt, setAutoEndPrompt] = React.useState<string>(
    templateSelected?.autoEndPrompt ?? undefined
  );

  const [personaToCreate, setPersonaToCreate] = React.useState<CreatePersonaRequest | null>(null);
  React.useEffect(() => {
    setShouldBlockNav?.(true);
    return () => {
      setShouldBlockNav?.(false);
    };
  }, []);

  React.useEffect(() => {
    if (showCreatePersona) {
      setShowCreatePersona(false);
    }
    if (showCreateQuestionBank) {
      setShowCreateQuestionBank(false);
    }
  }, [currStepIndex]);

  // Goals Step
  // Custom goals query
  const { data: customGoals, isPending: orgCustomGoalsLoading } = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.CustomGoals, defaultOrg?.id],
    queryFn: () => getAllCustomGoals(defaultOrg?.id),
    enabled: wizardType === WizardType.Org && !!defaultOrg?.id,
    refetchOnWindowFocus: false,
  });
  const customGoalsLoading = wizardType === WizardType.Org && orgCustomGoalsLoading;

  React.useEffect(() => {
    if (customGoals && goals) {
      const cleanedGoals = goals.filter(
        (goalId) => !!GoalLabels[goalId] || customGoals?.goals?.find((goal) => goal.id === goalId)
      );
      if (cleanedGoals.length !== goals.length) {
        setGoals(cleanedGoals);
      }
    }
  }, [goals, customGoals]);

  // Hub Details
  const [selectedHubIds, setSelectedHubIds] = React.useState<string[]>(
    templateSelected?.activeHubs ?? (defaultOrg?.hubs ?? []).map((hub) => hub.id)
  );
  const [selectedHubsError, setSelectedHubsError] = React.useState<string>(undefined);

  // Coachbot Step
  const [coachbotInputValue, setCoachbotInputValue] = React.useState<string>("");
  const [selectedCoachbot, setSelectedCoachbot] = React.useState<YoodliSelectOption>(undefined);
  React.useEffect(() => {
    const selCoachBot = perScenarioCoachbots?.find((bot) => bot.botId === templateSelected.botId);
    if (selCoachBot) {
      setSelectedCoachbot({ value: selCoachBot?.botId, label: selCoachBot?.name });
    }
  }, [templateSelected.botId, coachbotsDataPending, wizardState]);

  // Restart wizard if default org changes through org switcher
  React.useEffect(() => {
    if (defaultOrgLoading && wizardType === WizardType.Org) {
      closeWizard();
    }
  }, [defaultOrgLoading]);

  // Handlers
  const handleUpdateGoals = (newGoals: (DefaultGoal | string)[]) => {
    setGoals(newGoals);
  };
  const handleUpdateCustomGoalType = (type: GoalKind) => {
    setCustomGoalType(type);
  };
  const handleUpdateQuestionBankIds = (value: string[]) => {
    setQuestionBankIds(value);
  };
  const handleUpdatePersonaIds = (value: string[]) => {
    setPersonaIds(value);
  };
  const handleUpdateScenarioDetails = (key: keyof ScenarioDescriptors, value: string) => {
    setScenarioDescriptors((prev) => ({ ...prev, [key]: value }));
  };
  const handleSetAdditionalContext = (key: keyof AdditionalContextDetails, value: string) => {
    setAdditionalContext((prev) => ({ ...prev, [key]: value }));
  };
  const handleUpdateTalkingPoints = (newTalkingPoints: string[]) => {
    setTalkingPoints(newTalkingPoints);
  };
  const handleUpdateGoalTalkTime = (newGoalTalkTime: GoalTalkTime) => {
    setGoalTalkTime(newGoalTalkTime);
  };
  const handleUpdateAIConcerns = (newAIConcerns: string[]) => {
    setAiConcerns(newAIConcerns);
  };

  const patchScenarioMutation = useMutation({
    mutationFn: (body: PatchScenarioRequest) =>
      wizardType === WizardType.Org
        ? patchScenario(defaultOrg?.id, templateSelected.id, body)
        : patchUserScenario(templateSelected.id, body),
    onError: (error) => {
      console.error("Error updating scenario details in the db", error);
    },
  });

  const handleEditCustomGoal = async (goal: CustomGoalItemWithUsage) => {
    try {
      const fullGoal = await getCustomGoalbyId(defaultOrg?.id, goal.id);
      setGoalEditId(fullGoal.id);
      setCustomGoalState(fullGoal.goalKind, fullGoal);
    } catch (er) {
      console.error("Error fetching custom goal details", er);
    }
  };

  // Mutations (for custom goals)
  const { mutateAsync: handleCustomGoalCreation } = useMutation({
    mutationFn: async (goal: CreateCustomGoalRequest) => {
      if (goalEditId) {
        const updatedGoalId = await updateCustomGoal(defaultOrg?.id, goalEditId, goal);
        return updatedGoalId;
      }
      goal.spaceId = curSpaceId;
      const createdGoalId = await createCustomGoal(defaultOrg?.id, goal);
      return createdGoalId;
    },
    onSuccess: async (goalId, goal) => {
      await queryClient.invalidateQueries({
        queryKey: [CustomizePracticeQueryKey.CustomGoals, defaultOrg?.id],
      });
      if (!goalEditId) {
        setGoals((prev) => {
          return [...prev, goalId];
        });
      }
      handleUpdateCustomGoalType(undefined);
      setCustomGoalState(goal.goalKind);
      setCurrStepIndex((prev) => prev - CUSTOM_GOAL_FLOW_LENGTH);
    },
    onError: (error) => {
      console.error("Error creating/updating custom goal: ", error);
    },
  });

  const { mutateAsync: handleQuestionBankCreation } = useMutation({
    mutationFn: async (bank: InterviewBankResponse) => {
      if (wizardType === WizardType.Org) {
        if (bank.id) {
          await updateOrgInterviewBank(
            defaultOrg.id,
            bank.id,
            bank.name,
            bank.description,
            bank.interview_questions,
            bank.available_hubs
          );
          return bank.id;
        } else {
          const createdBank = await createOrgInterviewBank(
            defaultOrg?.id,
            undefined,
            bank.name,
            bank.interview_questions,
            bank.available_hubs,
            firebase.auth().currentUser.email,
            bank.description
          );
          return createdBank.id;
        }
      } else {
        console.error(
          "Error creating/updating question banks: ",
          "End users should not be able to create/update question banks"
        );
      }
      return null;
    },
    onSuccess: async (createdId, bank) => {
      if (wizardType === WizardType.Org) {
        await queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.InterviewBanks, defaultOrg?.id],
        });
        // is an update
        if (bank.id) {
          Instrumentation.logOrgInterviewBankUpdated(
            defaultOrg?.id,
            EventHubsWheres.SCENARIO_WIZARD
          );
        }
        // is a create
        else {
          setQuestionBankIds((prev) => [...prev, createdId]);
          Instrumentation.logOrgInterviewBankCreated(
            defaultOrg?.id,
            EventHubsWheres.SCENARIO_WIZARD
          );
        }
      }
    },
    onError: (error) => {
      console.error("Error updating question banks: ", error);
    },
  });

  const { mutateAsync: handlePersonaCreation } = useMutation({
    mutationFn: async (persona: UpdatePersonaRequest | PersonaResponse) => {
      let personaId = undefined;
      if (wizardType === WizardType.Org) {
        if (persona.id) {
          personaId = await updatePersona(defaultOrg?.id, persona.id, persona);
        } else {
          personaId = await createPersona(defaultOrg?.id, persona as CreatePersonaRequest);
        }
      } else {
        if (persona.id) {
          personaId = await updateUserPersona(persona.id, persona);
        } else {
          personaId = await createUserPersona(persona as CreatePersonaRequest);
        }
      }
      return personaId;
    },
    onSuccess: async (personaId, persona) => {
      if (wizardType === WizardType.Org) {
        await queryClient.invalidateQueries({
          queryKey: [CustomizePracticeQueryKey.Personas, defaultOrg?.id],
        });
        // is an update
        if (persona.id) {
          Instrumentation.logOrgPersonaUpdated(defaultOrg?.id);
        }
        // is a create
        else {
          Instrumentation.logOrgPersonaCreated(defaultOrg?.id);
        }
      }
      if (wizardType === WizardType.User) {
        await queryClient.invalidateQueries({
          queryKey: [BuilderQueryKeys.UserPersonas, firebase.auth().currentUser?.uid],
        });
      }
      if (!personaIds.includes(personaId)) {
        let updatedPersonaIds = [];
        // extra complication with end user personas because the templates are global/i.e. need to be copied
        // over first and then the template needs to be removed so there isn't a dupe
        if ((persona as PersonaResponse).is_template && wizardType === WizardType.User) {
          updatedPersonaIds = personaIds.map((pId) => (pId === persona.id ? personaId : pId));
        } else {
          updatedPersonaIds = [...personaIds, personaId];
        }
        setPersonaIds(updatedPersonaIds);
      }
    },
    onError: (error) => {
      console.error("Error updating persona: ", error);
    },
  });

  const validateTalkingPointsGoal = () => {
    if (goals.includes(DefaultGoal.HitTalkingPoints) && talkingPoints.length === 0) {
      return false;
    }
    return true;
  };
  const validateTalkTimeGoal = () => {
    if (
      goals.includes(DefaultGoal.HitTimeTarget) &&
      ((!goalTalkTime?.minutes && !goalTalkTime.seconds) ||
        specialGoalIdSelected === DefaultGoal.HitTimeTarget)
    ) {
      return false;
    }
    return true;
  };

  // Either set current state to selected goal to update or reset custom goal state to base state
  const setCustomGoalState = (goalKind: GoalKind, goalToUpdate?: CustomGoalResponse) => {
    switch (goalKind) {
      case GoalKind.BinaryGoal:
        setBinaryGoal((goalToUpdate as CreateBinaryGoalRequest) ?? { ...binaryGoalBase });
        break;
      case GoalKind.ScoreGoal:
        if (!goalToUpdate && localStorage.getItem("customGoalMaxScore")) {
          ratedGoalBase.maxScore = Number(localStorage.getItem("customGoalMaxScore"));
        }
        setRatedGoal((goalToUpdate as CreateScoreGoalRequest) ?? { ...ratedGoalBase });
        break;
      case GoalKind.CompoundGoal:
        if (!goalToUpdate && localStorage.getItem("customGoalMaxScore")) {
          compoundGoalBase.maxScore = Number(localStorage.getItem("customGoalMaxScore"));
        }
        setCompoundGoal((goalToUpdate as CreateCompoundGoalRequest) ?? { ...compoundGoalBase });
        break;
    }
  };

  const promptStep = React.useMemo(
    () => ({
      label: "Start",
      initialStep: true,
      id: CreateConvoScenarioStepId.PROMPT,
      component: (
        <ConvoScenarioStepWrapper
          title="Pitch prompt"
          subTitle="Write a clear and concise pitch topic description. Ensure it's easy for the presenter to read and understand."
        >
          <WYSIWYGEditor editor={promptEditor} characterLimit={MAX_SCENARIO_DESCRIPTION_LENGTH} />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !promptEditor.isEmpty && !error,
      next: async () => {
        if (promptEditor.getHTML() !== templateSelected.description) {
          handleUpdateScenarioDetails("description", promptEditor.getHTML());
          patchScenarioMutation.mutate({
            description: promptEditor.getHTML(),
          });
        }
      },

      enterToNavigate: false,
      showProgressTracker: true,
    }),
    [promptEditor.getHTML(), promptEditor.isEmpty, currStepIndex, error, templateSelected]
  );

  const jobDetailsStep = React.useMemo(
    () => ({
      label: "Start",
      initialStep: true,
      id: CreateConvoScenarioStepId.JOB_DETAILS,
      component: (
        <ConvoScenarioStepWrapper
          title="Enter job details"
          subTitle={
            wizardType === WizardType.Org
              ? "Add a default role and company for this interview scenario. Members may change these before practice"
              : "Add a default role and company for your interview scenario"
          }
        >
          <ScenarioJobDescription
            jobDetailStepState={jobDetailStepState}
            setJobDetailStepState={setJobDetailStepState}
            handleSetAdditionalContext={handleSetAdditionalContext}
            companyName={companyName}
            handleSetCompanyName={setCompanyName}
            loading={loading}
            jobRole={jobRole}
            handleSetJobRole={setJobRole}
            templateSubType={templateSubType}
            scenarioId={templateSelected.scenarioTypeId}
            setExtraQuestionSuggestions={setExtraQuestionSuggestions}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !!companyName && !!jobRole && !error,
      next: async () => {
        patchScenarioMutation.mutate({
          userProvidedContext: additionalContext.userProvidedContext,
          defaultCompany: companyName,
          defaultRole: jobRole,
        });
      },

      enterToNavigate: true,
      showProgressTracker: true,
    }),
    [
      currStepIndex,
      error,
      templateSelected,
      additionalContext,
      companyName,
      jobRole,
      loading,
      jobDetailStepState,
    ]
  );

  const scenarioContextStep = React.useMemo(() => {
    const youInstruction =
      'For the best results, write this in the form of directions to the AI. E.g. "You know that..." or "You should..."';
    const productCallSubTitle = (
      <>
        Add specific information about the product or service that will be discussed during the
        conversation. This is not shown to users.
        <br />
        {youInstruction}
      </>
    );

    // Add to this dataMap when scenario type specific copy needs arise
    const dataMap = {
      title: {
        [ScenarioTypeIdEnum.SALES_CALL]: "What’s the context of this sales call?",
        [ScenarioTypeIdEnum.COLD_CALL]: "What’s the context of this cold sales call?",
        [ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]: "What’s the context of this review?",
        [ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: "What’s the context of this coaching call?",
        [ScenarioTypeIdEnum.MEDIA_TRAINING]: "What’s the context of this interview?",
        [ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]:
          "What’s the context of this customer enablement call?",
        [ScenarioTypeIdEnum.PITCH]: "What’s the context of this pitch for the AI?",
        [ScenarioTypeIdEnum.SMALL_TALK]: "What’s the context of this conversation?",
        [ScenarioTypeIdEnum.PATIENT_COUNSELING]: "What is the medical context of this counseling?",
        [ScenarioTypeIdEnum.NETWORKING]: "What’s the context of this networking conversation?",
        [ScenarioTypeIdEnum.BEHAVIORAL_INTERVIEW]: `What’s the context of this behavioral interview for ${jobRole} at ${companyName}?`,
        [ScenarioTypeIdEnum.TECHNICAL_INTERVIEW]: `What’s the context of this technical interview for ${jobRole} at ${companyName}?`,
        [ScenarioTypeIdEnum.TUTOR]: "What is the AI tutoring the user on?",
        [ScenarioTypeIdEnum.SKILL_ACCREDITATION]: "What is the AI quizzing the user on?",
      },
      subTitle: {
        [ScenarioTypeIdEnum.SALES_CALL]: productCallSubTitle,
        [ScenarioTypeIdEnum.COLD_CALL]: productCallSubTitle,
        [ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]: productCallSubTitle,
        [ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]: (
          <>
            Write a short description giving the AI partner context for how it should behave in this
            performance review.
            <br />
            {youInstruction}
            <br />
            Questions to consider:
            <br />
            <br />
            <i>
              Why are you having this review?
              <br />
              Is the tone positive or negative?
            </i>
          </>
        ),
        [ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: (
          <>
            Write a short description giving the AI context for how it should behave in this
            coaching call.
            <br />
            {youInstruction}
            <br />
            Here are a few coaching topics to consider:
            <br />
            <br />
            <i>
              Conflict with a team member
              <br />
              Dealing with a difficult internal stakeholder
              <br />
              Conversation with a difficult customer
              <br />
              Conducting customer discovery
            </i>
          </>
        ),
        [ScenarioTypeIdEnum.MEDIA_TRAINING]: (
          <>
            Write a short description giving the AI context for how it should behave as it gives
            this interview.
            <br />
            {youInstruction}
            <br />
            Here are a few topics to consider:
            <br />
            <br />
            <i>
              What is the interview about?
              <br />
              Who is the interviewee?
              <br />
              What is the goal of the interview?
            </i>
          </>
        ),
        [ScenarioTypeIdEnum.GENERIC]: (
          <>
            Write a short description giving the AI context for how it should behave in this
            conversation.
            <br />
            {youInstruction}
            <br />
            Here are a few topics to consider:
            <br />
            <br />
            <i>
              What is the conversation about?
              <br />
              Who is the conversation with?
              <br />
              What is the goal of the conversation?
            </i>
          </>
        ),
        [ScenarioTypeIdEnum.PITCH]:
          "Write a short description telling the AI persona what role it will play. For the best results, write this in the form of directions to the AI.",
        [ScenarioTypeIdEnum.SMALL_TALK]: (
          <>
            Write a short description giving the AI context for how it should behave in this
            conversation.
            <br />
            {youInstruction}
            <br />
            Here are a few topics to consider:
            <br />
            <br />
            <i>
              What is the conversation about?
              <br />
              Who is the conversation with?
              <br />
              What is the goal of the conversation?
            </i>
          </>
        ),
        [ScenarioTypeIdEnum.PATIENT_COUNSELING]: (
          <>
            Write a short description giving the AI context for how it should behave in this
            counseling.
            <br />
            {youInstruction}
            <br />
            Here are a few topics to consider:
            <br />
            <br />
            <i>
              What sort of symptoms is the patient experiencing?
              <br />
              Are you speaking to a patient or a caregiver?
              <br />
              What is the goal of the counseling? Are you trying to diagnose, educate, comfort, or
              gain consent?
            </i>
          </>
        ),
        [ScenarioTypeIdEnum.NETWORKING]: (
          <>
            Write a short description giving the AI context for how it should behave in this
            networking conversation.
            <br />
            {youInstruction}
            <br />
            Here are a few topics to consider:
            <br />
            <br />
            <i>
              Is this at a networking event or is it a one-on-one conversation?
              <br />
              Who is the conversation with?
              <br />
              What is the goal of the conversation?
            </i>
          </>
        ),
        [ScenarioTypeIdEnum.BEHAVIORAL_INTERVIEW]: (
          <>
            Brief the AI on the interview as though it were the interviewer. It will navigate the
            interview based on the context entered below. If you uploaded a job description or job
            listing from a URL, details are pre-filled below
          </>
        ),
        [ScenarioTypeIdEnum.TECHNICAL_INTERVIEW]: (
          <>
            Brief the AI on the interview as though it were the interviewer. It will navigate the
            interview based on the context entered below. If you uploaded a job description or job
            listing from a URL, details are pre-filled below
          </>
        ),
        [ScenarioTypeIdEnum.TUTOR]: (
          <>
            Specify the topic you want the AI to tutor the user on. Include a short description of
            how the AI should behave, and any specific details it should be teaching the user.
            <br />
            {youInstruction}
            <br />
          </>
        ),
        [ScenarioTypeIdEnum.SKILL_ACCREDITATION]: (
          <>
            Specify the topic the AI is quizzing the user on. Write a short description giving the
            AI context for how it should accredit the user, and include any nuances or specific
            information that might not be common knowledge.
            <br />
            {youInstruction}
            <br />
          </>
        ),
      },
      placeholderCopy: {
        [ScenarioTypeIdEnum.SALES_CALL]: "e.g. Yoodli is an AI platform for sales training",
        [ScenarioTypeIdEnum.COLD_CALL]: "e.g. Yoodli is an AI platform for sales training",
        [ScenarioTypeIdEnum.PITCH]:
          "e.g. You (an audience member) are being pitched to as part of a pitch contest on the advantages of cloud vs edge computing. The presenter’s time is limited to 3 minutes",
      },
      // all except pitch should say "Tell the AI what it already knows"
      userProvidedContextTitle: Object.values(ScenarioTypeIdEnum).reduce((acc, key) => {
        if (key !== ScenarioTypeIdEnum.PITCH) {
          acc[key] = "Tell the AI what it already knows";
        }
        return acc;
      }, {}),

      showPromptDetails: {
        [ScenarioTypeIdEnum.SALES_CALL]: true,
        [ScenarioTypeIdEnum.COLD_CALL]: false,
        [ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]: false,
        [ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: false,
        [ScenarioTypeIdEnum.MEDIA_TRAINING]: false,
        [ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]: false,
        [ScenarioTypeIdEnum.PITCH]: false,
        [ScenarioTypeIdEnum.SMALL_TALK]: false,
        [ScenarioTypeIdEnum.PATIENT_COUNSELING]: false,
        [ScenarioTypeIdEnum.NETWORKING]: false,
        [ScenarioTypeIdEnum.BEHAVIORAL_INTERVIEW]: false,
        [ScenarioTypeIdEnum.TECHNICAL_INTERVIEW]: false,
        [ScenarioTypeIdEnum.TUTOR]: false,
        [ScenarioTypeIdEnum.SKILL_ACCREDITATION]: false,
      },
    };
    return {
      label: "Start",
      initialStep: true,
      id: CreateConvoScenarioStepId.ADDITIONAL_CONTEXT,
      component: (
        <ConvoScenarioStepWrapper
          title={dataMap.title[templateSelected.scenarioTypeId] ?? "Scenario Details"}
          subTitle={
            dataMap.subTitle[templateSelected.scenarioTypeId] ??
            "Add specific information about the scenario."
          }
        >
          <ScenarioAdditionalContext
            additionalContext={additionalContext}
            handleSetAdditionalContext={handleSetAdditionalContext}
            templateSubType={templateSubType}
            showPromptDetails={dataMap.showPromptDetails[templateSelected.scenarioTypeId]}
            userProvidedContextTitle={
              dataMap.userProvidedContextTitle[templateSelected.scenarioTypeId]
            }
            placeholderCopy={dataMap.placeholderCopy[templateSelected.scenarioTypeId]}
            scenarioId={templateSelected.scenarioTypeId}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !error,
      next: async () => {
        if (
          additionalContext.userProvidedContext !== templateSelected.userProvidedContext ||
          additionalContext.templateDefiningPromptDetails !==
            templateSelected.templateDefiningPromptDetails
        ) {
          patchScenarioMutation.mutate({
            userProvidedContext: additionalContext.userProvidedContext,
            templateDefiningPromptDetails: additionalContext.templateDefiningPromptDetails,
          });
        }
        if (templateSelected.scenarioTypeId === ScenarioTypeIdEnum.BEHAVIORAL_INTERVIEW) {
          patchScenarioMutation.mutate({
            defaultCompany: companyName,
            defaultRole: jobRole,
          });
        }
      },

      enterToNavigate: true,
      showProgressTracker: true,
    };
  }, [additionalContext, error, templateSelected, jobRole, companyName]);

  const goalsStep = React.useMemo(() => {
    // Add to this dataMap when scenario type specific copy needs arise
    const dataMap = {
      subTitleSubject: {
        [ScenarioTypeIdEnum.SALES_CALL]: "sales call",
        [ScenarioTypeIdEnum.COLD_CALL]: "cold sales call",
        [ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]:
          "Select goal(s) members should try to achieve during the performance review. Yoodli will measure the completion and provide feedback against those goals.",
        [ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: "skills training session",
        [ScenarioTypeIdEnum.MEDIA_TRAINING]: "media training session",
        [ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]: "customer enablement call",
        [ScenarioTypeIdEnum.PITCH]: "pitch",
        [ScenarioTypeIdEnum.SMALL_TALK]: "conversation",
        [ScenarioTypeIdEnum.GENERIC]: "scenario",
        [ScenarioTypeIdEnum.BEHAVIORAL_INTERVIEW]: "behavioral interview",
        [ScenarioTypeIdEnum.TECHNICAL_INTERVIEW]: "technical interview",
      },
    };
    return {
      label: "Customize",
      id: CreateConvoScenarioStepId.GOALS,
      component: (
        <ConvoScenarioStepWrapper
          title="Create a rubric"
          subTitle={`Add goal(s) to a rubric for this ${
            dataMap.subTitleSubject[templateSelected.scenarioTypeId]
          }. Yoodli will measure completion and provide feedback to members according to this rubric.`}
        >
          <GoalSelector
            goals={goals}
            handleUpdateGoals={handleUpdateGoals}
            handleUpdateCustomGoalType={setCustomGoalType}
            talkingPoints={talkingPoints}
            handleUpdateTalkingPoints={handleUpdateTalkingPoints}
            goalTalkTime={goalTalkTime}
            specialGoalIdSelected={specialGoalIdSelected}
            setSpecialGoalIdSelected={setSpecialGoalIdSelected}
            handleUpdateGoalTalkTime={handleUpdateGoalTalkTime}
            scenarioTypeId={templateSelected.scenarioTypeId}
            customGoals={customGoals?.goals}
            customGoalsLoading={customGoalsLoading}
            setCurrStepIndex={setCurrStepIndex}
            handleEditCustomGoal={handleEditCustomGoal}
            goalWeights={goalWeights}
            handleUpdateGoalWeights={(newGoalWeights) => {
              setGoalWeights(newGoalWeights);
            }}
            lockedWeightGoalIds={lockedWeightGoalIds}
            handleUpdateLockedWeightGoalIds={setLockedWeightGoalIds}
            goalOrder={goalOrder}
            handleUpdateGoalOrder={setGoalOrder}
            wizardType={wizardType}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !error && validateTalkingPointsGoal() && validateTalkTimeGoal(),
      next: async () => {
        if (
          convertGoalTalkTimeToS(goalTalkTime) !== templateSelected.targetTimeS ||
          !isEqual(talkingPoints, templateSelected.talkingPoints) ||
          !isEqual(goals, templateSelected.goalIds) ||
          !isEqual(goalWeights, templateSelected.goalWeights) ||
          !isEqual(lockedWeightGoalIds, templateSelected.lockedWeightGoalIds) ||
          !isEqual(goalOrder, templateSelected.goalRenderOrder)
        ) {
          patchScenarioMutation.mutate({
            talkingPoints,
            targetTimeS: convertGoalTalkTimeToS(goalTalkTime),
            goalIds: goals,
            goalRenderOrder: goalOrder,
            lockedWeightGoalIds: lockedWeightGoalIds,
            goalWeights: goalWeights
              ? Object.fromEntries(Object.entries(goalWeights).map(([k, v]) => [k, v]))
              : {},
          });
        }
      },
      nextHelperText: goals.length > 0 && (
        <Typography sx={{ fontSize: "12px", fontWeight: 500, color: getDynamicColor("dark4") }}>
          {goals.length} goals selected
        </Typography>
      ),
      enterToNavigate: true,
      showProgressTracker: true,
    };
  }, [
    goals,
    goalWeights,
    lockedWeightGoalIds,
    goalOrder,
    talkingPoints,
    currStepIndex,
    error,
    templateSelected,
    goalTalkTime,
    specialGoalIdSelected,
    customGoals,
    customGoalsLoading,
  ]);

  const interviewQuestionsStep = React.useMemo(() => {
    const hasValidInterviewBank = () => {
      return (
        showCreateQuestionBank &&
        questionBankToCreate?.interview_questions?.length > 0 &&
        questionBankToCreate?.name &&
        questionBankToCreate?.description
      );
    };
    return {
      label: "Customize",
      id: CreateConvoScenarioStepId.OBJECTIONS,
      component: (
        <ConvoScenarioStepWrapper
          title={!showCreateQuestionBank && "Add potential questions (optional)"}
          subTitle={
            !showCreateQuestionBank &&
            "The AI interviewer will dynamically choose from these questions based on the conversation. If none are specified, the AI interviewer will ask questions based on role and company."
          }
        >
          <InterviewQuestionsSelector
            showCreateQuestionBank={showCreateQuestionBank}
            setShowCreateQuestionBank={setShowCreateQuestionBank}
            banks={interviewBanksData}
            selectedBankIds={questionBankIds}
            handleUpdateQuestionBankIds={handleUpdateQuestionBankIds}
            currStepIndex={currStepIndex}
            setQuestionBankToCreate={setQuestionBankToCreate}
            aiConcerns={aiConcerns}
            handleUpdateAIConcerns={handleUpdateAIConcerns}
            extraQuestionSuggestions={extraQuestionSuggestions}
            wizardType={wizardType}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !error && (!showCreateQuestionBank || hasValidInterviewBank()),
      // if im currently creating a question bank, dont go back a step idx but just close the creation form
      skipPrevIndexUpdate: showCreateQuestionBank,
      skipNextIndexUpdate: showCreateQuestionBank,
      prev: () => {
        if (showCreateQuestionBank) {
          setShowCreateQuestionBank(false);
          setQuestionBankToCreate(null);
        }
      },
      next: async () => {
        // if im making a new
        if (showCreateQuestionBank) {
          await handleQuestionBankCreation(questionBankToCreate);
        }
        if (
          templateSelected.interviewBankIds?.length !== questionBankIds.length ||
          !templateSelected.interviewBankIds?.every((bankId) => questionBankIds.includes(bankId)) ||
          templateSelected.aiConcerns?.length !== aiConcerns.length ||
          !templateSelected.aiConcerns?.every((concern) => aiConcerns.includes(concern))
        ) {
          const patchReq = {
            aiConcerns,
            ...(wizardType === WizardType.Org && { interviewBankIds: questionBankIds }),
          };
          patchScenarioMutation.mutate(patchReq);
          // invalidate the interview banks to get updates
          void queryClient.invalidateQueries({
            queryKey: [CustomizePracticeQueryKey.InterviewBanks, defaultOrg?.id],
          });
        }

        // if creating an interview bank, close the creation form
        if (showCreateQuestionBank) {
          setShowCreateQuestionBank(false);
          setQuestionBankToCreate(null);
        }
      },
      enterToNavigate: true,
      showProgressTracker: true,
    };
  }, [
    questionBankToCreate,
    showCreateQuestionBank,
    interviewBanksData,
    questionBankIds,
    wizardType,
    currStepIndex,
    error,
    templateSelected,
    aiConcerns,
  ]);

  const objectionsStep = React.useMemo(() => {
    // Add to this dataMap when scenario type specific copy needs arise
    const dataMap = {
      title: {
        [ScenarioTypeIdEnum.SALES_CALL]: "AI partner objections (optional)",
        [ScenarioTypeIdEnum.COLD_CALL]: "AI partner objections (optional)",
        [ScenarioTypeIdEnum.PITCH]: "AI Q&A (optional)",
        [ScenarioTypeIdEnum.SKILL_ACCREDITATION]: "AI questions",
      },
      subTitle: {
        [ScenarioTypeIdEnum.SALES_CALL]: `Add up to ${MAX_AI_CONCERNS} top of mind objections that the AI will raise as follow-up questions if not addressed.`,
        [ScenarioTypeIdEnum.COLD_CALL]: `Add up to ${MAX_AI_CONCERNS} top of mind objections that the AI will raise as follow-up questions if not addressed.`,
        [ScenarioTypeIdEnum.SKILL_ACCREDITATION]: `Add up to ${MAX_AI_CONCERNS} top of mind questions that the AI should ask the user in order to accredit them.`,
      },
    };
    return {
      label: "Customize",
      id: CreateConvoScenarioStepId.OBJECTIONS,
      component: (
        <ConvoScenarioStepWrapper
          title={dataMap.title[templateSelected.scenarioTypeId] ?? "AI partner concerns (optional)"}
          subTitle={
            dataMap.subTitle[templateSelected.scenarioTypeId] ??
            `Add up to ${MAX_AI_CONCERNS} top of mind concerns that the AI will raise as follow-up questions if not addressed.`
          }
        >
          <WizardAddDraggableItems
            currStepIndex={currStepIndex}
            items={aiConcerns}
            handleUpdateItems={handleUpdateAIConcerns}
            inputPlaceholder="e.g. Why should I buy from you?"
            maxItems={MAX_AI_CONCERNS}
            maxInputLength={MAX_DND_INPUT_LENGTH}
            itemLabel="AI partner concerns"
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !error,
      next: async () => {
        if (!isEqual(aiConcerns, templateSelected.aiConcerns)) {
          patchScenarioMutation.mutate({
            aiConcerns,
          });
        }
      },
      enterToNavigate: true,
      showProgressTracker: true,
    };
  }, [aiConcerns, currStepIndex, error, templateSelected]);

  const hasValidPersonaToCreate = () => {
    return (
      showCreatePersona &&
      personaToCreate?.name &&
      personaToCreate?.job_title &&
      personaToCreate?.voice_id &&
      personaToCreate?.demeanor &&
      personaToCreate.profile_picture_id
    );
  };
  const personaStep = React.useMemo(() => {
    // Add to this dataMap when scenario type specific copy needs arise
    const dataMap = {
      title: {
        [ScenarioTypeIdEnum.SALES_CALL]: "Who is this sales call with?",
        [ScenarioTypeIdEnum.COLD_CALL]: "Who is this cold sales call with?",
        [ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]: "Who is this performance review with?",
        [ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: "Who is this skills coaching session with?",
        [ScenarioTypeIdEnum.MEDIA_TRAINING]: "Who is interviewing you?",
        [ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]: "Who is this customer enablement call with?",
        [ScenarioTypeIdEnum.PITCH]: "Who is being pitched to?",
      },
    };
    return {
      label: "Customize",
      id: CreateConvoScenarioStepId.PERSONA,
      component: (
        <ConvoScenarioStepWrapper
          title={
            dataMap.title[templateSelected.scenarioTypeId] ??
            `Who is this ${TEMPLATE_SUB_TYPE_DATA[
              templateSelected.templateSubType
            ]?.label?.toLowerCase()} with?`
          }
          subTitle={
            showCreatePersona
              ? "Create a new persona for this scenario"
              : "Add personas in the order you would like them to appear"
          }
        >
          <PersonaSelector
            showCreatePersona={showCreatePersona}
            setShowCreatePersona={setShowCreatePersona}
            personas={personas}
            personaIds={personaIds}
            handleUpdatePersonaIds={handleUpdatePersonaIds}
            currStepIndex={currStepIndex}
            setPersonaToCreate={setPersonaToCreate}
            wizardType={wizardType}
            aiTimeLimitS={aiTimeLimitS}
            setAiTimeLimitS={setAiTimeLimitS}
            autoEndEnabled={autoEndEnabled}
            setAutoEndEnabled={setAutoEndEnabled}
            scenarioTypeId={templateSelected?.scenarioTypeId}
            autoEndPrompt={autoEndPrompt}
            setAutoEndPrompt={setAutoEndPrompt}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () =>
        !error && ((!showCreatePersona && personaIds.length > 0) || hasValidPersonaToCreate()),
      // if im currently creating a persona, dont go back a step idx but just close the creation form
      skipPrevIndexUpdate: showCreatePersona,
      skipNextIndexUpdate: showCreatePersona,
      prev: () => {
        if (showCreatePersona) {
          setShowCreatePersona(false);
          setPersonaToCreate(null);
        }
      },
      next: async () => {
        // if im making a new
        if (personaToCreate) {
          await handlePersonaCreation(personaToCreate);
        }
        patchScenarioMutation.mutate({
          personaIds: personaIds,
          autoEndEnabled,
          aiTimeLimitS,
          autoEndPrompt,
        });

        // invalidate the personas from the customize practice personas tab so the "used by" number updates
        void queryClient.invalidateQueries({
          queryKey:
            wizardType === WizardType.Org
              ? [CustomizePracticeQueryKey.Personas, defaultOrg?.id]
              : [BuilderQueryKeys.UserPersonas, firebase.auth().currentUser?.uid],
        });

        // if creating a persona, close the creation form
        if (showCreatePersona) {
          setShowCreatePersona(false);
          setPersonaToCreate(null);
        }
      },

      enterToNavigate: true,
      showProgressTracker: true,
    };
  }, [
    queryClient,
    personas,
    personaIds,
    error,
    personaToCreate,
    showCreatePersona,
    autoEndEnabled,
    currStepIndex,
    hasValidPersonaToCreate,
  ]);

  const assignHubsStep = React.useMemo(
    () => ({
      label: "Customize",
      id: CreateConvoScenarioStepId.ASSIGN_TO_HUBS,
      component: (
        <ConvoScenarioStepWrapper
          title="Assign to groups (optional)"
          subTitle="Select / deselect groups you want to assign this scenario to. You can also do this later from your library"
        >
          <SelectHub
            hideTitle
            hubSelectorSx={{
              width: "100%",
            }}
            allHubs={defaultOrg?.hubs}
            selectedHubIds={selectedHubIds}
            setSelectedHubIds={(hubIds) => {
              setSelectedHubsError(undefined);
              setSelectedHubIds(hubIds);
            }}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !error,
      next: async () => {
        if (!isEqual(selectedHubIds, templateSelected.activeHubs)) {
          try {
            await patchScenarioMutation.mutateAsync({
              activeHubs: selectedHubIds,
            });
          } catch (e) {
            if (e.response?.data?.code === ApiErrorCode.UsedByPublishedProgram) {
              setSelectedHubsError(
                "This scenario is used by a published program and cannot be unassigned from one of its active groups."
              );
            }
            return true;
          }
        }
        return false;
      },
      enterToNavigate: true,
      showProgressTracker: true,
      nextHelperText: selectedHubsError && (
        <Typography sx={{ fontSize: "12px", fontWeight: 500, color: getDynamicColor("redError") }}>
          {selectedHubsError}
        </Typography>
      ),
    }),
    [selectedHubIds, error, templateSelected, selectedHubsError]
  );

  const assignCoachbotsStep = React.useMemo(
    () => ({
      label: "Customize",
      id: CreateConvoScenarioStepId.ASSIGN_COACHBOT,
      component: (
        <ConvoScenarioStepWrapper
          title="Assign Coach Bot (optional)"
          subTitle={
            <Typography component="div">
              Select a Coach Bot you want to associate with this scenario. You can change this later
              in your scenario or Coach Bot settings page.{" "}
              {defaultCoachbot ? (
                <Box>
                  If none is selected, this scenario will use the existing default Coach Bot:{" "}
                  <Box component="span" sx={{ fontWeight: 700 }}>
                    {defaultCoachbot.name}
                  </Box>
                </Box>
              ) : (
                <Box>If none is selected, no Coach Bot will be assigned to this scenario.</Box>
              )}
            </Typography>
          }
        >
          <YoodliAutocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value}
            placeholder={!defaultCoachbot ? `No Coach Bot selected` : undefined}
            value={selectedCoachbot ?? null}
            disablePortal={false}
            onChange={(event, newValue: YoodliSelectOption | null) => {
              setSelectedCoachbot(newValue);
            }}
            inputValue={coachbotInputValue}
            onInputChange={(event, newInputValue) => {
              setCoachbotInputValue(newInputValue);
            }}
            options={perScenarioCoachbots?.map((bot) => ({
              value: bot.botId,
              label: bot.name,
            }))}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () => !error,
      next: async () => {
        if (selectedCoachbot) {
          const coachbot = perScenarioCoachbots.find((bot) => bot.botId === selectedCoachbot.value);
          if (coachbot && !coachbot?.scenarioIds?.includes(templateSelected.id)) {
            await handleUpdateCoachBot({
              orgId: defaultOrg?.id,
              botId: selectedCoachbot.value,
              updateOptions: {
                scenarioIds: (coachbot.scenarioIds ?? []).concat(templateSelected.id),
              },
              isDefault: false,
            });
          }
        }
        if (!selectedCoachbot && templateSelected.botId) {
          const coachbot = perScenarioCoachbots.find((bot) => bot.botId === templateSelected.botId);
          await handleUpdateCoachBot({
            orgId: defaultOrg?.id,
            botId: templateSelected.botId,
            updateOptions: {
              scenarioIds: (coachbot.scenarioIds ?? []).filter((id) => id !== templateSelected.id),
            },
            isDefault: false,
          });
        }
      },
      showProgressTracker: true,
    }),
    [error, selectedCoachbot, perScenarioCoachbots, coachbotInputValue]
  );

  const reviewStep = React.useMemo(() => {
    // Add to this dataMap when scenario type specific copy needs arise
    const dataMap = {
      description: {
        [ScenarioTypeIdEnum.PITCH]: "Pitch prompt",
      },
      descriptionPlaceholder: {
        [ScenarioTypeIdEnum.PITCH]: "Describe your pitch",
      },
      descriptionRequired: {
        [ScenarioTypeIdEnum.PITCH]: true,
      },
    };
    return {
      label: "Review",
      id: CreateConvoScenarioStepId.REVIEW,
      component: (
        <ConvoScenarioStepWrapper
          title={`Name this ${TEMPLATE_SUB_TYPE_DATA[
            templateSelected.templateSubType
          ]?.scenarioTypeLabel?.toLowerCase()} scenario`}
          subTitle={`
        Edit the title and description of your scenario below. This is what the user will see when deciding which scenario to use.${
          exampleEnabled
            ? " Hit “See example” to see a simulated text transcript of the conversation"
            : ""
        }`}
        >
          <ScenarioPreview
            templateSubType={templateSubType}
            title={scenarioDescriptors.title}
            description={scenarioDescriptors.description}
            descriptionLabel={dataMap.description[templateSelected.scenarioTypeId]}
            decriptionPlaceholder={dataMap.descriptionPlaceholder[templateSelected.scenarioTypeId]}
            handleUpdateScenarioDetails={handleUpdateScenarioDetails}
          />
        </ConvoScenarioStepWrapper>
      ),
      validate: () =>
        !error &&
        scenarioDescriptors?.title &&
        (dataMap.descriptionRequired[templateSelected.scenarioTypeId]
          ? !!scenarioDescriptors.description
          : true),
      nextCopy: "Publish",
      nextButtonVariant: "gradient",
      next: async () => {
        let mutation: PatchScenarioRequest = {
          title: scenarioDescriptors.title,
          description: scenarioDescriptors.description,
          enabled: true,
        };
        if (wizardState === "edit") {
          mutation = {
            ...mutation,
            modifiedAt: new Date().toISOString(),
            modifiedByEmail: firebase.auth().currentUser?.email,
          };
        }
        // always patch here (instead of making sure title/desc changed) so we can be SURE the scenario is enabled
        patchScenarioMutation.mutate(mutation);
        // invalidate the personas from the customize practice personas tab so the "used by" number updates
        // (it relies on the enabled flag which is why im setting it here if it's a newly enabled scenario)
        if (wizardState === "create" || templateSelected.enabled === false) {
          void queryClient.invalidateQueries({
            queryKey: [CustomizePracticeQueryKey.Personas, defaultOrg?.id],
          });
        }
        // amplitude events
        if (wizardType === WizardType.User) {
          if (wizardState === "create") {
            Instrumentation.logEndUserScenarioCreated(
              qp.has(BuilderQueryParams.PRACTICE)
                ? EndUserScenarioCreationWheres.PRACTICE
                : EndUserScenarioCreationWheres.BUILDER
            );
          } else if (wizardState === "edit") {
            Instrumentation.logEndUserScenarioUpdated();
          }
        }
        // amplitude events
        if (wizardType === WizardType.Org) {
          if (wizardState === "create") {
            Instrumentation.logOrgScenarioCreated(defaultOrg?.id);
          } else if (wizardState === "edit") {
            Instrumentation.logOrgScenarioUpdated(defaultOrg?.id);
          }
        }
      },
      enterToNavigate: false,
      showProgressTracker: true,
    };
  }, [error, scenarioDescriptors, templateSelected]);
  const doneStep = React.useMemo(
    () => ({
      label: "Done",
      id: CreateConvoScenarioStepId.DONE,
      hideProgressMarker: true,
      progressTrackerComplete: true,
      component: (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
          sx={{
            my: "auto",
            pt: { xs: 6, md: 8 },
            pb: 12,
          }}
        >
          <YoureAllSetCopy />
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: getDynamicColor("dark5"),
            }}
          >
            Your scenario has been saved and is now live! Hit "Done" to go back to your library.
          </Typography>
        </Stack>
      ),
      validate: () => !error,
      enterToNavigate: true,
      next: () => {
        if (wizardState === "create" && qp.has(OrgContentQueryParams.PROGRAM_ID)) {
          void queryClient.invalidateQueries({
            queryKey: [OrgProgramsQueryKeys.ScenarioList, defaultOrg?.id],
          });
          navigate(
            `${WebServerInternalPath.ORG_PROGRAMS}?${OrgProgramsQueryParams.TAB}=${
              ProgramState.Draft
            }&${OrgProgramsQueryParams.SECTION}=${OrgProgramsSectionStatus.ProgramInfo}&${
              OrgProgramsQueryParams.PROGRAM_ID
            }=${qp.get(OrgContentQueryParams.PROGRAM_ID)}&${OrgProgramsQueryParams.PROGRAM_TAB}=${
              ProgramInfoTab.Scenarios
            }&${OrgProgramsQueryParams.SCENARIO_ID}=${templateSelected.id}`
          );
        } else if (qp.has(BuilderQueryParams.PRACTICE)) {
          void queryClient.invalidateQueries({
            queryKey: [FPRScenarioQueryKey.Scenario, defaultOrg?.id],
          });
          navigate(
            getScenarioPracticePath(
              templateSelected.id,
              null,
              false,
              scenarioTypeMap[templateSelected.scenarioTypeId]
            )
          );
        } else {
          closeWizard();
        }
      },
      nextCopy: "Done",
      showProgressTracker: true,
    }),
    [error]
  );

  const customGoalSteps = () => {
    switch (customGoalType) {
      case GoalKind.BinaryGoal:
        return CreateBinaryGoalSteps(
          binaryGoal,
          setBinaryGoal,
          handleCustomGoalCreation,
          goalEditId,
          setDiscardGoalModalOpen,
          goalHooks,
          closeWizard,
          true
        );
      case GoalKind.ScoreGoal:
        return CreateRatedGoalSteps(
          ratedGoal,
          setRatedGoal,
          handleCustomGoalCreation,
          goalEditId,
          setDiscardGoalModalOpen,
          goalHooks,
          closeWizard,
          true
        );
      case GoalKind.CompoundGoal:
        return CreateCompoundGoalSteps(
          compoundGoal,
          setCompoundGoal,
          handleCustomGoalCreation,
          goalEditId,
          setDiscardGoalModalOpen,
          goalHooks,
          closeWizard,
          true
        );
      default:
        if (goalEditId) {
          setGoalEditId(undefined);
        }
        return [];
    }
  };

  const mapStepIdx = (x: WizardStep, i: number) => ({
    ...x,
    stepIdx: i,
    hidePrev: x.hidePrev === true || i === 0,
  });

  const includePromptStep = [ScenarioTypeIdEnum.PITCH].includes(
    templateSelected.scenarioTypeId as ScenarioTypeIdEnum
  );

  const isInterviewScenario =
    scenarioTypeMap[templateSelected.scenarioTypeId] === ScenarioType.Interview;

  const CONVO_SCENARIO_STEPS: Array<WizardStep> = [
    includePromptStep ? promptStep : null,
    isInterviewScenario && isNewScenario ? jobDetailsStep : null,
    scenarioContextStep,
    goalsStep,
    ...customGoalSteps(),
    isInterviewScenario ? interviewQuestionsStep : objectionsStep,
    personaStep,
    wizardType === WizardType.Org && assignHubsStep,
    perScenarioCoachbots?.length > 0 ? assignCoachbotsStep : null,
    reviewStep,
    doneStep,
  ]
    .filter(Boolean)
    .map(mapStepIdx);

  return (
    <>
      <Wizard
        routeLeavingGuardUIProps={{
          title: "Exit scenario creation?",
          body: "Any progress you have made so far will not be saved. Are you sure you want to exit?",
          when: currStepIndex < CONVO_SCENARIO_STEPS.length - 1,
          okText: "Yes, I'm sure",

          onNavigate: () => {
            handleTemplateSelected(null);
          },
        }}
        shouldUseNavDrawerOffset
        steps={CONVO_SCENARIO_STEPS}
        currStepIndex={currStepIndex}
        setCurrStepIndex={setCurrStepIndex}
        loading={loading}
        setLoading={setLoading}
        containerSx={{
          display: "flex",
          flexDirection: "column",

          // TODO: move these to a higher level theme, or even just add to the global theme
          // but to keep things scoped do it here for now
          // defaults for the inputs inside fo the wizard
          fontFamily: "poppins",
          ".MuiFormControl-root > *": {
            fontFamily: "poppins !important",
          },
          ".Mui-disabled, .Mui-disabled > fieldset": {
            borderColor: `${getDynamicColor("dark3", 0.5)} !important`,
            WebkitTextFillColor: getDynamicColor("dark3"),
          },
          ".MuiButton-root.Mui-disabled": {
            color: getDynamicColor("dark4"),
            WebkitTextFillColor: getDynamicColor("dark4"),
          },
          ".MuiTextField-root, fieldset, fieldset &.Mui-disabled": {
            borderRadius: "8px",
            borderColor: getDynamicColor("purple1"),
            borderWidth: "1px !important",
          },
          "*::-webkit-input-placeholder": {
            fontWeight: 400,
            fontFamily: "poppins",
          },
        }}
      />
      <YoodliCtaModal
        ctaBody={{
          title: "This goal has not been saved",
          subtitle: 'Click "Keep editing" and then finish your goal in order to save',
        }}
        open={discardGoalModalOpen}
        theme={YoodliCtaModalTheme.Danger}
        hideCloseButton={true}
        close={() => setDiscardGoalModalOpen(false)}
        buttons={
          {
            primary: {
              text: "Discard changes",
              handler: () => {
                const goalKind = customGoalType;
                handleUpdateCustomGoalType(undefined);
                setCustomGoalState(goalKind);
                setCurrStepIndex((prev) => prev - 1);
              },
            },
            secondary: { text: "Keep editing", handler: () => setDiscardGoalModalOpen(false) },
          } as CtaButtonHandlers
        }
      />
    </>
  );
};
