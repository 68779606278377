import React from "react";

// Components
import { CreateConvoScenarioStepId, GoalHooks } from "components/ConvoScenarios/convoScenarioUtils";
import { WizardStep } from "components/Wizard/WizardTypes";
import { BinaryGoalDescriptionStep } from "./BinaryGoalDescriptionStep";
import { BinaryGoalEvaluationStep } from "./BinaryGoalEvaluationStep";

// Utils
import {
  CreateBinaryGoalRequest,
  CreateCustomGoalRequest,
} from "lib-fullstack/api/scenarioApiTypes";

export const CreateBinaryGoalSteps = (
  binaryGoal: CreateBinaryGoalRequest,
  setBinaryGoal: React.Dispatch<React.SetStateAction<CreateBinaryGoalRequest>>,
  createCustomGoal: (customGoal: CreateCustomGoalRequest) => Promise<string>,
  goalEditId: string,
  setDiscardGoalModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  goalHooks: GoalHooks,
  closeWizard: () => void,
  inScenarioCreation?: boolean
): WizardStep[] => {
  const descStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: inScenarioCreation ? "Customize" : "Start",
    component: (
      <BinaryGoalDescriptionStep
        binaryGoal={binaryGoal}
        setBinaryGoal={setBinaryGoal}
        goalEditId={goalEditId}
      />
    ),
    validate: () => binaryGoal.name.length > 0,
    prev: () => {
      setDiscardGoalModalOpen(true);
    },
    skipPrevIndexUpdate: inScenarioCreation,
    prevCopy: "Discard",
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: inScenarioCreation,
  } as WizardStep;

  const evaluationStep = {
    id: CreateConvoScenarioStepId.GOALS,
    label: "Customize",
    component: (
      <BinaryGoalEvaluationStep
        binaryGoal={binaryGoal}
        setBinaryGoal={setBinaryGoal}
        goalEditId={goalEditId}
        goalHooks={goalHooks}
      />
    ),
    validate: () =>
      binaryGoal.aiDescription.length > 0 &&
      binaryGoal.highScoreDescription.length > 0 &&
      binaryGoal.lowScoreDescription.length > 0,
    skipNextIndexUpdate: true,
    next: async () => {
      await createCustomGoal(binaryGoal);
      if (!inScenarioCreation) {
        closeWizard();
      }
    },
    nextCopy: "Save",
    enterToNavigate: true,
    showProgressTracker: true,
    ignoreStepInProgressTracker: inScenarioCreation,
  } as WizardStep;

  return [descStep, evaluationStep];
};
