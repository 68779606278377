import firebase from "firebase/app";
import { db } from "lib-fullstack";

// Utils
import { getSiteId } from "./LiveSiteDocs";
import {
  getLiveUserDocMain,
  getLiveUserDocPublic,
  getLiveUserDocReadonly,
  updateThisUserDocMain,
  updateThisUserDocPublic,
} from "./LiveUserDocs";
import { currentUserName, currentUserPhotoURL } from "lib-frontend/utils/AccountUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { isWhiteLabel } from "lib-frontend/utils/Utilities";
import { getFirebaseConfig } from "lib-fullstack/client_env";
import { ContextTypeValues, SpeechAnalyticsTypes } from "lib-fullstack/db";
import { onAll, onGet } from "typesaurus";
import { UsagePlanType } from "lib-fullstack/utils/pricingTypes";

export function getLinkToDbForRef(
  ref: firebase.firestore.DocumentReference | db.Ref<unknown>
): string {
  if ("path" in ref) {
    return (
      `https://console.cloud.google.com/firestore/databases/-default-/data/panel` +
      `/${ref.path}?project=${getFirebaseConfig().projectId}`
    );
  } else {
    return (
      `https://console.cloud.google.com/firestore/databases/-default-/data/panel` +
      `/${db.getRefPath(ref)}?project=${getFirebaseConfig().projectId}`
    );
  }
}

export function getLinkToDbOrg(orgId: string, siteId = "yoodli"): string {
  return (
    `https://console.cloud.google.com/firestore/databases/-default-/data/panel` +
    `/sites/${siteId}/orgs/${orgId}?project=${getFirebaseConfig().projectId}`
  );
}

export async function submitDownloadPoodliMobileNotificationJob(): Promise<void> {
  const notif: db.DownloadPoodliMobileNotificationJob = {
    type: db.NotificationTypes.DOWNLOAD_POODLI_MOBILE,
    createdAt: new Date().toISOString(),
    toEmail: firebase.auth().currentUser.email,
  };
  const ref = await db.add(db.notificationJobs(getSiteId()), notif);
  console.log(
    `Created new download poodli mobile notification job. DB link: ${getLinkToDbForRef(ref)}`
  );
}

export async function submitNewCommentNotificationJob(
  commentRef: db.Ref<db.SpeechComment>
): Promise<void> {
  const notif: db.NewCommentNotificationJob = {
    type: db.NotificationTypes.NEW_COMMENT,
    documentPath: db.getRefPath(commentRef),
    createdAt: new Date().toISOString(),
  };
  const ref = await db.add(db.notificationJobs(getSiteId()), notif);
  console.log(`Created new comment notification job. DB link: ${getLinkToDbForRef(ref)}`);
}

export function makeNewComment(
  speakerId: string,
  commentedBy: string,
  parentIds: string[],
  type: db.CommentType,
  value?: string,
  videoTimestamp?: number,
  isQuickActionComment = false,
  commenterName: string = currentUserName(),
  commenterPhotoUrl: string | null = currentUserPhotoURL()
): db.SpeechComment {
  let commentUpdateType;
  if (type === "text") {
    commentUpdateType = parentIds.length ? "NESTED_COMMENT_CREATED" : "COMMENT_CREATED";
  } else {
    commentUpdateType = "BOOKMARK_CREATED";
  }
  const comment: db.SpeechComment = {
    value,
    videoTimestamp,
    commentedBy,
    commenterName,
    commenterPhotoUrl,
    isRead: commentedBy === speakerId,
    commentedDate: new Date().toISOString(),
    lastUpdated: {
      date: new Date().toISOString(),
      type: commentUpdateType,
    },
    parentIds,
    likes: [],
    type: type,
    isQuickActionComment,
  };
  return comment;
}

/**
 * Listen to changes for a given Speech document via the onSnapshot() method
 * The return value is a function that detaches the listener so that event callbacks stop getting called
 */
export const subscribeToSpeechSnapshot = (
  speechId: string,
  userId: string,
  onSnapshotFunction: (arg: db.Doc<db.Speech>) => void
): (() => void) => {
  return onGet(db.userSpeeches([getSiteId(), userId]), speechId, (speechQuery) => {
    onSnapshotFunction(speechQuery);
  });
};

export const subscribeToCalendarSnapshot = (
  userId: string,
  onSnapshotFunction: (arg: db.Doc<db.Calendar>) => void
): (() => void) => {
  return onGet(db.userCalendars([getSiteId(), userId]), "calendar_default", (calendarQuery) => {
    onSnapshotFunction(calendarQuery);
  });
};

export const subscribeToEventsSnapshot = (
  userId: string,
  onSnapshotFunction: (arg: db.Doc<db.CalendarEvent>[]) => void
): (() => void) => {
  return onAll(db.calendarEvents([getSiteId(), userId, "calendar_default"]), (eventsQuery) => {
    onSnapshotFunction(eventsQuery);
  });
};

export const subscribeToAnalyticStatesSnapshot = (
  speechId: string,
  userId: string,
  onSnapshotFunction: (arg: db.Doc<db.AnalyticStates>) => void
): (() => void) => {
  return onGet<db.AnalyticStates>(
    db.speechAnalyticsV2([getSiteId(), userId, SpeechAnalyticsTypes.STATES]),
    speechId,
    (analyticStatesQuery: db.Doc<db.AnalyticStates>) => {
      onSnapshotFunction(analyticStatesQuery);
    }
  );
};

export const determineDailyStreak = (): number => {
  const lastStreakActionDate = getLiveUserDocPublic().lastStreakActionDate;
  const yesterday = new Date();
  const today = new Date();
  yesterday.setDate(today.getDate() - 1);

  // last streak action was > 1 day ago, therefore streak is 0
  if (
    today.toDateString() !== lastStreakActionDate &&
    yesterday.toDateString() !== lastStreakActionDate
  ) {
    // since this doesn't get updated regularly, dailyStreak #s in db will be unreliable
    // if a user hasn't logged in in a while
    updateThisUserDocPublic({ dailyStreak: 0 }).catch(console.error);

    return 0;
  } else {
    return getLiveUserDocPublic().dailyStreak;
  }
};

/** End Daily Streak-Related Queries **/

export const upsetContextTag = async (
  speechDocument: db.Doc<db.Speech>,
  prevContextTagValue: db.ContextTypeValues | undefined,
  newContextTagValue: db.ContextTypeValues,
  userChangeLocation: db.PossibleUserChangeLocations
): Promise<void> => {
  const userId = db.userIdFor(speechDocument);

  // Log to amplitude
  const prevContextTagString = prevContextTagValue ? prevContextTagValue : "na";
  Instrumentation.logContextTagUpdated(
    userChangeLocation,
    prevContextTagString,
    newContextTagValue
  );

  // Updates the tag on the speech if there is one, else create a new tag
  const speechRef = db.ref(db.speechTags([getSiteId(), userId, speechDocument.ref.id]), "tags");
  await db.transaction<db.Doc<db.SpeechTags>, void>(
    ({ get }) => get(speechRef),
    async ({ data, update, set }) => {
      const prevSpeechTags = data;
      const prevContextTags =
        prevSpeechTags?.data?.tags?.length > 0
          ? prevSpeechTags.data.tags.filter((tag) => tag.key === db.TagType.CONTEXT_TYPE)
          : [];

      const tagUpdate: Partial<db.Tag> & { value: db.AllTagValues; lastUpdated: string } = {
        value: newContextTagValue,
        userSet: true,
        userChangedFrom: prevContextTags.length > 0 ? prevContextTags[0].value : null,
        userChangeLocation,
        lastUpdated: new Date().toISOString(),
      };

      let newTag: db.Tag;
      if (prevContextTags.length > 0) {
        newTag = { ...prevContextTags[0], ...tagUpdate };
      } else {
        newTag = {
          ...tagUpdate,
          key: db.TagType.CONTEXT_TYPE,
          version: 1,
          timeFirstSet: "user_set",
        };
      }

      const oldTags =
        prevSpeechTags?.data?.tags?.length > 0
          ? prevSpeechTags.data.tags.filter((tag) => tag.key !== db.TagType.CONTEXT_TYPE)
          : [];
      const newTags = [...oldTags, newTag];

      if (prevContextTags.length === 0) {
        return set<db.SpeechTags>(speechRef, {
          version: 1,
          tags: newTags,
          lastUpdated: new Date().toISOString(),
        });
      } else {
        return update<db.SpeechTags>(speechRef, {
          ...prevSpeechTags?.data,
          tags: newTags,
          lastUpdated: new Date().toISOString(),
        });
      }
    }
  );

  // Updates the tag on the aggregate analytics
  const aggDoc = (
    await db.query<db.AggTags>(db.aggregateAnalytics([getSiteId(), userId]), [
      db.where("type", "==", "tags"),
      db.where("isLatest", "==", true),
    ])
  )[0];
  const aggRef = aggDoc
    ? aggDoc.ref
    : db.ref(db.aggregateAnalytics([getSiteId(), userId]), "tags-latest");
  await db.transaction<db.Doc<db.AggTags>, void>(
    ({ get }) => get(aggRef),
    async ({ data, update, set }) => {
      const prevAggSpeechTags = data;

      const createNewAggTag = (newContextValue: ContextTypeValues) => {
        return {
          recording_type: speechDocument.data.type,
          slug: speechDocument.data.slug,
          context_type: newContextValue,
          speechRecordedDate: speechDocument.data.recordedDate,
          speechTimeS: speechDocument.data.totalTimeS,
          version: 1,
          timestamp: new Date().toISOString(),
        };
      };

      if (prevAggSpeechTags) {
        // Get the tags for the slug.
        const prevSpeechTags = prevAggSpeechTags.data.values.filter(
          (tag) => tag.slug === speechDocument.data.slug
        );
        if (prevSpeechTags.length === 0) {
          prevSpeechTags.push(createNewAggTag(newContextTagValue));
        }

        const prevSpeechTag = prevSpeechTags[0];
        prevSpeechTag.context_type = newContextTagValue;

        const otherTags = prevAggSpeechTags.data.values.filter(
          (tag) => tag.slug !== speechDocument.data.slug
        );
        const newAggTags = [...otherTags, prevSpeechTag];

        return update<db.AggTags>(aggRef, {
          ...prevAggSpeechTags.data,
          values: newAggTags,
          lastUpdated: new Date().toISOString(),
        });
      } else {
        return set<db.AggTags>(aggRef, {
          type: db.AggregateAnalyticEnum.TAGS,
          values: [createNewAggTag(newContextTagValue)],
          lastUpdated: new Date().toISOString(),
          isLatest: true,
          version: 1,
        });
      }
    }
  );
};

export const isPricingEnabled = (): boolean => {
  return !isWhiteLabel();
};

export const shouldRenderPricingGate = (): boolean => {
  const enabled = isPricingEnabled();
  const currentUser = firebase.auth().currentUser;
  const isLowPlan = [UsagePlanType.FREE, UsagePlanType.PRO_V1].includes(
    getLiveUserDocReadonly().usagePlanType as UsagePlanType
  );
  return enabled && currentUser && isLowPlan;
};

// Self Confidence Eval Utils
export async function addSelfConfidenceEval(
  evalResults: db.SelfConfidenceEval
): Promise<db.Ref<db.SelfConfidenceEval>> {
  try {
    await updateThisUserDocMain({
      selfConfidenceEval: {
        ...getLiveUserDocMain().selfConfidenceEval,
        ftuxEvalCompleted: true,
      },
    });
    const newEvalRef = await db.add(
      db.userSelfConfidenceEvals([getSiteId(), firebase.auth().currentUser.uid]),
      evalResults
    );
    Instrumentation.logSelfConfidenceEvalCompleted(evalResults);
    return newEvalRef;
  } catch (e) {
    throw new Error(`Error completing self confidence eval: ${e}`);
  }
}
