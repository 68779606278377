import _ from "lodash";
import React from "react";

// Components
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Button,
  Container,
  Chip,
} from "@mui/material";
import PageContent from "components/PageContent";
import UploadModal from "components/Modals/UploadModal/UploadModal";

// Utils
import { getNewShares } from "./VideoJournalUtils";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { currentUserFirstName } from "lib-frontend/utils/AccountUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { useIsScreenSmallerThanPx, useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { UITestId } from "lib-fullstack/utils/enums";
import { JournalView } from "./VideoListing";

export const VIDEO_GRID_HEADER_HEIGHT = "132px";

type VideoGridHeaderProps = {
  journalView: JournalView;
  handleSwitchJournalView: (val: JournalView) => void;
  setPage: (event: React.ChangeEvent<unknown>, value: number) => void;
  searchText: string;
  setSearchText: (val: string) => void;
  requiredGradingCount?: number;
};

export function VideoGridHeader({
  journalView,
  handleSwitchJournalView,
  setPage,
  searchText,
  setSearchText,
  requiredGradingCount,
}: VideoGridHeaderProps): JSX.Element {
  const isSmallScreen = useIsSmallScreen();
  const isMdOrSmallerScreen = useIsScreenSmallerThanPx(990);
  const { userInCoachOrganization } = React.useContext(UserOrgContext);

  const [showUploadModal, setShowUploadModal] = React.useState(false);

  const badgeCount = getNewShares();

  const handleSearchTextChange = (event) => {
    setPage(event, 0);
    setSearchText(event.target.value);
  };

  const firstName = currentUserFirstName();

  const handleUploadSpeechClick = () => {
    setShowUploadModal(true);
  };

  const closeUploadSpeechModal = () => {
    setShowUploadModal(false);
  };

  return (
    <Box sx={{ backgroundColor: getDynamicColor("light1") }}>
      <PageContent title={(firstName ? `${firstName}'s ` : "") + "Recordings"} piiTitle>
        <Container maxWidth="xl">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            py={2}
            sx={{
              gap: 2,
              position: "relative",
            }}
          >
            <Stack direction="row" gap={{ xs: 1, md: 3 }}>
              <Button
                aria-label="My Yoodlis"
                disableTouchRipple
                disableRipple
                onClick={() => handleSwitchJournalView(JournalView.Library)}
                sx={{
                  fontWeight: 600,
                  color: getDynamicColor(
                    journalView === JournalView.Library ? "primary" : "purple3"
                  ),
                  minHeight: 44,
                  p: 1,
                  borderBottom: `2px solid ${
                    journalView === JournalView.Library ? getDynamicColor("primary") : "transparent"
                  }`,
                  borderRadius: 0,
                  transition: "all 0.3s ease-out",
                }}
              >
                My {userInCoachOrganization ? "Recordings" : "Yoodlis"}
              </Button>

              <Stack direction="row" sx={{ alignItems: "center" }}>
                <Button
                  aria-label={`Shared Yoodlis`}
                  disableTouchRipple
                  disableRipple
                  onClick={() => handleSwitchJournalView(JournalView.SharedWithMe)}
                  sx={{
                    fontWeight: 600,
                    color: getDynamicColor(
                      journalView === JournalView.SharedWithMe ? "primary" : "purple3"
                    ),
                    minHeight: 44,
                    p: 1,
                    borderBottom: `2px solid ${
                      journalView === JournalView.SharedWithMe
                        ? getDynamicColor("primary")
                        : "transparent"
                    }`,
                    borderRadius: 0,
                    transition: "border 0.3s ease-out",
                  }}
                >
                  Shared with me
                </Button>
                {badgeCount > 0 && (
                  <Chip
                    size="small"
                    sx={{
                      height: "20px",
                      cursor: "pointer",
                      "& > .MuiChip-label": { fontSize: "10px" },
                      backgroundColor: getDynamicColor("greenSuccess"),
                    }}
                    color="primary"
                    label={`${badgeCount}${isMdOrSmallerScreen ? "" : " new"}`}
                  />
                )}
              </Stack>
              {(localStorage.getItem("showGradingTab") || requiredGradingCount > 0) && (
                <Stack direction="row" sx={{ alignItems: "center" }}>
                  <Button
                    aria-label={`Yoodlis requiring grading`}
                    data-testid={UITestId.GradingTab}
                    disableTouchRipple
                    disableRipple
                    onClick={() => handleSwitchJournalView(JournalView.Grading)}
                    sx={{
                      fontWeight: 600,
                      color: getDynamicColor(
                        journalView === JournalView.Grading ? "primary" : "purple3"
                      ),
                      minHeight: 44,
                      p: 1,
                      borderBottom: `2px solid ${
                        journalView === JournalView.Grading
                          ? getDynamicColor("primary")
                          : "transparent"
                      }`,
                      borderRadius: 0,
                      transition: "border 0.3s ease-out",
                    }}
                  >
                    Requires grading
                  </Button>
                  {requiredGradingCount > 0 && (
                    <Chip
                      size="small"
                      sx={{
                        height: "20px",
                        cursor: "pointer",
                        "& > .MuiChip-label": { fontSize: "10px" },
                        backgroundColor: getDynamicColor("primary"),
                      }}
                      color="primary"
                      label={`${requiredGradingCount}`}
                    />
                  )}
                </Stack>
              )}
            </Stack>
            {!isSmallScreen && (
              <Stack direction="row" gap={3} alignItems="center">
                {!isSmallScreen && !isToastmasters() && (
                  <Button variant="outlined" onClick={handleUploadSpeechClick}>
                    Upload
                  </Button>
                )}
                <FormControl
                  sx={{ width: isMdOrSmallerScreen ? "14ch" : "25ch", backgroundColor: "white" }}
                  variant="outlined"
                  size="small"
                >
                  <InputLabel htmlFor="overall-input-search" margin="dense">
                    Search
                  </InputLabel>
                  <OutlinedInput
                    id="overall-input-search"
                    value={searchText}
                    onChange={handleSearchTextChange}
                    endAdornment={
                      // search icon turns into 'X' for clearing text when
                      // there is text to be cleared.
                      // Search is instantaneous.
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Search"
                          onClick={() => setSearchText("")}
                          edge="end"
                        >
                          {searchText.length > 0 ? <ClearIcon /> : <SearchIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Search"
                  />
                </FormControl>
              </Stack>
            )}
          </Stack>
          {!isToastmasters() && (
            <UploadModal open={showUploadModal} close={closeUploadSpeechModal} />
          )}
        </Container>
      </PageContent>
    </Box>
  );
}
