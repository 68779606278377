import hash from "string-hash";
import { updateUserDocMain } from "../db/user_docs";
import firebase from "firebase/app";

/**
 * Convert email to a hash string. Removes whitespace and capitalization
 * to help with de-dup'ing. Reasonably collision-free and space-efficient
 * for collections of less than a few thousand emails.
 * E.g., rob@yoodli.ai maps to 169y350
 * @param email
 */
export function emailToHash(email: string): string {
  return hash(email.trim().toLowerCase()).toString(36);
}

export async function addOrUpdateContact(
  siteId: string,
  email: string,
  name?: string,
  userId?: string
): Promise<void> {
  email = email.toLowerCase().trim();
  const contactFields = { contacts: {} };
  const emailHash = emailToHash(email);
  contactFields.contacts[emailHash] = { email };
  if (name) {
    contactFields.contacts[emailHash].name = name;
  }
  if (userId) {
    contactFields.contacts[emailHash].userId = userId;
  }
  return updateUserDocMain(siteId, firebase.auth().currentUser?.uid, contactFields);
}
