import firebase from "firebase/app";

// Utils
import { UseQueryResult, useQuery as useApiQuery } from "@tanstack/react-query";
import { getSpeech, getUserSpeech } from "lib-frontend/modules/AxiosInstance";
import { GetSpeechField, GetSpeechResponse } from "lib-fullstack/api/apiTypes";

export const SPEECH_QUERY_KEY = "speech";

export const useSpeechQuery = (
  speechId: string,
  hasSpeechDocPermissions: boolean,
  isSpeechOwner: boolean,
  isConvoScenario: boolean,
  speechAuthorId?: string
): UseQueryResult<GetSpeechResponse> => {
  const authedUser = firebase.auth()?.currentUser;
  if (isSpeechOwner) {
    const speechQuery = useApiQuery({
      queryKey: [SPEECH_QUERY_KEY, speechId],
      queryFn: () => getSpeech(speechId, { fields: GetSpeechField.PROGRAM }),
      refetchInterval: (query) =>
        query?.state?.data?.program?.pending_score || !query?.state?.data?.score ? 1000 : 0,
      enabled: hasSpeechDocPermissions && isSpeechOwner && !!authedUser && isConvoScenario,
    });
    return speechQuery;
  } else {
    const speechQuery = useApiQuery({
      queryKey: [SPEECH_QUERY_KEY, speechAuthorId, speechId],
      queryFn: () => getUserSpeech(speechAuthorId, speechId, { fields: GetSpeechField.PROGRAM }),
      refetchInterval: (query) =>
        query?.state?.data?.program?.pending_score || !query?.state?.data?.score ? 1000 : 0,
      enabled: hasSpeechDocPermissions && !!speechAuthorId && !!authedUser && isConvoScenario,
    });
    return speechQuery;
  }
};
