import React from "react";

interface IPracticeRecorderUploadContext {
  videoBlobUpload: (speechId: string, videoBlob: Blob, lastVideoBlob: boolean) => void;
}

export const PracticeRecorderUploadContext = React.createContext<IPracticeRecorderUploadContext>({
  videoBlobUpload: (_speechId: string, _videoBlob: Blob, _lastVideoBlob: boolean) => {
    return;
  },
});
