import { db } from "lib-fullstack";
import { useNavigate } from "react-router";

// Components
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Button, Dialog, Stack, Typography, Link } from "@mui/material";

// Utils
import { usePricingUsage } from "hooks";
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { handleDeleteSpeech } from "utils/EditVideoItemUtilities";
import { WebServerInternalPath } from "utils/paths";
import { UsagePlanType } from "lib-fullstack/utils/pricingTypes";

type DeleteYoodliModalProps = {
  open: boolean;
  onClose: () => void;
  dbSpeech: db.Doc<db.Speech>;
};

export default function DeleteYoodliModal({
  open,
  onClose,
  dbSpeech,
}: DeleteYoodliModalProps): JSX.Element {
  const { modalStyles } = useNavDrawerOffset();
  const { usagePlanType } = usePricingUsage();
  const navigate = useNavigate();
  /**
   * Deletes recording. Shows VideoJournal afterwards
   */
  function handleDeleteSpeechClick() {
    handleDeleteSpeech(dbSpeech)
      .then(() => {
        navigate(WebServerInternalPath.LIBRARY);
      })
      .catch(console.error);
  }

  const getSpeechType = () => {
    switch (dbSpeech?.data?.type) {
      case "LIVE":
        return "Practice Session";
      case "UPLOADED":
        return "Upload";
      case "POODLI":
      case "ZOOM_RECALL":
        return "Meeting Report";
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        cursor: "default",
      }}
      PaperProps={{
        sx: {
          ...modalStyles,
          borderRadius: "12px",
          width: "min(600px, 100%)",
          maxHeight: "min(600px, 100%)",
          maxWidth: "unset",
          alignItems: "center",
          position: "relative",
          backgroundColor: getDynamicColor("light1"),
          overflowY: "auto",
          p: 4,
          px: { xs: 3, lg: 4 },
        },
      }}
    >
      <Stack gap={2}>
        <Stack direction="row" gap={1}>
          <WarningAmberRoundedIcon />
          <Typography sx={{ fontFamily: "poppins", fontSize: "16px", fontWeight: 700 }}>
            Delete {getSpeechType()}?
          </Typography>
        </Stack>
        <Typography sx={{ fontFamily: "poppins", fontSize: "16px" }}>
          You'll lose your recording, transcription, and analytics.
        </Typography>
        {usagePlanType === UsagePlanType.FREE && (
          <Typography sx={{ fontFamily: "poppins", fontSize: "16px" }}>
            Deleting a Yoodli will <b>not</b> change your recording quota. To increase your quota,
            consider{" "}
            <Link href={WebServerInternalPath.CHECKOUT} sx={{ textDecoration: "underline" }}>
              upgrading
            </Link>{" "}
            instead.
          </Typography>
        )}
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={{ xs: 2, md: 0 }}
          sx={{ justifyContent: "space-between", pt: 3 }}
        >
          <Button
            onClick={handleDeleteSpeechClick}
            variant="outlined"
            sx={{
              width: { xs: "100%", md: "unset" },
              fontSize: "16px",
              fontWeight: 700,
              letterSpacing: "0.4px",
              border: `2px solid ${getDynamicColor("primary")}`,
            }}
          >
            Delete {getSpeechType()}
          </Button>
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              width: { xs: "100%", md: "unset" },
              fontSize: "16px",
              fontWeight: 700,
              letterSpacing: "0.4px",
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
