import firebase from "firebase/app";
import moment from "moment-timezone";
import React from "react";
import { useLocation } from "react-router-dom";

// Components
import { Box, Stack } from "@mui/material";
import YoodliNotificationBanner from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";

// Utils
import { DRAWER_MENU_WIDTH, SHOULD_REFRESH_FIREBASE_ID_TOKEN } from "../utils/Constants";
import { FeatureUpdateModal } from "./FeatureUpdate/FeatureUpdateModal";
import { GlobalBanner } from "./GlobalBanner";
import UploadModal from "./Modals/UploadModal/UploadModal";
import StandardAppBar from "./Nav/AppBars/StandardAppBar/StandardAppBar";
import NavigationDrawer from "./Nav/NavigationDrawer/NavigationDrawer";
import PricingModal from "./Pricing/PricingModal";
import { useFeatureUpdatesContext } from "contexts/FeatureUpdatesContext";
import { usePricingUsage } from "lib-frontend/hooks";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { getLiveUserDocMain, updateThisUserDocMain } from "lib-frontend/utils/LiveUserDocs";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { getEnabledFlag, getStringPayload } from "lib-frontend/utils/unleash";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { FeatureUpdateVisibility } from "lib-fullstack/db";
import { WEBCLIENT_TOP_NAVBAR_HEIGHT } from "lib-frontend/utils/constants";
import {
  LandingPageExternalPath,
  WebServerExternalPath,
  getLandingPageExternalUrl,
} from "lib-fullstack/utils/paths";
import { UsagePlanType } from "lib-fullstack/utils/pricingTypes";
import { SettingsQueryParams } from "lib-fullstack/utils/queryParams";
import { WebServerInternalPath } from "utils/paths";

// Any pathname CONTAINING one of these strings as a substring will be considered
// a match, and will have the navbar or sidebar removed.
// USE CASE: A page needs its own top AppBar. Add the path to this array,
// and add the page-specific appbar on said page.
const omitTopAppbarPaths = [
  WebServerExternalPath.SHARE,
  WebServerInternalPath.SUMMARY,
  WebServerInternalPath.ONBOARDING,
  WebServerInternalPath.RECORD_SPEECH_DEPRECATED,
  WebServerInternalPath.IMPROMPTU_PROMPT_DEPRECATED,
  WebServerInternalPath.EXERCISES_SPIN_A_YARN,
  WebServerInternalPath.EXERCISES_METAPHOR_MANIA,
  WebServerInternalPath.EXERCISES_NO_FILLER,
  LandingPageExternalPath.ABOUT,
  WebServerInternalPath.VERIFY_EMAIL,
  WebServerExternalPath.SIGN_IN,
  WebServerExternalPath.SIGN_UP,
  WebServerInternalPath.RESTRICTED,
  WebServerInternalPath.SETUP_DESKTOP_APP,
  WebServerExternalPath.POODLI_CALENDAR_CONNECT,
  WebServerExternalPath.DOWNLOAD_POODLI_DIRECT,
  WebServerInternalPath.FORGOT_PASSWORD,
];

export const omitSidebarPaths = [
  WebServerInternalPath.ONBOARDING,
  LandingPageExternalPath.COURSES,
  WebServerInternalPath.RESTRICTED,
  LandingPageExternalPath.ABOUT,
  WebServerInternalPath.VERIFY_EMAIL,
  WebServerExternalPath.SIGN_IN,
  WebServerExternalPath.SIGN_UP,
  WebServerInternalPath.SETUP_DESKTOP_APP,
  WebServerExternalPath.POODLI_CALENDAR_CONNECT,
];

const sideBarDefaultClosedPaths = [
  WebServerExternalPath.SHARE,
  WebServerInternalPath.SUMMARY,
  WebServerInternalPath.RECORD_SPEECH_DEPRECATED,
  WebServerInternalPath.PRACTICE_INTERVIEW_DEPRECATED,
  WebServerExternalPath.PRACTICE_INTERVIEW,
  WebServerExternalPath.PRACTICE_SPEECH,
  WebServerExternalPath.PRACTICE_CONVERSATION,
];

const privacyPolicyVisiblePaths = [
  WebServerExternalPath.HOME_LOGGED_IN,
  WebServerInternalPath.LIBRARY,
  WebServerExternalPath.SUPPORT,
  LandingPageExternalPath.CONTACT_SALES,
];

const WEB_SERVER_PATHS = [].concat(
  Object.values(WebServerInternalPath),
  Object.values(WebServerExternalPath)
);

export type LayoutProps = { children: React.ReactNode };

/**
 *
 * @param {ReactNode} children - React router Switch
 * @returns Functional Component with Navbar and sidebar injected.
 * This ensures navbars don't rerender when the page changes.
 */
export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({ children }) => {
  const isSmallScreen = useIsSmallScreen();
  const { pathname, search } = useLocation();
  const userDocMain = getLiveUserDocMain();
  const globalBannerMessage =
    getEnabledFlag("app-banner-message") && getStringPayload("app-banner-message");
  const isSpeechSummary =
    pathname.includes(WebServerInternalPath.SUMMARY) ||
    pathname.includes(WebServerExternalPath.SHARE);
  const { usagePlanType } = usePricingUsage();
  const siteConfig = getStaticFullSiteConf();
  const siteFaqFooterCopy = siteConfig?.featureDiffs?.faqDocumentation;
  const layoutFooterEl = (): React.ReactElement => {
    return (
      <>
        <span>{`© Yoodli 2024 | `}</span>
        <a
          target="_blank"
          href={getLandingPageExternalUrl(getClientEnvConfig(), LandingPageExternalPath.PRIVACY)}
          rel="noreferrer noopener"
        >
          Policy
        </a>

        {siteFaqFooterCopy && (
          <>
            <span>{` | `}</span>,
            <a
              target="_blank"
              href="https://www.toastmasters.org/footer/faq/yoodli"
              rel="noreferrer noopener"
            >
              FAQ
            </a>
          </>
        )}
      </>
    );
  };

  const { featureUpdate, featureUpdateVisibility, handleCloseFeatureUpdate } =
    useFeatureUpdatesContext();

  // #region State
  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(isSmallScreen);
  const [accountNavExpanded, setAccountNavExpanded] = React.useState(false);
  const [showTopAppBar, setShowTopAppBar] = React.useState<boolean>(true);
  const [showSidebar, setShowSidebar] = React.useState<boolean>(true);
  const [showPrivacyPolicyInFooter, setShowPrivacyPolicyInFooter] = React.useState<boolean>(false);
  const [showPricingModal, setShowPricingModal] = React.useState<boolean>(false);
  const [showUploadModal, setShowUploadModal] = React.useState(false);

  // #endregion State

  // #region Effects

  // backfill homeTimezone it it doesn't already exist (06/26/2023)
  React.useEffect(() => {
    if (firebase.auth()?.currentUser && !userDocMain?.homeTimezone) {
      updateThisUserDocMain({
        homeTimezone: moment.tz.guess(),
      }).catch((e) => console.warn("Failed to update user's home timezone", e));
    }
  }, [userDocMain]);

  // refresh firebase user id token on first mount if localStorage refresh flag is set (set on VerifyEmail mount)
  React.useEffect(() => {
    if (localStorage.getItem(SHOULD_REFRESH_FIREBASE_ID_TOKEN) === "true") {
      firebase
        .auth()
        ?.currentUser?.getIdTokenResult()
        .then(async (token) => {
          // if email is not verified, refresh token
          if (!token.claims?.email_verified) {
            await firebase.auth()?.currentUser?.getIdTokenResult(true);
          }
          localStorage.removeItem(SHOULD_REFRESH_FIREBASE_ID_TOKEN);
        })
        .catch((er) => {
          console.error("Error refreshing firebase user id token on Layout.tsx mount", er);
        });
    }
  }, []);

  // don't add sidebarOpen to the deps array, or else it will cause an infinite loop.
  // i only want the current value of sidebarOpen when the other deps change
  // also handles closing the sidebar (if open) when shrinking screen size from large to small
  React.useEffect(() => {
    if (isSmallScreen && sidebarOpen) {
      setSidebarOpen(false);
    }
  }, [pathname, isSmallScreen]);

  // effect runs whenever pathname changes
  React.useLayoutEffect(() => {
    // always start modal closed after path changes
    setShowUploadModal(false);

    // determine if i should open the pricing modal or nbot based on qp
    const shouldOpenPricingModal =
      new URLSearchParams(search).has(SettingsQueryParams.PRICING_MODAL_OPEN) &&
      usagePlanType !== UsagePlanType.ENTERPRISE;
    if (shouldOpenPricingModal) {
      setShowPricingModal(true);
    }

    // TODO: Create function for matching paths.
    if (WEB_SERVER_PATHS.some((path) => pathname.includes(path))) {
      if (omitTopAppbarPaths.some((v) => pathname.includes(v))) {
        setShowTopAppBar(false);
      } else {
        setShowTopAppBar(true);
      }

      if (omitSidebarPaths.some((v) => pathname.includes(v))) {
        setShowSidebar(false);
      } else {
        setShowSidebar(true);

        if (isSmallScreen || sideBarDefaultClosedPaths.some((v) => pathname.includes(v))) {
          setAccountNavExpanded(false);
          setSidebarOpen(false);
        } else {
          setSidebarOpen(userDocMain.navigationBarDefaultExpanded);
        }
      }
      // hide the sidebar if user is not logged in or logged in but not verified
      if (!firebase.auth()?.currentUser) {
        setShowSidebar(false);
      }
    }
    // unknown path, hide everything
    else {
      setShowTopAppBar(false);
      setShowSidebar(false);
    }

    // determine whether to show or hide the privacy policy footer
    if (privacyPolicyVisiblePaths.some((v) => pathname.includes(v))) {
      setShowPrivacyPolicyInFooter(true);
    } else {
      setShowPrivacyPolicyInFooter(false);
    }
  }, [pathname]);

  // #endregion Effects

  // #region Handlers

  const handleToggleAccountNavExpanded = (expanded = !accountNavExpanded) => {
    setAccountNavExpanded(expanded);
  };

  const handleUploadSpeechClick = () => {
    setShowUploadModal(true);
  };

  const closeUploadSpeechModal = () => {
    setShowUploadModal(false);
  };
  // #endregion Handlers

  return (
    <>
      {showTopAppBar && isSmallScreen && (
        <StandardAppBar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          handleUploadSpeechClick={handleUploadSpeechClick}
        />
      )}

      <FeatureUpdateModal
        featureUpdate={featureUpdate}
        open={featureUpdateVisibility === FeatureUpdateVisibility.Popup}
        onClose={handleCloseFeatureUpdate}
      />

      {globalBannerMessage && !isSpeechSummary && !isSmallScreen && (
        <GlobalBanner message={globalBannerMessage} />
      )}
      <YoodliNotificationBanner />

      <UploadModal open={showUploadModal} close={closeUploadSpeechModal} />

      <PricingModal open={showPricingModal} onClose={() => setShowPricingModal(false)} />
      <Stack direction="row">
        {showSidebar && (
          <NavigationDrawer
            open={sidebarOpen}
            setOpen={setSidebarOpen}
            handleUploadSpeechClick={handleUploadSpeechClick}
            accountNavExpanded={accountNavExpanded}
            handleToggleAccountNavExpanded={handleToggleAccountNavExpanded}
            openPricingModal={() => setShowPricingModal(true)}
          />
        )}

        <Box
          className="page-container"
          sx={{
            position: "relative",
            backgroundColor: { xs: getDynamicColor("light1"), md: getDynamicColor("dark1") },
            minHeight: { xs: `calc(100svh - ${WEBCLIENT_TOP_NAVBAR_HEIGHT})`, md: "100svh" },
            top: { xs: WEBCLIENT_TOP_NAVBAR_HEIGHT, md: 0 },
            flexGrow: 1,
            // TODO 2024-06-13 @danoodli: we shouldnt have to manage the width here, but other elements/pages break without it, so we need to keep it for now
            width: `calc(100% - ${
              sidebarOpen ? DRAWER_MENU_WIDTH.OPEN : DRAWER_MENU_WIDTH.CLOSED
            })`,
          }}
        >
          {children}
          {showPrivacyPolicyInFooter && (
            <footer
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                position: "relative",
                top: -16,
                backgroundColor: "transparent",
                height: 0,
              }}
            >
              <Box
                sx={{
                  color: getDynamicColor("dark4"),
                  fontSize: "10px",
                }}
              >
                {layoutFooterEl()}
              </Box>
            </footer>
          )}
        </Box>
      </Stack>
    </>
  );
};

export default Layout;
